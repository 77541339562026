import {
  Direction,
  api,
  useMutation,
  useQueryClient,
  useResourceListApi,
} from "@pharmupp/p3-front-commons"

export const useAppointmentApi = ({
  labId,
  sortDateDirection,
  size,
}: { labId: string; sortDateDirection: Direction; size: number }) => {
  const endpoint = `/api/laboratories/adherent/${labId}/appointments`
  const queryKey = ["laboratories", labId, "adherent", "appointments"]

  const queryClient = useQueryClient()

  // GET
  const { list } = useResourceListApi<ApiLabAppointment, LabAppointment>({
    queryKey,
    resourceEndpoint: endpoint,
    defaultColumnSort: "dateTime",
    defaultColumnDirection: sortDateDirection,
    rowsPerPage: size,
    select: (rawAppointments) =>
      rawAppointments.map((rawApointment) => ({
        ...rawApointment,
        day: new Date(rawApointment.dateTime).toLocaleDateString("fr-FR", {
          day: "2-digit",
        }),
        month: new Date(rawApointment.dateTime).toLocaleDateString("fr-FR", {
          month: "short",
        }),
      })),
  })

  // UPDATE
  const { mutate: createAppointment } = useMutation({
    mutationFn: (newAppointment: ApiNewLabAppointment) =>
      api.post<ApiLabAppointment>(endpoint, {
        body: JSON.stringify(newAppointment),
      }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey })
    },
  })

  return { appointments: list, createAppointment }
}

export type ApiLabAppointment = {
  id: number
  title: string
  dateTime: string // DateTime ISO without TZ (LocalDateTime)
}
type LabAppointment = ApiLabAppointment & {
  day: string
  month: string
}
type ApiNewLabAppointment = Omit<ApiLabAppointment, "id">
