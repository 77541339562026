import {
  Box,
  Card,
  CardActionArea,
  Link,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material"
import { Price } from "@pharmupp/p3-front-commons"
import { openInvoice } from "../../Offers"
import {
  ApiOrder,
  Laboratory,
  useOffersOrdersReferentialApi,
} from "../AdherentOrdersList"
import { LaboratoryCell } from "../AdherentOrdersList/tabs/commons"
import { useAdherentSdavChallengeItemOrdersApi } from "./useAdherentSdavChallengeItemOrdersApi"

export const AdherentSdavOrdersList = ({
  itemId,
  nbItems,
  year,
}: {
  itemId: number
  nbItems: number
  year: number
}) => {
  const { orders, isLoading } = useAdherentSdavChallengeItemOrdersApi({
    itemId,
    year,
  })
  const { referential, isLoading: isLoadingReferential } =
    useOffersOrdersReferentialApi()

  return (
    <Stack spacing={1}>
      {isLoading || isLoadingReferential
        ? Array.from({ length: nbItems }).map((_, index) => (
            <OrderLineSkeleton key={`placeholder_${index}`} />
          ))
        : orders?.map((order) => (
            <OrderLine
              key={order.orderNumber}
              date={order.orderDate}
              orderNumber={order.orderNumber}
              laboratory={
                referential && order.laboratoryId
                  ? referential?.laboratoriesRecord[order.laboratoryId]
                  : null
              }
              offer={order.offer}
              total={order.total}
              viewLink={order.viewLink}
            />
          ))}
    </Stack>
  )
}

const OrderLine = ({
  date,
  orderNumber,
  viewLink,
  laboratory,
  offer,
  total,
}: {
  date?: string | null
  laboratory: Laboratory | null
  total: number
} & Pick<ApiOrder, "offer" | "orderNumber" | "viewLink">) => {
  const { typography } = useTheme()

  return (
    <Card component={Box}>
      <CardActionArea onClick={() => openInvoice(viewLink)}>
        <Box
          px={3}
          py={2}
          display="grid"
          gridTemplateColumns="1fr 1.5fr 5fr 1fr"
          gap={2}
          alignItems="center"
        >
          <Stack fontSize={typography.pxToRem(12)}>
            <Typography fontSize="inherit" color="common.darkBlue">
              {!!date &&
                new Date(date).toLocaleDateString("fr-FR", {
                  day: "2-digit",
                  month: "long",
                })}
            </Typography>
            <Link underline="always" sx={{ fontSize: "inherit" }}>
              N°: {orderNumber}
            </Link>
          </Stack>
          <Box
            width="115px"
            height="50px"
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
          >
            <LaboratoryCell laboratory={laboratory} />
          </Box>
          <Stack>
            <Typography fontSize={typography.pxToRem(12)} color="common.darkBlue">
              {offer.title}
            </Typography>
            <Typography fontSize={typography.pxToRem(11)} color="grey.dark">
              {offer.cerpRef}
            </Typography>
          </Stack>
          <Typography
            fontSize={typography.pxToRem(12)}
            fontWeight="bold"
            color="common.darkBlue"
            justifySelf="flex-end"
          >
            <Price value={total} />
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  )
}

const OrderLineSkeleton = () => {
  return (
    <Card
      component={Box}
      px={3}
      py={2}
      display="grid"
      gridTemplateColumns="1fr 1.5fr 5fr 1fr"
      gap={3}
      alignItems="center"
    >
      <Stack>
        <Skeleton sx={{ fontSize: 12 }} width="60%" />
        <Skeleton sx={{ fontSize: 12 }} width="70%" />
      </Stack>
      <Skeleton variant="circular" height="50px" width="50px" />
      <Stack spacing={0.5}>
        <Skeleton width="90%" />
        <Skeleton width="30%" />
      </Stack>
      <Skeleton width="50%" sx={{ justifySelf: "end" }} />
    </Card>
  )
}
