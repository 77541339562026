import {
  api,
  formatDateWithDots,
  useQuery,
  useRoleConf,
} from "@pharmupp/p3-front-commons"
import { apiRoleConf } from "./api.roleConf"

export const useBonusOperationDisplayApi = ({
  operationId,
}: {
  operationId?: string
}) => {
  const { getDetailsEndpoint } = useRoleConf(apiRoleConf)

  const { data: operation, isLoading: loading } = useQuery<ApiBonusOperationDisplay>(
    {
      queryKey: ["operation", "bonus", operationId],
      queryFn: () => api.get(getDetailsEndpoint(operationId!)),
      select: (data) => ({
        ...data,
        validityStartDate: formatDateWithDots(data.validityStartDate),
        validityEndDate: formatDateWithDots(data.validityEndDate),
      }),
    },
  )

  return {
    operation,
    loading: loading || !operation,
  }
}

export interface ApiBonusOperationDisplay {
  bonusOperationId: number
  title: string
  status: RequestStatus
  conditions: string
  descriptionProofDocuments: string
  isProofRequired: boolean
  minBonus: number
  maxBonus: number
  bonus?: number
  justifiedAmount: number | null
  numProofDocuments: number
  /** YYYY-MM-DD */
  validityStartDate: string
  /** YYYY-MM-DD */
  validityEndDate: string
  tag?: { id: number; name: string }
}

export type RequestStatus = "ACTIVE" | "SUBMITTED" | "ACTIVABLE"
