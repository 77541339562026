import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  dialogClasses,
  styled,
} from "@mui/material"
import { FC } from "react"

export interface ModalProps extends MuiDialogProps {
  loading?: boolean
}

/**
 * Modals allows more customization on content than Dialogs (lower level than dialogs)
 */
export const Modal: FC<React.PropsWithChildren<ModalProps>> = ({
  open,
  loading = false,
  sx,
  children,
  ...props
}) => (
  <ModalContainer {...props} open={open} PaperProps={{ elevation: 1, sx }}>
    {loading ? <CircularProgress /> : children}
  </ModalContainer>
)

const ModalContainer = styled(MuiDialog)(({ theme: { spacing } }) => ({
  [`.${dialogClasses.paper}`]: {
    padding: `${spacing(6)} ${spacing(4)}`,
  },
}))

export const ModalTitle = styled(DialogTitle)(
  ({ theme: { palette, typography } }) => ({
    fontSize: typography.pxToRem(30),
    fontWeight: 600,
    color: palette.grey.dark,
    textAlign: "center",
  }),
)

export const ModalContent = styled(DialogContent)(({ theme }) => ({
  color: theme.palette.grey.dark,
}))

export const ModalContentText = styled(DialogContentText)(
  ({ theme: { palette, typography } }) => ({
    fontSize: typography.pxToRem(20),
    fontWeight: "normal",
    color: palette.grey.dark,
    textAlign: "center",
  }),
)

export const ModalDialogActions = styled(DialogActions)(() => ({
  justifyContent: "center",
}))

export interface ModalBasicActionsProps {
  cancelAction?: () => void
  cancelText?: string
  validateAction: () => void
  validateText?: string
  validateEnabled?: boolean
}
export const ModalBasicActions = ({
  cancelAction,
  cancelText = "Annuler",
  validateAction,
  validateText = "Valider",
  validateEnabled = true,
}: ModalBasicActionsProps) => (
  <ModalDialogActions>
    {!!cancelAction && <Button onClick={cancelAction}>{cancelText}</Button>}
    <Button onClick={validateAction} variant="contained" disabled={!validateEnabled}>
      {validateText}
    </Button>
  </ModalDialogActions>
)
