type RequestInterceptor = (
  options: RequestInit,
) => Promise<RequestInit> | RequestInit

class InterceptorHelper {
  interceptors: RequestInterceptor[] = []

  add(interceptor: RequestInterceptor) {
    this.interceptors.push(interceptor)
  }

  async run(options: RequestInit) {
    for (const interceptor of this.interceptors) {
      // biome-ignore lint/style/noParameterAssign: ⚠️ Write a test for this, the last interceptor seems to be the only used ?
      options = await interceptor(options)
    }
    return options
  }
}

export const fetchInterceptor = new InterceptorHelper()
