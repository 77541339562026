import { CssBaseline, ThemeProvider } from "@mui/material"
import {
  AppContextProvider,
  DialogProvider,
  FeatureFlagsProvider,
  QueryClient,
  QueryClientProvider,
  ROLES,
  Role,
  ToasterProvider,
  useSso,
} from "@pharmupp/p3-front-commons"
import * as P3Themes from "@pharmupp/p3-themes"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

import Router from "./Router"
import { globalStyles } from "./components/GlobalStyles"

const getThemeFromRoles = (roles: Role[] = []) => {
  switch (true) {
    case roles.includes(ROLES.ADMIN) || roles.includes(ROLES.ADHERENT_ADVISOR):
      return P3Themes.admin
    default:
      return P3Themes.adherent
  }
}

const queryClient = new QueryClient()

export default function App() {
  const { sso } = useSso({
    ...window.KEYCLOAK_CONF,
    // Mock auth
    isMocked: import.meta.env.VITE_MOCKED === "true",
    ...("user-mock" in window.localStorage && {
      mockConf: {
        tokenParsed: JSON.parse(window.localStorage.getItem("user-mock")!),
      },
    }),
  })
  const roles = sso?.getRoles?.()
  const theme = getThemeFromRoles(roles)

  if (!sso) return null

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {globalStyles}
        <FeatureFlagsProvider>
          <AppContextProvider sso={sso}>
            <QueryClientProvider client={queryClient}>
              <ToasterProvider>
                <DialogProvider>
                  <Router />

                  {!import.meta.env.IS_LADLE && !import.meta.env.VITE_MOCKED && (
                    <ReactQueryDevtools initialIsOpen={false} />
                  )}
                </DialogProvider>
              </ToasterProvider>
            </QueryClientProvider>
          </AppContextProvider>
        </FeatureFlagsProvider>
      </ThemeProvider>
    </div>
  )
}
