import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Button, Stack, Typography, styled } from "@mui/material"
import {
  FormPictureDropZone,
  FormTextField,
  LoadingButton,
  Modal,
  useToaster,
} from "@pharmupp/p3-front-commons"
import { ReactNode } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { AmmProofDocumentCount, ProofDocumentCount } from "../commons"
import { FormValues, schema } from "./schema"
import { useJustificationApi } from "./useJustificationApi"

type JustifyModalProps = {
  open?: boolean
  onClose: () => void
  onSuccess: () => void
  operationId: string
  adherentId?: string
  headerInfos?: ReactNode
  showReasonInput?: boolean
} & (
  | {
      loading: true
      operation?: never
    }
  | {
      loading: false
      operation: {
        name: string
        minFiles: number
        maxFiles: number
        nbProofDocuments: number
        nbAmmProducts: number
      }
    }
)

export const JustifyModal = ({
  open = false,
  onClose,
  onSuccess,
  operationId,
  adherentId,
  loading = false,
  headerInfos,
  showReasonInput = false,
  operation,
}: JustifyModalProps) => {
  const toaster = useToaster()

  const { sendDocuments, isLoading: isSending } = useJustificationApi({
    operationId,
    adherentId,
  })

  const formMethods = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    context: {
      minFiles: operation?.minFiles,
      maxFiles: operation?.maxFiles,
    },
  })

  return (
    <Modal
      open={open}
      onClose={onClose}
      loading={loading}
      sx={{ minWidth: "720px" }}
    >
      {operation && (
        <>
          <Stack gap={1}>
            <Title mb={0.5}>{operation.name}</Title>

            {!!headerInfos && <Box>{headerInfos}</Box>}

            <Box display="flex" gap={2} mb={2}>
              <ProofDocumentCount count={operation.nbProofDocuments} size="small" />
              {!!operation.nbAmmProducts && (
                <AmmProofDocumentCount
                  count={operation.nbAmmProducts}
                  size="small"
                />
              )}
            </Box>
          </Stack>

          <form
            onSubmit={formMethods.handleSubmit(
              (formValues) => {
                sendDocuments(formValues)
                  .then(() => {
                    onSuccess()
                    toaster.success("Les justificatifs ont bien été sauvegardés")
                  })
                  .catch(() =>
                    toaster.error("Erreur lors de la sauvegarde des justificatifs"),
                  )
              },
              (errors) => {
                console.error({
                  validation: errors,
                  values: formMethods.getValues(),
                })
              },
            )}
          >
            <FormProvider {...formMethods}>
              <FormPictureDropZone
                name="documents"
                label="Sélectionnez vos justificatifs"
                maxFilesCount={operation.maxFiles}
              />

              {!!showReasonInput && (
                <>
                  <Typography fontWeight="bold" color="grey.dark">
                    Transmettre la raison
                  </Typography>
                  <FormTextField<FormValues>
                    name="justificationReason"
                    placeholder="Ajouter un commentaire"
                    multiline
                    rows={4}
                    fullWidth
                  />
                </>
              )}
            </FormProvider>

            <Box display="flex" justifyContent="center" gap={2} mt={4}>
              <Button variant="text" onClick={() => onClose()}>
                Annuler
              </Button>
              <LoadingButton variant="contained" type="submit" loading={isSending}>
                Valider
              </LoadingButton>
            </Box>
          </form>
        </>
      )}
    </Modal>
  )
}

const Title = styled(Typography)(({ theme }) => ({
  lineHeight: theme.typography.pxToRem(22),
  fontSize: theme.typography.pxToRem(20),
  fontWeight: 600,
  color: theme.palette.common.darkBlue,
}))
