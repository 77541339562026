export const EuroBifton = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="30">
    <g id="Fiche-labo" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g
        id="Catalogues---Détail-laboratoire-Copy-7"
        fill="#8C20B1"
        fillRule="nonzero"
        transform="translate(-565 -567)"
      >
        <g id="Group-5" transform="translate(458 548)">
          <g id="Group-8" transform="translate(67.663 19)">
            <g id="Group" transform="translate(39.337)">
              <path
                id="Shape"
                d="M18.95.001c-.234.012-.463.11-.634.27l-2.328 2.071L13.66.272a1.032 1.032 0 0 0-1.33 0l-2.328 2.07L7.673.272a1.032 1.032 0 0 0-1.33 0l-2.328 2.07L1.687.272a1.01 1.01 0 0 0-.738-.26C.419.048-.027.574.024 1.104v27.678c-.097.416.107.882.478 1.093.37.211.877.15 1.185-.146l2.328-2.07 2.328 2.07c.362.324.969.324 1.33 0l2.329-2.07 2.328 2.07c.362.324.968.324 1.33 0l2.328-2.07 2.328 2.07c.306.29.807.35 1.175.146.367-.205.572-.66.488-1.072V1.22c.136-.6-.415-1.252-1.029-1.218ZM7.008 2.353l2.328 2.07c.362.324.969.324 1.33 0l2.329-2.07 2.328 2.07c.362.324.969.324 1.33 0l1.33-1.186v23.527l-1.33-1.186a1.032 1.032 0 0 0-1.33 0l-2.328 2.07-2.328-2.07a1.032 1.032 0 0 0-1.33 0l-2.329 2.07-2.328-2.07c-.485-.348-.973-.363-1.33 0l-1.33 1.186V3.237l1.33 1.187c.362.323.968.323 1.33 0l2.328-2.071Zm3.98 6.67c-2.468 0-4.602 1.517-5.518 3.662h-.81a.998.998 0 0 0 0 1.998h.353c-.007.112-.02.22-.02.333 0 .225.016.448.04.666H4.66a.998.998 0 0 0 0 1.998h.966c.986 1.97 3.022 3.33 5.363 3.33 1.902 0 3.273-.883 4.355-1.967.407-.361.316-1.186.042-1.457a.985.985 0 0 0-1.456.052c-.913.915-1.581 1.374-2.94 1.374a3.982 3.982 0 0 1-2.984-1.332h3.305a.998.998 0 0 0 0-1.998H7.05a4.094 4.094 0 0 1-.062-.666c0-.114.011-.221.02-.333h4.303a.998.998 0 0 0 0-1.998H7.746a3.98 3.98 0 0 1 3.243-1.664c1.36 0 2.028.448 2.941 1.363.358.41 1.07.435 1.455.052.386-.384.297-1.15-.041-1.457-1.082-1.084-2.453-1.956-4.355-1.956Z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
