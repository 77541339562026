export enum SolutionRubric {
  PHARMAGILE = "PHARMAGILE",
  SERVICE = "SERVICE",
}

export const rubricOptions = [
  { label: "Solution PharmAgile", value: SolutionRubric.PHARMAGILE },
  { label: "Service", value: SolutionRubric.SERVICE },
]

export enum PriceType {
  FIXED = "FIXED",
  STARTING_FROM = "STARTING_FROM",
  INCLUDED = "INCLUDED",
  ON_DEMAND = "ON_DEMAND",
}

export const PriceMeta: Record<PriceType, { label: string }> = {
  [PriceType.FIXED]: { label: "" },
  [PriceType.STARTING_FROM]: { label: "À partir de" },
  [PriceType.INCLUDED]: { label: "Inclus" },
  [PriceType.ON_DEMAND]: { label: "Prix sur demande" },
}

export type SolutionType = "ENTREPRENEUR" | "SALES_POINT" | "PATIENT_HEALTH"

export interface ApiSummary {
  id: number
  name: string
  subscribersCount: number
}
