import {
  Box,
  Divider,
  Stack,
  StackProps,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import { NumberFormat } from "@pharmupp/p3-front-commons"
import { Children, ReactNode } from "react"
import { Check, MoreHorizontal, X } from "react-feather"

interface HistoryLayoutProps {
  title?: string
  children?: ReactNode[]
}

export const HistoryLayout = ({
  title = "Historique des points cumulés",
  children,
}: HistoryLayoutProps) => {
  if (!children || Children.count(children) === 0) return null

  return (
    <Box>
      <HistoryTitle mt={8} mb={1}>
        {title}
      </HistoryTitle>

      <HistoryBox divider={<Divider />}>{children}</HistoryBox>
    </Box>
  )
}

const HistoryTitle = styled(Typography)(({ theme: { palette, typography } }) => ({
  fontSize: typography.pxToRem(14),
  textAlign: "center",
  fontWeight: 500,
  color: palette.grey.light,
  textTransform: "uppercase",
}))

const HistoryBox = styled(Stack)(({ theme: { palette } }) => ({
  borderTop: `1px solid ${palette.grey.extraLight}`,
  borderBottom: `1px solid ${palette.grey.extraLight}`,
}))

export interface HistoryLineProps {
  date: string
  title: string
  bonus: number
}
export const HistoryLine = ({ date, title, bonus }: HistoryLineProps) => (
  <HistoryLineBox>
    {/* DATE */}
    <HistoryLineDate date={date} />

    {/* TITLE */}
    <HistoryLineTitle title={title} bonus={bonus} />

    {/* BONUS */}
    <HistoryLineBonus bonus={bonus} />
  </HistoryLineBox>
)

export const HistoryLineBox = ({
  children,
  ...props
}: { children: ReactNode } & StackProps) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    color="grey.dark"
    py={2}
    {...props}
  >
    {children}
  </Stack>
)

export function HistoryLineBonus({
  bonus,
  isPending,
  nbItemsRelative,
  nbItemsRelativeGoal,
}: {
  bonus: number
  isPending?: boolean
  nbItemsRelative?: number
  nbItemsRelativeGoal?: number
}) {
  const { typography, palette } = useTheme()

  // const
  return (
    <Typography
      fontSize={typography.pxToRem(14)}
      fontWeight="bold"
      color={
        isPending
          ? palette.common.orange
          : bonus >= 0
            ? palette.loyalty.main
            : palette.error.main
      }
    >
      {isPending ? (
        `En cours ${nbItemsRelative}/${nbItemsRelativeGoal}`
      ) : (
        <NumberFormat
          prefix={bonus >= 0 ? "+ " : "- "}
          suffix=" pts"
          value={bonus}
        />
      )}
    </Typography>
  )
}

export function HistoryLineDate({ date }: { date: string }) {
  return (
    <Typography fontSize={(theme) => theme.typography.pxToRem(14)}>
      {new Date(date).toLocaleDateString("fr-FR", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      })}
    </Typography>
  )
}

export function HistoryLineTitle({
  title,
  bonus,
  isPending = false,
}: {
  title: string
  bonus: number
  isPending?: boolean
}) {
  const { palette, typography } = useTheme()
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {isPending ? (
        <MoreHorizontal color={palette.common.orange} />
      ) : (
        <>
          {bonus >= 0 ? (
            <Check color={palette.success.main} />
          ) : (
            <X color={palette.error.main} />
          )}
        </>
      )}
      <Typography fontSize={typography.pxToRem(14)} fontWeight={800}>
        {title}
      </Typography>
    </Stack>
  )
}
