import { Yup } from "@pharmupp/p3-front-commons"

export const openingHoursSchema = Yup.object({
  openingWeek: Yup.array().of(
    Yup.object({
      enable: Yup.boolean(),
      opening: Yup.object().when("enable", {
        is: true,
        then: (schema) =>
          schema.shape({
            start: Yup.date().required("Début requis"),
            end: Yup.date().required("Fin requise"),
          }),
      }),
      hasBreak: Yup.boolean(),
      workBreak: Yup.object({
        start: Yup.date(),
        end: Yup.date(),
      }),
    }),
  ),
})
