import { ContentCopy } from "@mui/icons-material"
import { IconButton, IconButtonProps } from "@mui/material"
import { forwardRef } from "react"
import { useClipBoard } from "../../../hooks"

type ClipBoardButtonProps = {
  label: string
  value: string
} & IconButtonProps

export const ClipBoardButton = forwardRef<HTMLButtonElement, ClipBoardButtonProps>(
  ({ label, value, ...otherProps }, ref) => {
    const clipboard = useClipBoard()
    return (
      <IconButton
        size="small"
        color="primary"
        {...otherProps}
        ref={ref}
        onClick={() => clipboard.write({ label, value })}
      >
        <ContentCopy fontSize="inherit" />
      </IconButton>
    )
  },
)
ClipBoardButton.displayName = "ClipBoardButton"
