import { ExportOptions, useExportDownload } from "../../../../../hooks"
import { CommonExportButton } from "./CommonExportButton"

export const SingleExportButton = ({
  export: currentExport,
}: { export: ExportOptions }) => {
  const { startDownload, isDownloading } = useExportDownload({
    name: currentExport.name,
    endpoint: currentExport.endpoint,
    extension: currentExport.extension,
  })

  return (
    <CommonExportButton
      title="Export"
      onClick={startDownload}
      loading={isDownloading}
    />
  )
}
