import { Chip } from "@pharmupp/p3-front-commons"

export const FlashChip = () => (
  <Chip
    label="flash"
    size="small"
    fontColor="#fff"
    backgroundColor="#951b81"
    sx={{ fontSize: "0.6rem" }}
  />
)
