import { Yup } from "@pharmupp/p3-front-commons"

export const billingSchema = Yup.object({
  siret: Yup.string()
    .nullable()
    .matches(/^(\d{14})?$/g, "Le SIRET est invalide"),
  intraCommunityVAT: Yup.string()
    .nullable()
    .matches(
      /^([a-zA-Z]{2}\d{11})?$/g,
      "Le numéro de TVA Intracommmunautaire est invalide",
    )
    .when("siret", ([siret], schema) =>
      siret
        ? schema.matches(
            new RegExp(`^([a-zA-Z]{2}\\d{2}${siret.substring(0, 9)})?$`, "gi"),
            "Le numéro de TVA Intracommunautaire ne correspond pas au SIRET",
          )
        : schema,
    ),
  cipCode: Yup.number()
    .required("Code CIP requis")
    .typeError("Le Code CIP doit être un nombre")
    .positive()
    .min(2000000, "Le Code CIP doit être compris entre 2000000 et 2999999")
    .max(2999999, "Le Code CIP doit être compris entre 2000000 et 2999999"),
  actualFinessCode: Yup.string()
    .notRequired()
    .when({
      is: (value: string | undefined) => value?.length,
      then: (rule) =>
        rule.length(9).matches(/^\d+$/, {
          excludeEmptyString: true,
          message: "Le code finess doit être une chaîne de chiffres",
        }),
    }),
  lastFinessCode: Yup.string(), // Readonly, not required
})
