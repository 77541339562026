import { ROLES } from "@pharmupp/p3-front-commons"

export const apiRoleConf = {
  [ROLES.ADMIN]: {
    resourceEndpoint: "/api/users",
    referentialEndpoint: (type: "list" | "create" | "update") =>
      `/api/users/referential/${type}`,
    sendVerifyMailEndpoint: (userId: string) => `/api/users/${userId}/verify-email`,
    exportFileName: "utilisateurs",
  },
  [ROLES.ADHERENT_HOLDER]: {
    resourceEndpoint: "/api/users/my-users/adherent",
    referentialEndpoint: (type: "list" | "create" | "update") =>
      `/api/users/referential/my-user/${type}`,
    sendVerifyMailEndpoint: (userId: string) =>
      `/api/users/my-users/adherent/${userId}/verify-email`,
    exportFileName: "", // no export allowed
  },
}
