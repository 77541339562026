import { Box, Card, CardActionArea, Stack, Typography, styled } from "@mui/material"
import { PropsWithChildren, ReactNode } from "react"
import { ChevronRight } from "react-feather"
import { Link } from "react-router-dom"

export const SectionLayout = ({ children }: PropsWithChildren) => (
  <Box display="grid" gridTemplateColumns="3fr 7fr" alignItems="center" gap={10}>
    {children}
  </Box>
)

type MainKpiCardProps = {
  title: string
  subTitle: ReactNode
  kpi: ReactNode
}

const CARD_PX = 3.5

export const MainKpiCard = ({ title, subTitle, kpi }: MainKpiCardProps) => (
  <Card
    component={Stack}
    px={CARD_PX}
    py={3}
    sx={{ border: "none", bgcolor: "#f4f7ff" }}
  >
    <Typography
      variant="18px"
      fontWeight={600}
      color="primary.dark1"
      textTransform="uppercase"
      mb={1}
    >
      {title}
    </Typography>
    <Typography variant="12px" fontWeight={500} color="#81adf8" mb={2}>
      {subTitle}
    </Typography>
    <Typography variant="29px" fontWeight={600} color="primary.dark1">
      {kpi}
    </Typography>
  </Card>
)

export const SectionContentTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark1,
  lineHeight: theme.typography.pxToRem(20),
  fontSize: theme.typography.pxToRem(18),
  fontWeight: 600,
  textTransform: "uppercase",
  marginBottom: theme.spacing(1),
}))

export const CardLink = ({ children, to }: PropsWithChildren<{ to: string }>) => (
  <Card
    sx={{
      bgcolor: "white",
      border: "none",
      "&:hover .icon": {
        marginRight: "-5px!important",
      },
    }}
  >
    <CardActionArea
      component={Link}
      to={to}
      color="primary.light2"
      sx={{ px: CARD_PX, py: 2 }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        color="#89a1cb"
      >
        <Typography variant="16px" color="inherit">
          {children}
        </Typography>
        <Chevron size="20px" className="icon" />
      </Stack>
    </CardActionArea>
  </Card>
)

const Chevron = styled(ChevronRight)(({ theme }) => ({
  marginRight: "0px",
  transition: theme.transitions.create(["margin-right"]),
}))
