import {
  TableCell,
  TableCellProps,
  TableSortLabel,
  styled,
  tableSortLabelClasses,
  useTheme,
} from "@mui/material"
import { ArrowDown } from "react-feather"
import { Direction } from "./useResourceListApi"

export type SortedTableCellProps = Omit<TableCellProps, "onClick"> &
  (
    | {
        columnName: string
        currentSortName: string
        currentSortDirection: Direction
        onClick: SortClickHandler
        disabled?: never
      }
    | { disabled: true; columnName?: string }
  )
export type SortClickHandler = (
  sortedColumnName: string,
  direction?: "asc" | "desc",
) => void

export const SortedTableCell = ({ children, ...props }: SortedTableCellProps) => {
  const theme = useTheme()

  if ("disabled" in props && props.disabled) {
    return <TableCell {...props}>{children}</TableCell>
  }

  const {
    columnName,
    currentSortName,
    currentSortDirection,
    onClick,
    ...otherProps
  } = props
  const active = currentSortName === columnName
  const rotationAngle = active ? DIRECTION_TO_ANGLE[currentSortDirection] : "0deg"

  return (
    <TableCell {...otherProps}>
      <Label
        IconComponent={(iconProps) => (
          <Arrow
            size={12}
            color={active ? theme.palette.primary.main : undefined}
            style={{ transform: `rotate(${rotationAngle})` }}
            {...iconProps}
          />
        )}
        active={active}
        direction={currentSortDirection.toLowerCase() as "asc" | "desc"}
        onClick={() => onClick(columnName)}
      >
        {children}
      </Label>
    </TableCell>
  )
}

// (arrow-down)
const DIRECTION_TO_ANGLE = {
  asc: "0deg",
  desc: "180deg",
}

const Arrow = styled(ArrowDown)`
  opacity: 1 !important;
  position: absolute;
  right: -21px;
  bottom: 1px;
`

const Label = styled(TableSortLabel)(({ theme }) => ({
  [`&.${tableSortLabelClasses.active}`]: {
    color: theme.palette.primary.main,
  },
}))
