import { Button, ButtonProps } from "@mui/material"
import { Link, LinkProps, NavLink, NavLinkProps } from "react-router-dom"

export type ButtonLinkProps = ButtonProps<typeof Link, LinkProps>
export const ButtonLink = ({ to, children, ...props }: ButtonLinkProps) => (
  <Button {...props} component={Link} to={to}>
    {children}
  </Button>
)

export type ButtonNavLinkProps = ButtonProps<typeof NavLink, NavLinkProps>
export const ButtonNavLink = ({ to, children, ...props }: ButtonNavLinkProps) => (
  <Button {...props} component={NavLink} to={to} role="link">
    {children}
  </Button>
)
