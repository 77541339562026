import { Box, CircularProgress, Stack } from "@mui/material"
import { NumberFormat, PaperContainerTitle, Price } from "@pharmupp/p3-front-commons"
import { FileText, ShoppingCart, Users } from "react-feather"
import { DashboardData } from "../../useMyPharmacyApi"
import { CreamIcon } from "../MyKPIs/CreamIcon"
import { BigEvolution, BigKPI, NoData, StatCard, ValueDetail } from "../layouts"

interface MyActivityProps {
  loading: boolean
  activities: DashboardData["activities"]
}
export const MyActivity = ({ loading, activities }: MyActivityProps) => {
  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Stack spacing={[5, 5, 5, 8, 8]}>
      <Box>
        <PaperContainerTitle>Activité</PaperContainerTitle>

        {!!activities?.turnover?.mainStat && (
          <Stack direction="row" spacing={[4, 6, 8, 10, 10]}>
            <BigKPI
              label="CHIFFRE AFFAIRES à J-7"
              value={<Price value={activities.turnover.mainStat} suffix=" €" />}
            />
            <BigEvolution
              title="ÉVOLUTION GLOBALE"
              subtitle="par rapport à l’année précédente"
              evolution={activities.turnover.evolution}
            />
          </Stack>
        )}
      </Box>

      {!activities?.attendance &&
        !activities?.globalCart &&
        !activities?.withoutPrescriptionCart &&
        !activities?.turnover?.mainStat && <NoData mt={6} />}

      <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={3}>
        {!!activities?.attendance && (
          <StatCard
            Icon={Users}
            title="Fréquentation"
            subtitle="Hebdomadaire"
            mainStat={<NumberFormat value={activities.attendance.mainStat} />}
            evolution={activities.attendance.evolution}
            franceEvolution={activities.attendance.evolutionFrance}
          />
        )}
        {!!activities?.globalCart && (
          <StatCard
            Icon={ShoppingCart}
            secondaryIcons={[FileText, CreamIcon]}
            title="Panier moyen"
            subtitle="Global"
            mainStat={
              <>
                <Price value={activities.globalCart.mainStat} suffix="€" />
                <ValueDetail>HT</ValueDetail>
              </>
            }
            evolution={activities.globalCart.evolution}
            franceEvolution={activities.globalCart.evolutionFrance}
          />
        )}
        {!!activities?.withoutPrescriptionCart && (
          <StatCard
            Icon={ShoppingCart}
            secondaryIcons={[CreamIcon]}
            title="Panier moyen"
            subtitle="Hors ordonnance"
            mainStat={
              <>
                <Price
                  value={activities.withoutPrescriptionCart.mainStat}
                  suffix="€"
                />
                <ValueDetail>HT</ValueDetail>
              </>
            }
            evolution={activities.withoutPrescriptionCart.evolution}
            franceEvolution={activities.withoutPrescriptionCart.evolutionFrance}
          />
        )}
      </Box>
    </Stack>
  )
}
