import { Box } from "@mui/material"
import { Check } from "react-feather"
import { Controller, useFormContext } from "react-hook-form"
import { ClearFieldButton, FilterButton } from "./common"

interface FilterToggleProps {
  name: string
  label: string
}
export const FilterToggle = ({ name, label }: FilterToggleProps) => {
  const formApi = useFormContext()
  const toggleOff = () => formApi.unregister(name)
  return (
    <Controller
      name={name}
      render={({ field }) => (
        <Box display="flex">
          <FilterButton
            onClick={() => (field.value ? toggleOff() : field.onChange(true))}
            disableRipple
            {...(field.value
              ? {
                  variant: "contained",
                  startIcon: <Check />,
                  sx: (theme) => ({ pr: `${theme.spacing(2)}!important` }),
                }
              : {
                  variant: "outlined",
                  sx: {
                    "&:hover": {
                      backgroundColor: "#f5f8fa",
                    },
                  },
                })}
          >
            {label}
          </FilterButton>
          {!!field.value && <ClearFieldButton onClick={toggleOff} />}
        </Box>
      )}
    />
  )
}
