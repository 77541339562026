export const StarPlus = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="43" height="35">
    <defs>
      <path
        id="star"
        d="M8.75 4.083h-3.5v-3.5a.584.584 0 0 0-1.167 0v3.5h-3.5a.584.584 0 0 0 0 1.167h3.5v3.5a.583.583 0 1 0 1.167 0v-3.5h3.5a.583.583 0 1 0 0-1.167"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#51C796"
        d="M14.547.662c-1.56 2.974-2.92 6.06-4.098 9.12-3.158.19-6.33.574-9.53 1.124-1.131.228-1.094 1.16-.553 1.757a89.546 89.546 0 0 0 7.066 6.478c-.811 3.098-1.526 6.347-1.97 9.562-.114.982.458 1.554 1.358 1.184 2.986-1.46 5.923-3.158 8.664-4.913 2.697 1.722 5.565 3.398 8.497 4.84.835.388 1.633-.108 1.514-1.063-.456-3.222-1.157-6.498-1.97-9.61a87.767 87.767 0 0 0 7.09-6.478c.707-.735.384-1.536-.505-1.757a85.715 85.715 0 0 0-9.59-1.136A90.33 90.33 0 0 0 16.41.64c-.486-.89-1.36-.82-1.863.023Zm.937 2.391a91.172 91.172 0 0 1 3.461 7.972.984.984 0 0 0 .841.622c2.754.154 5.503.432 8.268.86a84.23 84.23 0 0 1-6.213 5.547.976.976 0 0 0-.324.98 89.025 89.025 0 0 1 1.814 8.307c-2.517-1.317-4.99-2.792-7.33-4.303-.381-.22-.694-.206-1.046 0a89.924 89.924 0 0 1-7.33 4.29c.46-2.787 1.105-5.603 1.826-8.294a.976.976 0 0 0-.324-.98 87.195 87.195 0 0 1-6.213-5.546 83.338 83.338 0 0 1 8.256-.849.985.985 0 0 0 .853-.622 88.674 88.674 0 0 1 3.461-7.984Z"
      />
      <g transform="translate(24 16)">
        <circle cx="9" cy="9" r="9" stroke="#51C796" strokeWidth="2" />
        <use xlinkHref="#star" fill="#1DB778" transform="translate(4.333 4.333)" />
      </g>
    </g>
  </svg>
)
