import { Button } from "@mui/material"
import { LoadingButton, useQueryClient } from "@pharmupp/p3-front-commons"
import { useState } from "react"
import { JustifyModal } from "../../../JustifyModal"
import {
  getGetQueryKey,
  useAdhMarketingOPDisplayApi,
  useSubscriptionApi,
} from "../useAdhMarketingOPDisplayApi"

export const SubscribeButton = ({
  operationId,
  disabled,
}: {
  operationId: string
  disabled?: boolean
}) => {
  const { subscribe, isSubscribing } = useSubscriptionApi({ operationId })

  return (
    <LoadingButton
      variant="contained"
      onClick={() => subscribe()}
      loading={isSubscribing}
      disabled={disabled}
    >
      S&apos;inscrire
    </LoadingButton>
  )
}

export const UnsubscribeButton = ({ operationId }: { operationId: string }) => {
  const { unsubscribe, isUnsubscribing } = useSubscriptionApi({ operationId })

  return (
    <LoadingButton
      variant="contained"
      onClick={() => unsubscribe()}
      loading={isUnsubscribing}
    >
      Se désinscrire
    </LoadingButton>
  )
}

export const SubmitDocumentsButton = ({
  operationId,
  children,
}: {
  operationId: string
  children: string
}) => {
  const [modalOpened, setModalOpened] = useState(false)
  const displayQueryKey = getGetQueryKey(operationId)

  const queryClient = useQueryClient()
  const { operation } = useAdhMarketingOPDisplayApi({
    operationId,
    enabled: modalOpened,
  })

  return (
    <>
      {/* CTA */}
      <Button
        variant="contained"
        onClick={() => setModalOpened(true)}
        sx={{ bgcolor: "common.darkBlue" }}
      >
        {children}
      </Button>

      {/* MODAL */}
      {modalOpened && (
        <JustifyModal
          open
          onClose={() => setModalOpened(false)}
          onSuccess={() => {
            queryClient.invalidateQueries({ queryKey: displayQueryKey })
          }}
          operationId={operationId}
          {...(operation
            ? {
                loading: false,
                operation: {
                  name: operation.name,
                  minFiles: operation.nbProofDocumentsMin,
                  maxFiles: operation.nbProofDocumentsMax,
                  nbProofDocuments: operation.nbProofDocuments,
                  nbAmmProducts: operation.nbAmmProducts,
                },
              }
            : { loading: true })}
        />
      )}
    </>
  )
}
