import {
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  Typography,
  alpha,
  styled,
  useTheme,
} from "@mui/material"
import {
  Chip,
  FullWidthContent,
  FullWidthHeader,
  MarkdownDisplay,
  getPublicFileUrl,
} from "@pharmupp/p3-front-commons"
import { useMemo } from "react"
import { AlertCircle, Check, Heart, Share } from "react-feather"
import { useParams } from "react-router-dom"
import { DisplayNumberViews, SocialScore } from "../../core"
import { FlashChip } from "../components"
import { PostType } from "../model"
import { EventSection } from "./EventSection"
import { useNewsDisplayApi } from "./useNewsDisplayApi"

export const NewsDisplay = () => {
  const { id } = useParams()
  const theme = useTheme()

  // API
  const { news, referential, loading } = useNewsDisplayApi(id as string)
  const category = useMemo(
    () => referential?.uppCategories.find((cat) => cat.code === news?.category),
    [news, referential],
  )

  if ((loading && !news) || !news || !referential) return null

  return (
    <FullWidthContent>
      {/* HEADER */}
      <FullWidthHeader
        title={news.rubric}
        chip={
          news.creatorType === "PRESIDENT_UPP" && (
            <Chip label="INFO PRÉSIDENT" size="small" fontColor="primary.main" />
          )
        }
        level={2}
        linkLabel="Actualités"
        linkTo="../"
      />

      {/* CONTENT */}
      <Paper component={Box} width="100%" px={21} py={4} mb={19}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={5}
        >
          <Stack spacing={3}>
            <Stack direction="row" alignItems="center" spacing={1}>
              {!!news.flash && <FlashChip />}
              {/* CATEGORY */}
              {news.postType === PostType.EVENT ? (
                news.cancelled ? (
                  <Stack direction="row" spacing={1} color="common.red">
                    <AlertCircle />
                    <Chip
                      label="ÉVÈNEMENT ANNULÉ"
                      size="small"
                      fontColor={theme.palette.common.white}
                      backgroundColor={theme.palette.common.red}
                      sx={{ fontSize: theme.typography.pxToRem(9.6) }}
                    />
                  </Stack>
                ) : (
                  <Chip
                    label="ÉVÈNEMENT"
                    size="small"
                    fontColor={theme.palette.common.white}
                    backgroundColor={theme.palette.common.electricBlue}
                    sx={{ fontSize: "0.6rem" }}
                  />
                )
              ) : (
                category && (
                  <Chip
                    label={category.label}
                    size="small"
                    fontColor={category.color?.fontColor}
                    backgroundColor={category.color?.bkgColor}
                    sx={{ fontSize: "0.6rem" }}
                  />
                )
              )}
            </Stack>

            {/* Lab logo */}
            {!!news.labLogoUrl && (
              <img src={news.labLogoUrl} width="167px" alt="Logo du laboratoire" />
            )}
          </Stack>

          <Stack direction="row" spacing={3}>
            {!!news.views && (
              <DisplayNumberViews views={news.views} variant="full" />
            )}
            {/* SOCIAL SCORES: for v2 */}
            {!!false && (
              <>
                <SocialScore
                  Icon={Heart}
                  color="tertiary.dark2"
                  label={"89 J'aime"}
                />
                <SocialScore Icon={Share} color="primary" label="34 partages" />
              </>
            )}
          </Stack>
        </Stack>

        {/* TITLE */}
        <Box mb={5}>
          <Typography
            variant="h1"
            fontSize="36px"
            fontWeight={600}
            color="common.darkBlue"
          >
            {news.title}
          </Typography>
        </Box>

        <Divider />

        {/* POST META */}
        <Box my={2}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" spacing={2}>
              <Typography color="grey.light">
                Publié le {news.publicationDate}
              </Typography>
              <Typography color="common.darkBlue">
                Auteur - {news.author}
                {news.creatorType === "PRESIDENT_UPP" && (
                  <>
                    ,{" "}
                    <Typography
                      color="primary.main"
                      fontWeight={500}
                      component="span"
                    >
                      Président UPP
                    </Typography>
                  </>
                )}
              </Typography>
            </Stack>
            {/* VIEWED: v2 */}
            {!!false && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                color="success.main"
              >
                <Check />
                <Typography>Article lu</Typography>
              </Stack>
            )}
          </Stack>
        </Box>

        <Divider />

        {/* IMAGE */}
        {!!news.image && (
          <Box mt={5}>
            <StyledImg src={getPublicFileUrl(news.image)} alt="Illustration" />
          </Box>
        )}

        {/* CONTENT */}
        <Box px={14} my={5}>
          <Box fontSize="0.875rem" lineHeight="20px" color="common.darkBlue" mb={5}>
            <MarkdownDisplay>{news.description}</MarkdownDisplay>
          </Box>
        </Box>

        {/* EVENT DETAILS */}
        {news.postType === PostType.EVENT && <EventSection {...news} />}

        {/* ACTIONS: for v2 */}
        {!!false && (
          <>
            <Divider />

            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={5}
              my={4}
            >
              <LikeButton startIcon={<Heart />} variant="outlined">
                J&apos;aime
              </LikeButton>
              <Button startIcon={<Share />} variant="outlined">
                Partager
              </Button>
            </Stack>
          </>
        )}
      </Paper>
    </FullWidthContent>
  )
}

const StyledImg = styled("img")({
  width: "100%",
  objectFit: "cover",
  aspectRatio: "2.35 / 1",
})

const LikeButton = styled(Button)(({ theme }) => ({
  color: theme.palette.tertiary.dark2,
  borderColor: theme.palette.tertiary.dark4,
  "&:hover": {
    borderColor: theme.palette.tertiary.dark2,
    backgroundColor: alpha(theme.palette.tertiary.dark2, 0.04),
  },
}))
