import { api, useMutation, useToaster } from "@pharmupp/p3-front-commons"

export const useOperationActivationApi = () => {
  const toaster = useToaster()

  const { mutateAsync: activate } = useMutation({
    mutationFn: ({ id, bonus }: { id: number; bonus: number }) =>
      api.post("/api/operation/bonus/upp", {
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          bonusAmount: bonus,
          bonusOperationId: id,
        }),
      }),
    onSuccess: () => {
      toaster.success("Opération activée")
    },
    onError: () => {
      toaster.error("Une erreur est survenue")
    },
    onMutate: () => {
      toaster.info("Requête en cours...")
    },
  })

  return { activate }
}
