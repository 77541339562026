import { Yup } from "@pharmupp/p3-front-commons"

export const customerServiceSchema = Yup.object({
  customerServiceContacts: Yup.array().of(
    Yup.object().shape(
      {
        name: Yup.string()
          .nullable()
          .when(["email", "phone", "fax"], {
            is: (email?: string, phone?: string, fax?: string) =>
              !!email || !!phone || !!fax,
            then: (schema) =>
              schema.required("Vous devez remplir un nom de service"),
          }),
        email: Yup.string()
          .email("Email non valide")
          .nullable()
          .when(["name", "phone", "fax"], {
            is: (nameField?: string, phone?: string, fax?: string) =>
              !!nameField && !phone && !fax,
            then: (schema) =>
              schema.required(
                "Vous devez remplir au moins l’un des champs : email, téléphone ou fax",
              ),
          }),
        phone: Yup.string()
          .nullable()
          .when(["name", "email", "fax"], {
            is: (nameField?: string, email?: string, fax?: string) =>
              !!nameField && !email && !fax,
            then: (schema) =>
              schema.required(
                "Vous devez remplir au moins l’un des champs : email, téléphone ou fax",
              ),
          }),
        fax: Yup.string()
          .nullable()
          .when(["name", "email", "phone"], {
            is: (nameField?: string, email?: string, phone?: string) =>
              !!nameField && !email && !phone,
            then: (schema) =>
              schema.required(
                "Vous devez remplir au moins l’un des champs : email, téléphone ou fax",
              ),
          }),
      },
      [
        // Yup ? Nope 🖕
        ["name", "email"],
        ["name", "phone"],
        ["name", "fax"],
        ["email", "phone"],
        ["email", "fax"],
        ["phone", "fax"],
      ],
    ),
  ),
})
