import {
  Divider,
  Stack,
  StackProps,
  TablePagination,
  TablePaginationProps,
  TextField,
  styled,
  tablePaginationClasses,
} from "@mui/material"
import { ChevronDown } from "react-feather"
import { RequireAllOrNone } from "type-fest"
import { NumberFormat } from "../../numberDisplays"
import { OUTER_PADDING_X } from "../TableList"
import { PageSizeSelector } from "./PageSizeSelector"

export type TableToolbarProps = ToolbarContainerProps &
  Partial<ToolbarPaginationProps> &
  PageSizeProps

type PageSizeProps = RequireAllOrNone<
  {
    pageSize: number
    setPageSize: (value: number) => void
    sizes?: { label: string; value: number }[]
  },
  "pageSize" | "setPageSize" | "sizes"
>

export const TableToolbar = ({
  paginationProps,
  pageSize,
  setPageSize,
  sizes,
  children,
  ...props
}: TableToolbarProps) => (
  <ToolbarContainer {...props}>
    {/* PAGINATION */}
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
      divider={<Divider flexItem orientation="vertical" variant="middle" />}
    >
      {!!paginationProps && <ToolbarPagination paginationProps={paginationProps} />}
      {!!pageSize && (
        <PageSizeSelector
          value={pageSize}
          onChange={setPageSize}
          sizes={
            sizes || [
              { label: "50", value: 50 },
              { label: "100", value: 100 },
              { label: "200", value: 200 },
            ]
          }
          stackProps={{ pl: 1 }}
        />
      )}
    </Stack>
    {/* ACTIONS */}
    <ToolbarActionsRow>{children}</ToolbarActionsRow>
  </ToolbarContainer>
)

// CONTAINER

type ToolbarContainerProps = StackProps
export const ToolbarContainer = ({ children, ...props }: ToolbarContainerProps) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    px={OUTER_PADDING_X}
    pt={2}
    pb={1}
    spacing={2}
    {...props}
  >
    {children}
  </Stack>
)

// PAGINATION

interface ToolbarPaginationProps {
  paginationProps: Omit<TablePaginationProps, "onPageChange"> & {
    onPageChange: (pageIndex: number) => void
  }
  displayCount?: boolean
}
export const ToolbarPagination = ({
  paginationProps,
  displayCount = true,
}: ToolbarPaginationProps) => {
  const IS_MAXIMUM_SIZE = paginationProps.rowsPerPage < 1
  if (IS_MAXIMUM_SIZE) {
    paginationProps.rowsPerPage = paginationProps.count
  }
  return (
    <Pagination
      rowsPerPageOptions={[]}
      labelDisplayedRows={({ from, to, count }) => (
        <>
          <NumberFormat value={from} />
          {" - "}
          <NumberFormat value={to} />
          {!!displayCount && (
            <>
              {" sur "}
              <NumberFormat value={count} />
            </>
          )}
        </>
      )}
      component="div"
      {...paginationProps}
      onPageChange={(_event, pageIndex) => paginationProps.onPageChange(pageIndex)}
    />
  )
}

const Pagination = styled(TablePagination)(({ theme }) => ({
  display: "flex",
  [`& .${tablePaginationClasses.toolbar}`]: {
    minHeight: "auto",
    height: "auto",
    padding: "0!important",
  },
  [`& .${tablePaginationClasses.displayedRows}`]: {
    color: theme.palette.grey.dark,
  },
  [`& .${tablePaginationClasses.actions}`]: {
    color: theme.palette.primary.main,
  },
})) as typeof TablePagination

// DIVIDER

export const ToolbarDivider = styled(Divider)(({ theme }) => ({
  height: theme.typography.pxToRem(26),
  alignSelf: "center",
}))
ToolbarDivider.defaultProps = {
  orientation: "vertical",
  flexItem: true,
}

// ACTIONS

export const ToolbarActionsRow = ({ children, ...props }: StackProps) => (
  <Stack direction="row" spacing={1} alignItems="center" {...props}>
    {children}
  </Stack>
)

// SortBy Select
export const SortBySelect = styled(TextField)({
  width: "180px",
  "& .MuiSelect-icon": {
    marginTop: "-3px",
  },
})
SortBySelect.defaultProps = {
  select: true,
  label: "Tri par",
  SelectProps: { IconComponent: ChevronDown },
  size: "small",
}
