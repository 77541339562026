import { Box, Stack, Typography, TypographyProps } from "@mui/material"
import { FrontLink } from "../../core"

export const SubHeader = ({
  title,
  sideLinkLabel,
  sideLinkTo,
  ...props
}: TypographyProps & {
  title: string
  sideLinkLabel?: string
  sideLinkTo?: string
}) => (
  <Stack direction="row" justifyContent="space-between">
    <Typography
      variant="h1"
      component={Box}
      align="left"
      mb={2}
      fontWeight="500"
      color="primary.dark1"
      fontSize="1.3rem"
      {...props}
    >
      {title}
    </Typography>
    {!!sideLinkLabel && !!sideLinkTo && (
      <FrontLink to={sideLinkTo} sx={{ color: "grey.dark" }}>
        {sideLinkLabel}
      </FrontLink>
    )}
  </Stack>
)
