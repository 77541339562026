import { NumericFormat, NumericFormatProps } from "react-number-format"

/**
 * Those components are made to:
 * - Unify the way some kind of numbers are displayed
 * - Abstract the lib used to change/upgrade it
 * So all use of react-number-format outside front-commons should be
 * replaced by one of those components
 */

const commonProps: Partial<NumericFormatProps> = {
  decimalScale: 2,
  decimalSeparator: ",",
  thousandSeparator: " ",
  displayType: "text",
}

/** @deprecated use formatNumber instead */
export const NumberFormat = (props: NumericFormatProps) => (
  <NumericFormat {...commonProps} {...props} />
)

/** @deprecated use formatPercentage instead */
export const Percentage = (props: NumericFormatProps) => (
  <NumericFormat {...commonProps} suffix=" %" {...props} />
)

/** @deprecated use formatPrice instead */
export const Price = (props: NumericFormatProps) => (
  <NumericFormat {...commonProps} suffix=" €" {...props} />
)

export const PhoneNumber = (props: { value: string }) => (
  <span>{phoneNumberFormatter(props.value)}</span>
)

const countryOrZero = /((?:(?:\+33)?\s?0?)|0)?\s?(\d{1})/.source
const twoDigits = /\s?(\d{2})/.source
const phoneRegex = new RegExp(
  countryOrZero + twoDigits + twoDigits + twoDigits + twoDigits,
)

const phoneNumberFormatter = (value: string) => {
  const match = phoneRegex.exec(value)
  if (match) {
    const [, , firstNum, ...digits] = match
    return [0 + firstNum, ...digits].join(" ")
  }
  return value
}

const LOCALE = "fr-FR"
const commonOptions: Intl.NumberFormatOptions = {
  maximumFractionDigits: 2,
}
export const formatNumber = (
  value: number,
  extraOptions?: Intl.NumberFormatOptions,
) =>
  new Intl.NumberFormat(LOCALE, { ...commonOptions, ...extraOptions }).format(value)

export const formatPrice = (
  value: number,
  extraOptions?: Intl.NumberFormatOptions,
) =>
  new Intl.NumberFormat(LOCALE, {
    ...commonOptions,
    style: "currency",
    currency: "EUR",
    ...extraOptions,
  }).format(value)

export const formatPercentage = (
  value: number,
  extraOptions?: Intl.NumberFormatOptions,
) =>
  new Intl.NumberFormat(LOCALE, {
    ...commonOptions,
    style: "percent",
    ...extraOptions,
  }).format(value / 100)
