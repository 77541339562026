import { Box, Divider, styled, useTheme } from "@mui/material"
import { ChevronsRight } from "react-feather"

export const ChevronDivider = ({ accent = false }: { accent?: boolean }) => {
  const { palette } = useTheme()
  return (
    <Container>
      <div style={{ position: "relative", height: "100%" }}>
        <Box display="flex" flexDirection="column" alignItems="center" height="100%">
          <DividerLine />
          <ChevronsRight
            color={accent ? palette.common.darkBlue : "#b2b3b5"}
            size="20px"
          />
          <DividerLine />
        </Box>
      </div>
    </Container>
  )
}

const Container = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "30%",
  right: theme.spacing(-2),
  bottom: "30%",
  width: "1px",
}))
const DividerLine = styled(Divider)({
  flex: 1,
  borderColor: "#e0e7f4",
})
DividerLine.defaultProps = {
  flexItem: true,
  orientation: "vertical",
  variant: "fullWidth",
}
