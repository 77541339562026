import { api, useQuery } from "@pharmupp/p3-front-commons"
import { ApiLoyaltyCategoryEnum } from "../../types"

export const useLoyaltyCategoryApi = ({
  categoryEnum,
  year,
}: {
  categoryEnum: ApiLoyaltyCategoryEnum
  year: number
}) => {
  const { data: loyaltyCategory, isLoading: loading } = useQuery({
    queryKey: ["loyalty", "my-adherent", "detailed", categoryEnum, year],
    queryFn: () =>
      api.get<LoyaltyCategory>(
        `/api/loyalties/my-adherent/detailed/${categoryEnum}?year=${year}`,
      ),
    select: (rawLoyaltyCategory) => ({
      ...rawLoyaltyCategory,
      subCategories:
        rawLoyaltyCategory.subCategories?.map((subCategory) => ({
          ...subCategory,
          bonusChallenges:
            subCategory.challenges?.filter((challenge) => challenge.bonus) || [],
          nonBonusChallenges:
            subCategory.challenges?.filter((challenge) => !challenge.bonus) || [],
        })) || [],
    }),
  })

  return {
    loading,
    loyaltyCategory,
  }
}

export interface LoyaltyCategory {
  id: number
  subCategories?: SubCategory[] | null
  challenges?: Challenge[] | null
  history?: ChallengeHistory[] | null
  name: string
  orderNum: number
  totalEarnPoints: number
  totalExpectedPoints: number
  lastYearValue?: number | null
  ratio: number
  totalRealized: number
}

export type SubCategory = Omit<LoyaltyCategory, "subCategories"> & {
  subCategories?: null
  hasBonus: boolean
}

export type SelectedSubCategory = SubCategory & {
  bonusChallenges: Challenge[]
  nonBonusChallenges: Challenge[]
}

export interface Challenge {
  expectedPoint: number
  formattedLabel: string
  quantity?: string
  tag?: string | null
  caption?: string | null
  numberField?: string | null
  unitSuffix?: string | null
  tooltip?: string | null
  orderNumber: number
  unlocked: boolean
  bonus: boolean
  totalPointsUnlocked?: number | null
}

export type ChallengeHistory = {
  adherentId: number
  historyDate: string
  label: string
  pointDiff: number
  pointDiffRelative: number | null
  year: number | null
  historyDateWithoutTime: string
  descriptionItem: {
    id: number
    year: number
    expectedPoint: number
    maxMultiplier: number
    formattedLabel: string
    formattedLabelPlural: string | null
    caption: string
    tag: string | null
    numberField: string
    unitSuffix: string | null
    tooltip: string | null
    orderNumber: number
    minimum: number
  } | null
} & SdavHistory

export interface SdavHistory {
  nbItemsRelative?: number
  nbItemsRelativeGoal?: number
}
