/**
 * These hooks re-implement the now removed useBlocker hook in 'react-router-dom'.
 * https://gist.github.com/MarksCode/64e438c82b0b2a1161e01c88ca0d0355
 */
import { useContext, useEffect } from "react"
import {
  UNSAFE_NavigationContext as NavigationContext,
  resolvePath,
} from "react-router-dom"

/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 * TODO: use react-router implementation when shipped
 */
export function useBlocker(
  confirmExit: (pathname: string) => Promise<boolean> | boolean,
  when = true,
) {
  const { navigator } = useContext(NavigationContext)

  useEffect(() => {
    if (!when) return

    const push = navigator.push

    navigator.push = async (...args: Parameters<typeof push>) => {
      const { pathname } = resolvePath(args[0])
      const result = await confirmExit(pathname)
      if (result !== false) {
        push(...args)
      }
    }

    return () => {
      navigator.push = push
    }
  }, [navigator, when, confirmExit])
}
