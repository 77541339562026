import { Role } from "."
import { ROLES, useRoleCheck } from "./useRoleCheck"

export type RoleStrategy<T> = Partial<Record<Role, T>>

/**
 * Receives a Dictionary of object by roles and return the first match
 * with the user current role by order of importance
 */
export const useRoleConf = <T = unknown>(strategy: RoleStrategy<T>): T => {
  const { checkRole } = useRoleCheck()
  const hasRoleStrategy = (role: Role) => checkRole(role) && role in strategy

  // ⚠️ The order is important in case the user has multiple roles
  if (hasRoleStrategy(ROLES.ADMIN)) return strategy[ROLES.ADMIN]!
  if (hasRoleStrategy(ROLES.ADHERENT_ADVISOR))
    return strategy[ROLES.ADHERENT_ADVISOR]!
  if (hasRoleStrategy(ROLES.UPP_PRESIDENT)) return strategy[ROLES.UPP_PRESIDENT]!
  if (hasRoleStrategy(ROLES.ADHERENT_HOLDER)) return strategy[ROLES.ADHERENT_HOLDER]!
  if (hasRoleStrategy(ROLES.ADHERENT_TEAM)) return strategy[ROLES.ADHERENT_TEAM]!
  if (hasRoleStrategy(ROLES.EXTERN_CONTACT_LABORATORY))
    return strategy[ROLES.EXTERN_CONTACT_LABORATORY]!

  console.warn("No strategy found for current roles")

  // @ts-ignore
  return {}
}
