import { TextField, TextFieldProps } from "@mui/material"
import { Controller, FieldPath, FieldValues } from "react-hook-form"
import { useFieldRoleConf } from "../../../context/FormRoleConfContext"
import { FieldText } from "../../layout"

export type FormTextFieldProps<TFieldValues extends FieldValues = FieldValues> = {
  name: FieldPath<TFieldValues>
} & TextFieldProps

export const FormTextField = <TFieldValues extends FieldValues = FieldValues>({
  name,
  ...otherProps
}: FormTextFieldProps<TFieldValues>) => {
  const { displayOnlyValue, emptyDisplayValue, ...fieldConf } =
    useFieldRoleConf(name)

  return (
    <Controller<TFieldValues>
      name={name}
      render={({ field, fieldState: { error } }) =>
        displayOnlyValue ? (
          <FieldText>{field.value || emptyDisplayValue}</FieldText>
        ) : (
          <TextField
            // Link label to input
            id={name}
            {...field}
            {...otherProps}
            {...fieldConf}
            // Prevent render error (default value for MUI, not hook form)
            value={field.value || ""}
            error={!!error}
            helperText={error?.message}
          />
        )
      }
    />
  )
}
