import {
  ROLES,
  RoleStrategy,
  api,
  formatDateWithDots,
  getPublicFileUrl,
  useQuery,
  useRoleConf,
} from "@pharmupp/p3-front-commons"
import type { Except } from "type-fest"
import { ApiDisplayNews, ApiDisplayNewsReferential, rubricOptions } from "../model"

const formatRawNews = (rawNews: ApiDisplayNews): FormattedNews => ({
  ...rawNews,
  rubric:
    rubricOptions.find((rubric) => rubric.code === rawNews.rubric)?.label ||
    rawNews.rubric,
  publicationDate: formatDateWithDots(rawNews.publicationDate),
})

export const getNewsQueryKey = (id: string) => ["news", id]

export const useNewsDisplayApi = (id: string) => {
  const getApiEndpoint = useRoleConf(apiRoleConf)

  // News
  const { data: news, isLoading: newsLoading } = useQuery({
    queryKey: getNewsQueryKey(id),
    queryFn: () => api.get<ApiDisplayNews>(getApiEndpoint(id)),
    select: formatRawNews,
  })

  // Referential
  const { data: referential, isLoading: referentialLoading } =
    useQuery<ApiDisplayNewsReferential>({
      queryKey: ["news", "display", "referential"],
      queryFn: () => api.get("/api/news/referential/adherent/"),
    })

  return {
    news: news
      ? {
          ...news,
          imageUrl: news.image ? getPublicFileUrl(news.image) : undefined,
          labLogoUrl: news.logo ? getPublicFileUrl(news.logo) : undefined,
        }
      : undefined,
    loading: newsLoading || referentialLoading,
    referential,
  }
}

export type FormattedNews = Except<ApiDisplayNews, "rubric"> & {
  rubric: string
  publicationDate: string
}

const apiRoleConf: RoleStrategy<(id?: string) => string> = {
  [ROLES.ADHERENT_ADVISOR]: (id) => `/api/news/my-sector/latest-news/${id}`,
  [ROLES.ADHERENT_HOLDER]: (id) => `/api/news/${id}`,
  [ROLES.ADHERENT_TEAM]: (id) => `/api/news/${id}`,
}
