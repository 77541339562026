import { DebouncedFunc, noop, throttle } from "lodash-es"
import { useEffect, useMemo } from "react"

export const useThrottledOnScroll = (
  callback: (() => void) | null,
  delay: number,
) => {
  const throttledCallback = useMemo<DebouncedFunc<() => void> | typeof noop>(
    () => (callback ? throttle(callback, delay) : noop),
    [callback, delay],
  )

  useEffect(() => {
    if (throttledCallback === noop) {
      return undefined
    }

    window.addEventListener("scroll", throttledCallback)
    return () => {
      window.removeEventListener("scroll", throttledCallback)
      if ("cancel" in throttledCallback) throttledCallback.cancel()
    }
  }, [throttledCallback])
}
