import { yupResolver } from "@hookform/resolvers/yup"
import { Stack } from "@mui/material"
import {
  FormTextField,
  Modal,
  ModalBasicActions,
  ModalContent,
  ModalTitle,
  api,
  useMutation,
  useToaster,
} from "@pharmupp/p3-front-commons"
import { FormProvider, useForm } from "react-hook-form"
import {
  apiErrorHandlerConfig,
  isHandledError,
} from "../../../User/apiErrorHandlerConfig"
import { useApiErrorHandler } from "../../../User/useApiErrorHandler"
import { ApiUser } from "../types"
import defaultValues from "./CreateUserModal.default"
import { createUserModalFields as fields } from "./CreateUserModal.fields"
import { createUserModalSchema } from "./CreateUserModal.schema"

export interface UserServiceParams {
  getCreateUrl: (apiEndpoint: string) => string
  getSearchUrl: (apiEndpoint: string, query?: string) => string
}

export default function CreateUserModal({
  open,
  onClose,
  userServiceParams,
}: {
  open: boolean
  onClose: (createdUser?: ApiUser) => void
  userServiceParams: UserServiceParams
}) {
  const toaster = useToaster()
  const { mutateAsync: saveUser, isPending: isLoading } = useMutation({
    mutationFn: (formValues: FormValues) =>
      api.post<ApiUser>(userServiceParams.getCreateUrl(""), {
        headers: { "Content-Type": "application/json" },
        mode: "cors",
        body: JSON.stringify(formValues),
      }),
  })
  const formMethods = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(createUserModalSchema),
    reValidateMode: "onBlur",
  })
  const { handleSaveError } = useApiErrorHandler(
    apiErrorHandlerConfig,
    formMethods.setError,
  )

  const submitHandler = formMethods.handleSubmit(async (formValues) => {
    await saveUser(formValues, {
      onError: (error) => {
        toaster.error("Une erreur est survenue")
        if (isHandledError(error)) {
          handleSaveError(error)
        }
      },
      onSuccess(savedUser) {
        toaster.success("L'utilisateur a bien été créé")
        formMethods.reset(defaultValues)
        onClose(savedUser)
      },
    })
  })

  return (
    <Modal open={open} loading={isLoading}>
      <ModalTitle>Créer un Utilisateur</ModalTitle>

      <ModalContent>
        <FormProvider {...formMethods}>
          <Stack p={2} spacing={2}>
            <Stack direction="row" spacing={2}>
              <FormTextField
                label="Nom"
                fullWidth
                inputProps={{ style: { textTransform: "uppercase" } }}
                name={fields.lastName}
                required
              />
              <FormTextField
                label="Prénom"
                name={fields.firstName}
                fullWidth
                required
              />
            </Stack>
            <FormTextField label="E-mail" name={fields.email} fullWidth required />
          </Stack>
        </FormProvider>
      </ModalContent>

      <ModalBasicActions
        cancelAction={onClose}
        validateText="Créer l'utilisateur"
        validateAction={submitHandler}
      />
    </Modal>
  )
}

type FormValues = Pick<ApiUser, "lastName" | "firstName" | "email">
