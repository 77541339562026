import {
  CircularProgress,
  Grid,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material"
import { ReactElement, ReactNode } from "react"
import { MainAreaContainer } from "../../layout/MainAreaContainer"
import { EmptyContent } from "./EmptyContent"
import { SortableContainer, SortableContainerProps } from "./sortableComponents"
import { DEFAULT_TABLE_HEIGHT, useTableResize } from "./useTableResize"

export const OUTER_PADDING_X = 4

export interface TableListProps<T> {
  initialLoading: boolean
  contentLoading: boolean
  header: ReactElement
  entities: T[] | undefined
  entityMapper: (entity: T, index: number) => ReactElement
  emptyMessage: string
  handleDragEnd?: SortableContainerProps["onDragEnd"]
  fixedLayout?: boolean
}

export const TableList = <T,>({
  initialLoading,
  contentLoading,
  header,
  entities,
  entityMapper,
  emptyMessage,
  handleDragEnd,
  fixedLayout = false,
}: TableListProps<T>) => {
  const { filledTableHeight, elementRef } = useTableResize({
    initialLoading,
    contentLoading,
    entities,
  })

  const hasEntity = !!entities?.length
  const tableStyle = {
    height: contentLoading ? filledTableHeight : !hasEntity ? "100%" : undefined,
  }

  return (
    <>
      {initialLoading ? (
        <LoaderContainer sx={{ height: DEFAULT_TABLE_HEIGHT }}>
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        </LoaderContainer>
      ) : (
        <TableContainer sx={tableStyle}>
          <Table
            size="medium"
            sx={{ ...tableStyle, tableLayout: fixedLayout ? "fixed" : "auto" }}
            ref={elementRef}
          >
            <TableHead
              sx={{ display: !hasEntity || contentLoading ? "none" : undefined }}
            >
              {header}
            </TableHead>
            <TableBody>
              {contentLoading ? (
                <TableMainCell>
                  <Grid container justifyContent="center">
                    <CircularProgress sx={{ align: "center" }} />
                  </Grid>
                </TableMainCell>
              ) : (
                <>
                  {hasEntity ? (
                    <>
                      {handleDragEnd ? (
                        <SortableContainer
                          entities={entities}
                          onDragEnd={handleDragEnd}
                        >
                          {entities.map(entityMapper)}
                        </SortableContainer>
                      ) : (
                        entities.map(entityMapper)
                      )}
                    </>
                  ) : (
                    <TableMainCell>
                      <EmptyContent imageSize="10vw">{emptyMessage}</EmptyContent>
                    </TableMainCell>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

const LoaderContainer = styled(MainAreaContainer)({
  flexGrow: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
})

const Table = styled(MuiTable)(({ theme: { spacing } }) => ({
  "& tr > .MuiTableCell-root:first-of-type": {
    paddingLeft: spacing(OUTER_PADDING_X),
  },
  "& tr > .MuiTableCell-root:last-of-type": {
    paddingRight: spacing(OUTER_PADDING_X),
  },
}))

const TableMainCell = ({ children }: { children: ReactNode }) => (
  <TableRow sx={{ bgcolor: "transparent!important" }}>
    <TableCell colSpan={100} color="common.black">
      {children}
    </TableCell>
  </TableRow>
)
