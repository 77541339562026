import { Box, Button, Stack, Typography, styled } from "@mui/material"
import { SidebarCard } from "@pharmupp/p3-front-commons"
import { useState } from "react"
import { JustifyModal } from "../JustifyModal"
import { useParticipantDetailsApi } from "./useParticipantDetailsApi"

export const AddDocumentCard = ({
  operationId,
  adherentId,
}: {
  operationId: string
  adherentId: string
}) => {
  const [modalOpened, setModalOpened] = useState(false)

  const { participant, refreshParticipant } = useParticipantDetailsApi({
    operationId,
    adherentId,
  })

  return (
    <>
      <SidebarCard sx={{ px: 4.5, py: 4 }}>
        <Stack gap={2}>
          <Stack direction="row" gap={1}>
            {/* TODO: ICON */}
            <Title>Charger un justificatif manuellement </Title>
          </Stack>

          <Text>
            La pharmacie n'est pas en mesure de soumettre son justificatif depuis son
            «&nbsp;Espace adhérent&nbsp;», vous pouvez exceptionnellement procéder à
            cette opération à sa place
          </Text>
        </Stack>

        {/* CTA */}
        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="tertiary"
            sx={{ mt: 3 }}
            onClick={() => setModalOpened(true)}
          >
            Ajouter justificatif
          </Button>
        </Box>
      </SidebarCard>

      {/* MODAL */}
      {modalOpened && (
        <JustifyModal
          open
          onClose={() => setModalOpened(false)}
          onSuccess={() => {
            refreshParticipant()
          }}
          operationId={operationId}
          adherentId={adherentId}
          showReasonInput
          headerInfos={
            <Stack>
              <ModalInfo fontWeight={600}>{participant?.pharmacy.name}</ModalInfo>
              <Box display="flex" alignItems="center" gap={1}>
                <ModalInfo>
                  {participant?.pharmacy.primaryHolder.firstName}{" "}
                  {participant?.pharmacy.primaryHolder.lastName}
                </ModalInfo>
                <ModalInfoSmall>
                  ID : {participant?.pharmacy.centralBusinessId} - CIP :{" "}
                  {participant?.pharmacy.cipCode}
                </ModalInfoSmall>
              </Box>
            </Stack>
          }
          {...(participant
            ? {
                loading: false,
                operation: {
                  name: participant.marketingOperationName,
                  minFiles: participant.nbProofDocumentsMin,
                  maxFiles: participant.nbProofDocumentsMax,
                  nbProofDocuments: participant.nbProofDocuments,
                  nbAmmProducts: participant.nbAmmProducts,
                },
              }
            : { loading: true })}
        />
      )}
    </>
  )
}

const Text = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  color: theme.palette.common.darkBlue,
}))
const Title = styled(Text)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 600,
  color: theme.palette.common.darkBlue,
}))

// MODAL

const ModalInfo = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  color: theme.palette.common.darkBlue,
}))
const ModalInfoSmall = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  color: theme.palette.grey.light,
}))
