import { Avatar as MuiAvatar, avatarClasses, styled } from "@mui/material"
import { Menu, ROLES, useRoleCheck, useSsoService } from "@pharmupp/p3-front-commons"
import * as React from "react"
import { Power, Shield, User } from "react-feather"
import { CircleButton, CircleIconButton } from "./layouts"

const AVATAR_SIZE = "32px"

export const AccountMenu = ({ isFixed = false }: { isFixed?: boolean }) => {
  // User
  const isAdherentHolder = useRoleCheck(ROLES.ADHERENT_HOLDER)
  const isAdherentTeam = useRoleCheck(ROLES.ADHERENT_TEAM)
  const sso = useSsoService()
  const userInitials = sso.getInitials()
  const userFullName = sso.getFullName()

  // Menu opening
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      {isFixed ? (
        <CircleIconButton onClick={handleClick} active={open}>
          <Avatar sx={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}>
            {userInitials}
          </Avatar>
        </CircleIconButton>
      ) : (
        <CircleButton
          onClick={handleClick}
          active={open}
          startIcon={
            <Avatar sx={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}>
              {userInitials}
            </Avatar>
          }
        >
          {userFullName}
        </CircleButton>
      )}

      <Menu
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleClose}
        onClick={handleClose}
        items={[
          {
            Icon: User,
            label: "Mon compte",
            to: "/mon-compte",
            hidden: !(isAdherentHolder || isAdherentTeam),
          },
          {
            Icon: Shield,
            label: "Mon coffre-fort",
            href: "https://app.digipharmacie.fr/account/keycloak/login/?process=login&next=/documents",
          },
          {
            Icon: Power,
            label: "Déconnexion",
            onClick: sso.logout,
          },
        ]}
      />
    </>
  )
}

const Avatar = styled(MuiAvatar)(({ theme }) => ({
  // Prevents overrides by using a stronger selector
  [`&.${avatarClasses.root}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.header.containedButton.backgroundColor,
    fontSize: theme.typography.pxToRem(12),
  },
}))
