import type { SliderProps } from "@mui/material"
import {
  Box,
  Slider,
  Typography,
  darken,
  sliderClasses,
  styled,
  useTheme,
} from "@mui/material"
import { get } from "lodash-es"
import { ReactNode } from "react"
import { RequireAllOrNone } from "type-fest"
import { Percentage } from "../../dataDisplay/numberDisplays"
import { Flag } from "./Flag"

export type ProgressProps = Omit<SliderProps, "color" | "value"> & {
  value: number
  color?: string
  halfMarkColor?: string
  showPercentage?: boolean
  showEndMark?: boolean
  endLabel?: ReactNode
} & RequireAllOrNone<
    {
      referenceValue: number
      referenceLabel: ReactNode
    },
    "referenceLabel" | "referenceValue"
  >

export const Progress = ({
  value,
  referenceValue,
  referenceLabel,
  endLabel,
  showPercentage = false,
  showEndMark = false,
  halfMarkColor,
  color = "primary.main",
  ...props
}: ProgressProps) => {
  const { palette } = useTheme()
  const finalColor = get(palette, color, color)
  const darkColor = darken(finalColor, 0.3)
  return (
    <Box position="relative" lineHeight={0}>
      {/* BAR */}
      <ProgressBar
        {...props}
        value={value}
        trackColor={finalColor}
        thumbColor={darkColor}
      />

      {/* HALF MARK */}
      {!!halfMarkColor && <HalfMark bgcolor={halfMarkColor} />}

      {/* END MARK */}
      {!!showEndMark && value !== 100 && <EndMark bgcolor={finalColor} />}

      {/* END LABEL */}
      {!!endLabel && <EndLabel>{endLabel}</EndLabel>}

      {/* PERCENTAGE LABEL */}
      {!!showPercentage && value !== 0 && (
        <PercentageLabel
          color={darkColor}
          bgcolor={halfMarkColor}
          percentage={value}
        >
          <Percentage value={Math.floor(value)} suffix="%" />
        </PercentageLabel>
      )}

      {/* REFERENCE VALUE */}
      {!!referenceValue && (
        <ReferenceMark color={finalColor} percentage={referenceValue}>
          <div style={{ position: "relative" }}>
            <ReferenceMarkIcon>
              <Flag color={finalColor} />
            </ReferenceMarkIcon>
            <ReferenceMarkLabel color={finalColor}>n-1</ReferenceMarkLabel>
            <ReferenceMarkValue>{referenceLabel}</ReferenceMarkValue>
          </div>
        </ReferenceMark>
      )}
    </Box>
  )
}

const BAR_HEIGHT = 8

const ProgressBar = styled(Slider, {
  shouldForwardProp: (prop) => prop !== "trackColor" && prop !== "thumbColor",
})<{ trackColor: string; thumbColor: string }>(({ trackColor, thumbColor }) => ({
  height: BAR_HEIGHT,

  [`& .${sliderClasses.rail}`]: {
    backgroundColor: "#cfd4d9",
    height: "6px",
  },
  [`& .${sliderClasses.track}`]: {
    backgroundColor: trackColor,
  },
  [`& .${sliderClasses.thumb}`]: {
    backgroundColor: thumbColor,
    zIndex: 5,
  },
}))
ProgressBar.defaultProps = {
  disabled: true,
  size: "small",
}

const HalfMark = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "2px",
  height: BAR_HEIGHT,
  zIndex: 0,
})

const EndMark = styled(Box)({
  position: "absolute",
  top: "50%",
  right: 0,
  transform: "translate(-50%, -50%)",
  width: "2px",
  height: 12,
  zIndex: 0,
})

const EndLabel = styled(Typography)(
  ({ theme: { palette, typography, spacing } }) => ({
    position: "absolute",
    bottom: spacing(-3),
    right: 0,
    transform: "translateY(-50%)",
    fontSize: typography.pxToRem(12),
    lineHeight: typography.pxToRem(12),
    color: palette.grey.light,
    zIndex: 0,
  }),
)

const PercentageLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "percentage",
})<{ percentage: number }>(({ theme: { typography }, percentage }) => ({
  position: "absolute",
  top: "calc(50% - 10px)",
  left: `${percentage}%`,
  transform: "translate(-50%, -100%)",
  fontSize: typography.pxToRem(14),
  lineHeight: typography.pxToRem(14),
  fontWeight: 600,
  textAlign: "center",
  padding: "1px 2px",
  zIndex: 2,
}))

const REFERENCE_MARK_SIZE = 8
const ReferenceMark = styled("div", {
  shouldForwardProp: (prop) => prop !== "percentage",
})<{ percentage: number }>(({ color, percentage }) => ({
  position: "absolute",
  top: "50%",
  left: `${percentage}%`,
  transform: "translate(-50%, -50%)",
  width: REFERENCE_MARK_SIZE,
  height: REFERENCE_MARK_SIZE,
  backgroundColor: color,
  border: "1px solid #f2f5f8",
  borderRadius: "100px",
  zIndex: 1,
}))

const ReferenceMarkIcon = styled("div")(() => ({
  position: "absolute",
  top: "-30px",
  left: "calc(50% - 1px)",
}))

const REFERENCE_MARK_LABEL_SIZE = 10
const ReferenceMarkLabel = styled(Typography)({
  position: "absolute",
  top: "10px",
  left: "50%",
  transform: "translateX(-50%)",
  fontSize: `${REFERENCE_MARK_LABEL_SIZE}px`,
  lineHeight: `${REFERENCE_MARK_LABEL_SIZE}px`,
  fontWeight: 600,
  textAlign: "center",
  whiteSpace: "nowrap",
})

const REFERENCE_MARK_VALUE_SIZE = 12
const ReferenceMarkValue = styled(Typography)(({ theme: { palette } }) => ({
  position: "absolute",
  top: `calc(10px + ${REFERENCE_MARK_LABEL_SIZE}px + 2px)`,
  left: "50%",
  transform: "translateX(-50%)",
  fontSize: `${REFERENCE_MARK_VALUE_SIZE}px`,
  lineHeight: `${REFERENCE_MARK_VALUE_SIZE}px`,
  textAlign: "center",
  whiteSpace: "nowrap",
  color: palette.grey.light,
}))
