import {
  ColumnDefinition,
  FilterSearchAndSelect,
  FilterToggle,
  ROLES,
  Table,
  TableProps,
  api,
  useQuery,
  useRoleCheck,
} from "@pharmupp/p3-front-commons"
import { ApiAdherentLoyaltyList } from "./types"

export const AdherentsLoyaltyList = ({
  queryConfig,
  exportButtonProps,
  columns,
  extraToolbarElements,
}: { columns: ColumnDefinition<ApiAdherentLoyaltyList>[] } & Pick<
  TableProps<ApiAdherentLoyaltyList, ApiAdherentLoyaltyList>,
  "queryConfig" | "exportButtonProps" | "extraToolbarElements"
>) => {
  const isAdmin = useRoleCheck(ROLES.ADMIN)
  const { data: uppList } = useQuery({
    queryKey: ["adherents", "referential", "loyalties"],
    queryFn: () =>
      api.get<{ upps: { id: number; name: string }[] }>(
        "/api/adherents/referential/loyalties",
      ),
    select: ({ upps }) => upps.map(({ id, name }) => ({ value: id, label: name })),
    enabled: isAdmin,
  })

  return (
    <Table<ApiAdherentLoyaltyList>
      queryConfig={queryConfig}
      exportButtonProps={exportButtonProps}
      extraToolbarElements={extraToolbarElements}
      FilterSectionElement={
        <>
          {!!uppList && (
            <FilterSearchAndSelect
              name="uppId"
              label="UPP"
              options={uppList}
              multiple
            />
          )}
          <FilterToggle name="isUltra" label="Ultra" />
        </>
      }
      emptyMessage="Pas encore de pharmacie"
      columns={columns}
    />
  )
}
