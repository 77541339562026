import { Avatar, styled } from "@mui/material"

export const BONUS_OP_COLOR = "#03a2ff"

export const BonusOpIcon = ({ size = "88px" }: { size?: string }) => (
  <Avatar sx={{ width: size, height: size, bgcolor: BONUS_OP_COLOR }}>
    <Image src="/illustration/present.svg" />
  </Avatar>
)
export const ChallengesIcon = ({ size = "88px" }: { size?: string }) => (
  <Avatar
    sx={({ palette }) => ({
      width: size,
      height: size,
      bgcolor: palette.loyalty.main,
    })}
  >
    <Image src="/illustration/challenge.svg" />
  </Avatar>
)

const Image = styled("img")({
  width: "50%",
  height: "50%",
  objectFit: "contain",
})
