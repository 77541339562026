import {
  ERROR_KINDS,
  OpenGenericActionDialogProps,
  OpenInfoDialogProps,
  useDialog,
  useToaster,
} from "@pharmupp/p3-front-commons"
import { FieldValues, UseFormSetError } from "react-hook-form"

export interface UnhandledError {
  statusCode: number
  reason: string
  kind: keyof typeof ERROR_KINDS
}

interface DialogInfoType {
  type: "dialogInfo"
  props: OpenInfoDialogProps
  toasterMessage?: string
}

interface DialogActionType {
  type: "dialogAction"
  props: OpenGenericActionDialogProps
  toasterMessage?: string
}

interface ToasterType {
  type: "toaster"
  toasterMessage: string
}

interface FieldErrorType {
  type: "fieldError"
  props: {
    fieldName: string
    message: string
  }
  toasterMessage?: string
}

interface OptionalKey {
  key?: string
}

export type HandledError = OptionalKey &
  (DialogInfoType | DialogActionType | ToasterType | FieldErrorType)

export interface ApiErrorHandlerConfig {
  saveErrorHandler: (error: UnhandledError) => HandledError
  deleteErrorHandler: (error: UnhandledError) => HandledError
}

export const useApiErrorHandler = <T extends FieldValues = Record<string, unknown>>(
  apiErrorHandlerConfig: ApiErrorHandlerConfig,
  setFormError?: UseFormSetError<T>,
) => {
  const toaster = useToaster()
  const dialog = useDialog()

  const handleSaveError = (error: UnhandledError) => {
    displayError(apiErrorHandlerConfig.saveErrorHandler(error))
  }

  const handleDeleteError = (error: UnhandledError) => {
    displayError(apiErrorHandlerConfig.deleteErrorHandler(error))
  }

  const getSaveError = (error: UnhandledError) =>
    apiErrorHandlerConfig.saveErrorHandler(error)

  const displayError = (error: HandledError, setError = setFormError) => {
    switch (error.type) {
      case "dialogInfo":
        dialog.info({ ...error.props })
        break
      case "dialogAction":
        dialog.action({ ...error.props })
        break
      case "fieldError":
        if (setError) {
          // @ts-ignore
          setError(error.props.fieldName, {
            message: error.props.message,
          })
        }
        break
    }

    if (error.toasterMessage) {
      toaster.error(error.toasterMessage)
    }
  }

  return {
    getSaveError,
    displayError,
    handleSaveError,
    handleDeleteError,
  }
}
