import { LoadingButton, LoadingButtonProps } from "@mui/lab"
import { IconButton } from "@mui/material"
import { forwardRef } from "react"

export type LoadingIconButtonProps = Omit<LoadingButtonProps, "component">
export const LoadingIconButton = forwardRef<
  HTMLButtonElement,
  LoadingIconButtonProps
>(({ children, sx, ...props }, ref) => (
  <LoadingButton
    component={IconButton}
    ref={ref}
    {...props}
    sx={[{ p: "8px", minWidth: 0 }, ...(Array.isArray(sx) ? sx : [sx])]}
  >
    {children}
  </LoadingButton>
))
LoadingIconButton.displayName = "LoadingIconButton"
