import {
  Timeline as MuiTimeline,
  TimelineItem as MuiTimelineItem,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineSeparator,
} from "@mui/lab"
import { Chip, Link, LinkProps, Typography, styled } from "@mui/material"
import { ReactElement } from "react"
import { ChevronRight } from "react-feather"

export type TimelineItem = ReactElement
export interface TimelineProps {
  items: TimelineItem[]
  oneSided?: boolean
}

export const Timeline = ({ items, oneSided = false }: TimelineProps) => (
  <MuiTimeline sx={{ padding: 0 }}>
    {items.map((item, index) => (
      <Item key={index} oneSided={oneSided}>
        <TimelineSeparator>
          <Dot variant="outlined" />
          <Connector sx={{ bgcolor: "primary.light4" }} />
        </TimelineSeparator>
        <Content isLast={index === items.length - 1}>{item}</Content>
      </Item>
    ))}
  </MuiTimeline>
)

const Item = styled(MuiTimelineItem, {
  shouldForwardProp: (prop) => prop !== "oneSided",
})<{ oneSided: boolean }>(({ oneSided }) => ({
  ...(oneSided && {
    "&.MuiTimelineItem-missingOppositeContent:before": {
      display: "none",
    },
  }),
}))

const DOT_SIZE = "12px"

const Dot = styled(TimelineDot)(({ theme }) => ({
  margin: 0,
  width: DOT_SIZE,
  height: DOT_SIZE,
  boxSizing: "border-box",
  zIndex: 1,
  borderColor: theme.palette.common.darkBlue,
  backgroundColor: "white",
}))

const Connector = styled(TimelineConnector)({
  marginTop: `calc(-${DOT_SIZE} - 4px)`,
  width: "1px",
})

const Content = styled(TimelineContent, {
  shouldForwardProp: (prop) => prop !== "isLast",
})<{ isLast: boolean }>(({ theme, isLast }) => ({
  marginTop: `-${DOT_SIZE}`,
  paddingBottom: isLast ? 0 : theme.spacing(3),
}))

export const TimelineChip = styled(Chip)(({ theme }) => ({
  display: "block",
  marginRight: "auto",
  marginBottom: "4px",
  padding: "4px",
  height: "auto",
  borderRadius: "3px",
  backgroundColor: theme.palette.primary.dark1,
  color: "white",
  fontSize: theme.typography.pxToRem(11),
  lineHeight: theme.typography.pxToRem(11),
  "& .MuiChip-label": {
    padding: 0,
  },
}))

export const TimelineTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
}))
export const TimelineText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  lineHeight: theme.typography.pxToRem(18),
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  /* number of lines to show */
  WebkitLineClamp: "2",
  lineClamp: "2",
})) as typeof Typography
export const TimelineLink = ({ children, ...props }: LinkProps) => (
  <Link
    {...props}
    sx={(theme) => ({
      display: "flex",
      alignItems: "center",
      gap: "2px",
      fontHeight: theme.typography.pxToRem(14),
      fontSize: theme.typography.pxToRem(12),
    })}
  >
    {children}
    <ChevronRight size="12px" style={{ marginBottom: "-2px" }} />
  </Link>
)
