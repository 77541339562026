import { Box, BoxProps, Stack, Typography, styled } from "@mui/material"
import { Accept, ErrorCode, FileRejection, useDropzone } from "react-dropzone"
import { PlusCircle } from "react-feather"
import { FieldPath, FieldValues, useController } from "react-hook-form"
import { UploadedPictures } from "./UploadedPictures"

export interface FormDropZoneProps<TFieldValues extends FieldValues = FieldValues>
  extends BoxProps {
  name: FieldPath<TFieldValues>
  label: string
  maxFilesCount: number
  /** In Mo */
  maxFileSize?: number
}

export const FormPictureDropZone = <TFieldValues extends FieldValues = FieldValues>({
  name,
  label,
  maxFilesCount,
  maxFileSize = Number.POSITIVE_INFINITY,
  ...props
}: FormDropZoneProps<TFieldValues>) => {
  // FORM CONTROL
  const {
    field,
    fieldState: { error: fieldError },
  } = useController<Record<string, (File & { preview: string })[]>>({ name })

  // DROP ZONE
  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    accept: mimeTypes,
    multiple: true,
    maxFiles: maxFilesCount,
    maxSize: maxFileSize * 1000000,
    disabled: (field.value || []).length >= maxFilesCount,
    onDrop(addedFiles) {
      const addedFilesWithPreview = addedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      )
      const newFiles = [...(field.value || []), ...addedFilesWithPreview]
      field.onChange(newFiles)
    },
  })
  const libErrors = getLibErrorMessages(fileRejections)

  return (
    <Stack spacing={3}>
      {/* DROP ZONE */}
      <Stack spacing={1.5}>
        <DropBox
          {...props}
          focused={isDragActive}
          hasError={!!fileRejections.length}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <IconWrapper>
            <PlusCircle size={47} />
          </IconWrapper>
          <Label>{label}</Label>
          <SizeCaption>
            {`${maxFilesCount} ${maxFilesCount > 1 ? "fichiers" : "fichier"} maximum`}
          </SizeCaption>
          <ErrorCaption>{libErrors.join(",")}</ErrorCaption>
        </DropBox>

        <ValidationDetails>
          {`Formats ${acceptedExtensions.join(", ")}`}&nbsp;
          {maxFileSize !== Number.POSITIVE_INFINITY && `(${maxFileSize} Mo maximum)`}
        </ValidationDetails>
      </Stack>

      {/* UPLOADED PICTURES */}
      <UploadedPictures
        count={maxFilesCount}
        files={field.value}
        onRemove={(index) => {
          field.onChange(field.value.filter((_el, i) => i !== index))
        }}
      />

      {/* ERRORS */}
      <Typography color="error.main">
        {Array.isArray(fieldError) ? fieldError[0].message : fieldError?.message}
      </Typography>
    </Stack>
  )
}

const DropBox = styled(Box, {
  shouldForwardProp: (propName) => propName !== "focused" && propName !== "hasError",
})<{ focused?: boolean; hasError?: boolean }>(
  ({ theme, focused = false, hasError = false }) => ({
    border: `2px dashed  ${theme.palette.common.electricBlue}`,
    borderRadius: "8px",
    padding: `${theme.spacing(6)} ${theme.spacing(2)}`,
    textAlign: "center",
    cursor: "pointer",
    ...(focused && {
      borderColor: theme.palette.secondary.main,
      svg: {
        color: theme.palette.secondary.main,
      },
    }),
    ...(hasError && {
      borderColor: theme.palette.error.main,
    }),
    transition: theme.transitions.create("borderColor"),
  }),
)

const IconWrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.light1,
}))

const Label = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(20),
  color: theme.palette.grey.dark,
}))

const SizeCaption = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  color: theme.palette.grey.dark,
}))

const ErrorCaption = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  color: theme.palette.error.main,
}))

const ValidationDetails = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  color: theme.palette.grey.light,
}))

interface ACCEPTED_FORMAT {
  mime: string
  extensions: string[]
}
export const ACCEPTED_FORMATS = {
  JPG: { mime: "image/jpeg", extensions: [".jpg", ".jpeg"] },
  PNG: { mime: "image/png", extensions: [".png"] },
  GIF: { mime: "image/gif", extensions: [".gif"] },
  WEBP: { mime: "image/webp", extensions: [".webp"] },
} satisfies Record<string, ACCEPTED_FORMAT>

const { acceptedExtensions, mimeTypes } = Object.values(ACCEPTED_FORMATS).reduce(
  (result, { extensions, mime }) => {
    result.acceptedExtensions = [...result.acceptedExtensions, ...extensions]
    result.mimeTypes[mime] = extensions
    return result
  },
  { acceptedExtensions: [], mimeTypes: {} } as {
    acceptedExtensions: string[]
    mimeTypes: Accept
  },
)

const errorToLabel: Record<ErrorCode, string> = {
  [ErrorCode.FileInvalidType]: "Type de fichier invalide",
  [ErrorCode.FileTooLarge]: "Fichier trop lourd",
  [ErrorCode.FileTooSmall]: "Fichier trop petit",
  [ErrorCode.TooManyFiles]: "Trop de fichiers",
}

function getLibErrorMessages(fileRejections: FileRejection[]) {
  return [
    ...new Set<ErrorCode>(
      fileRejections.flatMap((rejectedFile) =>
        rejectedFile.errors.map((error) => error.code as ErrorCode),
      ),
    ),
  ].map((error) => errorToLabel?.[error] || error)
}
