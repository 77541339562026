import {
  api,
  useDialog,
  useMutation,
  useQueryClient,
  useToaster,
} from "@pharmupp/p3-front-commons"
import { ApiDisplayNews } from "../../model"
import { getNewsQueryKey } from "../useNewsDisplayApi"

export const useSubscriptionApi = ({ id }: { id?: string }) => {
  const dialog = useDialog()
  const toaster = useToaster()
  const queryClient = useQueryClient()

  const newsQueryKey = getNewsQueryKey(id!)

  // SUBSCRIBE
  const { mutate: subscribe, isPending: subscribeLoading } = useMutation({
    mutationFn: () => api.put(`/api/news/${id}/subscribe`),
    onError() {
      toaster.error("Une erreur est survenue")
    },
    onSuccess() {
      toaster.success("Inscription réussie")
      queryClient.invalidateQueries({ queryKey: newsQueryKey })
    },
  })

  // UNSUBSCRIBE
  const { mutate: unsubscribe, isPending: unsubscribeLoading } = useMutation({
    mutationFn: () => api.put(`/api/news/${id}/unsubscribe`),
    onError: () => toaster.error("Une erreur est survenue"),
    onSuccess() {
      toaster.success("Désinscription réussie")
      queryClient.invalidateQueries({ queryKey: newsQueryKey })
    },
  })

  return {
    subscribe,
    subscribeLoading,
    unsubscribe() {
      const news = queryClient.getQueryData<ApiDisplayNews>(newsQueryKey)
      dialog.confirm({
        title: "Attention",
        message: (
          <div style={{ textAlign: "center" }}>
            Souhaitez-vous vraiment vous désinscrire de l’évènement :<br />
            <b>{news?.title}</b> ?
          </div>
        ),
        validateAction: unsubscribe,
      })
    },
    unsubscribeLoading,
  }
}
