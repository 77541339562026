import { PaginatedApiResponse, api, useQuery } from "@pharmupp/p3-front-commons"
import { ApiAdherentSolutionsList } from "../../../types"
import { SolutionRubric } from "../../model"

export const useMyServicesApi = () => {
  const { data: services, isLoading } = useQuery({
    queryKey: ["solutions", "services", "adherent", "my-services"],
    queryFn: () =>
      api.get<PaginatedApiResponse<ApiAdherentSolutionsList>>(
        `/api/solutions/adherent/?onlySubscribed=true&size=24&rubric=${SolutionRubric.SERVICE}`,
      ),
  })
  return {
    services: services?.content || [],
    isLoading,
  }
}
