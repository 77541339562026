import { Box, Card, CardActionArea, Stack, Typography } from "@mui/material"
import { ArrowRight } from "react-feather"
import { Link } from "react-router-dom"

export const LastCard = ({
  offer,
  link,
}: {
  offer: "SDAV" | "COALIA"
  link: string
}) => (
  <Card sx={{ height: "12.5rem" }}>
    <CardActionArea component={Link} to={link} sx={{ height: "100%" }}>
      <Stack justifyContent="center" p={1} height="100%">
        <Typography
          color="common.darkBlue"
          fontSize={(theme) => theme.typography.pxToRem(12)}
        >
          Toutes les
        </Typography>
        <Typography
          color="common.darkBlue"
          fontSize={(theme) => theme.typography.pxToRem(12)}
          fontWeight="bold"
        >
          Offres {offer}
        </Typography>
        <Box color="common.electricBlue">
          <ArrowRight />
        </Box>
      </Stack>
    </CardActionArea>
  </Card>
)
