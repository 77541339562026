import { Box, Card, Divider, Stack, Typography, alpha, lighten } from "@mui/material"
import { Offer as OfferType } from "../../../types"
import { Offer } from "./Offer"

export const UpcomingOffers = ({
  offers,
  onOfferClick,
}: {
  offers: OfferType[]
  onOfferClick: (offer: OfferType) => void
}) => (
  <Box gridTemplateColumns="1fr 0fr 11fr 0fr" display="grid" columnGap={3}>
    <Stack spacing={2}>
      <Typography
        fontWeight={500}
        color="common.white"
        textTransform="uppercase"
        borderRadius="2px"
        textAlign="center"
        px={1}
        sx={(theme) => ({
          backgroundColor: lighten(theme.palette.primary.dark, 0.5),
        })}
      >
        À VENIR
      </Typography>
    </Stack>
    <Box />
    <Card
      sx={(theme) => ({
        backgroundColor: alpha(theme.palette.common.white, 0.4),
      })}
    >
      <Stack
        direction="row"
        divider={
          <Divider
            orientation="vertical"
            flexItem
            sx={{ my: 2, borderColor: "#f1f3f5" }}
          />
        }
      >
        {offers.map((offer, index) => (
          <Offer
            key={index}
            source={offer.offerSource}
            title={offer.title}
            startDate={offer.startDateFrenchFormat}
            logoUrl={offer.labLogoUrl}
            onClick={() => onOfferClick(offer)}
          />
        ))}
      </Stack>
    </Card>
  </Box>
)
