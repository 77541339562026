import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import { ReactNode } from "react"
import { Percentage } from "../../dataDisplay"

export type HalfCircularProgressProps = Omit<
  CircularProgressProps,
  "color" | "value"
> & {
  color?: string
  progressBgColor?: string
  value: number | null
  size: number
  bottomCaption?: ReactNode
}

export const HalfCircularProgress = ({
  color = "primary.main",
  progressBgColor,
  bottomCaption,
  ...props
}: HalfCircularProgressProps) => {
  const { typography } = useTheme()

  const hasData = props.value !== null
  const progress = props.value !== null ? Math.min(props.value, 100) / 2 : 0
  const finalBottomCaption = hasData ? bottomCaption : ""

  return (
    <Box>
      <Stack width={props.size} overflow="hidden" spacing={1}>
        {props.title !== undefined && (
          <Typography
            color={!hasData ? "grey.light" : "primary.dark"}
            fontWeight={600}
            align="center"
          >
            {props.title}
          </Typography>
        )}
        <Box position="relative" display="inline-block" height={props.size / 2}>
          <ProgressFull
            {...props}
            variant="determinate"
            value={50}
            style={{ transform: "rotate(180deg)" }}
            sx={{ color: progressBgColor || "white" }}
          />
          <CircularProgress
            {...props}
            variant="determinate"
            value={progress}
            style={{ transform: "rotate(180deg)" }}
            thickness={4.6}
            sx={{ color }}
          />

          <ValueAndObjectiveStack>
            <Typography
              fontSize={
                !hasData
                  ? typography.pxToRem(14)
                  : typography.pxToRem(props.size / 7.5)
              }
              fontWeight={!hasData ? 300 : 600}
              color={!hasData ? "grey.light" : "common.darkBlue"}
            >
              {!hasData ? "Aucune donnée" : <Percentage value={props.value} />}
            </Typography>
          </ValueAndObjectiveStack>
        </Box>
      </Stack>

      {finalBottomCaption && (
        <Box
          fontSize={typography.pxToRem(14)}
          textAlign="center"
          color={!hasData ? "grey.light" : "common.darkBlue"}
        >
          {finalBottomCaption}
        </Box>
      )}
    </Box>
  )
}

const ProgressFull = styled(CircularProgress)(({ theme }) => ({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  padding: "2px",
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
  circle: {
    strokeWidth: "2px",
  },
}))

const ValueAndObjectiveStack = styled(Stack)({
  left: 0,
  bottom: 0,
  right: 0,
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
})
