import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  alpha,
  useTheme,
} from "@mui/material"
import {
  FullWidthContent,
  FullWidthHeader,
  PaperContent,
  TabbedPanel,
} from "@pharmupp/p3-front-commons"
import { useParams } from "react-router-dom"
import { InfoBanner } from "../InfoBanner"
import { DefaultRow, PriorityRow, TotalRow } from "./rowComponents"
import { useCoaliaStatsApi } from "./useCoaliaStatsApi"

const yearTabs = [
  new Date().getFullYear(),
  new Date().getFullYear() - 1,
  new Date().getFullYear() - 2,
  new Date().getFullYear() - 3,
]

export const CoaliaStats = () => {
  const { id: adherentId } = useParams()
  return (
    <FullWidthContent>
      {/* HEADER */}
      <FullWidthHeader
        title="Statistiques Coalia"
        linkTo=".."
        linkLabel="Mon Officine"
      />

      <InfoBanner adherentId={adherentId} />
      <PaperContent sx={{ mt: -2 }}>
        <TabbedPanel
          tabs={yearTabs.map((year) => ({
            label: year.toString(),
            to: year.toString(),
            Element: () => <StatsTable year={year} adherentId={adherentId} />,
          }))}
        />
      </PaperContent>
    </FullWidthContent>
  )
}

const StatsTable = ({ year, adherentId }: { year: number; adherentId?: string }) => {
  const { palette } = useTheme()
  const { coaliaStats } = useCoaliaStatsApi({ year, adherentId })

  // LOADING
  if (!coaliaStats) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" flex="1">
        <CircularProgress />
      </Box>
    )
  }

  // NO DATA
  if (!coaliaStats.laboratories.length) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" flex="1">
        <Box textAlign="center">
          <span>Aucune donnée disponible pour cette année</span>
        </Box>
      </Box>
    )
  }

  return (
    <Table>
      {/* TABLE HEAD */}
      <TableHead>
        <TableRow>
          <TableCell>Laboratoire</TableCell>
          {Array.from({ length: 12 }).map((_, index) => (
            <TableCell key={index} align="right">
              {new Date(`${index + 1}/01/2024`).toLocaleDateString("fr-fr", {
                month: "short",
              })}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      {/* TABLE BODY */}
      <TableBody>
        {/* LABS ROWS */}
        {coaliaStats.laboratories.map((laboratory, index) => {
          const isEven = index % 2 === 0
          const labValues = Object.values(laboratory.values) || []
          const hasGoal = labValues.some((value) => value.goal !== null)
          const hasLastYearTurnoverPercent = labValues.some(
            (value) => value.lastYearTurnoverPercent !== null,
          )
          const hasGoalPercent = labValues.some(
            (value) => value.goalPercent !== null,
          )

          // PRIORITY LABS
          return laboratory.priority ? (
            <>
              <PriorityRow
                key={`${laboratory.name}ca`}
                name={`${laboratory.name} CA`}
                values={laboratory.values}
                valueKey="turnover"
                isEven={isEven}
              />
              {!!hasGoal && (
                <PriorityRow
                  key={`${laboratory.name}obj`}
                  name={`${laboratory.name} OBJECTIF`}
                  values={laboratory.values}
                  valueKey="goal"
                  isEven={isEven}
                />
              )}
              {!!hasLastYearTurnoverPercent && (
                <PriorityRow
                  key={`${laboratory.name}evol`}
                  name={`${laboratory.name} EVOL. N-1`}
                  values={laboratory.values}
                  valueKey="lastYearTurnoverPercent"
                  getValueBgColor={(value) => {
                    if (value > 1) return palette.success.main
                    return palette.error.main
                  }}
                  isEven={isEven}
                />
              )}
              {!!hasGoalPercent && (
                <PriorityRow
                  key={`${laboratory.name}ro`}
                  name={`${laboratory.name} RO`}
                  values={laboratory.values}
                  valueKey="goalPercent"
                  getValueBgColor={(value) => {
                    if (value > 100) return alpha(palette.secondary.main, 1)
                    if (value >= 80) return alpha(palette.warning.light, 1)
                    return alpha(palette.error.main, 1)
                  }}
                  isEven={isEven}
                />
              )}
            </>
          ) : (
            // NON PRIORITY LABS
            <DefaultRow
              key={laboratory.name}
              name={laboratory.name}
              values={laboratory.values}
            />
          )
        })}

        {/* TOTALS */}
        {!!coaliaStats.totalsPriority && (
          <TotalRow
            key="totals-priority"
            name="Total CA prioritaires"
            values={coaliaStats.totalsPriority}
          />
        )}
        {!!coaliaStats.totalsNonPriority && (
          <TotalRow
            key="totals-non-priority"
            name="Total CA secondaires"
            values={coaliaStats.totalsNonPriority}
          />
        )}
        {!!coaliaStats.totals && (
          <TotalRow key="totals" name="Total CA" values={coaliaStats.totals} />
        )}
      </TableBody>
    </Table>
  )
}
