import {
  Box,
  Card,
  CardActionArea,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import { ReactNode, useMemo } from "react"
import { Activity, Check, Clock } from "react-feather"
import { RequireAllOrNone } from "type-fest"
import { NumberFormat } from "../../dataDisplay/numberDisplays"
import { Progress } from "../Progress"

export const CardLine = styled(Card)(({ theme }) => ({
  width: "100%",
  backgroundColor: "#f2f5f8",
  borderRadius: "100px",
  border: "none",
  overflow: "visible", // For absolute labels to be visible
}))
export const CardLineAction = styled(CardActionArea)<{ size?: "large" | "medium" }>(
  ({ theme, size = "medium" }) => ({
    height: size === "large" ? "78px" : "59px",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    borderRadius: "100px",
    overflow: "visible", // For absolute labels to be visible
  }),
)

export const ContentGrid = styled(Box, {
  shouldForwardProp: (prop) => prop !== "hasThirdColumn",
})<{ hasThirdColumn?: boolean }>(({ theme, hasThirdColumn = false }) => ({
  display: "grid",
  gridTemplateColumns: hasThirdColumn ? "4fr 5fr 2fr" : "4fr 5fr",
  gridGap: theme.spacing(2),
  width: "100%",
  alignItems: "center",
}))

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  lineHeight: theme.typography.pxToRem(18),
}))

export const ICON_SIZE = "24px"
export const ProgressStatus = ({
  progress,
  title,
  color,
  showZero = false,
}: {
  progress: number
  title: ReactNode
  color: string
  /** When progress is 0, shows a 0 or a waiting status */
  showZero?: boolean
}) => {
  const { palette, typography } = useTheme()
  const statusIcon = useMemo(() => {
    switch (true) {
      case progress === 100:
        return <Check color={palette.success.main} size={ICON_SIZE} />
      case progress > 0:
        return <Activity color={palette.grey.light} size={ICON_SIZE} />
      default:
        return showZero ? (
          <Activity color={palette.grey.light} size={ICON_SIZE} />
        ) : (
          <Clock color={palette.grey.light} size={ICON_SIZE} />
        )
    }
  }, [progress, showZero, palette.grey.light, palette.success.main])

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {statusIcon}
      {progress === 0 && !showZero ? (
        <Typography
          fontSize={typography.pxToRem(18)}
          fontWeight={500}
          color={palette.grey.dark}
        >
          Attente&nbsp;participation
        </Typography>
      ) : (
        <Title color={color}>{title}</Title>
      )}
    </Stack>
  )
}

export const SmallLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey.light,
  fontSize: theme.typography.pxToRem(11),
}))
export const ProgressBar = ({
  color,
  progress,
  referenceValue,
  goal,
  unit,
  showHalfMark = false,
}: {
  color: string
  progress: number
  goal: number
  showHalfMark?: boolean
  unit: string
} & RequireAllOrNone<{ referenceValue: number }, "referenceValue">) => {
  const lastYearProgress = referenceValue
    ? Math.min((100 * referenceValue) / goal, 100)
    : 0
  const extraProgressProps = referenceValue
    ? {
        referenceValue: lastYearProgress,
        referenceLabel: <NumberFormat value={referenceValue} suffix={` ${unit}`} />,
      }
    : {}

  return (
    <Progress
      value={progress}
      color={color}
      showPercentage
      showEndMark
      endLabel={<NumberFormat value={goal} suffix={` ${unit}`} />}
      {...(showHalfMark && { halfMarkColor: "#f2f5f8" })}
      {...extraProgressProps}
      sx={{ py: 0 }}
    />
  )
}

export interface PluralizedWording {
  one: string
  multiple: string
}
export const getPluralizedWording = (
  wording: string | PluralizedWording,
  value: number,
) => {
  if (typeof wording === "string") return wording
  if (value <= 1) return wording.one
  return wording.multiple
}
