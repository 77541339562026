import { useState } from "react"
import { CommonExportButton } from "./CommonExportButton"
import { ExportModal, MultipleExportOptions } from "./ExportModal"

export const MultipleExportsButton = ({
  exports,
}: { exports: MultipleExportOptions[] }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <CommonExportButton title="Exports" onClick={() => setOpen(true)} />
      <ExportModal open={open} onClose={() => setOpen(false)} exports={exports} />
    </>
  )
}
