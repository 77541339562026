import { Box, BoxProps, styled } from "@mui/material"

export type AppContainerProps = BoxProps
export const AppContainer = styled(Box)({
  // Dimensions
  width: "100%",
  minHeight: "100vh",

  // Display
  display: "flex",
  flexDirection: "column",
})
