import { ChipProps, Chip as MuiChip, Theme, lighten, styled } from "@mui/material"
import { get } from "lodash-es"

export type TableChipProps = Omit<ChipProps, "color"> & ExtraTableChipProps
interface ExtraTableChipProps {
  fontColor?: string
  backgroundColor?: string
  borderColor?: string
}

export const Chip = (props: TableChipProps) => <StyledChip {...props} />

const StyledChip = styled(MuiChip, {
  shouldForwardProp: (props) =>
    props !== "borderColor" && props !== "fontColor" && props !== "backgroundColor",
})<ExtraTableChipProps>(
  ({ fontColor, backgroundColor, variant, theme, borderColor }) => {
    const finalFontColor = getPaletteColor(theme.palette, fontColor)
    const finalBackgroundColor = backgroundColor
      ? getPaletteColor(theme.palette, backgroundColor)
      : lighten(finalFontColor, 0.85)

    const commonStyles = {
      borderRadius: "2px",
      textTransform: "uppercase",
      fontWeight: 500,
      color: finalFontColor,
    } as const

    switch (variant) {
      case "outlined":
        return {
          ...commonStyles,
          borderColor: borderColor || finalFontColor,
        }
      default:
        return {
          ...commonStyles,
          border: "none",
          backgroundColor: finalBackgroundColor,
        }
    }
  },
)

function getPaletteColor(palette: Theme["palette"], color: string | undefined) {
  if (!color) {
    return
  }

  const paletteColor = get(palette, color)

  // color = "primary"
  if (typeof paletteColor === "object" && "main" in paletteColor) {
    return get(paletteColor, "main")
    // color = "primary.main"
  }
  if (typeof paletteColor === "string") {
    return paletteColor
    // color = "#FFF"
  }
  return color
}
