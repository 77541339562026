import { Chip, Stack, styled } from "@mui/material"
import { useState } from "react"
import { Download, Table } from "react-feather"
import { ExportOptions, useExportDownload } from "../../../../../hooks"
import { Dialog, ModalContentText } from "../../../../feedback"

export type MultipleExportOptions = ExportOptions & {
  label: string
}
export const ExportModal = ({
  open,
  onClose,
  exports,
}: {
  open: boolean
  onClose: () => void
  exports: MultipleExportOptions[]
}) => {
  const [selectedExportIndex, setSelectedExportIndex] = useState<number | null>(null)
  const { startDownload, isDownloading } = useExportDownload(
    exports[selectedExportIndex || 0],
  )

  const close = () => {
    setSelectedExportIndex(null)
    onClose()
  }

  return (
    <Dialog
      open={open}
      Icon={Download}
      onClose={close}
      title="Export de données"
      validateText="Exporter"
      validateAction={async () => {
        await startDownload()
        close()
      }}
      validateEnabled={selectedExportIndex !== null}
      cancelAction={close}
      cancelText="Annuler"
      loading={isDownloading}
    >
      <Stack gap={3}>
        <ModalContentText>
          Choisir les informations que vous souhaitez exporter
        </ModalContentText>

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap={2}
          maxWidth="100%"
          flexWrap="wrap"
        >
          {exports.map((exportOption, index) => (
            <ExportChoice
              key={exportOption.name}
              icon={<Table size="28px" />}
              label={exportOption.label}
              variant={selectedExportIndex === index ? "filled" : "outlined"}
              onClick={() => setSelectedExportIndex(index)}
            />
          ))}
        </Stack>
      </Stack>
    </Dialog>
  )
}

const ExportChoice = styled(Chip)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  minWidth: "280px",
  height: "71px",
  fontSize: theme.typography.pxToRem(16),
  svg: {
    color: `${theme.palette.secondary.main}!important`,
  },
}))
