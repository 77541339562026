import { PropsWithChildren, createContext, useContext } from "react"

const defaultValues = {
  LABORATORY_SHEET_V2: false,
} satisfies Record<string, boolean>

type FeatureFlags = typeof defaultValues

const featureFlagContext = createContext<FeatureFlags>(defaultValues)

export const useFeatureFlag = (featureFlag: keyof FeatureFlags) => {
  const featureFlags = useContext(featureFlagContext)

  return (
    window.localStorage.getItem(featureFlag) === "true" ||
    featureFlags[featureFlag] ||
    false
  )
}

export const FeatureFlagsProvider = ({ children }: PropsWithChildren) => (
  <featureFlagContext.Provider
    value={{
      ...defaultValues,
      ...window.FEATURE_FLAGS,
    }}
  >
    {children}
  </featureFlagContext.Provider>
)
