export type CatalogName = "fdl" | "fdlPlus" | "sagitta" | "coalia" | "sdav"
export type CatalogType = Record<CatalogName, boolean>

export const catalogLabel: Record<CatalogName, string> = {
  fdl: "FDL",
  fdlPlus: "FDL +",
  sagitta: "Ma Boutique",
  coalia: "Coalia",
  sdav: "SDAV",
}
