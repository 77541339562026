export const PriceTag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30">
    <g id="Fiche-labo" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g
        id="Catalogues---Détail-laboratoire-Copy-7"
        fill="#8C20B1"
        fillRule="nonzero"
        transform="translate(-778 -568)"
      >
        <g id="Group-5" transform="translate(458 548)">
          <g id="Group-12" transform="translate(292.201 20)">
            <g id="Group" transform="translate(28.337)">
              <path
                id="Shape"
                d="M17.184 30c-.508.011-1-.181-1.364-.535L.515 14.14a1.962 1.962 0 0 1-.474-1.723L1.82 2.378a.712.712 0 0 1 .592-.594L12.26.062a2.074 2.074 0 0 1 1.958.534l15.186 15.266c.409.407.625.969.593 1.545a2.615 2.615 0 0 1-.771 1.485L18.904 29.287a2.49 2.49 0 0 1-1.72.712Zm-14-26.79-1.66 9.445a.476.476 0 0 0 .059.415l15.304 15.326c.178.178.594.178.95-.179l10.321-10.395c.356-.297.415-.712.237-.89L13.15 1.665a.711.711 0 0 0-.652-.12L3.185 3.21Z"
              />
              <path
                id="Path"
                d="M18.186 14a1.164 1.164 0 0 1-.581-.257l-3.226-3.21a.833.833 0 0 1 .299-1.517.84.84 0 0 1 .862.361l3.226 3.21a.832.832 0 0 1 0 1.156.97.97 0 0 1-.58.257ZM19.293 20.998a.67.67 0 0 1-.505-.17l-6.669-6.695a.734.734 0 0 1 .094-.919.726.726 0 0 1 .915-.094l6.67 6.696c.27.283.27.73 0 1.013a.894.894 0 0 1-.505.169ZM17.225 23a.858.858 0 0 1-.553-.185L9.36 15.473a.804.804 0 0 1 .284-1.458.797.797 0 0 1 .822.347l7.31 7.342c.297.31.297.8 0 1.11a.98.98 0 0 1-.552.186Z"
              />
              <path
                id="Shape"
                d="M6.527 9C5.153 9 4.03 7.89 4 6.5 4.03 5.11 5.153 4 6.527 4c.884 0 1.7.476 2.142 1.25a2.524 2.524 0 0 1 0 2.5A2.468 2.468 0 0 1 6.527 9Zm0-3.611c-.606 0-1.098.497-1.098 1.111s.492 1.111 1.098 1.111c.607 0 1.1-.497 1.1-1.111s-.493-1.111-1.1-1.111Z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
