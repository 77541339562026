import { fetchInterceptor } from "./fetchInterceptor"

/**
 * Opinionated query fetcher for our API calls
 * TODO: investigate using Ky instead for less code, better types, query params serialization
 */

/** Axios like API */
export function api<T>(endpoint: string, options?: RequestInit) {
  return query<T>(window.CONTEXT.apiEndpoint + endpoint, options)
}
// Shorthands
api.delete = <T = void>(endpoint: string, options?: RequestInit) =>
  query<T>(window.CONTEXT.apiEndpoint + endpoint, {
    method: "DELETE",
    ...options,
  })
api.get = <T>(endpoint: string, options?: RequestInit) =>
  query<T>(window.CONTEXT.apiEndpoint + endpoint, { method: "GET", ...options })
api.patch = <T = void>(endpoint: string, options?: RequestInit) =>
  query<T>(window.CONTEXT.apiEndpoint + endpoint, { method: "PATCH", ...options })
api.post = <T = void>(endpoint: string, options?: RequestInit) =>
  query<T>(window.CONTEXT.apiEndpoint + endpoint, { method: "POST", ...options })
api.put = <T = void>(endpoint: string, options?: RequestInit) =>
  query<T>(window.CONTEXT.apiEndpoint + endpoint, { method: "PUT", ...options })

/** Should not be used directly, exported for now for one case */
export const query = async <T>(url: string, options?: RequestInit) => {
  const finalOptions = await fetchInterceptor.run({
    ...options,
    headers: {
      Accept: "application/json",
      ...options?.headers,
    },
  })

  const response = await fetch(url, finalOptions)

  if (!response.ok) {
    let error = { statusCode: response.status }
    try {
      error = { ...(await response.json()), ...error }
    } catch (e) {
      /*response is empty or not formatted in JSON, empty catch because of ts rules TS1472*/
    }
    try {
      error = { message: await response.text(), ...error } as typeof error & {
        message: string
      }
    } catch (e) {
      /*response is unknown or something strange, empty catch because of ts rules TS1472*/
    }
    throw error
  }

  const contentType = response.headers?.get("content-type")
  const data: T =
    contentType && contentType.indexOf("application/json") !== -1
      ? await response.json()
      : await response.blob()

  return data
}
