import { Typography, styled } from "@mui/material"

const variations = {
  default: 14,
  small: 12,
}

export interface FieldTextProps {
  variation?: keyof typeof variations
}

/**
 * Text replacing a readonly field
 * To use when there is no other input in the page
 */
export const FieldText = styled(Typography)<FieldTextProps>(
  ({ theme, variation = "default" }) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(variations[variation]),
  }),
)
