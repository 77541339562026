import {
  Box,
  Button,
  Divider,
  Stack,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import {
  Chip,
  SidebarCard,
  SidebarCardDetails,
  SidebarCardImage,
  UploadedFile,
  getPublicFileUrl,
} from "@pharmupp/p3-front-commons"
import { PropsWithChildren } from "react"
import { Check, RefreshCw, X } from "react-feather"
import { MarketOPTypes, OperationState } from ".."
import { ProofDocumentCount } from "../../commons"
import {
  DocumentsGrid,
  QrCodeLink,
  StatusChip,
  SubmitDocumentsButton,
  SubscribeButton,
  TakePictureIcon,
} from "./components"
import {
  ApiMarketingOperationDisplay,
  MarketingOperationDisplay,
} from "./useAdhMarketingOPDisplayApi"

export function Sidebar({ operation }: { operation: MarketingOperationDisplay }) {
  return (
    <SidebarCard gridArea="sidebar">
      {!!operation?.type && (
        <Chip
          size="small"
          label={MarketOPTypes[operation.type].label}
          fontColor={MarketOPTypes[operation.type].color || "primary.main"}
          sx={{ fontSize: "0.6rem", mb: 2 }}
        />
      )}

      {!!operation && (
        <SidebarImageAndState
          state={operation.state}
          operationId={String(operation.id)}
          image={operation.image}
          startDate={operation.validityStartDate}
          endDate={operation.validityEndDate}
          nbProofDocuments={operation.nbProofDocuments}
          proofDocuments={operation.proofDocuments}
          kitOrderEndDateFormatted={operation.kitOrderEndDateFormatted}
          qrCodeToken={operation.qrCodeToken}
        />
      )}

      <Divider sx={{ mt: 3 }} />

      <SidebarCardDetails phone="03 84 22 84 99" email="adv@pharm-upp.com" />
    </SidebarCard>
  )
}

const SidebarImageAndState = ({
  state,
  operationId,
  image,
  startDate,
  endDate,
  nbProofDocuments,
  proofDocuments,
  kitOrderEndDateFormatted,
  qrCodeToken,
}: {
  state: OperationState
  operationId: string
  image?: UploadedFile | null
  startDate: string
  endDate: string
} & Pick<
  ApiMarketingOperationDisplay,
  "nbProofDocuments" | "proofDocuments" | "kitOrderEndDateFormatted" | "qrCodeToken"
>) => {
  const { palette } = useTheme()

  switch (state) {
    case "UPCOMING":
      return (
        <SidebarImageAndStateLayout image={image}>
          <Tooltip title="L'inscription ne sera possible que lorsque l'opération aura démarré">
            <span>
              <SubscribeButton operationId={operationId} disabled />
            </span>
          </Tooltip>
        </SidebarImageAndStateLayout>
      )
    case "WAITING":
      return (
        <SidebarImageAndStateLayout
          image={image}
          dateLabel={`Lancement le ${startDate}`}
        >
          <Tooltip title="L'envoi du justificatif ne sera possible que lorsque l'opération aura démarré">
            <span>
              <Button variant="contained" sx={{ color: "white!important" }} disabled>
                Soumettre justificatif
              </Button>
            </span>
          </Tooltip>
        </SidebarImageAndStateLayout>
      )
    case "EXPIRED":
      return (
        <SidebarImageAndStateLayout image={image}>
          <Tooltip
            title={`L'opération est maintenant terminée depuis le ${endDate}`}
          >
            <StatusChip
              label="Opération clôturée"
              icon={<X />}
              mainColor={palette.common.red}
            />
          </Tooltip>
        </SidebarImageAndStateLayout>
      )
    case "OUTDATED":
      return (
        <SidebarImageAndStateLayout image={image}>
          <Tooltip
            title={`L'opération est maintenant terminée depuis le ${kitOrderEndDateFormatted}`}
          >
            <StatusChip
              label="Opération clôturée"
              icon={<X />}
              mainColor={palette.common.red}
            />
          </Tooltip>
        </SidebarImageAndStateLayout>
      )
    case "STARTED":
      return (
        <SidebarImageAndStateLayout image={image}>
          <Stack spacing={2} width="100%">
            <ProofDocumentCount
              count={nbProofDocuments}
              size="small"
              sx={{ width: "100%" }}
            />
            <SubmitDocumentsButton operationId={operationId}>
              Soumettre justificatif
            </SubmitDocumentsButton>

            {!!qrCodeToken && <QrCodeBlock qrCodeToken={qrCodeToken} />}
          </Stack>
        </SidebarImageAndStateLayout>
      )
    case "REVIEWED":
      return (
        <SidebarImageAndStateLayout image={image}>
          <SubmittedDocuments proofDocuments={proofDocuments} />
          <StatusChip
            label="Justificatif transmis"
            icon={<RefreshCw />}
            mainColor={palette.success.main}
          />
        </SidebarImageAndStateLayout>
      )
    case "REJECTED":
      return (
        <SidebarImageAndStateLayout image={image}>
          <Stack spacing={2} width="100%">
            <SubmittedDocuments proofDocuments={proofDocuments} />
            <StatusChip
              label="Justificatif refusé"
              icon={<X />}
              mainColor={palette.error.main}
            />
            <SubmitDocumentsButton operationId={operationId}>
              Nouveau justificatif
            </SubmitDocumentsButton>

            {!!qrCodeToken && <QrCodeBlock qrCodeToken={qrCodeToken} />}
          </Stack>
        </SidebarImageAndStateLayout>
      )
    case "VALIDATED":
      return (
        <SidebarImageAndStateLayout image={image}>
          <SubmittedDocuments proofDocuments={proofDocuments} />
          <StatusChip
            label="Opération validée"
            icon={<Check />}
            mainColor={palette.success.main}
          />
        </SidebarImageAndStateLayout>
      )
    default:
      return (
        <SidebarImageAndStateLayout image={image}>
          <SubscribeButton operationId={operationId} />
        </SidebarImageAndStateLayout>
      )
  }
}

const SidebarImageAndStateLayout = ({
  image,
  dateLabel,
  children,
}: PropsWithChildren<{ image?: UploadedFile | null; dateLabel?: string }>) => {
  const { typography } = useTheme()
  return (
    <Box>
      <Box display="flex" flexDirection="column" mb={2}>
        {/* IMAGE */}
        <SidebarCardImage
          src={image ? getPublicFileUrl(image) : "/news-placeholder.png"}
        />
        {/* DATE */}
        {!!dateLabel && (
          <Box
            width="100%"
            color="primary.main"
            bgcolor="#d8e2f4"
            borderRadius="0 0 7px 7px"
            fontSize={typography.pxToRem(12)}
            lineHeight={typography.pxToRem(22)}
            textAlign="center"
          >
            {dateLabel}
          </Box>
        )}
      </Box>

      <Stack alignItems="flex-start" spacing={2}>
        {children}
      </Stack>
    </Box>
  )
}

const SubmittedDocuments = ({
  proofDocuments,
}: {
  proofDocuments: ApiMarketingOperationDisplay["proofDocuments"]
}) => {
  return (
    <Stack spacing={1}>
      <Typography
        fontSize={(theme) => theme.typography.pxToRem(16)}
        fontWeight={600}
        color="common.darkBlue"
      >
        {proofDocuments?.length}{" "}
        {(proofDocuments?.length ?? 0) > 1 ? "justificatifs" : "justificatif"} soumis
      </Typography>
      <DocumentsGrid proofDocuments={proofDocuments} />
    </Stack>
  )
}

const OrBox = styled(Box)(({ theme: { palette, typography } }) => ({
  border: `1px solid ${palette.grey.ultraLight}`,
  borderRadius: "100px",
  width: "32px",
  height: "32px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: typography.pxToRem(12),
  color: palette.common.darkBlue,
}))

const QrCodeBlock = ({ qrCodeToken }: { qrCodeToken: string }) => {
  const { typography } = useTheme()
  return (
    <>
      <Divider>
        <OrBox>ou</OrBox>
      </Divider>

      <Stack direction="row" gap={1.5}>
        <TakePictureIcon />
        <Stack color="common.darkBlue">
          <Typography fontWeight={600} fontSize={typography.pxToRem(14)}>
            Justifier via mobile
          </Typography>
          <Typography
            lineHeight={typography.pxToRem(13)}
            fontSize={typography.pxToRem(11)}
          >
            Scanner le code pour transmettre vos justificatifs
          </Typography>
        </Stack>
      </Stack>

      <Stack alignItems="center">
        <QrCodeLink link={`${window.CONTEXT.justifyOpeAppUrl}/${qrCodeToken}`} />
      </Stack>
    </>
  )
}
