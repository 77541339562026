import { Box, BoxProps, Typography, lighten, useTheme } from "@mui/material"
import { LogoCircle } from "@pharmupp/p3-front-commons"
import { ReactNode } from "react"
import { Check, Clock, File, Plus, User, X } from "react-feather"

export const SuccessCircle = ({
  icon = <Check />,
  variant = "contained",
  sx,
}: { variant?: "contained" | "outlined"; icon?: ReactNode } & Pick<
  BoxProps,
  "sx"
>) => {
  const { palette } = useTheme()
  return (
    <LogoCircle
      {...(variant === "contained"
        ? { bgcolor: "success.main" }
        : { borderColor: lighten(palette.success.main, 0.5), color: "success" })}
      size="22px"
      sx={sx}
    >
      {icon}
    </LogoCircle>
  )
}

export const SubscribedIcon = () => (
  <Box display="block" position="relative">
    <User size="27px" />
    <SuccessCircle sx={{ position: "absolute", bottom: "-5px", right: "-11px" }} />
  </Box>
)

export const WarnCircle = ({ sx }: Pick<BoxProps, "sx">) => {
  const { palette } = useTheme()

  return (
    <LogoCircle
      borderColor={lighten(palette.common.orange, 0.5)}
      color="common.orange"
      size="22px"
      sx={sx}
    >
      <Typography fontWeight={500}>!</Typography>
    </LogoCircle>
  )
}

export const UnsubscribedIcon = () => (
  <Box display="block" position="relative" height="35px">
    <Clock size="27px" />
    <WarnCircle sx={{ position: "absolute", bottom: "0px", right: "-11px" }} />
  </Box>
)

export const ValidatedIcon = () => {
  const { palette } = useTheme()
  return (
    <Box display="block" position="relative">
      <File size="27px" />
      <LogoCircle
        borderColor={lighten(palette.success.main, 0.5)}
        color="success.main"
        size="22px"
        sx={{ position: "absolute", bottom: "-5px", right: "-11px" }}
      >
        <Plus />
      </LogoCircle>
    </Box>
  )
}

export const DangerCircle = ({
  icon = <X />,
  sx,
}: { icon?: ReactNode } & Pick<BoxProps, "sx">) => {
  const { palette } = useTheme()
  return (
    <LogoCircle
      borderColor={lighten(palette.error.main, 0.5)}
      color="error.main"
      size="22px"
      sx={sx}
    >
      {icon}
    </LogoCircle>
  )
}

export const NonValidatedIcon = () => (
  <Box display="block" position="relative">
    <Clock size="27px" />
    <DangerCircle sx={{ position: "absolute", bottom: "-5px", right: "-11px" }} />
  </Box>
)
