import * as React from "react"
import { SsoInit, SsoOptions, SsoService } from "../../sso"
import { fetchInterceptor } from "../http"

export const useSso = (ssoOptions: SsoOptions) => {
  const [ssoService, setSsoService] = React.useState<SsoService>()
  React.useEffect(() => {
    if (!ssoService) {
      SsoInit(ssoOptions).then((service) => {
        /** Avoid landing on a broken page (full of 403) when changing roles  */
        const hasRoleChange = checkRolesChange(service)
        if (hasRoleChange) {
          window.location.href = "/"
        }
        fetchInterceptor.add(async (options) => ({
          ...options,
          headers: {
            ...(options.headers || {}),
            "X-P3-Authorization": (await service.getToken()) || "",
          },
        }))
        setSsoService(service)
      })
    }
  }, [ssoService, ssoOptions])

  return { sso: ssoService }
}

const LS_STORED_ROLES_KEY = "storedRoles"
export const checkRolesChange = (service: SsoService) => {
  const actualRoles = JSON.stringify(service.getRoles())
  if (actualRoles === undefined) return false

  const storedRoles = localStorage.getItem(LS_STORED_ROLES_KEY)
  if (!storedRoles) {
    localStorage.setItem(LS_STORED_ROLES_KEY, actualRoles)
    return false
  }

  if (actualRoles !== storedRoles) {
    localStorage.setItem(LS_STORED_ROLES_KEY, actualRoles)
    return true
  }
  return false
}
