import {
  Avatar,
  Badge,
  Box,
  Link,
  TableCell,
  Theme,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material"
import {
  ColumnRenderDefinition,
  Percentage,
  Price,
  SortedTableCell,
} from "@pharmupp/p3-front-commons"
import { ExternalLink } from "react-feather"
import { LaboratoryChip, ProductLabel } from ".."
import {
  CatalogChannel,
  ListProduct,
  channelLabel,
  shortChannelLabel,
} from "./useCatalogsApi"

export const catalogColumns = {
  source: {
    renderHeader: ({ sortProps, width }) => (
      <SortedTableCell {...sortProps} columnName="source" width={width}>
        Canal
      </SortedTableCell>
    ),
    renderRow: ({ row, api }) => (
      <TableCell>
        <SourceTag
          source={row.source}
          sourceId={row.sourceId}
          showLink={!api.isCDO}
        />
      </TableCell>
    ),
  },
  product: {
    renderHeader: ({ sortProps, width }) => (
      <SortedTableCell {...sortProps} columnName="label" width={width}>
        Produit
      </SortedTableCell>
    ),
    renderRow: ({ row }) => (
      <TableCell>
        <ProductLabel
          hasOffer={row.hasOffer}
          offersCount={row.offersCount}
          title={row.label}
          subtitle={`Code : ${row.ean || row.cip}`}
        />
      </TableCell>
    ),
  },
  laboratory: {
    renderHeader: ({ sortProps, width }) => (
      <SortedTableCell {...sortProps} columnName="laboratory" width={width}>
        Labo
      </SortedTableCell>
    ),
    renderRow: ({ row }) => (
      <TableCell>
        {/* Laboratory = entity from our db, laboratoryName is from catalog/ospharm */}
        {row.laboratory?.name || row.laboratoryName ? (
          <LaboratoryChip
            label={row.laboratory?.name || row.laboratoryName}
            title={row.laboratory?.name || row.laboratoryName}
          />
        ) : (
          "-"
        )}
      </TableCell>
    ),
  },
  grossPrice: {
    renderHeader: ({ sortProps, width }) => (
      <SortedTableCell
        {...sortProps}
        columnName="grossPrice"
        width={width}
        align="right"
      >
        Prix brut
      </SortedTableCell>
    ),
    renderRow: ({ row }) => (
      <TableCell align="right">
        <Price value={row.grossPrice} />
      </TableCell>
    ),
  },
  discountRate: {
    renderHeader: ({ sortProps, width }) => (
      <SortedTableCell
        {...sortProps}
        columnName="discountRate"
        width={width}
        align="right"
      >
        Remise
      </SortedTableCell>
    ),
    renderRow: ({ row }) => (
      <TableCell align="right">
        <Percentage value={row.discountRate} />
      </TableCell>
    ),
  },
  negociatedPrice: {
    renderHeader: ({ sortProps, width }) => (
      <SortedTableCell
        {...sortProps}
        columnName="negociatedPrice"
        width={width}
        align="right"
      >
        Prix net
      </SortedTableCell>
    ),
    renderRow: ({ row }) => (
      <TableCell align="right">
        <Price value={row.negociatedPrice} />
      </TableCell>
    ),
  },
  returnedPrice: {
    renderHeader: ({ sortProps, width }) => (
      <SortedTableCell
        {...sortProps}
        columnName="returnedPrice"
        width={width}
        align="right"
      >
        Prix rendu
      </SortedTableCell>
    ),
    renderRow: ({ row }) =>
      row.returnedPrice ? (
        <TableCell align="right" sx={{ fontWeight: "bold" }}>
          <Tooltip title="Livraison incluse" arrow>
            <PriceChip>
              <Price value={row.returnedPrice} />
            </PriceChip>
          </Tooltip>
        </TableCell>
      ) : (
        <TableCell align="right">-</TableCell>
      ),
  },
  threshold: {
    renderHeader: ({ sortProps, width }) => (
      <SortedTableCell
        {...sortProps}
        columnName="threshold"
        width={width}
        align="center"
      >
        Multiples
      </SortedTableCell>
    ),
    renderRow: ({ row }) => (
      <TableCell align="center">
        <Badge
          badgeContent={row.threshold}
          showZero
          sx={{
            ".MuiBadge-badge": {
              p: 1.5,
              border: "1px solid #dbe1e7",
              borderRadius: "100px",
              // bgcolor: "white",
              aspectRatio: "1",
            },
          }}
        />
      </TableCell>
    ),
  },
} satisfies Record<string, ColumnRenderDefinition<ListProduct, { isCDO: boolean }>>

const SourceTag = ({
  source,
  sourceId,
  showLink,
}: {
  source: CatalogChannel
  sourceId?: number | null
  showLink: boolean
}) => {
  switch (source) {
    case "PHARMAT":
      return showLink ? (
        <Link href="https://mypharmat.com" target="_blank" underline="hover">
          <Channel source={source} isLink />
        </Link>
      ) : (
        <Channel source={source} />
      )
    case "SAGITTA":
      return showLink ? (
        <Link
          href={`https://maboutique-upp.fr/product/${sourceId}`}
          target="_blank"
          underline="hover"
        >
          <Channel source={source} isLink />
        </Link>
      ) : (
        <Channel source={source} />
      )
    default:
      return <Channel source={source} />
  }
}

export const Channel = ({
  source,
  isLink = false,
}: {
  source: CatalogChannel
  isLink?: boolean
}) => {
  const isUpMediumScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md"),
  )

  if (isUpMediumScreen) {
    return (
      <ChannelBox title={channelLabel[source]} px={1} py={0.8}>
        <Typography
          component="span"
          fontSize="inherit"
          fontWeight="inherit"
          lineHeight="inherit"
          color="inherit"
        >
          {channelLabel[source]}
        </Typography>
        {!!isLink && <ExternalLink />}
      </ChannelBox>
    )
  }
  return (
    <ChannelBox
      component={Avatar}
      title={channelLabel[source]}
      p={1}
      sx={{
        width: "100%",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        aspectRatio: "1",
      }}
    >
      <Typography fontSize="inherit">{shortChannelLabel[source]}</Typography>
    </ChannelBox>
  )
}
export const ChannelBox = styled(Box)(({ theme }) => ({
  display: "inline-block",
  borderRadius: "2px",
  lineHeight: theme.typography.pxToRem(12),
  fontSize: theme.typography.pxToRem(10),
  fontWeight: 500,
  color: theme.palette.primary.dark1,
  backgroundColor: "#eaeaf1",
  textDecoration: "inherit",
  width: "auto",
  maxWidth: "100%",
  height: "auto",
  alignItems: "center",
  hyphens: "auto",
  svg: {
    display: "inline-block",
    height: theme.typography.pxToRem(12),
    verticalAlign: "-2px",
  },
}))

const PriceChip = styled(Box)(({ theme }) => ({
  display: "inline-block",
  padding: `${theme.spacing(0.8)} ${theme.spacing(1)}`,
  borderRadius: "2px",
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 800,
  color: theme.palette.common.darkBlue,
  backgroundColor: "#e8eff7",
  whiteSpace: "nowrap",
  width: "100%",
  textAlign: "center",
}))
