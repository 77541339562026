import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useFormContext } from "react-hook-form"
import { ReferentialCode, ReferentialValue } from "../../../../../types"

export const SelectedOptionsContext = createContext<{
  optionsRecord: Record<string, AvailableOption[]>
  setFieldOptions: FieldOptionsSetter
  fieldsToWatch: string[]
}>(undefined!)

export const SelectedOptionsCtxProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [fieldsToWatch, setFieldsToWatch] = useState<string[]>([])
  const [optionsRecord, setOptionsRecord] = useState<
    Record<string, AvailableOption[]>
  >({})

  const setFieldOptions = useRef<FieldOptionsSetter>((name, options) => {
    setFieldsToWatch((prevFieldsToWatch) => [
      ...new Set([...prevFieldsToWatch, name]),
    ])
    setOptionsRecord((prevOptionsRecord) => ({
      ...prevOptionsRecord,
      [name]: options.map((option) => ({
        fieldName: name,
        label: option.label,
        value: "code" in option ? option.code : option.value,
      })),
    }))
  })

  return (
    <SelectedOptionsContext.Provider
      value={{
        optionsRecord,
        setFieldOptions: setFieldOptions.current,
        fieldsToWatch,
      }}
    >
      {children}
    </SelectedOptionsContext.Provider>
  )
}

export const useSelectedOptionsRegistration = ({
  name,
  options,
  disabled,
}: {
  name: string
  options: (ReferentialCode | ReferentialValue)[]
  disabled: boolean
}) => {
  const { setFieldOptions } = useContext(SelectedOptionsContext)

  useEffect(() => {
    if (disabled) return
    setFieldOptions(name, options)
  }, [disabled, name, options, setFieldOptions])
}

export const useSelectedOptions = () => {
  const formApi = useFormContext()
  const { fieldsToWatch, optionsRecord } = useContext(SelectedOptionsContext)
  const formValues = formApi.watch(fieldsToWatch, []) as WatchedValues

  const sortedOptions = useMemo(
    () =>
      formValues.flatMap((values, index) => {
        if (!values) return []
        const parsedValues = Array.isArray(values) ? values : [values]
        const options = optionsRecord[fieldsToWatch[index]] || []
        return options.filter((option) => parsedValues.includes(option.value))
      }),
    [fieldsToWatch, formValues, optionsRecord],
  )

  const deleteOption = (option: AvailableOption) =>
    formApi.setValue(
      option.fieldName,
      formApi
        .getValues(option.fieldName)
        .filter?.((value: string | number) => value !== option.value),
    )

  return {
    options: sortedOptions,
    deleteOption,
  }
}

export type AvailableOption = ReferentialValue & { fieldName: string }
type FieldOptionsSetter = (
  name: string,
  options: (ReferentialCode | ReferentialValue)[],
) => void

type CorrectWatchedValues = (string | number)[][]
/** A single value array in url is like a non array value uppId=1 */
type UnwrappedByUrlParsingValues = (string | number)[]
type WatchedValues = CorrectWatchedValues | UnwrappedByUrlParsingValues
