import { Box } from "@mui/material"
import { Folder, UserCheck } from "react-feather"

import { Cash, Megaphone, PhotoClick } from "../icons"
import { ExternalLinkCard } from "./ExternalLinkCard"

export const OpeazOperationsSummary = () => (
  <Box display="grid" gridTemplateColumns="repeat(5, 1fr)" gap={4}>
    <ExternalLinkCard
      title="Opérations"
      Icon={Megaphone}
      type="ALL"
      variant="neutral"
    />
    <ExternalLinkCard
      title="Inscriptions"
      Icon={UserCheck}
      type="PARTICIPATING"
      variant="info"
    />
    <ExternalLinkCard
      title="À justifier"
      Icon={PhotoClick}
      type="PENDING_PROOF"
      variant="warning"
    />
    <ExternalLinkCard
      title="Compensations à demander"
      Icon={Cash}
      type="UNREQUESTED_COMPENSATIONS"
      variant="warning"
    />
    <ExternalLinkCard
      title="Demandes transmises"
      Icon={Folder}
      type="COMPENSATED"
      variant="success"
      divider={false}
    />
  </Box>
)
