export const fields = {
  publishLater: "publishLater",
  publicationDate: "publicationDate",
  rubric: "rubric",
  category: "category",
  labExtern: "labExtern",
  title: "title",
  image: "image",
  description: "description",
  status: "status",
  dbStatus: "dbStatus",
  isFlash: "isFlash",
  restrictedUppIds: "restrictedUppIds",
} as const

export const defaultValues = {
  [fields.publishLater]: false,
  [fields.isFlash]: false,
  [fields.restrictedUppIds]: [],
}
