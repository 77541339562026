import { Box, Theme, Typography, styled } from "@mui/material"
import { PropsWithChildren, ReactNode } from "react"

export type PageHeaderProps = PropsWithChildren<{
  title: ReactNode
  subtitle?: string
  level?: PageTitleLevel
}>

export const PageHeader = ({
  title,
  subtitle,
  level = 1,
  children,
}: PageHeaderProps) => (
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    gap={1}
    width="100%"
    height={getHeaderHeight}
    gridArea="header"
  >
    <Box>
      <PageTitle level={level}>{title}</PageTitle>
      {!!subtitle && <PageSubtitle>{subtitle}</PageSubtitle>}
    </Box>
    {children}
  </Box>
)

/**
 * Enforce consistent header height
 * Must be equals to the bigger title level font size
 * to have a fixed height across all title levels
 * and avoid content paper to "jump" when navigating
 */
export const getHeaderHeight = (theme: Theme) => theme.typography.pxToRem(36)

/**
 * Level 1: root navigation
 * Level 2: nested route
 */
export type PageTitleLevel = 1 | 2
export const PageTitle = ({
  level = 1,
  children,
}: PropsWithChildren<{ level?: PageTitleLevel }>) => {
  switch (level) {
    case 2:
      return <TitleLevel2 role="heading">{children}</TitleLevel2>
    default:
      return <TitleLevel1 role="heading">{children}</TitleLevel1>
  }
}

const BaseTitle = styled("span")(({ theme }) => ({
  display: "block",
  fontFamily: theme.typography.fontFamily,
  fontWeight: 500,
  textAlign: "left",
}))
const TitleLevel1 = styled(BaseTitle)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(36),
  lineHeight: theme.typography.pxToRem(36),
})) as typeof Typography
const TitleLevel2 = styled(BaseTitle)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(28),
  lineHeight: theme.typography.pxToRem(28),
})) as typeof Typography

export const PageSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  fontWeight: 500,
  color: theme.palette.grey.dark,
}))
