import { Alarm } from "@mui/icons-material"
import { Button, Stack, Typography } from "@mui/material"
import { LoadingButton } from "@pharmupp/p3-front-commons"
import { format, isSameDay } from "date-fns"
import { fr as frLocale } from "date-fns/locale"
import { useMemo } from "react"
import { Check } from "react-feather"
import { useParams } from "react-router-dom"
import { useSubscriptionApi } from "./useSubscriptionApi"

export const Subscription: React.FC<
  React.PropsWithChildren<{
    isRegistered: boolean
    canSubscribe: boolean
    remainingSubscriptions: number
    registrationDeadline: Date
  }>
> = ({ isRegistered, canSubscribe, registrationDeadline }) => {
  const { id } = useParams()
  const { subscribe, subscribeLoading } = useSubscriptionApi({ id })

  const registrationEndDate = useMemo(
    () => new Date(registrationDeadline),
    [registrationDeadline],
  )
  const isRegistrationEnded = useMemo(
    () =>
      new Date() >= registrationEndDate &&
      !isSameDay(new Date(), registrationEndDate),
    [registrationEndDate],
  )

  return (
    <Stack spacing={2}>
      {isRegistered ? (
        <Button
          variant="outlined"
          color="success"
          startIcon={<Check />}
          sx={{
            pointerEvents: "none",
          }}
        >
          Inscrit
        </Button>
      ) : (
        canSubscribe && (
          <LoadingButton
            variant="contained"
            onClick={() => subscribe()}
            loading={subscribeLoading}
          >
            S&apos;inscrire
          </LoadingButton>
        )
      )}
      <Stack
        textAlign="center"
        color={isRegistrationEnded ? "grey.light" : "common.darkBlue"}
      >
        <Typography fontSize="0.75rem">
          {isRegistrationEnded ? "Inscriptions closes" : "Date limite d’inscription"}
        </Typography>
        <Stack direction="row" justifyContent="center" spacing={1}>
          <Alarm />
          <Typography color="inherit" textTransform="capitalize">
            {useMemo(
              () => format(registrationEndDate, "PPP", { locale: frLocale }),
              [registrationEndDate],
            )}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
