import { Stack, SvgIcon, Typography, alpha, useTheme } from "@mui/material"
import { Price } from "@pharmupp/p3-front-commons"
import { ActivityCategory } from "../../../kpi.models"
import { CategoryStats } from "../../layouts"

import { Icon as FeatherIcon } from "react-feather"
import { PillsIcon } from "./PillsIcon"

type CategoryProps = ActivityCategory<string> & {
  Icon?: FeatherIcon | typeof SvgIcon
}

export const Category = ({ name, turnover, Icon = PillsIcon }: CategoryProps) => {
  const { palette } = useTheme()

  return (
    <Stack spacing={1} width="100%">
      <Stack direction="row" justifyContent="space-between" px={4}>
        <Typography variant="body2" color="common.darkBlue">
          {name}
        </Typography>
        <Typography variant="caption" color="grey.ultraLight">
          Évol. France
        </Typography>
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        bgcolor={alpha(palette.primary.main, 0.04)}
        py={2}
        px={4}
        borderRadius="2000px"
        spacing={2}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Icon height={20} style={{ color: palette.grey.ultraLight }} />
          <Typography variant="h6" color="common.darkBlue">
            <Price value={turnover.mainStat} />
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <CategoryStats evol={turnover.evolution} unit=" %" />
          <CategoryStats evol={turnover.evolutionFrance} unit=" %" neutral />
        </Stack>
      </Stack>
    </Stack>
  )
}
