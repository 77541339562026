/**
 * Replaces all occurrences of `cur` with `tar` inside given `obj`
 * Mainly used by forms to replace `""` with `null`
 * Eg: `convertObjectValues(myObj, "", null)`
 */
const convertObjectValues = <V extends object, C, T>(obj: V, cur: C, tar: T): V =>
  Object.fromEntries(
    Object.entries(obj).map(([key, val]) => {
      if (Array.isArray(val)) {
        return [key, val.map((v) => convertObjectValues(v, cur, tar))]
      }

      if (val === Object(val) && !(val instanceof Date)) {
        return [key, convertObjectValues(val, cur, tar)]
      }

      return [key, val === cur ? tar : val]
    }),
  ) as V

export const FormHelpers = {
  convertObjectValues,
}
