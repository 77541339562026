export const Megaphone = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="37">
    <g fill="#0C233D" fillRule="nonzero">
      <path
        stroke="#0C233D"
        strokeWidth=".5"
        d="M32.952 13.752C31.48 7.61 28.596 1 25.747 1c-.14 0-.282.017-.417.05-.62.15-1.115.627-1.478 1.4-.038.031-.08.055-.112.096-1.595 2.053-7.305 8.836-13.82 10.422l-4.976 1.294a6.185 6.185 0 0 0-3.7 2.755 6.17 6.17 0 0 0-.741 4.7 6.132 6.132 0 0 0 2.79 3.84 6.145 6.145 0 0 0 4.676.715L9 26.022h.001l1.242-.303 4.118 8.526A3.102 3.102 0 0 0 17.154 36c.455 0 .917-.1 1.352-.314a3.137 3.137 0 0 0 1.43-4.175l-3.33-6.893c5.946-.05 12.018 2.733 13.405 3.411.497.373 1 .58 1.503.58.14 0 .281-.017.417-.05 1.314-.32 2.07-2.081 2.185-5.093.104-2.705-.31-6.155-1.164-9.714ZM7.66 24.982a4.839 4.839 0 0 1-5.876-3.578 4.844 4.844 0 0 1 .582-3.69 4.875 4.875 0 0 1 2.976-2.186l.44-.107 2.27 9.465-.392.096Zm10.27 9.51a1.778 1.778 0 0 1-2.382-.829l-1.495-3.093 3.249-1.478 1.45 3c.43.89.061 1.967-.822 2.4Zm-1.205-6.595-3.248 1.478-1.92-3.975 1.525-.372a14.365 14.365 0 0 1 2.093-.34l1.55 3.21Zm-3.953-4.16-3.439.838-2.27-9.466 3.17-.774c5.477-1.334 10.348-6.06 12.967-9.038-.023.27-.042.55-.054.846-.054 1.408.035 3.021.251 4.744.003.024-.003.048.003.073.002.008.008.014.01.022.198 1.558.498 3.203.9 4.875.393 1.64.888 3.312 1.454 4.884.004.03-.004.06.004.091a.65.65 0 0 0 .117.238c.65 1.755 1.391 3.366 2.183 4.646-3.544-1.416-9.784-3.321-15.296-1.978Zm12.034-12.264c.993.275 2.171 1.64 2.67 3.719.496 2.066.076 3.811-.675 4.523a44.87 44.87 0 0 1-1.21-4.17 45.474 45.474 0 0 1-.785-4.072ZM32.8 23.415c-.096 2.487-.661 3.728-1.179 3.854a.436.436 0 0 1-.107.012.887.887 0 0 1-.388-.106.649.649 0 0 0-.207-.174c-.028-.015-.099-.052-.205-.105-.954-.792-2.24-2.796-3.436-5.877 1.485-.92 2.132-3.415 1.478-6.14-.657-2.739-2.373-4.67-4.125-4.785-.152-1.41-.214-2.733-.17-3.9.096-2.488.66-3.728 1.179-3.854a.436.436 0 0 1 .107-.012c1.27 0 4.112 4.179 5.925 11.736.826 3.444 1.227 6.764 1.128 9.35Z"
      />
      <path d="M37.985 13.342a.664.664 0 0 1-.142-1.309l4.305-1.063a.664.664 0 0 1 .314 1.29l-4.306 1.063a.637.637 0 0 1-.171.02ZM34.723 6.089a.656.656 0 0 1-.473-.233.667.667 0 0 1 .075-.936l3.882-3.326a.656.656 0 0 1 .929.075.667.667 0 0 1-.075.936l-3.882 3.326a.65.65 0 0 1-.456.158ZM43.78 22.474a.653.653 0 0 1-.27-.01l-4.771-1.188a.663.663 0 0 1-.482-.804.655.655 0 0 1 .797-.485l4.773 1.188a.663.663 0 0 1-.046 1.299Z" />
    </g>
  </svg>
)
