import { Stack, Typography, styled } from "@mui/material"
import { ChevronRight, Clock, Plus } from "react-feather"
import { Link } from "react-router-dom"
import { CardLine, CardLineAction } from "./common"

interface GoalTrackingPlaceholderProps {
  title: string
  link?: string
}
export const GoalTrackingPlaceholder = ({
  title,
  link,
}: GoalTrackingPlaceholderProps) => (
  <Wrapper>
    <Container
      {...(link ? { component: Link, to: link } : { sx: { pointerEvents: "none" } })}
    >
      <Stack direction="row" spacing={3}>
        {link ? <Plus /> : <Clock />}
        <Title>{title}</Title>
      </Stack>
      {!!link && <ChevronRight />}
    </Container>
  </Wrapper>
)

const Wrapper = styled(CardLine)(({ theme }) => ({
  borderWidth: "1px",
  borderStyle: "dashed",
  borderColor: theme.palette.grey.ultraLight,
  backgroundColor: "transparent",
}))
const Container = styled(CardLineAction)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
  color: theme.palette.grey.ultraLight,
}))
const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  fontWeight: 500,
  color: theme.palette.grey.dark,
}))
