import { Box, Stack, useTheme } from "@mui/material"
import { HalfCircularProgress, PaperContainer } from "@pharmupp/p3-front-commons"
import { DashboardData } from "../../useMyPharmacyApi"
import { LineBlock, ServiceTurnover } from "./components"

export type MyPurchasesProps = Pick<
  DashboardData,
  "objectives" | "progressions" | "serviceTurnover"
> & {
  loading: boolean
}

const HALF_DONUT_WIDTH_IN_PX = 200

export const MyPurchases = ({
  serviceTurnover,
  objectives,
  progressions,
  loading,
}: MyPurchasesProps) => {
  const { palette } = useTheme()
  const colors = [
    palette.common.violet,
    palette.common.pinky,
    palette.common.turquoise,
    palette.common.salmon,
  ]

  if (
    !loading &&
    !serviceTurnover &&
    !objectives?.objectives &&
    !progressions?.progressions
  ) {
    return null
  }

  const hasObjectives = false && !!objectives?.objectives

  return (
    <PaperContainer loading={loading}>
      <Stack spacing={[4, 4, 4, 8, 8]} mb={5}>
        {/* Tracking */}
        {!!serviceTurnover && (
          <LineBlock mainTitle="Mes achats" divider={hasObjectives}>
            <ServiceTurnover serviceTurnover={serviceTurnover} />
          </LineBlock>
        )}
        {/* Component not refactored for responsive and disable beacause data not yet available TARGETS TRACKING (HALF PROGRESS CHARTS) */}
        {hasObjectives && (
          <LineBlock subtitle="Détail Coalia" title="SUIVI OBJECTIF ENGAGEMENT">
            <Stack
              component={Box}
              direction="row"
              justifyContent="space-between"
              flexWrap="wrap"
              gap={4}
              paddingTop={4}
            >
              {objectives.objectives.map((objective, index) => (
                <Box key={objective.type}>
                  <HalfCircularProgress
                    title={objective.type}
                    value={objective.percentage}
                    size={HALF_DONUT_WIDTH_IN_PX}
                    color={colors[index]}
                    progressBgColor="grey.extraLight"
                    bottomCaption={`Obj. ${objective.value}/${objective.objective}`}
                  />
                </Box>
              ))}
            </Stack>
          </LineBlock>
        )}
      </Stack>
    </PaperContainer>
  )
}
