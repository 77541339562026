import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  alpha,
  styled,
} from "@mui/material"
import {
  ButtonLink,
  FullWidthContent,
  FullWidthHeader,
} from "@pharmupp/p3-front-commons"
import { HeaderDivider } from "./HeaderDivider"
import { LatestNewsItems } from "./LatestNewsItems"
import { SubHeader } from "./SubHeader"
import { useLatestNewsApi } from "./useLatestNewsApi"

interface LatestNewsProps {
  showTitle?: boolean
  showHistoryBtn?: boolean
  /** Column nbr for the smallest line */
  columnNbr?: number
  linkPrefix?: string
}

export const LatestNews = ({
  showTitle = true,
  showHistoryBtn = true,
  columnNbr = 4,
  linkPrefix = "",
}: LatestNewsProps) => {
  const { entity, referential, loading } = useLatestNewsApi()

  return (
    <FullWidthContent>
      {loading || !entity || !referential ? (
        <Grid container justifyContent="center">
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {/* TITLE */}
          {!!showTitle && <FullWidthHeader title="Les actualités Pharm-UPP" />}

          <Paper component={Box} width="100%" overflow="scroll" mb={19}>
            <Stack direction="column" spacing={0}>
              {/* PHARM-UPP SECTION */}
              <NewsSection px={4} pt={4} pb={7}>
                <SubHeader
                  title="Pharm-UPP vous informe"
                  sideLinkLabel="Voir les précédentes actus"
                  sideLinkTo={`${linkPrefix}pharm-upp`}
                />
                <HeaderDivider variant="under" />

                <LatestNewsItems
                  items={entity.uppPosts}
                  referential={referential}
                  lineNbr={2}
                  columnNbr={columnNbr}
                  link={(id) => linkPrefix + id}
                />
              </NewsSection>

              {/* LABS/PRESTAS SECTION */}
              <NewsSection
                px={4}
                pt={5}
                pb={9}
                bgcolor={(theme) => alpha(theme.palette.common.electricBlue, 0.08)}
              >
                <SubHeader
                  title="Actualités Labos & Prestataires"
                  sideLinkLabel="Toutes les actus Labos & Prestataires"
                  sideLinkTo={`${linkPrefix}labos-prestataires`}
                />
                <HeaderDivider variant="under" />

                <LatestNewsItems
                  items={entity.labPosts}
                  referential={referential}
                  lineNbr={2}
                  columnNbr={columnNbr + 1}
                  link={(id) => linkPrefix + id}
                />
              </NewsSection>

              {/* SANTÉ/PHARMA SECTION */}
              <NewsSection px={4} pt={8} pb={5}>
                {showHistoryBtn ? (
                  <HeaderDivider variant="around">
                    <SubHeader title="Actualités Santé / Pharma" fontSize="1.5rem" />
                  </HeaderDivider>
                ) : (
                  <>
                    <SubHeader
                      title="Actualités Santé / Pharma"
                      sideLinkLabel="Toutes les actus Santé / Pharma"
                      sideLinkTo={`${linkPrefix}sante-pharma`}
                    />
                    <HeaderDivider variant="under" />
                  </>
                )}

                <LatestNewsItems
                  items={entity.healthPosts}
                  referential={referential}
                  columnNbr={columnNbr}
                  link={(id) => linkPrefix + id}
                />

                {!!showHistoryBtn && (
                  <Box textAlign="center" mt={4}>
                    <ButtonLink variant="contained" to="sante-pharma">
                      Voir l&apos;historique
                    </ButtonLink>
                  </Box>
                )}
              </NewsSection>
            </Stack>
          </Paper>
        </>
      )}
    </FullWidthContent>
  )
}

const NewsSection = styled(Box)({ width: "100%" })
