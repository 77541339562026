import {
  Box,
  CircularProgress,
  Link,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import { getPublicFileUrl } from "@pharmupp/p3-front-commons"
import { ExternalLink } from "react-feather"
import { useMyServicesApi } from "./useMyServicesApi"

const ShortcutsAligned = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  columnGap: theme.spacing(5),
  rowGap: theme.spacing(3),
}))

const ShortcutsCentered = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(6),
  flexWrap: "wrap",
  padding: `0 ${theme.spacing(2)}`,
  "& > a": {
    maxWidth: "20%",
  },
}))

export const Shortcuts = (
  { variant }: { variant?: "aligned" | "centered" } = { variant: "aligned" },
) => {
  const { isLoading, services } = useMyServicesApi()

  const Container = variant === "centered" ? ShortcutsCentered : ShortcutsAligned

  return (
    <Container>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gridColumn="span 6"
          minHeight="150px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {services.length === 0 ? (
            <Typography
              color="common.darkBlue"
              fontSize={(theme) => theme.typography.pxToRem(14)}
              gridColumn="span 3"
            >
              Aucun raccourci disponible
            </Typography>
          ) : (
            services.map(({ id, name, logoShortcut, website }) => (
              <SubscribedSolution
                key={id}
                name={name}
                logoUrl={getPublicFileUrl(logoShortcut)}
                link={website!}
                hideLabel={variant === "centered"}
              />
            ))
          )}
        </>
      )}
    </Container>
  )
}

const SubscribedSolution = ({
  name,
  logoUrl,
  link,
  hideLabel,
}: {
  name: string
  logoUrl: string
  link: string
  hideLabel?: boolean
}) => {
  const { palette, typography } = useTheme()
  const logoSize = hideLabel ? "80px" : "50px"

  return (
    <Link href={link} underline="hover" minWidth={0}>
      <Box display="flex" gap={2} alignItems="center">
        <Box
          borderRadius="300px"
          boxShadow={2}
          minWidth={logoSize}
          width={logoSize}
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative"
          sx={{ aspectRatio: "1/1" }}
        >
          <img
            src={logoUrl}
            style={{ width: "70%", aspectRatio: "1/1", objectFit: "contain" }}
            alt=""
          />
          {!!hideLabel && (
            <ExternalLink
              size={hideLabel ? "16px" : "14px"}
              style={{
                position: "absolute",
                top: "7%",
                right: "7%",
              }}
            />
          )}
        </Box>

        {!hideLabel && (
          <Stack direction="row" alignItems="center" color={palette.primary.dark1}>
            <Typography
              fontSize={typography.pxToRem(14)}
              lineHeight={typography.pxToRem(18)}
              color="inherit"
              whiteSpace="nowrap"
            >
              {name}
              <ExternalLink
                size="14px"
                style={{
                  marginLeft: "4px",
                  marginBottom: "-1px",
                  display: "inline-block",
                }}
              />
            </Typography>
          </Stack>
        )}
      </Box>
    </Link>
  )
}
