import { Box, Button, Stack, Typography, styled } from "@mui/material"
import { ChevronRight } from "react-feather"
import { OfferChip } from "../../../../common"
import { OfferSource } from "../../../types"

interface OfferProps {
  logoUrl?: string
  title: string
  source: OfferSource
  startDate: string
  onClick: () => void
}

export const Offer = ({
  logoUrl,
  source,
  title,
  startDate,
  onClick,
}: OfferProps) => (
  <Stack
    direction="row"
    component={Button}
    spacing={2}
    borderRadius={0}
    onClick={onClick}
    width="50%"
    px={3}
  >
    <Logo src={logoUrl} loading="lazy" />
    <Stack alignItems="flex-start" spacing={1} width="100%" height="100%">
      <OfferChip label={source} />
      <Description color="common.darkBlue">{title}</Description>
      <Typography
        color="grey.light"
        fontSize={(theme) => theme.typography.pxToRem(10)}
      >{`À partir du ${startDate}`}</Typography>
    </Stack>
    <Box color="common.darkBlue">
      <ChevronRight width={16} height={16} />
    </Box>
  </Stack>
)

const Description = styled(Typography)(({ theme }) => ({
  height: "100%",
  display: "-webkit-box",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
  whiteSpace: "break-spaces",
  overflow: "hidden",
  fontSize: theme.typography.pxToRem(12),
  textAlign: "left",
}))

const Logo = styled("img")`
  max-width: 45px;
  height: 45px;
  object-fit: contain;
`
