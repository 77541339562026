import {
  Box,
  Card,
  Divider,
  Stack,
  Typography,
  alpha,
  styled,
  useTheme,
} from "@mui/material"
import {
  MyBonusOperations,
  useBonusOpSummaryApi,
} from "@pharmupp/p3-bonus-operation-domain"
import { PaperContainerTitle, YearTabs } from "@pharmupp/p3-front-commons"
import {
  ApiLoyaltyCategoryEnum,
  LoyaltyCategoryList,
  getRouteForCategoryEnum,
  useLoyaltySummaryApi,
} from "@pharmupp/p3-loyalty-domain"
import { ComponentType, PropsWithChildren, useState } from "react"

import { LoyaltyGauge } from "./LoyaltyGauge"
import { BONUS_OP_COLOR, BonusOpIcon, ChallengesIcon } from "./layouts"

export const LoyaltyTracking = () => {
  const { palette } = useTheme()
  const [year, setYear] = useState(currentYear)

  // API
  const { summary: bonusOpSummary } = useBonusOpSummaryApi({ year })
  const { summary: loyaltySummary } = useLoyaltySummaryApi({ year })

  return (
    <Card component={Box} mt={3}>
      <Box pt={5} pb={3.5}>
        <LoyaltyTrackingGrid alignItems="center">
          <PaperContainerTitle mb={0}>Suivi fidélité</PaperContainerTitle>

          <YearTabs selectedYear={year} onChange={setYear} />
        </LoyaltyTrackingGrid>
      </Box>

      {/* CHARTS (half doughnuts) */}
      <PrimarySection pb={7}>
        <LoyaltyTrackingGrid
          bgcolor={alpha(palette.primary.main, 0.1)}
          borderRadius="6px"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            gap={{ xs: "5%", lg: "20%" }}
            py={7}
            gridColumn="2 / -1"
          >
            <LoyaltyGauge
              title="Primes vie des UPP"
              description="Total primes validées"
              currentValue={bonusOpSummary?.validated}
              maxValue={bonusOpSummary?.total}
              unit="€"
              color={BONUS_OP_COLOR}
            />
            <LoyaltyGauge
              title="Challenges"
              description="Total points cumulés"
              currentValue={loyaltySummary?.earnedPoint}
              maxValue={
                loyaltySummary?.lastYearEarnedPoints || loyaltySummary?.expectedPoint
              }
              lastYearValue={loyaltySummary?.lastYearEarnedPoints}
              unit="pts"
              color={palette.loyalty.main}
            />
          </Box>
        </LoyaltyTrackingGrid>
      </PrimarySection>

      <Stack
        py={8}
        divider={
          <LoyaltyTrackingGrid>
            <div />
            <Divider sx={{ my: 5 }} />
          </LoyaltyTrackingGrid>
        }
      >
        <TrackingSection
          title="Vie de mon UPP"
          description={`Primes actives pour ${year}`}
          Icon={BonusOpIcon}
        >
          <MyBonusOperations
            year={year}
            operationLink={(id: number) => `./operations-vie/${id}`}
            allOperationsLink={`../espace-president/operations-vie/${year}`}
          />
        </TrackingSection>
        <TrackingSection
          title="Challenges"
          description={`Barème proposé pour ${year}`}
          Icon={ChallengesIcon}
        >
          <LoyaltyCategoryList
            year={year}
            categoryLink={(categoryEnum: ApiLoyaltyCategoryEnum) =>
              `./challenges/${getRouteForCategoryEnum(categoryEnum)}/${year}`
            }
          />
        </TrackingSection>
      </Stack>
    </Card>
  )
}

const currentYear = new Date().getFullYear()

const PrimarySection = styled(Box)(({ theme }) => ({
  padding: `0 ${theme.spacing(11)} 0 ${theme.spacing(4)}`,
}))

const LoyaltyTrackingGrid = styled(Box)(({ theme }) => ({
  padding: `0 ${theme.spacing(11)} 0 ${theme.spacing(4)}`,
  display: "grid",
  gridTemplateColumns: "1fr 4fr",
  gap: theme.spacing(10),
  [theme.breakpoints.down("md")]: {
    padding: `0 ${theme.spacing(4)} 0 ${theme.spacing(2)}`,
    gridTemplateColumns: "1fr 5fr",
    gap: theme.spacing(4),
  },
}))

const TrackingSection = ({
  title,
  description,
  Icon,
  children,
}: PropsWithChildren<{
  title: string
  description: string
  Icon: ComponentType
}>) => (
  <LoyaltyTrackingGrid>
    <Stack spacing={2}>
      <Icon />
      <Typography
        fontSize={(theme) => theme.typography.pxToRem(14)}
        fontWeight={600}
        textTransform="uppercase"
      >
        {title}
      </Typography>
      <Typography fontSize={(theme) => theme.typography.pxToRem(13)}>
        {description}
      </Typography>
    </Stack>
    <Box>{children}</Box>
  </LoyaltyTrackingGrid>
)
