import { api, useQuery } from "@pharmupp/p3-front-commons"

export const useSalesSummaryApi = ({ labId }: { labId: string }) => {
  const endpoint = `/api/laboratories/adherent/${labId}/sales/summary`
  const queryKey = ["laboratories", labId, "adherent", "sales", "summary"]

  // GET
  const { data: salesSummary, isLoading } = useQuery({
    queryKey,
    queryFn: () => api.get<ApiSalesSummary>(endpoint),
  })

  return { salesSummary, isLoading }
}

export type ApiSalesSummary = {
  turnover: number
}
