import { Box, BoxProps, styled } from "@mui/material"

export const LogoCircle = styled(Box)<BoxProps & { size?: string }>(
  ({ size = "28px" }) => ({
    borderRadius: "150px",
    borderWidth: "1px",
    borderStyle: "solid",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...(size && {
      width: size,
      height: size,
    }),
    "& *": {
      fontSize: "70%",
      fontWeight: 800,
    },
    "& svg": {
      width: "60%",
    },
  }),
)
LogoCircle.defaultProps = {
  color: "white",
}
