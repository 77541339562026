import { Tab } from "@pharmupp/p3-front-commons"
import { SpecificTimeRange } from "./SpecificTimeRange"

const year = new Date().getFullYear()
export const specificTimeRangeTabs: Tab[] = [
  {
    Element: (props) => <SpecificTimeRange {...props} timeRange="non-validated" />,
    label: "Commandes en cours",
    to: "en-cours",
  },
  {
    Element: (props) => (
      <SpecificTimeRange {...props} timeRange="last-three-months" />
    ),
    label: "3 derniers mois",
    to: "latest",
  },
  {
    Element: (props) => <SpecificTimeRange {...props} timeRange="this-year" />,
    label: year,
    to: year.toString(),
  },
  {
    Element: (props) => <SpecificTimeRange {...props} timeRange="last-year" />,
    label: year - 1,
    to: (year - 1).toString(),
  },
  {
    Element: (props) => <SpecificTimeRange {...props} timeRange="two-years-ago" />,
    label: year - 2,
    to: (year - 2).toString(),
  },
  {
    Element: (props) => <SpecificTimeRange {...props} timeRange="three-years-ago" />,
    label: year - 3,
    to: (year - 3).toString(),
  },
]
