import { ROLES, Yup } from "@pharmupp/p3-front-commons"

const sdavCustomerServiceContactShape = Yup.object().shape(
  {
    firstName: Yup.string().nullable().required("Le prénom est requis"),
    lastName: Yup.string().nullable().required("Le nom est requis"),
    phone: Yup.string()
      .nullable()
      .when("email", {
        is: (email?: string) => !email,
        then: (schema) =>
          schema.required("Un numéro de téléphone ou un email est requis"),
      }),
    email: Yup.string()
      .email("Email non valide")
      .nullable()
      .when("phone", {
        is: (phone?: string) => !phone,
        then: (schema) =>
          schema.required("Un numéro de téléphone ou un email est requis"),
      }),
  },
  [["phone", "email"]],
)

const baseSchema = {
  offerType: Yup.string().nullable().required("Le type d'offre est requis"),
  freeShippingThreshold: Yup.number()
    .nullable()
    .min(0, "Le franco de port doit être positif")
    .required("Le franco de port est requis"),
  deliveryTimeInDays: Yup.number()
    .nullable()
    .min(0, "Le délai doit être positif")
    .required("Le délai est requis"),
  billingEntity: Yup.string()
    .nullable()
    .required("L'entité de facturation est requise"),
  billingDeadlineInDays: Yup.number()
    .nullable()
    .min(1, "Le délai doit être supérieur à 1")
    .required("Le délai est requis"),
  sdavCustomerServiceContact: sdavCustomerServiceContactShape,
  products: Yup.array().of(Yup.object()),
  discountInformation: Yup.array().of(Yup.object()),
  conditionsSheet: Yup.array().of(Yup.object()),
}

export const schemaRoleConf = {
  [ROLES.ADMIN]: {
    schema: Yup.object({ ...baseSchema }),
  },
  [ROLES.EXTERN_CONTACT_LABORATORY]: {
    schema: Yup.object({
      ...baseSchema,
      products: Yup.array()
        .of(Yup.object())
        .min(1, "Une liste de produits est requise"),
      discountInformation: Yup.array()
        .of(Yup.object())
        .min(1, "Un barême quantité/remise est requis"),
      conditionsSheet: Yup.array()
        .of(Yup.object())
        .min(1, "Une fiche condition est requise"),
    }),
  },
}
