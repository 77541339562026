import { Box, Stack, Typography, styled, useTheme } from "@mui/material"
import { ChevronRight, Gift } from "react-feather"
import { Link } from "react-router-dom"
import { AmountChip } from "../../dataDisplay"
import { CardLine, CardLineAction, ICON_SIZE, SmallLabel } from "./common"

interface GoalTrackingWithoutProgressProps {
  tag: string
  title: string
  link: string
  maxGain: number
  maxGainTitle?: string
  gainUnit?: string
}
export const GoalTrackingWithoutProgress = ({
  tag,
  title,
  link,
  maxGain,
  maxGainTitle = "Prime",
  gainUnit = "€",
}: GoalTrackingWithoutProgressProps) => {
  const theme = useTheme()

  return (
    <Stack spacing={1}>
      {/* TITLE */}
      <Typography ml={2} color="grey.dark">
        {tag}
      </Typography>

      {/* ROW */}
      <Stack direction="row" spacing={2}>
        {/* PROGRESS LINE */}
        <CardLine>
          <CardLineAction
            {...(link
              ? { component: Link, to: link }
              : { sx: { pointerEvents: "none" } })}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Gift color={theme.palette.grey.ultraLight} />
                <Title>{title}</Title>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <LinkText>Détail de l&apos;opération</LinkText>
                <ChevronRight color={theme.palette.grey.dark} size={ICON_SIZE} />
              </Stack>
            </Stack>
          </CardLineAction>
        </CardLine>

        {/* TARGET CHIP */}
        <Box position="relative">
          <SmallLabel
            position="absolute"
            top={theme.spacing(-3)}
            textAlign="center"
            width="100%"
          >
            {maxGainTitle}
          </SmallLabel>
          <AmountChip
            color={theme.palette.common.electricBlue}
            unit={gainUnit}
            prefix="+"
            variant="outlined"
            sx={{ minWidth: "120px", height: "100%" }}
          >
            {maxGain}
          </AmountChip>
        </Box>
      </Stack>
    </Stack>
  )
}

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
}))

const LinkText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  color: theme.palette.common.electricBlue,
}))
