import { Yup } from "@pharmupp/p3-front-commons"
import { fields } from "./participantCondition.const"

export const schema = Yup.object({
  [fields.freeOfCharge]: Yup.boolean().required(),
  [fields.kitOrderStartDate]: Yup.date()
    .nullable()
    .required("La date de début de commande des kits est obligatoire"),
  [fields.kitOrderEndDate]: Yup.date()
    .nullable()
    .required("La date de fin de commande des kits est obligatoire"),
  [fields.deliveryCarrier]: Yup.string().max(255, "max length: 255").nullable(),
  [fields.deliveryDate]: Yup.date()
    .nullable()
    .required("La date de livraison est obligatoire"),
  [fields.nbLoyaltyPoints]: Yup.number().min(1, "loyalty point min 1").nullable(),
  [fields.numProofDocuments]: Yup.number().nullable().min(0),
  [fields.completionProofDetails]: Yup.string().nullable(),
})
