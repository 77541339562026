import { ROLES, api, useQuery, useRoleConf } from "@pharmupp/p3-front-commons"
import { EventRubric, LocationType } from "../model"

export const useEventsListApi = () => {
  const { listEndpoint, referentialEndpoint, getDeleteEndpoint } =
    useRoleConf(apiRoleConf)

  // Referential
  const { data: referential } = useQuery<ListReferential>({
    queryKey: ["news", "events", "referential", "list"],
    queryFn: () => api.get(referentialEndpoint),
  })

  return {
    referential,
    listEndpoint,
    getDeleteEndpoint,
  }
}

export interface ListEvent {
  id: number
  startDate: string
  publicationDate: string
  title: string
  rubric: EventRubric
  locationType: LocationType
  registrationDeadline: string
  registeredCount: number
  maximumGuests?: number | null
  creatorTypeEnum?: "ADMINISTRATOR" | "PRESIDENT_UPP"
  status: string
  views: number
  subscribedCount?: number
}

interface ListReferential {
  statuses: { code: string; label: string; color: string }[]
}

const apiRoleConf = {
  [ROLES.ADMIN]: {
    listEndpoint: "/api/news/event",
    referentialEndpoint: "/api/news/referential/list/events",
    getDeleteEndpoint: (id: number) => `/api/news/event/${id}`,
  },
  [ROLES.UPP_PRESIDENT]: {
    listEndpoint: "/api/news/upp/event/",
    referentialEndpoint: "/api/news/upp/referential/list/events",
    getDeleteEndpoint: (id: number) => `/api/news/upp/event/${id}`,
  },
}
