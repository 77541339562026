import {
  Button,
  Chip as MuiChip,
  Stack,
  Typography,
  alpha,
  chipClasses,
  styled,
  useTheme,
} from "@mui/material"
import { NumberFormat, Price } from "@pharmupp/p3-front-commons"

export const HeadSectionTitle = ({ children }: { children: string }) => (
  <Typography fontWeight={600} color="common.darkBlue">
    {children}
  </Typography>
)

export const PointsChip = ({ children }: { children: number }) => {
  const theme = useTheme()
  const color = theme.palette.common.orange
  return (
    <Button
      variant="outlined"
      sx={{
        borderColor: color,
        pointerEvents: "none",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={0.5}
        fontWeight={600}
        color={color}
        pb={0.5}
        borderBottom={`1px solid ${color}`}
        lineHeight={theme.typography.pxToRem(16)}
      >
        <Typography
          fontSize={theme.typography.pxToRem(16)}
          lineHeight={theme.typography.pxToRem(16)}
          fontWeight="600"
        >
          +
        </Typography>
        <NumberFormat suffix="pts" value={children} />
      </Stack>
    </Button>
  )
}

export const PriceChip = ({ children }: { children: number }) => {
  const theme = useTheme()
  const color = theme.palette.primary.main
  return (
    <Button
      variant="outlined"
      sx={{
        borderColor: color,
        pointerEvents: "none",
      }}
    >
      <Stack
        direction="row"
        spacing={0.5}
        pb={0.5}
        alignItems="center"
        borderBottom={`1px solid ${color}`}
        fontWeight={600}
        lineHeight={theme.typography.pxToRem(16)}
      >
        <Typography
          fontSize={theme.typography.pxToRem(16)}
          lineHeight={theme.typography.pxToRem(16)}
          fontWeight="600"
          color={theme.palette.success.main}
        >
          +
        </Typography>
        <Price value={children} />
      </Stack>
    </Button>
  )
}

export const StatusChip = styled(MuiChip, {
  shouldForwardProp: (propName) => propName !== "mainColor",
})<{ mainColor: string }>(({ theme, mainColor }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
  borderColor: alpha(mainColor, 0.2),
  borderRadius: "100px",
  color: mainColor,
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 500,
  height: "48px",
  [`.${chipClasses.icon}`]: {
    height: theme.typography.pxToRem(19),
    color: mainColor,
  },
}))
StatusChip.defaultProps = { variant: "outlined" }
