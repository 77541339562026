import { FormTab } from "@pharmupp/p3-front-commons"
import GeneralInfos from "./GeneralInfos.component"
import { generalInfosSchema } from "./GeneralInfos.schema"

export const generalInfosTab: FormTab = {
  label: "Informations générales",
  to: "informations-generales",
  schema: generalInfosSchema,
  Element: GeneralInfos,
}
