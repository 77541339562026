import {
  ROLES,
  RoleStrategy,
  api,
  useQuery,
  useRoleConf,
} from "@pharmupp/p3-front-commons"
import { OperationSection } from "../../model"

export const useMyMarketingOpSummary = ({
  id,
  year,
}: {
  id?: string
  year: number
}) => {
  const getEndpoint = useRoleConf(apiRoleConf)
  const queryKey = ["marketing-operations", "adherent", "summary", year]
  if (id) queryKey.push(id)
  const { data: operationsSheet, isLoading } = useQuery({
    queryKey,
    queryFn: () =>
      api.get<ApiMarketingOpAdhSummary>(`${getEndpoint(id)}?year=${year}`),
  })
  return { operationsSheet, isLoading }
}

export interface ApiMarketingOpAdhSummary {
  [OperationSection.VALIDATED]: SectionSummary
  [OperationSection.REGISTERED]: SectionSummary
  [OperationSection.ALL]: SectionSummary
  [OperationSection.REQUIRES_PROOF]: SectionSummary
  [OperationSection.REGISTRABLE]: SectionSummary
  [OperationSection.IN_PROGRESS]: SectionSummary
}

interface SectionSummary {
  count: number
  pointsCount?: number
  requiresProofCount?: number
}

const apiRoleConf: RoleStrategy<(id?: string) => string> = {
  [ROLES.ADMIN]: (id) =>
    `/api/operation/marketing/pharmasheet/adherent/${id}/summary`,
  [ROLES.UPP_PRESIDENT]: (id) =>
    id
      ? `/api/operation/marketing/upp/adherent/${id}/summary`
      : "/api/operation/marketing/adherent/summary",
  [ROLES.ADHERENT_ADVISOR]: (id) =>
    `/api/operation/marketing/my-sector/adherent/${id}/summary`,
  [ROLES.ADHERENT_HOLDER]: () => "/api/operation/marketing/adherent/summary",
  [ROLES.ADHERENT_TEAM]: () => "/api/operation/marketing/adherent/summary",
}
