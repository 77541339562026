const flatValues = (object: Record<string, any>): any =>
  Object.values(object).flatMap((current) =>
    typeof current === "string" ? [current] : flatValues(current),
  )

const convertObjectValues = (obj: Record<string, any>, cur: any, tar: any): any => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, val]) => {
      if (val === Object(val)) {
        return [key, convertObjectValues(val, cur, tar)]
      }

      return [key, val === cur ? tar : val]
    }),
  )
}

const getMaxStringLengthMessage = (fieldLabel: string, number: number) =>
  `${fieldLabel} doit comporter au maximum ${number} caractères`

const getMinDateMessage = (fieldLabel: string, minDate: Date) => {
  return getMinValueMessage(fieldLabel, false, minDate.toLocaleDateString("fr-FR"))
}

const getMinValueMessage = (
  fieldLabel: string,
  isFieldLabelMasculine: boolean,
  minValue: string | number,
) => {
  return `${fieldLabel} doit être supérieur${
    isFieldLabelMasculine ? "" : "e"
  } à ${minValue}`
}

export {
  convertObjectValues,
  flatValues,
  getMaxStringLengthMessage,
  getMinDateMessage,
  getMinValueMessage,
}
