export const Inventory = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28">
    <g id="Fiche-labo" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g
        id="Catalogues---Détail-laboratoire-Copy-7"
        fill="#8C20B1"
        fillRule="nonzero"
        transform="translate(-1009 -569)"
      >
        <g id="Group-5" transform="translate(458 548)">
          <g id="Group-15" transform="translate(520 21)">
            <g id="Group" transform="translate(31.538)">
              <path
                id="Shape"
                d="M26.477 5.65H16.211c-.84 0-1.522.683-1.522 1.522v9.701c0 .84.683 1.523 1.522 1.523h10.266c.84 0 1.523-.683 1.523-1.523v-9.7c0-.84-.683-1.523-1.523-1.523Zm.64 11.223a.64.64 0 0 1-.64.64H16.211a.64.64 0 0 1-.64-.64v-9.7a.64.64 0 0 1 .64-.64h3.262v4.077a.176.176 0 0 0 .274.147l1.597-1.064 1.597 1.064a.176.176 0 0 0 .181.01.176.176 0 0 0 .094-.157V6.532h3.261a.64.64 0 0 1 .64.64v9.701Z"
              />
              <path
                id="Path"
                d="M21.468 22.033H18.14c-.969 0-1.757.788-1.757 1.757v3.328H1.757a.875.875 0 0 1-.874-.875V1.757c0-.482.392-.874.874-.874h18.836c.483 0 .874.392.874.874v2.639h.883V1.757C22.35.788 21.562 0 20.593 0H1.757C.788 0 0 .788 0 1.757v24.486C0 27.212.788 28 1.757 28h15.067c.015 0 .03 0 .044-.002l.015-.002a.36.36 0 0 0 .028-.004l.019-.005.022-.005.019-.007.022-.008.016-.008.023-.01.016-.01.02-.012.019-.014.017-.012a.367.367 0 0 0 .032-.03l5.085-5.084c.01-.01.018-.02.026-.03l.007-.009a.6.6 0 0 0 .018-.024l.006-.009a.47.47 0 0 0 .037-.07l.003-.008a.439.439 0 0 0 .023-.076.481.481 0 0 0 .007-.05l.001-.03V19.65h-.882v2.383Z"
              />
              <path
                id="Path"
                d="m7.139 12.255-2.086 2.053-.703-.76-1.038.957 1.197 1.297c.13.14.311.223.502.227h.017a.706.706 0 0 0 .496-.202l2.605-2.565-.99-1.007Z"
              />
              <path id="Rectangle" d="M9.889 5.928h2.807v1H9.889z" />
              <path
                id="Path"
                d="M7.139 4.599 5.053 6.652l-.703-.761-1.038.958 1.197 1.297c.13.14.311.223.502.227h.017a.706.706 0 0 0 .496-.202l2.605-2.565-.99-1.007Z"
              />
              <path
                id="Rectangle"
                d="M9.889 13.585h2.981v1H9.889zM9.889 21.241h4.645v1H9.889z"
              />
              <path
                id="Path"
                d="m6.766 19.65-1.034 1.033-1.033-1.033-.999.999 1.034 1.033L3.7 22.716l.999.998 1.033-1.033 1.034 1.033.999-.998-1.034-1.034 1.034-1.033z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
