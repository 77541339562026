import { ReactNode } from "react"
import { AlertCircle } from "react-feather"
import { CommonsDialogProps, Dialog } from "../Dialog"

export interface ConfirmDialogProps
  extends Pick<CommonsDialogProps, "open" | "title"> {
  message: ReactNode
  validateButtonText?: string
  validateAction: () => void
  cancelAction?: () => void
  onClickClose: () => void
}

export const ConfirmDialog = ({
  open,
  title,
  message,
  validateButtonText,
  validateAction,
  cancelAction,
  onClickClose,
}: ConfirmDialogProps) => (
  <Dialog
    Icon={AlertCircle}
    title={title}
    validateAction={() => {
      validateAction()
      onClickClose()
    }}
    validateText={validateButtonText}
    cancelAction={() => {
      cancelAction?.()
      onClickClose()
    }}
    open={open}
    onClose={onClickClose}
  >
    {message}
  </Dialog>
)
