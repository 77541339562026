import {
  ROLES,
  RoleStrategy,
  formatDateWithDots,
  useResourceListApi,
  useRoleConf,
} from "@pharmupp/p3-front-commons"

export const selectNotification = (notifications: ApiNotification[]) =>
  notifications.map<Notification>((notification) => ({
    ...notification,
    title: getTitleFromType(notification.type),
    criticality:
      notification.domain === "MARKETING_OPERATION" &&
      notification.type === "RESEND_PROOF_DOCUMENT"
        ? "HIGH"
        : "MEDIUM",
    deadline: formatDateWithDots(notification.deadline),
    link: getLink({ domain: notification.domain, entityId: notification.entityId }),
    linkText: getLinkText({ domain: notification.domain, type: notification.type }),
  }))

export const useMyNotifications = ({
  size,
  adherentId,
}: {
  size?: number
  adherentId?: string
}) => {
  const endpoint = useRoleConf(obtainNotificationEndpointForRole).notification(
    adherentId,
  )

  const { list, paginationProps, initialLoading } = useResourceListApi<
    ApiNotification,
    Notification
  >({
    resourceEndpoint: endpoint,
    queryKey: ["notifications"],
    rowsPerPage: size,
    select: selectNotification,
  })

  return {
    list,
    count: paginationProps.count,
    isLoading: initialLoading,
  }
}

export type Notification = ApiNotification & {
  title: string
  criticality: "LOW" | "MEDIUM" | "HIGH"
  link: string | null
  linkText: string | null
}

export interface ApiNotification {
  domain: ApiDomain
  type: ApiNotificationType
  tag?: ApiNotificationTag
  description: string
  entityId: number
  deadline: string
}

export type ApiDomain = "MARKETING_OPERATION" | "E_SIGNATURE"

export type ApiNotificationType =
  | "REGISTER"
  | "SEND_PROOF_DOCUMENT"
  | "RESEND_PROOF_DOCUMENT"
  | "SIGN_DOCUMENT"

export type ApiNotificationTag = "PROOF_DOCUMENT_REFUSED" | "E_SIGNATURE"

function getTitleFromType(type: ApiNotificationType) {
  switch (type) {
    case "REGISTER":
      return "S'inscrire"
    case "SEND_PROOF_DOCUMENT":
      return "Opé. marketing à justifier"
    case "RESEND_PROOF_DOCUMENT":
      return "Nouveau justificatif à fournir"
    case "SIGN_DOCUMENT":
      return "Document à signer"
  }
}

function getLinkText({ domain, type }: Pick<Notification, "domain" | "type">) {
  switch (domain) {
    case "MARKETING_OPERATION":
      switch (type) {
        case "REGISTER":
          return "S'inscrire"
        case "SEND_PROOF_DOCUMENT":
        case "RESEND_PROOF_DOCUMENT":
          return "Justifier"
        default:
          return null
      }
    case "E_SIGNATURE":
      switch (type) {
        default:
          return "Signer"
      }

    default:
      return "Voir"
  }
}

function getLink({ domain, entityId }: Pick<Notification, "domain" | "entityId">) {
  switch (domain) {
    case "MARKETING_OPERATION":
      return `/operations/liste/${entityId}`
    default:
      return null
  }
}

const obtainNotificationEndpointForRole: RoleStrategy<{
  notification: (adherentId?: string) => string
}> = {
  [ROLES.ADHERENT_HOLDER]: {
    notification: () => "/api/notifications/my",
  },
  [ROLES.ADHERENT_TEAM]: {
    notification: () => "/api/notifications/my",
  },
  [ROLES.ADHERENT_ADVISOR]: {
    notification: (adherentId) =>
      `/api/notifications/my-sector/adherent/${adherentId}/`,
  },
  [ROLES.ADMIN]: {
    notification: (adherentId) => `/api/notifications/adherent/${adherentId}/`,
  },
}
