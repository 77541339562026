import * as Sentry from "@sentry/react"
import { findLast } from "lodash-es"
import { FC, useMemo } from "react"
import {
  Location,
  RouteObject,
  RouterProvider,
  createBrowserRouter,
  resolvePath,
  useLocation,
  useNavigate,
} from "react-router-dom"
import { AppErrorElement } from "../layout"

const MAX_HISTORY_LENGTH = 10
const historyStack: Location[] = []

export const useHistory = () => {
  const navigate = useNavigate()
  const location = useLocation()
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const initLocation = useMemo(() => location, [])

  return {
    stack: historyStack,
    /**
     * Resolve the given relative path relatively to the hook init path
     * then navigate to the last matching occurrence in history or fallback
     * by navigating using relative path
     */
    goBack: (defaultRelativePath: string) => {
      const { pathname } = resolvePath(defaultRelativePath, initLocation.pathname)
      const match = findLast(historyStack, (el) => el.pathname === pathname)
      navigate(match ? match.pathname + match.search : defaultRelativePath)
    },
  }
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

export const AppRouter: FC<{
  routes?: RouteObject[]
}> = ({ routes = [] }) => {
  const router = useMemo(
    () =>
      sentryCreateBrowserRouter([
        {
          errorElement: <AppErrorElement />,
          children: routes,
        },
      ]),
    [routes],
  )

  router.subscribe((state) => {
    if (state.historyAction === "PUSH") {
      const length = historyStack.push(state.location)
      if (length > MAX_HISTORY_LENGTH) {
        historyStack.splice(0, historyStack.length - MAX_HISTORY_LENGTH)
      }
    }
  })

  return <RouterProvider router={router} />
}
