import {
  Alert,
  FilterSearchAndSelect,
  Table,
  TableProps,
} from "@pharmupp/p3-front-commons"
import { ReactNode } from "react"
import { TagObject, openInvoice, tagObjectRecord } from "../../../../Offers"
import { ApiOrder } from "../../types"
import {
  Laboratory,
  OffersOrderReferential,
  useAdherentOrdersReferential,
} from "../../useAdherentOrdersReferential"

type OrderTableProps = {
  displayCancelled?: boolean
  displayStatusFilter?: boolean
  showDateYear?: boolean
  alertText?: ReactNode
  extraFilterElements?: ReactNode
} & ReturnType<typeof useAdherentOrdersReferential> &
  Pick<
    TableProps<ApiOrder, OrderRow>,
    "queryConfig" | "emptyMessage" | "exportButtonProps"
  > &
  Required<Pick<TableProps<ApiOrder, OrderRow>, "columns">>

export const OrderTable = ({
  queryConfig,
  referential,
  displayCancelled = true,
  displayStatusFilter = true,
  showDateYear = false,
  alertText,
  extraFilterElements,
  ...props
}: OrderTableProps) => (
  <Table<ApiOrder, OrderRow>
    queryConfig={{
      ...queryConfig,
      select: getOrdersSelector({ displayCancelled, showDateYear, referential }),
    }}
    renderAlerts={() =>
      !!alertText && (
        <Alert
          severity="info"
          variant="standard"
          sx={{ borderRadius: "0px", px: 4 }}
        >
          <i>{alertText}</i>
        </Alert>
      )
    }
    FilterSectionElement={
      !!referential && (
        <>
          {extraFilterElements}
          <FilterSearchAndSelect
            name="offerSource"
            label="Canal"
            options={referential.sources}
          />
          <FilterSearchAndSelect
            name="tag"
            label="Catégorie"
            options={referential.tags}
          />
          <FilterSearchAndSelect
            name="labId"
            label="Laboratoires"
            options={referential.laboratories}
          />
          {!!displayStatusFilter && (
            <FilterSearchAndSelect
              name="status"
              label="Statut"
              options={[
                { label: "Validée", value: "VALIDATED" },
                { label: "En cours", value: "NOT_VALIDATED" },
                { label: "Annulée", value: "CANCELLED" },
              ]}
            />
          )}
        </>
      )
    }
    fixedLayout
    onRowClick={(row) => openInvoice(row.viewLink)}
    rowClickCondition={(row) => !!row.viewLink}
    renderRowTooltip={(row) => {
      if (!row.viewLink) {
        return <p>⚠️ Commande indisponible</p>
      }
    }}
    {...props}
  />
)

export type OrderRow = ApiOrder & {
  displayCancelled: boolean
  showDateYear: boolean
  offerTag?: TagObject
  offerSource: string
  laboratory: Laboratory | null
}

export const getOrdersSelector =
  ({
    displayCancelled = true,
    showDateYear = false,
    referential,
  }: {
    displayCancelled?: boolean
    showDateYear?: boolean
    referential: OffersOrderReferential
  }) =>
  (orders: ApiOrder[]): OrderRow[] =>
    orders.map((order) => ({
      ...order,
      displayCancelled,
      showDateYear,
      offerTag: order.offer.tag ? tagObjectRecord[order.offer.tag] : undefined,
      offerSource:
        referential?.sourcesRecord[order.offer.source] ?? order.offer.source,
      laboratory:
        order.laboratoryId && referential
          ? referential.laboratoriesRecord[order.laboratoryId]
          : null,
    }))
