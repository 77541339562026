/**
 * Resize the given image file to the given width and height
 * Handles portrait/landscape
 */
export const resizeImage = async (file: File, maxPixels: number) => {
  // Playwright has no FileReader, so we can't read the file content
  if (import.meta.env.VITE_TEST === "true") return file

  const imgPath = await new Promise<string>((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      resolve(reader.result as string)
    }
    reader.readAsDataURL(file)
  })

  const canvas = document.createElement("canvas")
  const ctx = canvas.getContext("2d")!
  const img = new Image()

  return new Promise<File>((resolve) => {
    img.onload = () => {
      const { newWidth, newHeight } = getResizedImgDimensions(img, maxPixels)

      canvas.width = newWidth
      canvas.height = newHeight

      // step 1: create an offscreen canvas and resize by using
      // bi-linear interpolation (1 steps of 0/5 scale down)
      const oc = document.createElement("canvas")
      const octx = oc.getContext("2d")!
      oc.width = img.width * 0.5
      oc.height = img.height * 0.5
      octx.drawImage(img, 0, 0, oc.width, oc.height)

      // step 2: draw scaled
      ctx.drawImage(oc, 0, 0, oc.width, oc.height, 0, 0, canvas.width, canvas.height)

      canvas.toBlob((blob) => {
        if (blob === null) throw new Error("Failed to create blob")
        resolve(new File([blob], file.name, { type: "image/jpeg" }))
      }, "image/jpeg")
    }
    img.src = imgPath
  })
}

/**
 * Returns the new width and height of the image after resizing it to maxPixels
 */
export function getResizedImgDimensions(
  img: HTMLImageElement,
  maxPixels: number,
): { newWidth: number; newHeight: number } {
  // No need to resize ?
  if (img.width * img.height <= maxPixels)
    return { newWidth: img.width, newHeight: img.height }

  const currentPixelAmount = img.width * img.height
  const ratio = Math.sqrt(maxPixels / currentPixelAmount)

  return {
    newWidth: Math.round(img.width * ratio),
    newHeight: Math.round(img.height * ratio),
  }
}
