import { Link, Stack, Typography } from "@mui/material"

export const OfferName = ({ offer, href }: { offer: string; href?: string }) => {
  return (
    <Stack spacing={2}>
      <Typography
        bgcolor="primary.dark"
        color="common.white"
        textTransform="uppercase"
        borderRadius="2px"
        textAlign="center"
        px={1}
      >
        {offer}
      </Typography>
      <Typography
        component={Link}
        href={href}
        color="text.secondary"
        fontSize={(theme) => theme.typography.pxToRem(12)}
        underline="hover"
      >
        {"Voir toutes les offres >"}
      </Typography>
    </Stack>
  )
}
