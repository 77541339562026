import { useTheme } from "@mui/material"
import { ArrowRight, TrendingDown, TrendingUp } from "react-feather"

export function useEvolutionUI(value?: number | null) {
  const theme = useTheme()

  if (value != null && value > 0) {
    return {
      Icon: TrendingUp,
      color: theme.palette.success.main,
      prefix: "+",
    }
  }
  if (value != null && value < 0) {
    return {
      Icon: TrendingDown,
      color: theme.palette.error.main,
      prefix: "-",
    }
  }
  return {
    Icon: ArrowRight,
    color: theme.palette.grey.light,
    prefix: "",
  }
}
