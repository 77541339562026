import { AccessAlarmOutlined, TimerOutlined } from "@mui/icons-material"
import { Theme } from "@mui/material"
import { Clock, Layers, Tag } from "react-feather"
import { OfferTypeValue, TypeObject } from "../../types"

export type TypeObjectRecord = Record<OfferTypeValue, TypeObject>
export const getTypeObjectRecord = ({ palette }: Theme): TypeObjectRecord => ({
  GROUPED: {
    label: "Groupée",
    color: palette.secondary.light1,
    Icon: Layers,
  },
  IMMEDIATE: {
    label: "Immédiate",
    color: palette.common.orange,
    Icon: AccessAlarmOutlined,
  },
  PERMANENT: {
    label: "Permanente",
    color: palette.common.electricBlue,
    Icon: Clock,
  },
  PREORDER: {
    label: "Précommande",
    color: palette.tertiary.dark1,
    Icon: TimerOutlined,
  },
  PROMOTION: {
    label: "Promotion des ventes",
    color: palette.common.pink,
    Icon: Tag,
  },
  STANDARD: {
    label: "Commande Standard",
    color: palette.common.electricBlue,
    Icon: Clock,
  },
})
