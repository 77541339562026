import {
  Box,
  CardActionArea,
  Paper,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import {
  Chip,
  MarkdownDisplay,
  formatDateWithDots,
  getPublicFileUrl,
} from "@pharmupp/p3-front-commons"
import { formatDistanceToNowStrict, isSameDay } from "date-fns"
import { fr as frLocale } from "date-fns/locale"
import { useMemo } from "react"
import { Calendar, ChevronRight, Clock } from "react-feather"
import { Link } from "react-router-dom"
import { FlashChip } from "../../components"
import { ApiDisplayNewsReferential, PostRubric } from "../../model"
import { LatestNewsItemModel } from "./LatestNewsItem.model"

export type LatestNewsItemVariant = "list" | "search"

export interface LatestNewsItemProps {
  item: LatestNewsItemModel
  referential: ApiDisplayNewsReferential
  variant?: LatestNewsItemVariant
  link?: (id: number) => string
}

export const LatestNewsItem = ({
  item,
  referential,
  variant = "list",
}: LatestNewsItemProps) => {
  const theme = useTheme()
  const category = useMemo(
    () => referential.uppCategories.find((ref) => ref.code === item.category),
    [item, referential],
  )

  const displayUppNewsPostChip =
    item.rubric === PostRubric.UPP_GROUP &&
    item.postType === "NEWS_POST" &&
    !!category
  const displayEventChip = item.postType === "EVENT"

  const borderRadiusCard =
    variant === "search"
      ? "6px!important"
      : item.rubric === PostRubric.UPP_GROUP && item.postType !== "EVENT"
        ? "0px!important"
        : undefined

  const selectLink = `/actualites/${item.id}`

  return (
    <Paper
      component={Box}
      width="100%"
      height="100%"
      minHeight={getRubricMinHeight(variant, item.rubric)}
      borderRadius={borderRadiusCard}
      position="relative"
      sx={{ overflow: "visible" }}
    >
      <CardActionArea component={Link} to={selectLink} sx={{ height: "100%" }}>
        {/* UPP CHIP */}
        {item.creatorType === "PRESIDENT_UPP" && (
          <PresidentInfoChip>Info président</PresidentInfoChip>
        )}
        {/* CONTENT */}
        <Box p={2} height="100%">
          <Stack spacing={2} height="100%">
            {item.rubric === PostRubric.LAB_EXTERN && variant !== "search" && (
              <Box height="50px" width="50%">
                {!!item.logo && <Logo src={getPublicFileUrl(item.logo)} alt="" />}
              </Box>
            )}
            {item.rubric === PostRubric.HEALTH_PHARMA && variant !== "search" && (
              <Box height="150px" width="100%">
                <Image
                  src={
                    item.image
                      ? getPublicFileUrl(item.image)
                      : "/news-placeholder.png"
                  }
                  alt="Image"
                  sx={{ borderRadius: "6px" }}
                />
              </Box>
            )}
            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-start"
              alignItems="center"
            >
              {!!(item.flash || displayUppNewsPostChip || displayEventChip) && (
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {!!item.flash && <FlashChip />}
                  {!!displayUppNewsPostChip && (
                    <Chip
                      label={category.label}
                      size="small"
                      fontColor={category.color?.fontColor}
                      backgroundColor={category.color?.bkgColor}
                      sx={{ fontSize: "0.6rem" }}
                    />
                  )}
                  {!!displayEventChip && (
                    <Chip
                      label="EVENEMENT"
                      size="small"
                      fontColor={theme.palette.common.white}
                      backgroundColor={theme.palette.common.electricBlue}
                      sx={{ fontSize: "0.6rem" }}
                    />
                  )}
                </Stack>
              )}

              <DisplayDate item={item} />
            </Stack>
            {item.rubric === PostRubric.LAB_EXTERN && variant === "search" && (
              <Box height="50px" width="50%" mt={3}>
                {!!item.logo && <Logo src={getPublicFileUrl(item.logo)} alt="" />}
              </Box>
            )}
            <Stack justifyContent="space-between" height="100%" spacing={2}>
              <Stack>
                <Typography
                  fontSize="0.9rem"
                  fontWeight="bold"
                  color="common.darkBlue"
                >
                  {item.title}
                </Typography>

                {item.rubric !== PostRubric.LAB_EXTERN && (
                  <Description mt={1}>
                    <MarkdownDisplay ignoreFormatting>
                      {item.description}
                    </MarkdownDisplay>
                  </Description>
                )}
              </Stack>

              <Typography
                color="common.electricBlue"
                fontWeight="600"
                display="flex"
                flexDirection="row"
                alignItems="center"
                fontSize={theme.typography.pxToRem(12)}
              >
                {getLinkLabel(variant, item.rubric)}
                <ChevronRight size="1.2em" />
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </CardActionArea>
    </Paper>
  )
}

const getLinkLabel = (variant: LatestNewsItemVariant, rubric: PostRubric) => {
  switch (variant) {
    case "search":
      return "Lire la suite"
    default:
      switch (rubric) {
        case PostRubric.UPP_GROUP:
          return "Lire la suite"
        case PostRubric.LAB_EXTERN:
          return "Voir plus"
        case PostRubric.HEALTH_PHARMA:
          return "Lire l'article"
        default:
          return "erreur"
      }
  }
}

const getRubricMinHeight = (variant: LatestNewsItemVariant, rubric: PostRubric) => {
  switch (variant) {
    case "search":
      return "240px"
    case "list":
      switch (rubric) {
        case PostRubric.UPP_GROUP:
        case PostRubric.LAB_EXTERN:
          return "210px"
        case PostRubric.HEALTH_PHARMA:
          return "345px"
      }
  }
}

const Image = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
})

const Logo = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "contain",
  objectPosition: "left",
})

const Description = styled(Box)({
  fontSize: "0.75rem",
  color: "common.darkBlue",
  wordBreak: "break-word",
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  /* number of lines to show */
  WebkitLineClamp: "2",
  lineClamp: "2",
})

const DisplayDate = ({ item }: { item: LatestNewsItemModel }) => {
  if (item.postType === "EVENT") {
    return (
      <Stack direction="row" spacing={0.5} color="common.electricBlue">
        <Calendar size="1rem" />
        <Typography fontSize="0.75rem">
          {formatDateWithDots(item.startDate)}
        </Typography>
      </Stack>
    )
  }

  const publicationDate = new Date(item.publicationDate)

  return (
    <Stack direction="row" spacing={0.5} color="grey.light">
      <Clock size="1rem" />
      <Typography fontSize="0.75rem">
        {isSameDay(new Date(), publicationDate)
          ? "Aujourd'hui"
          : formatDistanceToNowStrict(publicationDate, {
              addSuffix: true,
              locale: frLocale,
            })}
      </Typography>
    </Stack>
  )
}

const PresidentInfoChip = styled(Typography)(
  ({ theme: { palette, spacing, typography } }) => ({
    position: "absolute",
    top: typography.pxToRem(-18 / 2),
    left: spacing(2),
    borderRadius: "3px",
    padding: `0 ${spacing(0.5)}`,
    textTransform: "uppercase",
    lineHeight: typography.pxToRem(18),
    fontSize: typography.pxToRem(12),
    fontWeight: 500,
    color: palette.primary.main,
    backgroundColor: "white",
  }),
)
