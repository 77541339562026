import { IconProps } from "react-feather"

export const CreamIcon = ({ color, size }: IconProps) => (
  <svg
    height={size}
    viewBox="0 0 9 21"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-144.000000, -1699.000000)"
        fill="#000000"
        fillRule="nonzero"
        stroke={color}
        strokeWidth="0.7"
      >
        <g transform="translate(145.000000, 1700.000000)">
          <path d="M0,0.316613999 L0,2.21659652 C0,2.22161159 0.00250749079,2.22612511 0.00284180007,2.23113975 C0.00317615215,2.23615482 0.00100297064,2.24033368 0.00284180007,2.24534875 L1.26946829,16.1787536 L1.26963544,16.1789208 C1.28451332,16.3412397 1.42025008,16.4659423 1.58340345,16.4666142 L2.21680228,16.4666142 L2.21680228,17.1541479 C1.93262227,17.2544492 1.71281349,17.48363 1.62452879,17.7718412 C1.5362655,18.0598684 1.58992573,18.3728019 1.76929344,18.6150178 C1.94849469,18.8574092 2.23198571,19 2.53338615,19 L5.06676751,19 C5.36800105,19 5.65168037,18.8574092 5.83086021,18.6150178 C6.01006146,18.3727934 6.06372127,18.059847 5.97545797,17.7718412 C5.88719467,17.4836471 5.66753995,17.2544578 5.38335566,17.1541479 L5.38335566,16.4666142 L6.01675449,16.4666142 C6.18090925,16.46745 6.31848873,16.3424122 6.33336404,16.1789208 L7.59999053,2.2455159 C7.59999053,2.24050084 7.5981517,2.23598732 7.59848605,2.2313069 C7.59882036,2.22645916 7.59999053,2.22161142 7.59999053,2.21659635 L7.59999053,0.316613827 C7.59999053,0.232698432 7.56672478,0.152120994 7.50721243,0.0926112028 C7.44786953,0.0332683097 7.36729637,-5.60068915e-16 7.2833767,-5.60068915e-16 L0.316888225,-5.60068915e-16 C0.142034884,-5.60068915e-16 0.000111778782,0.141756208 0.000111778782,0.316609548 L0,0.316613999 Z M5.0668483,18.3664052 L2.53346695,18.3664052 C2.35861361,18.3664052 2.2168574,18.224649 2.2168574,18.0497956 C2.2168574,17.8749423 2.35861361,17.7331861 2.53346695,17.7331861 L5.0668483,17.7331861 C5.24170164,17.7331861 5.38345785,17.8749423 5.38345785,18.0497956 C5.38345785,18.224649 5.24170164,18.3664052 5.0668483,18.3664052 Z M4.75023876,17.0997787 L2.85008505,17.0997787 L2.85008505,16.4663799 L4.75006758,16.4663799 L4.75023876,17.0997787 Z M5.72783236,15.8331522 L1.87266263,15.8331522 L0.665220694,2.53310337 L6.93844107,2.53310337 L5.72783236,15.8331522 Z M0.633595686,0.633035252 L6.96715607,0.633035252 L6.96715607,1.89983292 L0.633595686,1.89983292 L0.633595686,0.633035252 Z" />
        </g>
      </g>
    </g>
  </svg>
)
