import {
  Card,
  CardActionArea,
  Link as MuiLink,
  Stack,
  Typography,
  useTheme,
} from "@mui/material"
import { ExternalLink, Icon } from "react-feather"

export interface ExternalLinkBoxProps {
  leftIcon: Icon
  linkUrl: string
  title: string
  topLabel?: string
  bottomLabel?: string
  bgcolor?: string
}

export const ExternalLinkBox = ({
  leftIcon: LeftIcon,
  linkUrl,
  title,
  topLabel,
  bottomLabel,
  bgcolor,
}: ExternalLinkBoxProps) => {
  const { palette, typography } = useTheme()

  return (
    <Card sx={{ backgroundColor: bgcolor || "#ecf5ff" }}>
      <CardActionArea
        component={MuiLink}
        href={linkUrl}
        target="_blank"
        rel="noreferrer"
        sx={{ p: 3 }}
      >
        <Stack
          direction="row"
          spacing={2}
          color="primary.dark2"
          alignItems="center"
          width="100%"
          justifyContent="space-between"
        >
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <LeftIcon size={typography.pxToRem(34)} />
            <Stack direction="column" justifyContent="start">
              <Typography color="text.primary" fontSize={typography.pxToRem(14)}>
                {topLabel}
              </Typography>
              <Typography
                fontSize={typography.pxToRem(16)}
                lineHeight={typography.pxToRem(18)}
                fontWeight={600}
              >
                {title}
              </Typography>
              <Typography
                color="primary.light1"
                fontSize={typography.pxToRem(14)}
                lineHeight={typography.pxToRem(16)}
              >
                {bottomLabel}
              </Typography>
            </Stack>
          </Stack>
          <ExternalLink
            size={typography.pxToRem(18)}
            color={palette.primary.dark4}
          />
        </Stack>
      </CardActionArea>
    </Card>
  )
}
