import { buildTheme } from "../const"

export const adminTheme = buildTheme(({ palette }) => ({
  palette: {
    header: {
      containedButton: {
        backgroundColor: palette.common.blueGreen,
        primaryColor: palette.common.white,
        secondaryColor: palette.common.blueGreen2,
        hover: {
          backgroundColor: palette.common.blueGreenDark,
        },
      },
      gradient:
        "linear-gradient(-180deg, rgb(118, 179, 181) 0%, rgb(173, 220, 220) 100%);",
    },
    background: {
      default: "#edf5f5",
    },
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          "&.header-menu .MuiTab-root:hover": {
            color: palette.primary.dark,
          },
        },
      },
    },
  },
}))
