export const PhotoClick = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="39">
    <g fill="#F67848" fillRule="evenodd">
      <path d="M20.625 8.75c.46 0 .89.23 1.144.612l2.342 3.513h4.764a4.125 4.125 0 0 1 4.118 3.883L33 17v15.125a4.125 4.125 0 0 1-4.125 4.125H4.125A4.125 4.125 0 0 1 0 32.125V17a4.125 4.125 0 0 1 4.125-4.125h4.763l2.343-3.513c.223-.334.58-.552.973-.601l.171-.011Zm-.737 2.75h-6.777l-2.342 3.513c-.223.334-.58.552-.973.601l-.171.011h-5.5c-.76 0-1.375.616-1.375 1.375v15.125c0 .76.616 1.375 1.375 1.375h24.75c.76 0 1.375-.616 1.375-1.375V17c0-.76-.616-1.375-1.375-1.375h-5.5c-.46 0-.89-.23-1.144-.612L19.888 11.5ZM16.5 17a6.875 6.875 0 1 1 0 13.75 6.875 6.875 0 0 1 0-13.75Zm0 2.75a4.125 4.125 0 1 0 0 8.25 4.125 4.125 0 0 0 0-8.25Z" />
      <g fillRule="nonzero">
        <path d="M34.905 11.13a.664.664 0 0 1-.814-1.034l3.087-3.185a.664.664 0 0 1 .95.927l-3.088 3.184a.637.637 0 0 1-.135.107ZM30.261 9.183a.656.656 0 0 1-.524.053.667.667 0 0 1-.433-.833l1.53-4.878c.109-.348.48-.54.827-.428a.667.667 0 0 1 .433.833l-1.53 4.878a.65.65 0 0 1-.303.375ZM42.693 13.324a.653.653 0 0 1-.234.135l-4.677 1.521a.663.663 0 0 1-.833-.425.655.655 0 0 1 .419-.834l4.676-1.522a.663.663 0 0 1 .65 1.125Z" />
      </g>
    </g>
  </svg>
)
