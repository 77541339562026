export const Cash = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="25">
    <g id="Page-1" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g
        id="Opérations---Tableau-de-bord---Desktop-"
        fill="#F67848"
        fillRule="nonzero"
        transform="translate(-884 -361)"
      >
        <g id="Group" transform="translate(884.688 361)">
          <path
            id="Path"
            d="M24.836 12.648a3.243 3.243 0 0 0 3.25-3.236 3.243 3.243 0 0 0-3.25-3.236 3.243 3.243 0 0 0-3.25 3.236 3.243 3.243 0 0 0 3.25 3.236ZM35.395 10.65a1.24 1.24 0 1 0 0-2.479c-.688 0-1.245.556-1.245 1.24 0 .685.557 1.24 1.245 1.24ZM14.277 10.65a1.24 1.24 0 1 0 0-2.479c-.688 0-1.246.556-1.246 1.24 0 .685.558 1.24 1.246 1.24Z"
          />
          <path
            id="Shape"
            d="M2.068 25H34.26a2.065 2.065 0 0 0 2.069-2.06v-4.112h4.604A2.065 2.065 0 0 0 43 16.768V2.06C43 .923 42.073 0 40.932 0H8.74a2.065 2.065 0 0 0-2.069 2.06v4.112H2.068A2.065 2.065 0 0 0 0 8.232V22.94C0 24.077.927 25 2.068 25ZM9.327 5.11a2.318 2.318 0 0 0 2.327-2.32h26.364a2.316 2.316 0 0 0 2.32 2.32v8.6a2.32 2.32 0 0 0-2.32 2.319H11.654a2.322 2.322 0 0 0-2.327-2.318V5.109Zm-6.673 6.179A2.329 2.329 0 0 0 4.99 8.971h1.683v7.796c0 1.137.926 2.06 2.068 2.06h24.933v1.064a2.322 2.322 0 0 0-2.328 2.318H4.99a2.324 2.324 0 0 0-2.336-2.318v-8.602Z"
          />
        </g>
      </g>
    </g>
  </svg>
)
