import { useToaster } from "../components/feedback/Toaster"

export const useClipBoard = () => {
  const toaster = useToaster()

  return {
    write: ({ label, value }: { value: string; label: string }) =>
      navigator.clipboard.writeText(value).then(() => {
        toaster.success(`${label} copié`)
      }),
  }
}
