import { createContext, useContext } from "react"

export type RoleConfShape = Record<string, any>
export const FormRoleConfContext = createContext<RoleConfShape>({})

export const ALL_FIELDS = "ALL_FIELDS"

export const useFieldRoleConf = <Conf extends RoleConfShape>(name: string) => {
  const conf = (useContext(FormRoleConfContext) as Conf) || {}

  return {
    ...conf[ALL_FIELDS],
    ...conf[name],
  }
}
