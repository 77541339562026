import { useSimpleResourceListApi } from "@pharmupp/p3-front-commons"
import { BonusOperation } from "../BonusOperationList/models/BonusOperation.model"

export const useMyBonusOperationsApi = ({ year }: { year: number }) => {
  const { list: operations, initialLoading } =
    useSimpleResourceListApi<BonusOperation>({
      queryKey: ["bonus-operations", "list", year],
      resourceEndpoint: `/api/operation/bonus/adherent?year=${year}`,
    })
  return {
    operations,
    loading: initialLoading,
  }
}
