import type { YupTypes } from "@pharmupp/p3-front-commons"
import { Yup } from "@pharmupp/p3-front-commons"
import { getMaxDate, isValidDate } from "../../../../core/date-utils"
import {
  getMaxStringLengthMessage,
  getMinDateMessage,
  getMinValueMessage,
} from "../../../../core/form-utils"

const verifyValidityEndDate = ([startDate]: Date[], schema: YupTypes.DateSchema) => {
  const endOfYesterday = new Date()
  endOfYesterday.setHours(0, 0, 0, -1)

  const endOfStartDate = startDate
  if (isValidDate(endOfStartDate)) {
    endOfStartDate.setHours(23, 59, 59, 999)
  }

  const maxDate = getMaxDate(endOfYesterday, endOfStartDate)
  return schema.min(maxDate, getMinDateMessage("Date de fin de validité", maxDate))
}

const verifyMaxBonusAmount = (
  [minBonus]: number[],
  schema: YupTypes.NumberSchema,
) => {
  if (minBonus) {
    return schema.min(
      minBonus,
      getMinValueMessage("Le montant max de la prime", true, minBonus),
    )
  }

  return schema
}

export const detailsSchema = Yup.object().shape({
  title: Yup.string()
    .required()
    .max(100, getMaxStringLengthMessage("L'intitulé de l'opération", 100)),
  validityStartDate: Yup.date().required(),
  validityEndDate: Yup.date()
    .required()
    .when("validityStartDate", verifyValidityEndDate),
  forcePublish: Yup.bool().nullable(),
  forceHide: Yup.bool().nullable(),
  minBonus: Yup.number()
    .min(1, getMinValueMessage("Le montant min de la prime", true, 0))
    // typeError used instead of required because JS will try to cast null/undefined to a number and it'll crash
    .required(),
  maxBonus: Yup.number()
    // typeError used instead of required because JS will try to cast null/undefined to a number and it'll crash
    .required()
    .when("minBonus", verifyMaxBonusAmount),
  isProofRequired: Yup.bool().nullable(),
  numProofDocuments: Yup.number()
    .nullable()
    .when("isProofRequired", {
      is: true,
      then: (schema) => schema.min(1).required(),
    }),
  descriptionProofDocuments: Yup.string()
    .nullable()
    .when("isProofRequired", {
      is: true,
      then: (schema) =>
        schema.max(
          250,
          getMaxStringLengthMessage("La description des justificatifs", 250),
        ),
    }),
  tag: Yup.object({
    value: Yup.number().required(),
  }).required(),
})
