import {
  Card,
  CardActionArea,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import { ElementType, ReactNode } from "react"
import { ChevronRight, ExternalLink as ExternalLinkIcon } from "react-feather"
import { Link } from "react-router-dom"

interface LocalLink {
  href?: never
  to: string
  target?: never
}
interface ExternalLink {
  href: string
  to?: never
  target?: string
}
export type CategoryCardProps = {
  children: ReactNode
} & (LocalLink | ExternalLink)

const isExternalLink = (card: LocalLink | ExternalLink): card is ExternalLink =>
  "href" in card

export const CategoryCard = ({ children, ...cards }: CategoryCardProps) => {
  let component: ElementType = Link
  if (isExternalLink(cards)) {
    component = "a"
    cards.target = cards.target ?? "_blank"
  }

  return (
    <Card>
      <CardActionArea component={component} {...cards}>
        <Stack alignItems="center" px={3} pt={3} pb={4}>
          {children}
        </Stack>
      </CardActionArea>
    </Card>
  )
}

export const CategoryCardTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(20),
  fontWeight: "bold",
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
}))

export const CategoryCardDescription = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  marginBottom: theme.spacing(2),
}))

export const CategoryCardLink = ({ children, ...cards }: CategoryCardProps) => {
  const theme = useTheme()
  return (
    <Stack
      direction="row"
      alignItems="center"
      color="common.electricBlue"
      spacing={0.3}
    >
      <Typography fontSize={theme.typography.pxToRem(12)}>{children}</Typography>
      {isExternalLink(cards) ? (
        <ExternalLinkIcon size={theme.typography.pxToRem(14)} />
      ) : (
        <ChevronRight size={theme.typography.pxToRem(14)} />
      )}
    </Stack>
  )
}
