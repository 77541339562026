import { useTheme } from "@mui/material"
import { useCallback, useEffect, useRef, useState } from "react"

interface UseCardSliderArgs {
  /** Average card width in px (calculation base) */
  cardBaseWidthInPx: string
  cardSpacing: number
}

const endScrollTolerance = 2

/**
 * Setup a cards row with:
 * - dynamic card width calculation based on available width
 * - scroll animation (left/right)
 */
export const useCardSlider = ({
  cardBaseWidthInPx,
  cardSpacing,
}: UseCardSliderArgs) => {
  const theme = useTheme()
  const gap = Number.parseInt(theme.spacing(cardSpacing))

  // Ref setup
  const [scrollContainerRef, setScrollContainerRef] = useState<HTMLDivElement>()
  const observer = useRef<ResizeObserver | null>(null)

  // Scroll
  const [scrollPosition, setScrollPosition] = useState<number>(0)
  const scroll = useCallback(
    (direction: ScrollDirection) => {
      if (scrollContainerRef) {
        const scrollWidth = scrollContainerRef.scrollWidth
        const visibleWidth = scrollContainerRef.getBoundingClientRect().width
        const increment = visibleWidth * direction
        const newPosition = scrollPosition + increment + gap * direction
        let newPositionWithTolerance = newPosition

        if (direction === ScrollDirection.LEFT) {
          if (newPosition <= endScrollTolerance) {
            newPositionWithTolerance = 0
          }
        } else {
          if (scrollWidth - (newPosition + increment) <= endScrollTolerance) {
            newPositionWithTolerance = scrollWidth - increment
          }
        }

        scrollContainerRef.scrollLeft = newPositionWithTolerance

        setScrollPosition(newPositionWithTolerance)
      }
    },
    [gap, scrollContainerRef, scrollPosition],
  )

  /**
   * Styles : card width calculation + container styling
   */
  const computeCardStyles = useCallback(
    (scrollContainer: HTMLDivElement) => {
      scrollContainer.style.display = "grid"
      scrollContainer.style.gridAutoFlow = "column"
      scrollContainer.style.overflowX = "hidden"
      scrollContainer.style.scrollBehavior = "smooth"

      const cardBaseWidth = Number.parseInt(cardBaseWidthInPx)
      const visibleWidth = scrollContainer.getBoundingClientRect().width
      const nbOfCardToDisplay = Math.floor(visibleWidth / cardBaseWidth)

      scrollContainer.style.gridAutoColumns = `${
        visibleWidth / nbOfCardToDisplay - (gap - gap / nbOfCardToDisplay)
      }px`
      scrollContainer.style.columnGap = `${gap}px`
    },
    [cardBaseWidthInPx, gap],
  )

  /**
   * Near components could change (load then show elements) affecting the
   * available width so we need to trigger recalculation accordingly
   */
  useEffect(() => {
    if (observer && scrollContainerRef) {
      observer.current = new ResizeObserver((entries) => {
        entries.forEach((entry) => computeCardStyles(entry.target as HTMLDivElement))
      })
      observer.current.observe(scrollContainerRef)

      return () => observer.current?.unobserve(scrollContainerRef)
    }
  }, [computeCardStyles, scrollContainerRef])

  return {
    refHandler: setScrollContainerRef,
    scrollLeft: () => scroll(ScrollDirection.LEFT),
    scrollRight: () => scroll(ScrollDirection.RIGHT),
    scrollPosition: getScrollPosition(
      scrollPosition,
      scrollContainerRef?.scrollWidth ?? 0,
      scrollContainerRef?.getBoundingClientRect().width ?? 0,
    ),
  }
}

export enum ScrollDirection {
  LEFT = -1,
  RIGHT = 1,
}

type ScrollPosition = "start" | "somewhere" | "end"

const getScrollPosition = (
  scrollLeft: number,
  scrollWidth: number,
  visibleWidth: number,
): ScrollPosition => {
  switch (true) {
    case scrollLeft <= 0:
      return "start"
    case scrollWidth - scrollLeft <= visibleWidth:
      return "end"
    default:
      return "somewhere"
  }
}
