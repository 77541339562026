export const ERROR_KINDS = {
  NOT_VALID_BEAN: "NOT_VALID_BEAN",
  NOT_FOUND: "NOT_FOUND",
  LINK_NOT_FOUND: "LINK_NOT_FOUND",
  ALREADY_EXIST: "ALREADY_EXIST",
  ID_MISSING: "ID_MISSING",
  UNDEFINED: "UNDEFINED",
  NOT_AUTHORIZE: "NOT_AUTHORIZE",
  NOT_ACTIVATABLE: "NOT_ACTIVATABLE",
  NOT_MOVABLE_COMMITTEE: "NOT_MOVABLE_COMMITTEE",
  NOT_MOVABLE_ADHERENT: "NOT_MOVABLE_ADHERENT",
}
