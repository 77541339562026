export interface ApiLoyaltyCategory {
  id: number
  name: string
  orderNum: number
  earnPoint: number
  lastYearEarnedPoints: number
  expectedPoint: number
  unlockedChallenge: number
  totalChallenge: number
  ratio: number
  categoryEnum: ApiLoyaltyCategoryEnum
}

export type ApiLoyaltyCategoryEnum = "QUALITATIVE" | "SDAV" | "CATEGORICAL"

export const getRouteForCategoryEnum = (categoryEnum: ApiLoyaltyCategoryEnum) => {
  switch (categoryEnum) {
    case "CATEGORICAL":
      return "categoriels"
    case "SDAV":
      return "sdav"
    case "QUALITATIVE":
      return "qualitatifs"
  }
}
