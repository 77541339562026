import { api, useQuery, useRoleConf } from "@pharmupp/p3-front-commons"
import { apiRoleConf } from "./api.roleConf"

export const useBonusOperationSummaryApi = ({ id }: { id: string }) => {
  const { getSummaryEndpoint } = useRoleConf(apiRoleConf)

  const { data: summary, isLoading } = useQuery({
    queryKey: ["bonus-operation", id, "upp", "summary"],
    queryFn: () => api.get<ApiUppBonusOpSummary>(getSummaryEndpoint(id!)),
  })

  return {
    summary,
    isLoading,
  }
}

export interface ApiUppBonusOpSummary {
  name: string
  validityStartDate: string
  validityEndDate: string
  bonus?: number
  maxBonus: number
  tag: string
  totaljustified?: number
  totalParticipants?: number
  validatedParticipants?: number
  inProgressParticipants?: number
  paidBonuses?: number
  maxPaidBonuses?: number
}
