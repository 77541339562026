import { api, useSuspenseQuery } from "@pharmupp/p3-front-commons"

export const useMyBriOperationsSummary = () => {
  const { data: operations } = useSuspenseQuery({
    queryKey: ["opeaz-operations", "adherent", "summary"],
    queryFn: () =>
      api.get<ApiOpeazOpAdhSummary>("/api/operation/opeaz/adherent/summary"),
  })
  return { operations }
}

export type OpeazOpeType =
  | "ALL"
  | "PARTICIPATING"
  | "PENDING_PROOF"
  | "UNREQUESTED_COMPENSATIONS"
  | "COMPENSATED"

type OpeazOpeSummary = {
  url: string
  count: number | null
}

export type ApiOpeazOpAdhSummary = Record<OpeazOpeType, OpeazOpeSummary>
