import { styled } from "@mui/material"
import * as React from "react"
import ReactMarkdown, { Options as ReactMarkdownOptions } from "react-markdown"
import remarkBreaks from "remark-breaks"
import remarkGfm from "remark-gfm"

export interface MarkdownDisplayProps {
  /** Markdown string */
  children: string
  ignoreFormatting?: boolean
}
export const MarkdownDisplay = ({
  children,
  ignoreFormatting = false,
}: MarkdownDisplayProps) => {
  const ignoreFormattingProps = ignoreFormatting
    ? {
        allowedElements: [],
        unwrapDisallowed: true,
      }
    : {}

  return (
    <StyledReactMarkdown
      remarkPlugins={[remarkBreaks, remarkGfm]}
      {...ignoreFormattingProps}
    >
      {children}
    </StyledReactMarkdown>
  )
}

/**
 * Those styles should look like those of the editor
 * to have a same rendering between those two
 */
const StyledReactMarkdown: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<ReactMarkdownOptions>>
> = styled(ReactMarkdown)(({ theme }) => ({
  "&, *": {
    fontFamily: theme.typography.fontFamily,
  },

  // Headings
  h1: {
    fontSize: "32px",
  },
  a: {
    color: theme.palette.common.electricBlue,
    textDecoration: "underline",
  },

  // Table
  table: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    maxWidth: "100%",
    overflowY: "scroll",
    tableLayout: "fixed",
    width: "calc(100% - 25px)",
    margin: "30px 0",
  },
  "th,td": {
    border: "1px solid #bbb",
    minWidth: "75px",
    verticalAlign: "top",
    textAlign: "start",
    padding: `${theme.spacing(2)}`,
    position: "relative",
    cursor: "default",
    outline: "none",
    fontSize: "1rem",
    fontWeight: "initial",
    hyphens: "auto",
    wordBreak: "break-word",
    "& > p": {
      margin: 0,
    },
  },
  "thead th": {
    backgroundColor: "#f2f3f5",
    fontWeight: 600,
    textAlign: "start",
  },
}))
