import {
  Box,
  Divider,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  styled,
} from "@mui/material"
import {
  Chip,
  PaperContainer,
  PaperContainerProps,
  Rating,
  getPublicFileUrl,
} from "@pharmupp/p3-front-commons"
import { keyBy } from "lodash-es"
import { Suspense, useMemo } from "react"

import { CatalogName, catalogLabel } from "../../../../../../model"
import { PartnerChip, RoundedChip } from "../../../../../common"
import { useAdherentLabDetailsV2 } from "../../../../useAdherentLabDetails"
import { useRatingApi } from "../../api/useRatingApi"
import { GreyLabel } from "./commons"

type LabInfosBlockProps = PaperContainerProps & {
  labId: string
}

export const LabInfosBlock = ({ labId, ...props }: LabInfosBlockProps) => (
  <Suspense
    fallback={
      <Skeleton
        {...props}
        component={Box}
        variant="rectangular"
        width="100%"
        height="100%"
        borderRadius="6px"
      />
    }
  >
    <LabInfosBlockInner labId={labId} {...props} />
  </Suspense>
)

const LabInfosBlockInner = ({ labId, ...props }: LabInfosBlockProps) => {
  // API
  const { laboratory, referential } = useAdherentLabDetailsV2({ labId })
  const { rating } = useRatingApi({ labId })

  const referentialCategories = useMemo(
    () => keyBy(referential?.categories, "code"),
    [referential?.categories],
  )

  return (
    <PaperContainer {...props}>
      <Stack gap={2.5}>
        {/* LOGO */}
        <LabLogo
          src={laboratory.logo ? getPublicFileUrl(laboratory.logo) : undefined}
        />

        {/* PARTNERSHIP TYPE */}
        {(laboratory.partnershipType || laboratory.ultraConditions) && (
          <Stack direction="row" justifyContent="center" gap={1}>
            {laboratory.partnershipType && (
              <PartnerChip type={laboratory.partnershipType} />
            )}
            {laboratory.ultraConditions && (
              <Tooltip title="Ce laboratoire propose des conditions spécifiques pour les adhérents Ultra">
                <RoundedChip
                  label="ULTRA"
                  fontColor="#dbe7fd"
                  borderColor="#dbe7fd"
                  finalSize="small"
                />
              </Tooltip>
            )}
          </Stack>
        )}

        {/* CATEGORIES */}
        <Stack gap={0.5}>
          <Divider />
          <Box display="flex" justifyContent={"center"} gap={1} flexWrap="wrap">
            {laboratory.categories?.length ? (
              laboratory.categories.map((code) => (
                <Chip
                  key={code}
                  label={referentialCategories[code]?.label}
                  fontColor="primary.main"
                  size="small"
                />
              ))
            ) : (
              <>-</>
            )}
          </Box>
          <Divider />
        </Stack>

        {/* CATALOGS */}
        {!!laboratory.catalogType && (
          <Stack alignItems="center" spacing={1}>
            <GreyLabel>Canal de distribution</GreyLabel>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
              gap={1}
            >
              {(
                Object.entries(laboratory.catalogType) as [CatalogName, boolean][]
              ).map(([code, enabled]) =>
                enabled ? (
                  <Chip
                    key={code}
                    label={catalogLabel[code]}
                    variant="outlined"
                    fontColor="primary.main"
                    borderColor="#c9d0d6"
                    size="medium"
                  />
                ) : null,
              )}
            </Stack>
          </Stack>
        )}

        {/* NEGOTIATION STATUS */}
        <Stack alignItems="center" gap={1}>
          <GreyLabel>Statut négociation</GreyLabel>
          <NegotiationStatus>En cours</NegotiationStatus>
        </Stack>

        {/* GLOBAL SCORE */}
        <>
          <Divider />
          <Stack alignItems="center" gap={1}>
            <GreyLabel>Note globale</GreyLabel>
            <Stack direction="row" alignItems="center" gap={2}>
              <AverageRatingValue>{rating.averageValue}</AverageRatingValue>
              <Rating value={rating.averageValue} max={rating.max} />
              {rating.ratingsCount && (
                <RatingsCount>({rating.ratingsCount} avis)</RatingsCount>
              )}
            </Stack>
          </Stack>
        </>
      </Stack>
    </PaperContainer>
  )
}

export const LabLogo = styled("img")({
  width: "100%",
  aspectRatio: "16/9",
  objectFit: "contain",
  maxHeight: "80px",
})

const NegotiationStatus = styled(Typography)(
  ({ theme: { palette, typography } }) => ({
    fontSize: typography.pxToRem(16),
    lineHeight: typography.pxToRem(18),
    fontWeight: 500,
    color: palette.primary.dark1,
  }),
)

const AverageRatingValue = styled(Typography)(
  ({ theme: { palette, typography } }) => ({
    fontSize: typography.pxToRem(20),
    lineHeight: typography.pxToRem(22),
    fontWeight: 500,
    color: palette.primary.dark1,
  }),
)

const RatingsCount = styled(Typography)(({ theme: { typography } }) => ({
  fontSize: typography.pxToRem(16),
  lineHeight: typography.pxToRem(18),
  fontWeight: 500,
  color: "#91a2c3",
}))
