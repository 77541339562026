import { Box, Card, CardProps, Stack, Typography, useTheme } from "@mui/material"
import { Percentage } from "@pharmupp/p3-front-commons"
import { ReactNode } from "react"
import { Icon as FeatherIcon } from "react-feather"
import { NoDataWarning, StatCardTitle } from "."
import { useEvolutionUI } from "./useEvolutionUI"

export const HighlightedStatCard = ({
  Icon,
  secondaryIcons = [],
  title,
  subtitle,
  mainStat,
  hasValue = true,
  evolution,
  ...props
}: {
  Icon: FeatherIcon
  secondaryIcons?: FeatherIcon[]
  title: string
  subtitle: string
  mainStat: ReactNode
  hasValue?: boolean
  evolution?: number
} & CardProps<typeof Box>) => {
  const { palette, typography } = useTheme()
  const evolutionUI = useEvolutionUI(evolution)

  return (
    <Card component={Box} px={3} py={2} sx={{ bgcolor: "#dde6ff" }} {...props}>
      <Stack justifyContent="space-between" height="100%">
        <Stack spacing={2}>
          <Stack direction="row" alignItems="center" spacing={2}>
            {/* ICONS */}
            <Stack direction="row" alignItems="flex-end" spacing={0.5}>
              <Icon size={typography.pxToRem(30)} color={palette.tertiary.dark2} />
              {secondaryIcons.map((SecondaryIcon, index) => (
                <SecondaryIcon
                  key={index}
                  color={palette.tertiary.dark4}
                  size={typography.pxToRem(24)}
                />
              ))}
            </Stack>

            {/* TITLE */}
            <StatCardTitle
              title={title}
              subtitle={subtitle}
              titleProps={{ color: palette.common.darkBlue, fontWeight: 600 }}
            />
          </Stack>

          {/* MAIN STAT */}
          <Stack direction="row" spacing={1} justifyContent="space-between">
            {hasValue ? (
              <>
                <Typography
                  fontSize={typography.pxToRem(40)}
                  fontWeight={600}
                  color={palette.common.darkBlue}
                  lineHeight={1}
                >
                  {mainStat}
                </Typography>
                {/* GROWTH */}
                {evolution != null && (
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography fontSize={typography.pxToRem(11)}>
                      Encaissement
                    </Typography>
                    <Typography
                      fontSize={typography.pxToRem(16)}
                      color={evolutionUI.color}
                    >
                      <Percentage
                        value={evolution}
                        allowNegative={false}
                        style={{ whiteSpace: "nowrap" }}
                      />
                    </Typography>
                  </Stack>
                )}
              </>
            ) : (
              <NoDataWarning />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Card>
  )
}
