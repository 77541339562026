import {
  Box,
  Divider,
  Skeleton,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import { PaperContainer, PaperContainerProps } from "@pharmupp/p3-front-commons"
import { ReactNode, useState } from "react"
import { ChevronRight, Plus } from "react-feather"
import { useAppointmentApi } from "../../api/useAppointmentApi"

type RdvBlockProps = PaperContainerProps & { labId: string }

export const RdvBlock = ({ labId, ...props }: RdvBlockProps) => {
  const { appointments } = useAppointmentApi({
    labId,
    sortDateDirection: "desc",
    size: 1,
  })

  if (!appointments) {
    return (
      <Skeleton
        {...props}
        component={Box}
        variant="rectangular"
        width="100%"
        height="100%"
        sx={{ minHeight: "203px" }}
      />
    )
  }

  return (
    <PaperContainer {...props} minHeight={MIN_HEIGHT}>
      <Stack gap={2} divider={<Divider />}>
        {/* LAST RDV */}
        {!!appointments?.length && (
          <Line
            startBox={
              <DateBox bgcolor="#f6f6fb">
                <Day>{appointments[0].day}</Day>
                <Month>{appointments[0].month}</Month>
              </DateBox>
            }
            title={<Title fontWeight={600}>{appointments[0].title}</Title>}
          />
        )}

        <Line
          startBox={
            <DateBox border="1px solid rgb(237, 240, 247)">
              <Plus color={AGENDA_BLUE} size="20px" />
            </DateBox>
          }
          title={<Title>Nouveau compte rendu</Title>}
        />
      </Stack>
    </PaperContainer>
  )
}

const AGENDA_BLUE = "#2989ff"
const MIN_HEIGHT = "203px"

const DateBox = styled(Stack)(({ theme: { spacing } }) => ({
  borderRadius: "2px",
  padding: spacing(1.5),
  justifyContent: "center",
  alignItems: "center",
  bgcolor: "#f6f6fb",
  height: "52px",
  aspectRatio: "1",
}))

const Day = styled(Typography)(({ theme: { typography } }) => ({
  fontSize: typography.pxToRem(16),
  lineHeight: typography.pxToRem(18),
  fontWeight: 600,
  color: AGENDA_BLUE,
}))
const Month = styled(Typography)(({ theme: { typography } }) => ({
  fontSize: typography.pxToRem(12),
  lineHeight: typography.pxToRem(14),
  fontWeight: 600,
  color: "#a4a6b1",
  textTransform: "uppercase",
}))
const Title = styled(Typography)(({ theme: { palette, typography } }) => ({
  fontSize: typography.pxToRem(16),
  lineHeight: typography.pxToRem(18),
  color: palette.common.darkBlue,
}))

const Line = ({ startBox, title }: { startBox: ReactNode; title: ReactNode }) => {
  const { palette, transitions } = useTheme()
  const [isHovered, setIsHovered] = useState(false)
  return (
    <LineWrapper
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        transition: transitions.create("opacity"),
        "&:hover": {
          opacity: 0.8,
        },
      }}
    >
      <Box display="flex" alignItems="center" gap={2}>
        {startBox}
        {title}
      </Box>
      <ChevronRight
        size="20px"
        color={palette.primary.dark4}
        style={{
          transition: transitions.create("transform"),
          transform: isHovered ? "translateX(5px)" : "translateX(0)",
        }}
      />
    </LineWrapper>
  )
}

const LineWrapper = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
}))
