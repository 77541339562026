export const getYearsToDisplay: (currentDate: Date) => number[] = (currentDate) => {
  const currentYear = currentDate.getFullYear()
  const showLastYear =
    currentDate >= new Date(`${currentYear}-01-01`) &&
    currentDate <= new Date(`${currentYear}-11-30`)
  const showNextYear =
    currentDate >= new Date(`${currentYear}-12-01`) &&
    currentDate <= new Date(`${currentYear}-12-31`)
  return [
    ...(showNextYear ? [currentYear + 1] : []),
    ...[currentYear],
    ...(showLastYear ? [currentYear - 1] : []),
  ]
}
