import {
  ROLES,
  RoleStrategy,
  api,
  useQuery,
  useRoleConf,
} from "@pharmupp/p3-front-commons"
import { format } from "date-fns"
import { useMemo } from "react"

export const useMyAgendaApi = (adherentId?: string) => {
  const endpoints = useRoleConf(apiRoleConf)

  const { data, isLoading: loading } = useQuery<MyAgendaEvent[]>({
    queryKey: ["news", "agenda", "adherent", adherentId],
    queryFn: () => api.get(endpoints.agenda(adherentId)),
  })

  const events = useMemo(
    () =>
      (Array.isArray(data) ? data : []).map((event) => {
        const start = new Date(event.startDate)
        const end = new Date(event.endDate)

        return {
          id: event.id,
          title: event.title,
          day: format(start, "d"),
          month: start.toLocaleDateString("fr-FR", { month: "short" }),
          startTime: format(start, "HH'h'mm"),
          endTime: format(end, "HH'h'mm"),
          cancelled: event.cancelled,
        }
      }),
    [data],
  )

  return {
    events,
    loading,
  }
}

export interface MyAgendaEvent {
  id: number
  startDate: string
  endDate: string
  title: string
  cancelled: boolean
}

const apiRoleConf: RoleStrategy<{
  agenda: (id?: string) => string
}> = {
  [ROLES.ADHERENT_HOLDER]: {
    agenda: () => "/api/news/my-event/my-agenda",
  },
  [ROLES.ADHERENT_TEAM]: {
    agenda: () => "/api/news/my-event/my-agenda",
  },
  [ROLES.UPP_PRESIDENT]: {
    agenda: (id) =>
      id ? `/api/news/event/adherent/${id}/agenda` : "/api/news/my-event/my-agenda",
  },
  [ROLES.ADHERENT_ADVISOR]: {
    agenda: (id) => `/api/news/my-sector/${id}/agenda`,
  },
  [ROLES.ADMIN]: {
    agenda: (id) => `/api/news/event/adherent/${id}/agenda`,
  },
}
