import { Yup, YupTypes } from "@pharmupp/p3-front-commons"
import { fields } from "./PatientServices.const"
import { ApiAdhPatientServicesReferential } from "./usePatientServicesApi"

export const patientServicesSchema = Yup.object({
  hasPrescriptionScanner: Yup.boolean().required(),
  orderDeliveryServices: Yup.object({}),
  socialMediaUrls: Yup.object({}),
  website: Yup.object({
    siteUrl: Yup.string().url().required(),
    websiteService: Yup.string().required(),
  })
    .default(undefined)
    .notRequired(),
})

export type PatientServiceFormValues = YupTypes.InferType<
  typeof patientServicesSchema
>

export const updateSchemaWithReferential = (
  referential: ApiAdhPatientServicesReferential,
) => {
  const newSocialMediaShape =
    referential.socialMediaTypes.reduce<YupTypes.ObjectShape>(
      (result, socialMedia) => {
        result[socialMedia.code] = Yup.string().url()
        return result
      },
      {},
    )

  return patientServicesSchema.shape({
    [fields.socialMediaUrls]: Yup.object().shape(newSocialMediaShape),
  })
}
