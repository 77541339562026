import { Alert, IconButton, Snackbar } from "@mui/material"
import { useEffect, useState } from "react"
import { X } from "react-feather"

export const AdBlockerWarning = () => {
  const [hasAdBlocker, setHasAdBlocker] = useState(false)
  useEffect(() => {
    detectAdBlocker().then(setHasAdBlocker)
  }, [])
  const [isBannerVisible, setBannerVisible] = useState(true)

  return hasAdBlocker && isBannerVisible ? (
    <Snackbar open anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
      <Alert
        severity="warning"
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => {
              setBannerVisible(false)
            }}
          >
            <X />
          </IconButton>
        }
      >
        Veuillez désactiver votre adblocker pour accéder au chat avec les équipes
        Pharm-UPP
      </Alert>
    </Snackbar>
  ) : null
}

async function detectAdBlocker() {
  // if (import.meta.env.DEV) return false
  if (window.IGNORE_ADBLOCKER) return false

  const googleAdUrl = "https://www.googletagmanager.com/gtm.js?id=GTM-LMFAO0O"

  return fetch(new Request(googleAdUrl), { mode: "no-cors" }).then(
    () => false,
    () => true,
  )
}
