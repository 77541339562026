import { Box, Divider, Stack, Typography } from "@mui/material"
import {
  FilterSearchAndSelect,
  FullWidthContent,
  FullWidthHeader,
  MainContainer,
  PaperContent,
  ROLES,
  ReferentialValue,
  RoleStrategy,
  Table,
  useRoleCheck,
  useRoleConf,
} from "@pharmupp/p3-front-commons"
import { useNavigate } from "react-router-dom"
import { MarketingOperationLogo } from ".."
import { OperationType } from "../../operationMarketing.model"
import { ApiListAdhMarketingOperation, OperationSection } from "../model"
import { marketingOpColumns } from "./columns"

export const AdhMarketingOPList = ({ adherentId }: { adherentId?: string }) => {
  const navigate = useNavigate()
  const endpoint = useRoleConf(apiRoleConf)(adherentId)
  const queryKey = ["operation", "marketing", "adherent"]
  const isAdherentHolder = useRoleCheck(ROLES.ADHERENT_HOLDER)
  const isAdherentTeam = useRoleCheck(ROLES.ADHERENT_TEAM)
  const isRowClickable = (isAdherentHolder || isAdherentTeam) && !adherentId

  if (adherentId) {
    queryKey.push(adherentId)
  }

  return (
    <MainContainer>
      <FullWidthContent>
        <FullWidthHeader title="OP Pharm-UPP" linkLabel="Opérations" linkTo="../" />

        <PaperContent>
          {/* LOGO + TITLE */}
          <Box display="flex" alignItems="center" gap={4} px={4} pt={4}>
            <MarketingOperationLogo />
            {/* <Logo width="96px" /> */}
            <Stack spacing={1}>
              <Typography
                fontSize={({ typography }) => typography.pxToRem(21)}
                fontWeight={500}
                color="primary.dark1"
                maxWidth="673px"
              >
                Développez votre attractivité commerciale et communiquez sur votre
                accompagnement pharmaceutique
              </Typography>

              <Divider color="success" sx={{ width: "110px", borderWidth: "1px" }} />
            </Stack>
          </Box>

          <Table<ApiListAdhMarketingOperation>
            queryConfig={{
              queryKey,
              resourceEndpoint: endpoint,
              select: selectMarketingOpList,
            }}
            FilterSectionElement={
              <>
                <FilterSearchAndSelect
                  label="Année"
                  name="year"
                  options={yearOptions}
                />
                <FilterSearchAndSelect
                  label="Type"
                  name="type"
                  options={typeOptions}
                />
                <FilterSearchAndSelect
                  label="Statut"
                  name="section"
                  options={sectionOptions}
                />
              </>
            }
            emptyMessage="Aucune opération"
            columns={[
              { renderDefinition: marketingOpColumns.type, width: "13%" },
              { renderDefinition: marketingOpColumns.divider, width: "3%" },
              { renderDefinition: marketingOpColumns.operation, width: "27%" },
              { renderDefinition: marketingOpColumns.points, width: "15%" },
              { renderDefinition: marketingOpColumns.participation, width: "15%" },
              { renderDefinition: marketingOpColumns.justification, width: "15%" },
              { renderDefinition: marketingOpColumns.status, width: "12%" },
            ]}
            searchDefaultOpen
            searchPlaceholder="Je recherche une opération"
            fixedLayout
            onRowClick={(row) => navigate(`./${row.id}`)}
            rowClickCondition={() => isRowClickable}
          />
        </PaperContent>
      </FullWidthContent>
    </MainContainer>
  )
}

const selectMarketingOpList = (operations: ApiListAdhMarketingOperation[]) =>
  operations.map((operation) => ({
    ...operation,
    validityStartDate: new Date(operation.validityStartDate).toLocaleDateString(),
    validityEndDate: new Date(operation.validityEndDate).toLocaleDateString(),
  }))

const currentYear = new Date().getFullYear()
const years = [currentYear, currentYear - 1, currentYear - 2, currentYear - 3]
const yearOptions: ReferentialValue<number>[] = years.map((year) => ({
  label: year.toString(),
  value: year,
}))

const typeOptions: ReferentialValue<OperationType>[] = [
  { label: "Offre du moment", value: OperationType.PROMOTIONAL },
  { label: "Opération vasque", value: OperationType.DISPLAY_BASIN },
  { label: "Opération métier", value: OperationType.BUSINESS },
]

const sectionOptions: ReferentialValue<OperationSection>[] = [
  { label: "Auxquelles s'inscrire", value: OperationSection.REGISTRABLE },
  { label: "Inscrites", value: OperationSection.REGISTERED },
  { label: "À justifier", value: OperationSection.REQUIRES_PROOF },
  { label: "Validées", value: OperationSection.VALIDATED },
  { label: "Périmées", value: OperationSection.OUTDATED },
]

const apiRoleConf: RoleStrategy<(id?: string) => string> = {
  [ROLES.ADMIN]: (id) => `/api/operation/marketing/pharmasheet/adherent/${id}`,
  [ROLES.UPP_PRESIDENT]: (id) =>
    id
      ? `/api/operation/marketing/upp/adherent/${id}`
      : "/api/operation/marketing/adherent",
  [ROLES.ADHERENT_ADVISOR]: (id) =>
    `/api/operation/marketing/my-sector/adherent/${id}`,
  [ROLES.ADHERENT_HOLDER]: () => "/api/operation/marketing/adherent",
  [ROLES.ADHERENT_TEAM]: () => "/api/operation/marketing/adherent",
}
