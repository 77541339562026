import {
  ReferentialValue,
  UploadedFile,
  api,
  useQuery,
} from "@pharmupp/p3-front-commons"

export const useOffersOrdersReferentialApi = () => {
  const { data: referential, isLoading } = useQuery({
    queryKey: ["offers", "orders", "referential"],
    queryFn: () => api.get<OrdersReferential>("/api/offers/referential/orders"),
    select: (apiReferential) => ({
      sources: apiReferential.sources,
      sourcesRecord: Object.fromEntries(
        apiReferential.sources.map(({ label, value }) => [value, label]),
      ),
      tags: apiReferential.tags.sort((a, b) => a.label.localeCompare(b.label)),
      tagsRecord: Object.fromEntries(
        apiReferential.tags.map(({ label, value }) => [value, label]),
      ),
      laboratories: apiReferential.laboratories
        .map((lab) => ({
          label: lab.name,
          value: lab.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
      laboratoriesRecord: apiReferential.laboratories.reduce<
        Record<number, Laboratory>
      >((result, lab) => {
        result[lab.id] = lab
        return result
      }, {}),
      upps: apiReferential.upps.map((upp) => ({
        label: upp.name,
        value: upp.id,
      })),
    }),
  })

  return {
    referential,
    isLoading,
  }
}
export type OffersOrderReferential = ReturnType<
  typeof useOffersOrdersReferentialApi
>["referential"]

export const useAdherentOrdersReferential = () => {
  const { referential, isLoading } = useOffersOrdersReferentialApi()

  return {
    referential,
    referentialLoading: isLoading,
  }
}

export interface OrdersReferential {
  sources: ReferentialValue<string>[]
  tags: ReferentialValue<string>[]
  laboratories: Laboratory[]
  upps: { id: number; name: string }[]
}

export interface Laboratory {
  id: number
  name: string
  logo?: UploadedFile
}
