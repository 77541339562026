import {
  ROLES,
  RoleStrategy,
  UploadedFile,
  api,
  query,
  resizeImage,
  useMutation,
  useRoleConf,
} from "@pharmupp/p3-front-commons"
import { MAX_PIXELS_AMOUNT_JUSTIFICATION_PIC } from "../AdherentMarketingOperations"
import { FormValues } from "./schema"

export const useJustificationApi = ({
  operationId,
  adherentId,
}: { operationId: string; adherentId?: string }) => {
  const endpoint = useRoleConf(apiRoleConf)(operationId, adherentId)

  const { mutateAsync: sendDocuments, isPending: isLoading } = useMutation({
    mutationFn: async (formValues: FormValues) => {
      const uploadedDocs = await Promise.all(
        formValues.documents.flatMap(async (doc) => {
          if (!doc) return []
          const resizedDoc = await resizeImage(
            doc,
            MAX_PIXELS_AMOUNT_JUSTIFICATION_PIC,
          )
          const formData = docToFormData(resizedDoc)
          return query<UploadedFile>(window.CONTEXT.uploadTempEndpoint, {
            method: "POST",
            body: formData,
          })
        }),
      )

      // Submit documents references
      return api.post(endpoint, {
        headers: { "content-type": "application/json" },
        body: JSON.stringify({
          documents: uploadedDocs.map((document) => ({ document })),
          justificationReason: formValues.justificationReason,
        }),
      })
    },
  })

  return { sendDocuments, isLoading }
}

const docToFormData = (doc: File) => {
  const formData = new FormData()
  formData.append("file", doc)
  return formData
}

const apiRoleConf: RoleStrategy<
  (operationId: string, adherentId?: string) => string
> = {
  [ROLES.ADMIN]: (operationId, adherentId) =>
    `/api/operation/marketing/${operationId}/participants/${adherentId}/document`,
  [ROLES.ADHERENT_HOLDER]: (operationId) =>
    `/api/operation/marketing/adherent/${operationId}/document`,
}
