export const BoxIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="30">
    <defs>
      <path
        id="path-1"
        d="M24.303 21.777 15 26.428v-12.21l10-5v11.439c0 .477-.266.906-.697 1.12Zm-21.117.001a1.245 1.245 0 0 1-.686-1.132V9.218l10 5v12.218l-9.314-4.658Zm10.564-9.73-9.699-4.85 3.454-1.726 9.699 4.849-3.454 1.727Zm-.556-9.42c.174-.087.365-.13.556-.13.189 0 .38.043.553.129l9.145 4.571L20 8.923l-9.699-4.85 2.893-1.445Zm12.227 2.763-10-5V.389a3.776 3.776 0 0 0-3.343.002L2.08 5.389a3.726 3.726 0 0 0-2.08 3.357v11.89a3.727 3.727 0 0 0 2.064 3.377l10 5a3.758 3.758 0 0 0 1.679.394c.576 0 1.152-.131 1.677-.394l9.997-4.999a3.725 3.725 0 0 0 2.082-3.357V8.746c0-1.434-.799-2.72-2.079-3.355Z"
      />
    </defs>
    <g id="Fiche-labo" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g
        id="Catalogues---Détail-laboratoire-Copy-7"
        transform="translate(-1227 -568)"
      >
        <g id="Group-5" transform="translate(458 548)">
          <g id="Group-16" transform="translate(715 20)">
            <g id="Icon/Miscellaneous/Package" transform="translate(54.289)">
              <mask id="mask-2" fill="#fff">
                <use xlinkHref="#path-1" />
              </mask>
              <use xlinkHref="#path-1" id="Icon-Package" fill="#8C20B1" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
