import { Box, Stack, Typography, styled, useTheme } from "@mui/material"
import { ReactNode } from "react"
import { ChevronRight } from "react-feather"
import { Link } from "react-router-dom"
import { MergeExclusive } from "type-fest"
import { AmountChip, NumberFormat, Price } from "../../dataDisplay"
import {
  CardLine,
  CardLineAction,
  ContentGrid,
  ICON_SIZE,
  ProgressBar,
  ProgressStatus,
  SmallLabel,
  getPluralizedWording,
} from "./common"

export enum GoalTrackingVariant {
  OPERATION = "operation",
  CHALLENGE = "challenge",
}

interface CommonProps {
  link?: string
  title?: string
  tag?: string
  status: {
    boldPart: ReactNode
    regularPart: string
  }
  showTargetChip?: boolean
  // Data
  currentValue: number
  goal: number
}

export interface OperationTrackingRowProps extends CommonProps {
  variant: GoalTrackingVariant.OPERATION
}
export interface ChallengeTrackingRowProps extends CommonProps {
  variant: GoalTrackingVariant.CHALLENGE
  lastYearValue: number
}
export type GoalTrackingRowProps = MergeExclusive<
  OperationTrackingRowProps,
  ChallengeTrackingRowProps
>

const GoalTrackingRow = ({
  variant,
  link,
  title,
  tag,
  status,
  showTargetChip = true,
  // Data
  currentValue,
  lastYearValue,
  goal,
}: GoalTrackingRowProps) => {
  const { spacing, typography } = useTheme()

  const { color, unit, size, showZero, maxGainTitle } = useVariantConfig(variant)

  const progress = Math.min((100 * currentValue) / goal, 100)
  const progressBarProps =
    variant === GoalTrackingVariant.CHALLENGE
      ? {
          referenceValue: lastYearValue,
          showHalfMark: true,
          showPercentage: true,
        }
      : {}

  return (
    <Stack spacing={1}>
      {/* TITLE */}
      <Stack direction="row" spacing={1}>
        {!!title && (
          <Typography ml={4} fontWeight={500}>
            {title}
          </Typography>
        )}
        {!!tag && <Typography color="grey.dark">{tag}</Typography>}
      </Stack>

      {/* ROW */}
      <Stack direction="row" spacing={2}>
        {/* PROGRESS LINE */}
        <CardLine>
          <CardLineAction
            size={size}
            {...(link
              ? { component: Link, to: link }
              : { sx: { pointerEvents: "none" } })}
          >
            <ContentGrid hasThirdColumn={variant === GoalTrackingVariant.OPERATION}>
              <ProgressStatus
                progress={progress}
                title={
                  <span style={{ whiteSpace: "nowrap" }}>
                    <b>{status.boldPart}</b>
                    &nbsp;
                    {status.regularPart}
                  </span>
                }
                color={color}
                showZero={showZero}
              />

              <ProgressBar
                color={color}
                progress={progress}
                goal={goal}
                unit={unit}
                {...progressBarProps}
              />

              {variant === GoalTrackingVariant.OPERATION && (
                <Box
                  textAlign="center"
                  position="relative"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <SmallLabel
                    position="absolute"
                    top={spacing(-6)}
                    textAlign="center"
                  >
                    Reste à justifier
                  </SmallLabel>
                  <Typography fontSize={typography.pxToRem(18)} fontWeight={600}>
                    {progress === 100 || progress === 0 ? (
                      "-"
                    ) : (
                      <>
                        {currentValue ? (
                          <NumberFormat value={currentValue} suffix={` ${unit}`} />
                        ) : (
                          <NumberFormat
                            value={goal - currentValue}
                            suffix={` ${getPluralizedWording(
                              unit,
                              goal - currentValue,
                            )}`}
                          />
                        )}
                      </>
                    )}
                  </Typography>
                </Box>
              )}
            </ContentGrid>

            {!!link && (
              <Box position="relative" width="14px" height="16px">
                <ChevronRight
                  color="#8da2c6"
                  size={ICON_SIZE}
                  style={{
                    // This svg contains unwanted margins conflicting with given size
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              </Box>
            )}
          </CardLineAction>
        </CardLine>

        {/* TARGET CHIP */}
        {!!showTargetChip && (
          <Box position="relative">
            <SmallLabel
              position="absolute"
              top={spacing(-3)}
              textAlign="center"
              width="100%"
            >
              {progress === 100 ? (
                <Typography component="span" color="success.main" fontSize="inherit">
                  Validé
                </Typography>
              ) : (
                maxGainTitle
              )}
            </SmallLabel>
            <AmountChip
              color={color}
              unit={unit}
              variant={progress === 100 ? "contained" : "outlined"}
              sx={{ minWidth: "120px", height: "100%" }}
            >
              {currentValue}
            </AmountChip>
          </Box>
        )}
      </Stack>
    </Stack>
  )
}

export const OperationTrackingRow = (
  props: Omit<OperationTrackingRowProps, "variant" | "status">,
) => (
  <GoalTrackingRow
    variant={GoalTrackingVariant.OPERATION}
    status={{
      boldPart: <Price value={Math.min(props.currentValue, props.goal)} />,
      regularPart: props.currentValue >= 1 ? "justifiés" : "justifié",
    }}
    {...props}
  />
)

export const ChallengeTrackingRow = (
  props: Omit<ChallengeTrackingRowProps, "variant">,
) => <GoalTrackingRow variant={GoalTrackingVariant.CHALLENGE} {...props} />

export const GoalTrackingContainer = styled(Stack)({})
GoalTrackingContainer.defaultProps = {
  spacing: 3,
}

const useVariantConfig = (variant: GoalTrackingVariant) => {
  const { palette } = useTheme()
  switch (variant) {
    case GoalTrackingVariant.OPERATION:
      return {
        color: palette.common.electricBlue,
        unit: "€",
        size: "large" as const,
        showZero: false,
        maxGainTitle: "Prime",
      }
    case GoalTrackingVariant.CHALLENGE:
      return {
        color: palette.loyalty.main,
        unit: "pts",
        size: "large" as const,
        showZero: true,
        maxGainTitle: "Points cumulés",
      }
  }
}
