import { useLocation } from "react-router-dom"
import { useDialog } from "../../../feedback/Dialog"
import { useBlocker } from "./useBlocker"

const getPrevPath = (path: string) => path.substr(0, path.lastIndexOf("/"))

export interface DirtyExitDialogProps {
  when: boolean
  allowSameLevelRouting: boolean
}

/**
 * Stop navigation and prompt a Dialog when the `when` prop
 * condition is matched.
 * Eg: In forms when the form is dirty to avoid an exit without saving data
 */
export const DirtyExitDialog = ({
  when,
  allowSameLevelRouting = false,
}: DirtyExitDialogProps) => {
  const dialog = useDialog()
  const { pathname } = useLocation()

  useBlocker((nextLocation) => {
    /**
     * Allow navigation to /foo/baz when on /foo/bar but not on /bar
     * (eg: on tabbed forms to only allow cross tab nav)
     */
    if (allowSameLevelRouting) {
      const currentPrevPath = getPrevPath(pathname)
      const destinationPrevPath = getPrevPath(nextLocation)
      const isSameLevel = currentPrevPath === destinationPrevPath

      if (isSameLevel) {
        return true
      }
    }

    return new Promise((resolve) =>
      dialog.confirm({
        title: "Attention",
        message: "Voulez vous quitter sans sauvegarder les modifications ?",
        validateButtonText: "Quitter",
        validateAction: () => resolve(true),
        cancelAction: () => resolve(false),
      }),
    )
  }, when)

  return null
}
