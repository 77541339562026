import { Box, Divider, Stack, Typography, styled, useTheme } from "@mui/material"
import {
  NumberFormat,
  PaperContainer,
  PaperContainerProps,
  Price,
} from "@pharmupp/p3-front-commons"
import { Flag } from "./icons/Flag"
import { Money } from "./icons/Money"

export const LoyaltyBlock = ({ ...props }: PaperContainerProps) => {
  const { palette } = useTheme()
  return (
    <PaperContainer {...props}>
      <Box
        display="grid"
        gridTemplateColumns="1fr auto 1fr"
        gap={3}
        height="100%"
        pt={1}
      >
        <Stack gap={1}>
          <Circle bgcolor={palette.loyalty.main}>
            <Flag />
          </Circle>

          <Title>CHALLENGES</Title>

          <KpisWrapper>
            <KpiWrapper>
              <KPI>
                <NumberFormat value={220} suffix=" pts" />
              </KPI>
              <KpiLabel>Catégoriels</KpiLabel>
            </KpiWrapper>

            <KpiWrapper>
              <KPI>
                <NumberFormat value={150} suffix=" pts" />
              </KPI>
              <KpiOptionalLabel>dont 4 commandes</KpiOptionalLabel>
              <KpiLabel>SDAV</KpiLabel>
            </KpiWrapper>
          </KpisWrapper>
        </Stack>

        <Divider orientation="vertical" flexItem />

        <Stack gap={1}>
          <Circle bgcolor="#f67848">
            <Money />
          </Circle>

          <Title>COMPENSATION</Title>

          <KpisWrapper>
            <KpiWrapper>
              <KPI>
                <Price value={220} />
              </KPI>
              <KpiLabel>Pharmedisound</KpiLabel>
            </KpiWrapper>

            <KpiWrapper>
              <KPI>
                <Price value={150} />
              </KPI>
              <KpiLabel>Opeaz</KpiLabel>
            </KpiWrapper>
          </KpisWrapper>
        </Stack>
      </Box>
    </PaperContainer>
  )
}

const Circle = styled(Box)(({ theme: { palette, spacing } }) => ({
  marginBottom: spacing(1.5),
  width: "47px",
  height: "47px",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

const Title = styled(Typography)(({ theme: { palette, spacing, typography } }) => ({
  marginBottom: spacing(2),
  fontSize: typography.pxToRem(19),
  fontWeight: 500,
  lineHeight: typography.pxToRem(21),
  color: palette.common.darkBlue,
  textTransform: "uppercase",
}))

const KpisWrapper = styled(Stack)(({ theme: { spacing } }) => ({
  gap: spacing(1),
}))

const KpiWrapper = styled(Stack)(({ theme: { spacing } }) => ({
  gap: spacing(0.5),
}))

const KPI = styled(Typography)(({ theme: { palette, typography } }) => ({
  fontSize: typography.pxToRem(22),
  lineHeight: typography.pxToRem(24),
  fontWeight: 600,
  color: palette.primary.dark1,
}))

const KpiOptionalLabel = styled(Typography)(
  ({ theme: { palette, typography } }) => ({
    fontSize: typography.pxToRem(12),
    lineHeight: typography.pxToRem(14),
    color: palette.primary.main,
  }),
)

const KpiLabel = styled(Typography)(({ theme: { palette, typography } }) => ({
  fontSize: typography.pxToRem(12),
  lineHeight: typography.pxToRem(14),
  color: palette.grey.light,
}))
