import { Chip, styled } from "@mui/material"

export type ChipVariation =
  | "default"
  | "blue"
  | "green"
  | "orange"
  | "pink"
  | "purple"
  | "yellow"
  | "main"
  | "light-blue"
  | "bright-green"
  | "purple-bold"

export const TinyChip = styled(Chip, {
  shouldForwardProp: (prop) =>
    !(prop === "variation" || prop === "backgroundColor" || prop === "textColor"),
})<{
  variation?: ChipVariation
  textColor?: string
  backgroundColor?: string
}>(({ theme, variation, backgroundColor, textColor }) => {
  const baseStyle = {
    height: "15px",
    padding: "2px 4px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "2px",
    backgroundColor: "transparent",
    borderColor: "transparent",
    color: "white",
    fontSize: theme.typography.pxToRem(8),
    fontWeight: 500,
    textTransform: "uppercase",
    alignItems: "center",
    "& .MuiChip-label": {
      padding: 0,
    },
  } as const

  if (backgroundColor !== undefined) {
    return {
      ...baseStyle,
      borderColor: backgroundColor,
      backgroundColor: backgroundColor,
      color: textColor ?? "white",
    }
  }

  switch (variation) {
    case "blue":
      return {
        ...baseStyle,
        borderColor: theme.palette.common.electricBlue,
        backgroundColor: theme.palette.common.electricBlue,
        color: "white",
      }
    case "bright-green":
      return {
        ...baseStyle,
        color: "white",
        fontWeight: "bold",
        backgroundColor: theme.palette.common.brightGreen,
      }
    case "green":
      return {
        ...baseStyle,
        color: "white",
        backgroundColor: theme.palette.common.greenLight1,
      }
    case "orange":
      return {
        ...baseStyle,
        backgroundColor: theme.palette.common.orangeDark1,
      }
    case "pink":
      return {
        ...baseStyle,
        backgroundColor: theme.palette.common.pink,
      }
    case "purple":
      return {
        ...baseStyle,
        color: theme.palette.primary.main,
        borderColor: theme.palette.tertiary.light1,
      }
    case "purple-bold":
      return {
        ...baseStyle,
        color: theme.palette.common.white,
        fontWeight: "bold",
        backgroundColor: theme.palette.common.darkViolet,
      }
    case "yellow":
      return {
        ...baseStyle,
        backgroundColor: theme.palette.common.orange,
      }
    case "main":
      return {
        ...baseStyle,
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
        color: "white",
      }
    case "light-blue":
      return {
        ...baseStyle,
        backgroundColor: theme.palette.common.lightBlue,
        color: theme.palette.common.blue,
      }
    default:
      return {
        ...baseStyle,
        color: theme.palette.primary.main,
        borderColor: theme.palette.grey.ultraLight,
      }
  }
})
