import {
  DashboardInfoBanner,
  DashboardInfoBannerCaption,
  DashboardInfoBannerTitle,
} from "@pharmupp/p3-front-commons"
import { useAdherentSummaryApi } from "./useAdherentSummaryApi"

export const InfoBanner = ({
  adherentId,
  adherentCentralBusinessId,
}: {
  adherentId?: string
  adherentCentralBusinessId?: string
}) => {
  const { summary } = useAdherentSummaryApi({ adherentId })

  if (!summary) return null

  return (
    <DashboardInfoBanner>
      <DashboardInfoBannerTitle>{summary.name}</DashboardInfoBannerTitle>
      {!!adherentCentralBusinessId && (
        <DashboardInfoBannerCaption>{`ID ${adherentCentralBusinessId}`}</DashboardInfoBannerCaption>
      )}
    </DashboardInfoBanner>
  )
}
