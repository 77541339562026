import { useSsoService } from "./useSso"

export const ROLES = {
  // PharmUPP
  ADMIN: "administrator",
  // CDO
  ADHERENT_ADVISOR: "adherent-advisor",
  // UPP
  UPP_PRESIDENT: "upp-president",
  UPP_REPRESENTATIVE: "upp-representative",
  UPP_TREASURER: "upp-treasurer",
  // Adherent
  ADHERENT_HOLDER: "adherent-holder",
  ADHERENT_TEAM: "adherent-team",
  ADHERENT_EMPLOYEE: "adherent-employee",
  // Extern
  EXTERN_CERP_OPERATOR: "extern-cerp-operator",
  EXTERN_CONTACT_LABORATORY: "extern-contact-laboratory",
  EXTERN_CONTACT_PROVIDER: "extern-contact-provider",
  EXTERN_FORMER: "extern-former",
  EXTERN_INTERN: "extern-intern",
  EXTERN_SPEAKER: "extern-speaker",
} as const

type ValueOf<T> = T[keyof T]
export type Role = ValueOf<typeof ROLES>

export function useRoleCheck(role: Role): boolean
export function useRoleCheck(): { checkRole: (role: Role) => boolean }
export function useRoleCheck(role?: Role) {
  const sso = useSsoService()
  const roles = sso.getRoles() || []
  const checkRole = (roleToCheck: Role) => roles.includes(roleToCheck)

  return role ? checkRole(role) : { checkRole }
}
