import { Link as MuiLink, LinkProps as MuiLinkProps } from "@mui/material"
import { forwardRef } from "react"
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom"

export type LinkProps = MuiLinkProps<typeof RouterLink, RouterLinkProps>

/**
 * MUI+ReactRouter consistent wiring across apps
 */
export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children, ...args }, ref) => (
    <MuiLink component={RouterLink} ref={ref} role="link" {...args}>
      {children}
    </MuiLink>
  ),
)
Link.displayName = "Link"
