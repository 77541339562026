import {
  Divider as MuiDivider,
  Link as MuiLink,
  Tab as MuiTab,
  Tabs as MuiTabs,
  styled,
  tabClasses,
  tabsClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useRoleConf } from "@pharmupp/p3-front-commons"
import * as React from "react"
import { Link, matchPath, useLocation } from "react-router-dom"
import { menuRoleConf } from "./menu.roleConf"

interface NavBarProps {
  height: number
  isFixed: boolean
}
export const NavBar = ({ height, isFixed }: NavBarProps) => {
  const { breakpoints } = useTheme()
  const compact = useMediaQuery(breakpoints.down("md"))
  const { tabs } = useRoleConf(menuRoleConf)

  // Routing
  const { pathname } = useLocation()
  const selectedTab = React.useMemo(() => {
    const currentIndex = tabs.findIndex(
      (tab) => !("divider" in tab) && !!matchPath(`${tab.to}/*`, pathname),
    )
    return currentIndex > -1 ? currentIndex : false
  }, [tabs, pathname])

  return (
    <Tabs
      textColor="primary"
      indicatorColor="secondary"
      value={selectedTab}
      className="header-menu"
      height={`${height}px`}
      compact={compact}
    >
      {tabs.map((tab, index) =>
        "divider" in tab ? (
          <Tab
            key={index}
            label=""
            icon={<Divider orientation="vertical" flexItem />}
            disabled
          />
        ) : (
          <Tab
            key={index}
            icon={tab.icon}
            label={tab.icon && isFixed ? undefined : tab.label}
            sx={{
              ...(!!tab.accent && {
                bgcolor: "primary.dark1",
                borderRadius: "5px",
                py: 0.5,
                px: 1,
                ...(isFixed
                  ? {
                      height: "auto!important",
                      minHeight: "auto!important",
                      alignSelf: "center",
                    }
                  : {
                      height: "28px!important",
                      minHeight: "28px!important",
                      mt: "5px",
                    }),
              }),
            }}
            {...(tab.href !== undefined
              ? {
                  component: MuiLink,
                  href: tab.href,
                  target: "_self",
                }
              : {
                  component: Link,
                  to: tab.to,
                })}
          />
        ),
      )}
    </Tabs>
  )
}

const Tabs = styled(MuiTabs, {
  shouldForwardProp: (prop) => !["height", "compact"].includes(String(prop)),
})<{ height: string; compact: boolean }>(({ theme, height, compact }) => ({
  height: height,
  minHeight: height,
  "& .MuiTabs-indicator": {
    height: "4px",
  },
  [`.${tabClasses.root}`]: {
    height: height,
    minHeight: height,
    ...(compact && {
      fontSize: theme.typography.pxToRem(13),
    }),
  },
  [`.${tabsClasses.flexContainer}`]: {
    ...(compact && {
      gap: theme.spacing(3),
    }),
  },
}))

const Tab = styled(MuiTab)(({ theme }) => ({
  fontWeight: 600,
  "&:not(.Mui-selected)": {
    color: theme.palette.common.white,
  },
}))
Tab.defaultProps = {
  disableRipple: true,
  iconPosition: "start",
}

const Divider = styled(MuiDivider)(({ theme: { palette } }) => ({
  borderColor: palette.common.darkBlue,
  height: "21px",
  alignSelf: "center",
}))
