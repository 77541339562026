import { Yup } from "@pharmupp/p3-front-commons"

export const informationsSchema = Yup.object({
  signatory: Yup.object({
    lastName: Yup.string().nullable(),
    firstName: Yup.string().nullable(),
    function: Yup.string().nullable(),
    phone: Yup.string().nullable(),
  }),
  accountManagers: Yup.array().of(
    Yup.object({
      entity: Yup.object().shape({
        id: Yup.string().required("Un responsable est requis"),
      }),
      function: Yup.string().max(100),
    }),
  ),
})
