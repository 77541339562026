import { Box, Stack, TableCell, Typography, styled } from "@mui/material"
import {
  ClipBoardButton,
  ColumnRenderDefinition,
  Price,
  SortedTableCell,
} from "@pharmupp/p3-front-commons"
import { Check, X } from "react-feather"
import { ApiParticipant } from "./types"

export const upp: ColumnRenderDefinition<ApiParticipant> = {
  renderHeader: ({ width }) => <TableCell width={width}>UPP</TableCell>,
  renderRow: ({ row }) => <TableCell>{row.uppName}</TableCell>,
}

export const pharmacy: ColumnRenderDefinition<ApiParticipant> = {
  renderHeader: ({ sortProps, width }) => (
    <SortedTableCell {...sortProps} columnName="adherentName" width={width}>
      Pharmacie
    </SortedTableCell>
  ),
  renderRow: ({ row }) => (
    <TableCell>
      <Typography
        fontWeight="bold"
        fontSize={({ typography }) => typography.pxToRem(12)}
      >
        {row.adherentName}
      </Typography>
      <Typography
        color="grey.dark"
        fontSize={({ typography }) => typography.pxToRem(11)}
      >
        ID : {row.adherentId}
      </Typography>
    </TableCell>
  ),
}

export const contact: ColumnRenderDefinition<ApiParticipant> = {
  renderHeader: ({ width }) => <TableCell width={width}>Contact</TableCell>,
  renderRow: ({ row }) => (
    <TableCell>
      <Typography fontSize="inherit">{row.holderNames.join(", ")}</Typography>
      {!!row.email && (
        <Stack direction="row" alignItems="center">
          <Typography fontSize="inherit" noWrap>
            {row.email}
          </Typography>
          <ClipBoardButton
            label="email"
            value={row.email}
            size="small"
            sx={({ palette, typography }) => ({
              color: palette.grey.dark,
              fontSize: typography.pxToRem(14),
            })}
          />
        </Stack>
      )}
      <Typography fontSize="inherit">{row.phone}</Typography>
    </TableCell>
  ),
}

export const justifiedAmount: ColumnRenderDefinition<ApiParticipant> = {
  renderHeader: ({ sortProps, width }) => (
    <SortedTableCell {...sortProps} columnName="justifiedAmount" width={width}>
      Montant HT justifié
    </SortedTableCell>
  ),
  renderRow: ({ row }) => (
    <TableCell>
      {row.justifiedAmount ? (
        <Typography
          color="common.electricBlue"
          fontSize={({ typography }) => typography.pxToRem(14)}
        >
          <b>
            <Price value={row.justifiedAmount} />
          </b>{" "}
          justifiés
        </Typography>
      ) : (
        <Typography
          color="grey.light"
          fontSize={({ typography }) => typography.pxToRem(14)}
        >
          En attente
        </Typography>
      )}
      <Typography
        fontSize={({ typography }) => typography.pxToRem(12)}
        color="grey.light"
      >
        Obj : <Price value={row.bonusMax} />
      </Typography>
    </TableCell>
  ),
}

export const validated: ColumnRenderDefinition<ApiParticipant> = {
  renderHeader: ({ width }) => (
    <TableCell width={width} align="center">
      Prime validée
    </TableCell>
  ),
  renderRow: ({ row }) => (
    <TableCell>
      {row.status === "VALIDATED" ? (
        <Circle
          bgcolor="success.main"
          borderColor="transparent"
          color="white"
          margin="0 auto"
        >
          <Check size="14px" />
        </Circle>
      ) : (
        <Circle color="grey.light" borderColor="grey.light" margin="0 auto">
          <X size="14px" />
        </Circle>
      )}
    </TableCell>
  ),
}

const Circle = styled(Box)(({ theme }) => ({
  width: theme.typography.pxToRem(28),
  height: theme.typography.pxToRem(28),
  borderRadius: "150px",
  borderWidth: "1px",
  borderStyle: "solid",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))
