import { Box, useTheme } from "@mui/material"
import { ResponsivePie } from "@nivo/pie"
import { PropsWithChildren, useMemo } from "react"

interface Datum {
  label: string
  /** percentage */
  value: number
  color: string
}

export interface DoughnutProps {
  data: Datum[]
  height?: string
}

export const Doughnut = ({
  data,
  height,
  children,
}: PropsWithChildren<DoughnutProps>) => {
  const { palette } = useTheme()
  const hasData = data.length > 0
  const finalData = useMemo(
    () =>
      hasData ? data : [{ label: "", value: 100, color: palette.grey.extraLight }],
    [data, hasData, palette.grey.extraLight],
  )

  return (
    <Box position="relative" width="100%" height={height || "100%"}>
      <Box position="relative" width="100%" height={height || "100%"} zIndex={5}>
        <ResponsivePie
          data={finalData}
          id="label"
          innerRadius={0.75}
          margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
          activeOuterRadiusOffset={4}
          padAngle={1.2}
          valueFormat={(value) => `${value}%`}
          arcLabel={(datum) =>
            hasData && datum.value > 3 ? datum.formattedValue : ""
          }
          arcLabelsTextColor={{
            from: "color",
            modifiers: [["darker", 2]],
          }}
          enableArcLinkLabels={false}
          colors={{ datum: "data.color" }}
          isInteractive={hasData}
          theme={{
            labels: {
              text: {
                fontSize: "12px",
                fontWeight: "bold",
              },
            },
            tooltip: {
              container: {
                fontSize: "12px",
              },
            },
          }}
        />
      </Box>

      {/* Center element  */}
      <Box
        zIndex={0}
        position="absolute"
        top={0}
        right={0}
        left={0}
        bottom={0}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {children}
      </Box>
    </Box>
  )
}
