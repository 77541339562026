import { AlertCircle } from "react-feather"
import { Dialog } from "../Dialog"

export interface InfoDialogProps {
  open: boolean
  title: string
  message: string
  onClickClose: () => void
}

export const InfoDialog = ({
  open,
  title,
  message,
  onClickClose,
}: InfoDialogProps) => (
  <Dialog
    Icon={AlertCircle}
    title={title}
    validateAction={onClickClose}
    validateText="OK"
    open={open}
    loading={false}
    onClose={onClickClose}
  >
    {message}
  </Dialog>
)
