import {
  Box,
  Button,
  Card,
  CardActionArea,
  Stack,
  Tooltip,
  Typography,
  styled,
} from "@mui/material"
import { Check } from "react-feather"
import { OfferChip, TagChip } from "../../common"
import { TimeType } from "../api"
import { openOffer } from "../common"
import { Offer } from "../types"

export const OfferCard = ({
  offer,
  showLabLogo = true,
  timeType = "IN_PROGRESS",
  isAdmin = false,
}: {
  offer: Offer
  showLabLogo?: boolean
  timeType?: "IN_PROGRESS" | "COMING_SOON"
  isAdmin?: boolean
}) => {
  return (
    <Card key={offer.id} component={Box} height={showLabLogo ? "350px" : "290px"}>
      <CardActionArea
        component={Box}
        height="100%"
        onClick={() => timeType !== "COMING_SOON" && openOffer(offer)}
        disabled={timeType === "COMING_SOON"}
      >
        <Stack justifyContent="space-between" height="100%" pt={1} px={2} pb={3}>
          <Stack alignItems="flex-start">
            {/* CHIPS */}
            <Stack direction="row" spacing={1} mb={2}>
              <OfferChip label={offer.offerSource} />
              <TagChip tag={offer.tag} isCatalog={offer.isCatalog} />
            </Stack>

            {/* LOGO */}
            {!!showLabLogo && (
              <CardLogo src={offer.labLogoUrl} height="71px" loading="lazy" />
            )}

            {/* TITLE */}
            <CardTitle mt={2} mb={1}>
              {offer.title}
            </CardTitle>
          </Stack>

          <Stack>
            <Box height={(theme) => theme.spacing(3)}>
              {!!offer.type && (
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={0.3}
                  color={offer.type.color}
                  height={(theme) => theme.typography.pxToRem(14)}
                >
                  <offer.type.Icon style={{ height: "inherit" }} />
                  <CardOfferKind>{offer.type.label}</CardOfferKind>
                </Stack>
              )}
            </Box>
            <CardOfferDates>
              {getDisplayedDateInfo(
                timeType,
                offer.startDateFrenchFormat,
                offer.endDateFrenchFormat,
              )}
            </CardOfferDates>

            {timeType !== "COMING_SOON" && (
              <Stack direction="row" alignItems="center" spacing={1} mt={3}>
                {!!offer.lastOrderDate && (
                  <AlreadyOrderedIcon orderDate={offer.lastOrderDate} />
                )}
                <Button
                  variant="contained"
                  size="small"
                  sx={{ pointerEvents: "none" }}
                >
                  {isAdmin ? (
                    "Consulter"
                  ) : (
                    <>{offer.isPreorder ? "Précommander" : "Commander"}</>
                  )}
                </Button>

                {!!offer.maxItems && (
                  <Typography
                    fontSize={(theme) => theme.typography.pxToRem(10)}
                    color="grey.light"
                    sx={{ textDecoration: "underline" }}
                  >
                    Nb max : {offer.maxItems}
                  </Typography>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  )
}

const CardLogo = styled("img")({
  maxWidth: "100%",
  objectFit: "contain",
})

const CardTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.darkBlue,
  fontSize: theme.typography.pxToRem(14),
  fontWeight: "normal",
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  /* number of lines to show */
  WebkitLineClamp: "5",
  lineClamp: "5",
}))

const CardOfferKind = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(11),
  textTransform: "uppercase",
}))
const CardOfferDates = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.darkBlue,
  fontSize: theme.typography.pxToRem(11),
}))
const AlreadyOrderedIcon = ({ orderDate }: { orderDate: string }) => (
  <Tooltip title={`Déjà commandé le ${orderDate}`} arrow>
    <Box
      bgcolor="success.main"
      width={24}
      height={24}
      borderRadius="50%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Check height={18} width={18} color="#fff" />
    </Box>
  </Tooltip>
)

export const getDisplayedDateInfo = (
  timeType: TimeType,
  startDate: string,
  endDate: string,
) =>
  timeType === "IN_PROGRESS" ? `Jusqu'au ${endDate}` : `À partir du ${startDate}`
