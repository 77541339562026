import { Yup } from "@pharmupp/p3-front-commons"
import { getRequiredUserSchema } from "@pharmupp/p3-user-domain"

const holderSchema = getRequiredUserSchema("Titulaire requis")

export const detailsSchema = Yup.object({
  pharmacy: Yup.string().required().max(75),
  address: Yup.object({
    line1: Yup.string()
      .max(60)
      .when("line2", ([line2], schema) =>
        line2
          ? schema.required(
              "La première ligne d'adresse est obligatoire si la seconde est saisie",
            )
          : schema,
      ),
    line2: Yup.string().max(60),
    postalCode: Yup.string()
      .matches(/^(\d{5})?$/g, "Le code postal doit être composé de cinq chiffres")
      .when("line1", ([line1], schema) =>
        line1
          ? schema.required(
              "Le code postal est obligatoire pour compléter l'adresse",
            )
          : schema,
      ),
    city: Yup.string()
      .max(255)
      .when("line1", ([line1], schema) =>
        line1
          ? schema.required("La ville est obligatoire pour compléter l'adresse")
          : schema,
      ),
  }).default(undefined),
  primaryHolder: holderSchema,
  otherHolders: Yup.array()
    .max(3, "Il ne peut y avoir que 3 autres titulaires")
    .of(holderSchema),
  email: Yup.string().email().max(75),
  phone: Yup.string(),
  mobile: Yup.string(),
  fax: Yup.string(),
})
