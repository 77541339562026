import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable"
import { FC } from "react"

export interface SortableContainerProps {
  entities: any[]
  onDragEnd: (event: { active: { id: any }; over: { id: any } }) => void
}

export const SortableContainer: FC<
  React.PropsWithChildren<SortableContainerProps>
> = ({ entities, onDragEnd, children }) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // Require pointer to move by 5 pixels before activating draggable
        // Allows nested onClicks/buttons/interactions to be accessed
        distance: 5,
      },
    }),
  )

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={onDragEnd as (e: DragEndEvent) => void}
    >
      <SortableContext items={entities} strategy={verticalListSortingStrategy}>
        {children}
      </SortableContext>
    </DndContext>
  )
}
