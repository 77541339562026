import { OfferTagValue, TagObject } from "../types"

export const tagObjectRecord: Record<OfferTagValue | string, TagObject> = {
  // SDAV
  CHALLENGE_SDAV: {
    label: "Challenge",
    chipVariation: "purple-bold",
  },

  // COALIA
  IMPLANTATION_PACK: {
    label: "Pack d'implantation",
    chipVariation: "light-blue",
  },
  RETURN_BONUS: {
    label: "Bonus de remise",
    chipVariation: "light-blue",
  },
  COOP: {
    label: "Cooperation Commerciale",
    chipVariation: "light-blue",
  },
  RFA_ACTIVATION_PACK: {
    label: "Pack d'activation RFA",
    chipVariation: "light-blue",
  },
  MY_COMMITMENTS: {
    label: "Mon engagement",
    chipVariation: "bright-green",
  },

  // COALIA - ARCHIVED (not shown in dropdown, only in tables)
  CHALLENGE: { label: "Défi", chipVariation: "default" },
  NOVELTY: { label: "Nouveauté", chipVariation: "default" },
  PROMOTION: { label: "Promotion", chipVariation: "default" },
  PERMANENT: { label: "Permanente", chipVariation: "default" },
  HIGHLIGHT: { label: "Temps Fort", chipVariation: "default" },
  NONE: { label: "Aucune", chipVariation: "default" },
  RFA_BUSINESS_COOP_ACTIVATION_PACK: {
    label: "Pack d'activation coopération commerciale",
    chipVariation: "default",
  },
  PUNCTUAL_OFFER: { label: "Offre ponctuelle", chipVariation: "default" },
  DESTOCKING: { label: "Déstockage", chipVariation: "default" },
}
