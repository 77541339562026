import { Box, Typography, styled, useMediaQuery, useTheme } from "@mui/material"
import { useMemo } from "react"
import { ApiDisplayNewsReferential } from "../model"
import { LatestNewsItem, LatestNewsItemModel } from "./LatestNewsItem"

export interface LatestNewsItemsProps {
  items: LatestNewsItemModel[]
  referential: ApiDisplayNewsReferential
  columnNbr: number
  lineNbr?: number
  link?: (id: number) => string
}

export const LatestNewsItems = ({
  items,
  referential,
  columnNbr,
  link,
  lineNbr = 1,
}: LatestNewsItemsProps) => {
  const theme = useTheme()
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"))
  const finalColumnsNbr = isSmallDevice ? columnNbr - 1 : columnNbr
  const newsItems = useMemo(
    () => items.slice(0, lineNbr * finalColumnsNbr),
    [finalColumnsNbr, items, lineNbr],
  )
  return (
    <NewsGridContainer columns={finalColumnsNbr}>
      {newsItems.length > 0 ? (
        newsItems.map((item) => (
          <LatestNewsItem
            key={item.id}
            item={item}
            referential={referential}
            link={link}
          />
        ))
      ) : (
        <Typography
          mt={2}
          variant="h1"
          component="div"
          align="left"
          fontSize="1rem"
          fontWeight="500"
          color="common.darkBlue"
        >
          Aucune actualité
        </Typography>
      )}
    </NewsGridContainer>
  )
}

export const NewsGridContainer = styled(Box)<{ columns: number }>(
  ({ theme, columns }) => ({
    display: "grid",
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    gridGap: theme.spacing(2),
  }),
)
