import { OpeningHours } from "./OpeningHours"
import { defaultValues } from "./openingHours.const"
import { openingHoursSchema } from "./openingHours.schema"

export const OpeningHoursTab = {
  label: "Horaires",
  to: "horaires",
  Element: OpeningHours,
  schema: openingHoursSchema,
  defaultValues,
}
