import { Box, Stack, Typography, styled, useTheme } from "@mui/material"
import { Check } from "react-feather"
import { ChipVariation, OfferChip } from "./components"

interface ProductLabelProps {
  hasOffer?: boolean
  offersCount?: number
  source?: string
  title: string
  subtitle: string
  tag?: {
    name: string
    checked: boolean
    chipVariation?: ChipVariation
  }
}

export const ProductLabel = ({
  source,
  title,
  subtitle,
  hasOffer = false,
  offersCount,
  tag,
}: ProductLabelProps) => {
  const { typography } = useTheme()
  return (
    <Stack>
      <Stack direction="row" spacing={0.5} mb={1}>
        {!!source && (
          <OfferChip
            variation={source === "SAGITTA" ? "purple" : "default"}
            label={source === "SAGITTA" ? "Ma boutique UPP" : source}
          />
        )}
        {!!hasOffer && (
          <OfferChip
            label={offersCount === 1 ? "Offre" : `${offersCount} offres`}
            variation="blue"
          />
        )}
        {!!tag && (
          <OfferChip
            icon={
              tag.checked ? (
                <ChallengeCheckWrapper>
                  <Check size={11} />
                </ChallengeCheckWrapper>
              ) : undefined
            }
            label={tag.name}
            variation={tag.chipVariation || "blue"}
            sx={{
              flexDirection: "row-reverse",
              gap: 0.5,
              "& .MuiChip-iconMedium": {
                marginRight: "2px",
                marginLeft: "0px",
              },
            }}
          />
        )}
      </Stack>
      <Typography
        title={title}
        fontSize={typography.pxToRem(12)}
        fontWeight="bold"
        color={hasOffer ? "common.electricBlue" : undefined}
        sx={{
          display: "-webkit-box",
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
        }}
      >
        {title}
      </Typography>
      <Typography fontSize={typography.pxToRem(11)} color="grey.dark">
        {subtitle}
      </Typography>
    </Stack>
  )
}

const ChallengeCheckWrapper = styled(Box)(({ theme: { palette } }) => ({
  width: "18px",
  height: "18px",
  border: `1px solid ${palette.purple.dark3}`,
  backgroundColor: "white",
  color: palette.purple.dark3,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "100px",
}))
