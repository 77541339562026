import { Yup } from "@pharmupp/p3-front-commons"
import { add } from "date-fns"
import { OperationType } from "../../../operationMarketing.model"
import { fields } from "./generalInfo.const"

export const generalInfosSchema = Yup.object({
  [fields.programmedPublication]: Yup.boolean(),
  [fields.publicationDate]: Yup.date()
    .nullable()
    .when("$isCreate", ([isCreate], schema) => {
      if (isCreate) {
        const today = new Date()
        today.setDate(today.getDate() - 1)
        today.setHours(23, 59, 59, 999)
        return schema.min(today, "La date de début ne peut être dans le passé")
      }
      return schema
    })
    .when(fields.programmedPublication, ([publishNow], schema) =>
      publishNow ? schema.required("Une date de publication est requise") : schema,
    )
    .when(fields.validityStartDate, ([start], schema) =>
      start
        ? schema.max(
            start,
            "La date de début doit être antérieure à la date de début de validité",
          )
        : schema,
    ),
  [fields.type]: Yup.string().required("Type d'opération marketing obligatoire"),
  [fields.name]: Yup.string()
    .min(1, "name min lenght: 1")
    .max(255, "name max length: 255")
    .required(),
  [fields.validityStartDate]: Yup.date()
    .required("La date de début de validité est obligatoire")
    .when("$isCreate", ([isCreate], schema) => {
      if (isCreate) {
        const today = new Date()
        today.setDate(today.getDate() - 1)
        today.setHours(23, 59, 59, 999)
        return schema.min(today, "La date de début ne peut être dans le passé")
      }
      return schema
    })
    .nullable(),
  [fields.validityEndDate]: Yup.date()
    .required("La date de fin de validité est obligatoire")
    .when(fields.validityStartDate, ([start], schema) =>
      start
        ? schema.min(
            add(start, { days: 1 }),
            "La date de fin de validité doit être ultérieure à la date de début de validité",
          )
        : schema,
    )
    .nullable(),
  [fields.partnerLaboratories]: Yup.array()
    .nullable()
    .when(fields.type, ([type], schema) =>
      type === OperationType.BUSINESS
        ? schema.min(1, "Minimum 1 labo").required("Laboratoire obligatoire")
        : schema,
    ),
  [fields.description]: Yup.string().nullable(),
  [fields.hasAmmProducts]: Yup.boolean(),
  [fields.nbAmmProducts]: Yup.number()
    .when(fields.hasAmmProducts, ([hasAamProducts], schema) =>
      hasAamProducts
        ? schema
            .min(1, "Nombre de produits doit être un nombre positif")
            .required("Nombre de produits obligatoire")
        : schema,
    )
    .nullable(),
})
