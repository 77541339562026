import { Button, buttonClasses, styled } from "@mui/material"
import { Check, MinusCircle } from "react-feather"

const BaseListChip = styled(Button)(({ theme }) => ({
  minWidth: "auto",
  padding: theme.spacing(0.2, 0.6),
  textTransform: "uppercase",
  lineHeight: theme.typography.pxToRem(11),
  fontSize: theme.typography.pxToRem(9),
  fontWeight: 600,
  pointerEvents: "none",
  [`.${buttonClasses.startIcon}`]: {
    marginRight: "2px",
  },
}))

export const ListUltraChip = styled(BaseListChip)(({ theme }) => ({
  color: theme.palette.common.electricBlue,
  borderColor: theme.palette.common.electricBlue,
}))
ListUltraChip.defaultProps = {
  variant: "outlined",
  size: "small",
  startIcon: <Check size={11} />,
  children: "ULTRA",
}

export const ListNegativeSegmentationChip = styled(BaseListChip)(({ theme }) => ({
  color: theme.palette.error.main,
  borderColor: theme.palette.error.main,
  opacity: 0.7,
}))
ListNegativeSegmentationChip.defaultProps = {
  variant: "outlined",
  size: "small",
  startIcon: <MinusCircle size={11} />,
  children: "Segmentation Négative",
}
