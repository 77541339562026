import { Box, Typography, styled } from "@mui/material"
import { ReactNode } from "react"

export const DashboardInfoBanner = ({ children }: { children: ReactNode }) => (
  <Container>
    <Box display="flex" alignItems="center" gap={0.5}>
      {children}
    </Box>
  </Container>
)

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "48px",
  padding: theme.spacing(1, 2),
  borderRadius: "5px",
  backgroundColor: "#ecf5ff",
  p: {
    fontSize: theme.typography.pxToRem(14),
  },
  svg: {
    height: "14px",
    color: theme.palette.common.electricBlue,
  },
}))

export const DashboardInfoBannerTitle = styled(Typography)({
  fontWeight: 600,
})
export const DashboardInfoBannerText = styled(Typography)({
  fontWeight: 500,
})
export const DashboardInfoBannerCaption = styled(Typography)(
  ({ theme: { palette } }) => ({
    color: palette.grey.dark,
  }),
)
