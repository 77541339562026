import {
  Stack,
  StackProps,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
  toggleButtonClasses,
} from "@mui/material"
import { useState } from "react"

interface PageSizeSelectorProps {
  sizes: { label: string; value: number }[]
  value: number
  onChange: (newValue: number) => void
  stackProps?: StackProps
}
export const PageSizeSelector = ({
  sizes,
  value,
  onChange,
  stackProps,
}: PageSizeSelectorProps) => (
  <Stack spacing={0.5} {...stackProps}>
    <MainLabel>Afficher par :</MainLabel>
    <SizeBtnGroup
      value={value}
      onChange={(_event, newValue) => !!newValue && onChange(newValue)}
      exclusive
    >
      {sizes.map((size) => (
        <SizeBtn value={size.value} key={size.value}>
          {size.label}
        </SizeBtn>
      ))}
    </SizeBtnGroup>
  </Stack>
)

const MainLabel = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(10),
  color: theme.palette.grey.light,
}))

const SizeBtnGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  gap: theme.spacing(1),
}))
const SizeBtn = styled(ToggleButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey.light}!important`,
  borderRadius: "3px!important",
  padding: `${theme.spacing(0.4)} ${theme.spacing(0.6)}!important`,
  minWidth: 0,
  fontSize: theme.typography.pxToRem(10),
  fontWeight: "initial",
  color: theme.palette.grey.dark,
  textTransform: "uppercase",
  [`&.${toggleButtonClasses.selected}`]: {
    border: `1px solid ${theme.palette.common.darkBlue}!important`,
    backgroundColor: "white",
  },
}))

interface UsePageSizeArgs {
  defaultValue: number
  storagePrefix?: string
}
export const usePageSize = ({
  defaultValue,
  storagePrefix = "",
}: UsePageSizeArgs) => {
  const storageKey = `${storagePrefix}rowsPerPage`
  const storedData = window.localStorage.getItem(storageKey)
  const [pageSize, setPageSize] = useState(
    storedData ? Number(storedData) : defaultValue,
  )
  return {
    pageSize,
    setPageSize(value: number) {
      window.localStorage.setItem(storageKey, String(value))
      setPageSize(value)
    },
  }
}
