import { Box, Grid, Stack, styled } from "@mui/material"
import {
  AppSubHeader,
  AppSubNav,
  FullWidthContent,
  FullWidthHeader,
  PaperContainer,
  ROLES,
  parseQueryString,
  scrollToId,
  useRoleCheck,
} from "@pharmupp/p3-front-commons"
import { useEffect, useMemo } from "react"
import { useLocation, useParams } from "react-router-dom"
import { InfoBanner } from "./InfoBanner"
import { SideBlock } from "./SideBlock"
import { useMyPharmacyApi } from "./useMyPharmacyApi"
import {
  HealthMissions,
  LoyaltyProgram,
  MarketShares,
  MyActivity,
  MyPurchases,
  MySales,
} from "./widgets"

const FixedGrid = styled(Grid)(({ theme }) => ({
  marginLeft: `-${theme.spacing(3)}`,
  marginTop: `-${theme.spacing(3)}`,
}))

/**
 * MON OFFICINE
 */
export const MyPharmacy = () => {
  const { id: adherentId } = useParams()

  // API
  const {
    loading,
    activities,
    serviceTurnover,
    objectives,
    progressions,
    adherentCentralBusinessId = "",
    healthMissions,
    marketShares,
    loyaltyProgramFigures,
    lastLoyaltyProgramSync,
    lastOspharmDashboardSync,
    lastOspharmPDMSync,
  } = useMyPharmacyApi()

  // ROUTING
  const location = useLocation()
  useEffect(() => {
    if (!loading && location.search) {
      const queryParams = parseQueryString(location.search) as Record<
        string,
        unknown
      >

      const menu = queryParams.menu as Record<string, unknown>
      const item = menu?.item as MenuItemId
      if (item) {
        scrollToId(item as string)
      }
    }
  }, [location.search, loading])

  // ROLES
  const isAdherent = useRoleCheck(ROLES.ADHERENT_HOLDER)
  const isPresident = useRoleCheck(ROLES.UPP_PRESIDENT)
  const isPresidentAdherent = isPresident && adherentId
  const menuItems = useMemo(
    () =>
      items.filter(({ enabledForPresident = true, id: itemId }) => {
        if (itemId === "fidelite" && !loyaltyProgramFigures) return false
        return enabledForPresident ? true : !isPresident || !isPresidentAdherent
      }),
    [isPresident, isPresidentAdherent, loyaltyProgramFigures],
  )
  const menuIds = useMemo(() => menuItems.map((item) => item.id), [menuItems])
  const hasActivityBlock = menuIds.includes(ACTIVITY_ID)
  const hasMySalesBlock = menuIds.includes(MY_SALES_ID)
  const hasLoyaltyProgramBlock = menuIds.includes(LOYALTY_PROGRAM_ID)

  return (
    <FullWidthContent>
      <Box display="flex" gap={3}>
        {adherentId !== undefined ? (
          <FullWidthHeader
            title="Mon officine"
            linkTo=".."
            linkLabel="Liste des pharmacies"
          />
        ) : (
          <FullWidthHeader title="Mon officine" />
        )}

        <AppSubHeader title="Mon officine">
          <AppSubNav items={menuItems} />
        </AppSubHeader>
      </Box>

      <InfoBanner
        adherentId={adherentId}
        adherentCentralBusinessId={adherentCentralBusinessId}
      />

      <FixedGrid container spacing={3} rowSpacing={3} sx={{ mt: -5 }}>
        {!!(hasActivityBlock || hasMySalesBlock) && (
          <>
            <Grid item xs={8}>
              <PaperContainer>
                <Stack spacing={7}>
                  {/* ACTIVITÉ */}
                  {!!hasActivityBlock && (
                    <Box id={ACTIVITY_ID}>
                      <MyActivity loading={loading} activities={activities} />
                    </Box>
                  )}
                  {/* VENTES */}
                  {!!hasMySalesBlock && (
                    <Box id={MY_SALES_ID}>
                      <MySales
                        loading={loading}
                        categories={activities?.categories}
                        specificMedications={activities?.specificMedications}
                        lastSyncDate={lastOspharmDashboardSync}
                      />
                    </Box>
                  )}
                </Stack>
              </PaperContainer>
            </Grid>

            <SideBlock adherentId={adherentId} isAdherent={isAdherent} />
          </>
        )}

        {/* MES ACHATS */}
        <Grid item xs={12} id={MY_PURCHASES_ID}>
          <MyPurchases
            serviceTurnover={serviceTurnover}
            progressions={progressions}
            loading={loading}
            objectives={objectives}
          />
        </Grid>

        {/* PDM Catégorielles */}
        <Grid item xs={12} id={MY_MARKET_SHARES_ID}>
          <MarketShares
            loading={loading}
            marketShares={marketShares}
            lastSyncDate={lastOspharmPDMSync}
          />
        </Grid>

        {/* MISSIONS DE SANTÉ */}
        <Grid item xs={12} id={HEALTH_MISSIONS_ID}>
          <HealthMissions
            loading={loading}
            healthMissions={healthMissions}
            lastSyncDate={lastOspharmDashboardSync}
          />
        </Grid>

        {/* PROGRAMME FIDÉLITÉ */}
        {!!loyaltyProgramFigures && !!hasLoyaltyProgramBlock && (
          <Grid item xs={12} id={LOYALTY_PROGRAM_ID}>
            <LoyaltyProgram
              loading={loading}
              loyaltyProgramFigures={loyaltyProgramFigures}
              lastSyncDate={lastLoyaltyProgramSync}
            />
          </Grid>
        )}
      </FixedGrid>
    </FullWidthContent>
  )
}

const ACTIVITY_ID = "activite"
const MY_SALES_ID = "ventes"
const MY_PURCHASES_ID = "achats"
const MY_MARKET_SHARES_ID = "pdm"
const HEALTH_MISSIONS_ID = "missions-de-sante"
const LOYALTY_PROGRAM_ID = "fidelite"
export type MenuItemId =
  | typeof ACTIVITY_ID
  | typeof MY_SALES_ID
  | typeof MY_PURCHASES_ID
  | typeof MY_MARKET_SHARES_ID
  | typeof HEALTH_MISSIONS_ID
  | typeof LOYALTY_PROGRAM_ID
interface MenuItem {
  id: MenuItemId
  label: string
  enabledForPresident?: boolean
}
const items: MenuItem[] = [
  { id: ACTIVITY_ID, label: "Activité", enabledForPresident: false },
  { id: MY_SALES_ID, label: "Ventes", enabledForPresident: false },
  { id: MY_PURCHASES_ID, label: "Achats" },
  { id: MY_MARKET_SHARES_ID, label: "PDM Catégorielles" },
  { id: HEALTH_MISSIONS_ID, label: "Missions de santé" },
  {
    id: LOYALTY_PROGRAM_ID,
    label: "Programme fidélité",
    enabledForPresident: false,
  },
]
