import { Box, styled } from "@mui/material"

export const CardContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "cardPerRow",
})<{ cardPerRow?: number; gap?: number }>(({ theme, cardPerRow, gap }) => ({
  display: "grid",
  gridTemplateColumns: `repeat(${cardPerRow || 4}, 1fr)`,
  gap: theme.spacing(gap || 3),
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: `repeat(${cardPerRow || 3}, 1fr)`,
    gap: theme.spacing(gap || 2),
  },
}))
