import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardProps,
  Link,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import { ReactNode } from "react"
import { ExternalLink, Icon as FeatherIcon } from "react-feather"

export type HighlightBoxProps = Pick<CardProps, "sx"> & {
  Icon: FeatherIcon
  iconColor?: string
  link?: string
} & ({ title: string } | { description: ReactNode })

export const HighlightBox = ({
  Icon,
  iconColor,
  link,
  sx,
  ...props
}: HighlightBoxProps) => {
  const theme = useTheme()
  return (
    <Card sx={sx}>
      <CardActionArea
        {...(link
          ? {
              // Handles only external links for now, could be updated (YAGNI)
              component: Link,
              href: link,
              target: "_blank",
              rel: "noreferrer",
            }
          : { sx: { pointerEvents: "none" } })}
      >
        <CardContent sx={{ p: 3 }}>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" spacing={2}>
              <Icon
                size={theme.typography.pxToRem(22)}
                color={iconColor || theme.palette.common.electricBlue}
              />
              <Stack spacing={2}>
                {"title" in props && <Title>{props.title}</Title>}
                {"description" in props && (
                  <Description>{props.description}</Description>
                )}
              </Stack>
            </Stack>
            {!!link && (
              <Box justifySelf="flex-end">
                <ExternalLink color={theme.palette.grey.light} />
              </Box>
            )}
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 600,
}))
const Description = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
}))
