import { IconButtonProps, IconButton as MuiIconButton, styled } from "@mui/material"
import { useState } from "react"
import { MoreVertical } from "react-feather"
import { Menu, MenuProps } from "../Menu"

export interface ActionButtonProps {
  items: MenuProps["items"]
  IconProps?: IconButtonProps
}

export const ActionButton = ({ items, IconProps }: ActionButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
  }
  const handleClose: React.MouseEventHandler = (e) => {
    e.stopPropagation()
    setAnchorEl(null)
  }
  const menuProps = {
    anchorEl: anchorEl,
    open: !!anchorEl,
    onClose: handleClose,
    onClick: handleClose,
    anchorOrigin: {
      vertical: "top",
      horizontal: "left",
    } as const,
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    } as const,
  }

  return (
    <>
      <IconButton {...IconProps} onClick={handleClick} data-testid="actions-button">
        <MoreVertical />
      </IconButton>

      <Menu {...menuProps} items={items} />
    </>
  )
}

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  "&:hover": {
    color: theme.palette.grey.light,
  },
}))
