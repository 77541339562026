import Keycloak, { KeycloakConfig } from "keycloak-js"
import { getKeycloackMock } from "./keycloack.mock"
import { PharmUPP_KeycloackInstance } from "./types"

export interface AuthService {
  loggedIn: boolean
  authz: PharmUPP_KeycloackInstance | null
}

const auth: AuthService = {
  loggedIn: false,
  authz: null,
}

export class SsoService {
  login() {
    auth.authz?.login().then(() => {
      auth.loggedIn = true
    })
  }

  getToken() {
    return new Promise<string | undefined>((resolve, reject) => {
      if (auth.authz?.token) {
        auth.authz
          .updateToken(5)
          .then(() => {
            resolve(auth.authz?.token)
          })
          .catch((e) => {
            console.error("Error Token refreshing", e)
            reject("Failed to refresh token")
          })
      } else {
        console.error("Not Logged In")
        reject("Not Logged in")
      }
    })
  }

  isLoggedIn() {
    return !!auth.authz?.authenticated
  }

  getAuth() {
    return auth.authz
  }

  logout() {
    return new Promise<void>((resolve, reject) => {
      auth.authz
        ?.logout({ redirectUri: document.baseURI })
        .then(() => {
          auth.loggedIn = false
          auth.authz = null
          console.info("Log out")
          resolve()
        })
        .catch((e) => {
          console.error("Unable to Log out")
          reject(e)
        })
    })
  }

  getFullName() {
    if (auth.authz?.authenticated) {
      return auth.authz.tokenParsed.name
    }
  }

  getName() {
    if (auth.authz?.authenticated) {
      return {
        firstName: auth.authz.tokenParsed.given_name,
        lastName: auth.authz.tokenParsed.family_name,
      }
    }
  }

  getRoles() {
    if (auth.authz?.authenticated) {
      return auth.authz.tokenParsed.realm_access.roles
    }
  }

  getInitials() {
    if (auth.authz?.authenticated) {
      return (
        auth.authz?.tokenParsed?.given_name.substring(0, 1).toUpperCase() +
        auth.authz?.tokenParsed?.family_name.substring(0, 1).toUpperCase()
      )
    }
  }

  getIds() {
    if (auth.authz?.authenticated) {
      return {
        keycloakId: auth.authz.tokenParsed.sub,
        adhId: auth.authz.tokenParsed["adherent-id"],
        chayallId: auth.authz.tokenParsed["chayall-id"],
        cerp: auth.authz.tokenParsed["cerp-code"],
        cip: auth.authz.tokenParsed["cip-code"],
        uppId: auth.authz.tokenParsed["upp-id"],
      }
    }
  }
}

export let serviceInstance: SsoService | null = null

export type SsoOptions = KeycloakConfig & {
  /** Disable auth (for testing purpose) */
  isMocked?: boolean
  mockConf?: Partial<PharmUPP_KeycloackInstance>
}
export async function SsoInit(options: SsoOptions) {
  if (!serviceInstance) {
    // Bypass for tests
    if (options.isMocked) {
      auth.loggedIn = true
      auth.authz = getKeycloackMock(options.mockConf)
      serviceInstance = new SsoService()
      return serviceInstance
    }

    const keycloakAuth = new Keycloak(options) as PharmUPP_KeycloackInstance

    auth.loggedIn = false

    try {
      await keycloakAuth.init({
        flow: "standard",
        onLoad: "login-required",
        checkLoginIframe: false,
      })
      auth.loggedIn = false
      auth.authz = keycloakAuth
      serviceInstance = new SsoService()
    } catch (e) {
      console.error(e)
    }
  }
  return serviceInstance!
}
