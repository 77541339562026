import type * as YupTypes from "yup"
import * as baseYup from "yup"
export type { YupTypes }

baseYup.setLocale({
  // Common messages
  mixed: {
    default: "Champ non valide",
    required: "Champ requis",
    notNull: "Champ requis",
    notType: "Type incorrect",
  },

  // Type specific messages
  string: {
    email: "L'email doit être valide (ex: nom@test.com)",
    url: "L'url doit être valide (ex: http://www.test.com)",
    uuid: "L'UUID doit être valide (ex: 31397ef3-c232-4b18-ab2e-715372489b7d",
    length: "La longueur doit être égale à ${length} caractères",
    min: "La longueur doit être supérieure à ${min} caractères",
    max: "La longueur doit être inférieure à ${max} caractères",
  },
  number: {
    positive: "La valeur doit être positive",
    min: "La valeur doit être supérieure ou égale à ${min}",
    max: "La valeur doit être inférieure ou égale à ${max}",
    lessThan: "La valeur doit être inférieure à ${less}",
    moreThan: "La valeur doit être supérieure à ${more}",
  },
  array: {
    length: "Le nombre d'éléments doit être égal à ${length}",
    min: "Le nombre d'éléments doit être supérieur ou égal à ${min}",
    max: "Le nombre d'éléments doit être inférieur ou égal à ${max}",
  },
  date: {
    min: "La date doit être supérieure à ${min}",
    max: "La date doit être inférieure à ${max}",
  },
})

/**
 * A standard HTML input when emptied get the value "" and Yup.number() doesn't like it
 * This method prevents a type error without altering .required() fn
 */
function stripEmptyString(value: unknown, originalValue: unknown) {
  if (typeof originalValue === "string" && originalValue === "") {
    return undefined
  }
  return value
}

/**
 * MUI DatePickers are returning null when the input is emptied
 * This method avoid the use of nullable by forcing undefined
 */
function stripNull(value: unknown, originalValue: unknown) {
  if (originalValue === null) {
    return undefined
  }
  return value
}

export const URL_MAX_LENGTH = 2048

class EnhancedStringSchema extends baseYup.StringSchema {
  email() {
    return super
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "L'email doit être valide (ex: nom@test.com)",
      )
      .transform((value: unknown) =>
        typeof value === "string" ? value.toLowerCase() : value,
      )
  }
  url() {
    return super.url().max(URL_MAX_LENGTH)
  }
}

export const Yup = {
  ...baseYup,
  number: () => baseYup.number().transform(stripEmptyString),
  date: () => baseYup.date().transform(stripEmptyString).transform(stripNull),
  string: () => new EnhancedStringSchema(),
} as typeof baseYup
