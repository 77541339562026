import {
  UploadedFile,
  api,
  formatDateWithDots,
  useDialog,
  useMutation,
  useQuery,
  useQueryClient,
  useToaster,
} from "@pharmupp/p3-front-commons"
import {
  OperationType,
  OperationValidationStatus,
} from "../../operationMarketing.model"
import { ProofDocument } from "../../types"
import { OperationState } from "../model"

export const getGetQueryKey = (operationId: string) => [
  "operation",
  "marketing",
  "adherent",
  operationId,
]

export const useAdhMarketingOPDisplayApi = ({
  operationId = "",
  enabled = true,
}: {
  operationId?: string
  enabled?: boolean
}) => {
  const endpoint = `/api/operation/marketing/adherent/${operationId}`
  const queryKey = getGetQueryKey(operationId)

  // OPERATION
  const { data: operation, isLoading: loading } = useQuery<
    ApiMarketingOperationDisplay,
    unknown,
    MarketingOperationDisplay
  >({
    queryKey,
    queryFn: () => api.get(endpoint),
    select: selectOperation,
    enabled,
  })

  return { operation, loading }
}

const selectOperation = (
  ope: ApiMarketingOperationDisplay,
): MarketingOperationDisplay => ({
  ...ope,
  validityStartDate: formatDateWithDots(ope.validityStartDate),
  validityEndDate: formatDateWithDots(ope.validityEndDate),
  deliveryDate: new Date(ope.deliveryDate).toLocaleDateString("fr", {
    day: "numeric",
    month: "long",
  }),
})

export type ApiMarketingOperationDisplay = {
  id: number
  programmedPublication: boolean
  publicationDate?: string | null
  name: string
  validityStartDate: string
  validityEndDate: string
  image?: UploadedFile | null
  description?: string | null
  registered: boolean
  state: OperationState
  validationStatus?: OperationValidationStatus
  rejectionReason?: string
  kitOrderStartDate: string
  kitOrderEndDate: string
  kitOrderStartDateFormatted: string
  kitOrderEndDateFormatted: string
  deliveryCarrier?: string | null
  deliveryDate: string
  nbLoyaltyPoints?: number | null
  participationBonus?: number | null
  completionProofDetails?: string | null
  nbAmmProducts: number
  nbProofDocuments: number
  nbProofDocumentsRecommended: number
  nbProofDocumentsMin: number
  nbProofDocumentsMax: number
  qrCodeToken?: string | null
  proofDocuments?: ProofDocument[]
  // {
  //     document: UploadedFile
  //     validationStatus: OperationValidationStatus
  //     rejectionReason?: string
  //   }[]
} & ConditionalFields

type ConditionalFields = (BusinessOperation | CommercialOperation) & AmmProducts

interface BusinessOperation {
  type: OperationType.BUSINESS
  partnerLaboratories: { id: number; name: string }[]
}
interface CommercialOperation {
  type: OperationType.DISPLAY_BASIN | OperationType.PROMOTIONAL
}
type AmmProducts =
  | {
      hasAmmProducts: true
      nbAmmProducts: number
    }
  | {
      hasAmmProducts: false
    }

export type MarketingOperationDisplay = Omit<
  ApiMarketingOperationDisplay,
  "registered" | "validationStatus"
> &
  ConditionalFields

export const useSubscriptionApi = ({
  operationId = "",
}: {
  operationId?: string
}) => {
  const toaster = useToaster()
  const dialog = useDialog()
  const queryClient = useQueryClient()

  const queryKey = getGetQueryKey(operationId)

  // SUBSCRIPTION
  const { mutateAsync: subscribe, isPending: isSubscribing } =
    useMutation<ApiMarketingOperationDisplay>({
      mutationFn: () =>
        api.put(`/api/operation/marketing/adherent/${operationId}/subscribe`, {
          headers: { "Content-Type": "application/json" },
        }),
      onSuccess: (data) => {
        queryClient.setQueryData(queryKey, data)
      },
      onError: () => {
        toaster.error("Erreur lors de la souscription")
      },
    })

  // UNSUBSCRIPTION
  const { mutateAsync: unsubscribe, isPending: isUnsubscribing } = useMutation({
    mutationFn: () =>
      api.put(`/api/operation/marketing/adherent/${operationId}/unsubscribe`, {
        headers: { "Content-Type": "application/json" },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey })
    },
    onError: () => {
      toaster.error("Erreur lors de la désinscription")
    },
  })

  return {
    subscribe,
    isSubscribing,
    unsubscribe() {
      const operation =
        queryClient.getQueryData<ApiMarketingOperationDisplay>(queryKey)
      dialog.confirm({
        title: "Attention",
        message: (
          <div style={{ textAlign: "center" }}>
            Souhaitez-vous vraiment vous désinscrire de l&apos;opération marketing :
            <br />
            <b>{operation?.name}</b> ?
          </div>
        ),
        validateAction() {
          unsubscribe()
        },
      })
    },
    isUnsubscribing,
  }
}
