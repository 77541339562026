export const ArrowUpIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="27">
    <defs>
      <path
        id="arrow-up"
        d="M13.713 7.707c-.2.19-.45.29-.71.29-.26 0-.51-.1-.71-.29l-4.29-4.3v13.59c0 .55-.45 1-1 1s-1-.45-1-1V3.407l-4.29 4.3c-.39.39-1.03.39-1.42 0-.39-.39-.39-1.03 0-1.42l6-6c.09-.09.2-.16.32-.21.05-.02.09-.04.14-.04.16-.05.34-.05.5 0 .05 0 .09.02.14.04.12.05.23.12.32.21l6 6c.39.39.39 1.03 0 1.42"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use xlinkHref="#arrow-up" fill="#2854A1" transform="translate(5 6)" />
      <path stroke="#1DB778" strokeLinecap="round" strokeWidth="2" d="M18 1H6" />
    </g>
  </svg>
)
