import { Stack, Typography, TypographyProps } from "@mui/material"
import { Eye, Icon as FeatherIcon } from "react-feather"

export const SocialScore = ({
  Icon,
  color,
  label,
}: {
  Icon: FeatherIcon
  color: TypographyProps["color"]
  label: string
}) => (
  <Stack direction="row" spacing={1} alignItems="center" color={color}>
    <Icon size="20px" />
    <Typography fontSize="0.875rem" color="common.darkBlue">
      {label}
    </Typography>
  </Stack>
)

interface NumberViewsProps {
  views: number
  variant: "compact" | "full"
  color: TypographyProps["color"]
}
export const NumberViews = ({ views, variant, color }: NumberViewsProps) => (
  <SocialScore
    Icon={Eye}
    color={color}
    label={`${views}${variant === "compact" ? "" : views > 1 ? " vues" : " vue"}`}
  />
)

export const FormNumberViews = ({
  views,
  variant,
}: Omit<NumberViewsProps, "color">) => (
  <NumberViews views={views} variant={variant} color="primary.light2" />
)

export const DisplayNumberViews = ({
  views,
  variant,
}: Omit<NumberViewsProps, "color">) => (
  <NumberViews views={views} variant={variant} color="common.electricBlue" />
)
