import { Tooltip } from "@mui/material"
import { Download } from "react-feather"
import { LoadingIconButton, LoadingIconButtonProps } from "../../../../inputs"

export const CommonExportButton = ({ title, ...props }: LoadingIconButtonProps) => (
  <Tooltip title={title}>
    <LoadingIconButton {...props}>
      <Download />
    </LoadingIconButton>
  </Tooltip>
)
