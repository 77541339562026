import {
  Box,
  BoxProps,
  Tab as MuiTab,
  Tabs as MuiTabs,
  styled,
  tabClasses,
  tabsClasses,
  useTheme,
} from "@mui/material"
import { ReactNode, useMemo } from "react"
import { AlertCircle, Icon as FeatherIcon } from "react-feather"
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom"
import { Chip } from "../../dataDisplay"

export interface Tab {
  label: string | React.ReactNode
  to: string
  Element: React.JSXElementConstructor<any>
  Icon?: FeatherIcon
  valid?: boolean
}
export interface TabbedPanelProps extends Pick<BoxProps, "sx"> {
  tabs: Tab[]
  RouteElementProps?: Record<string, unknown>
  chipLabel?: string
  tabsRightChildren?: ReactNode
}

export const TabbedPanel = ({
  tabs,
  RouteElementProps = {},
  tabsRightChildren = null,
  chipLabel,
  sx,
}: TabbedPanelProps) => {
  // Routing
  const { pathname } = useLocation()
  const currentTabPath = useMemo(() => {
    const paths = pathname.split("/")
    const path = paths[paths.length - 1]
    // At the first render, tab is not set so we need to fallback to avoid MUI error
    return tabs.find((tab) => tab.to === path) ? path : tabs[0].to
  }, [pathname, tabs])
  const theme = useTheme()

  return (
    <>
      {/* TABS */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={4}
        pt={1}
        pr={2}
        sx={{
          ...sx,
          overflow: "visible",
          borderBottom: `1px solid ${theme.palette.grey.extraLight}`,
        }}
      >
        {!!chipLabel && <TopChip label={chipLabel} fontColor="primary.main" />}
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          value={currentTabPath}
        >
          {tabs.map(({ label, to, Icon, valid = true }) => (
            <MuiTab
              component={Link}
              to={to}
              value={to}
              disabled={!to}
              label={label}
              iconPosition="start"
              icon={
                !valid ? (
                  <AlertCircle color={theme.palette.error.main} size="12px" />
                ) : Icon ? (
                  <Icon size="12px" />
                ) : (
                  ""
                )
              }
              key={to}
              disableRipple
            />
          ))}
        </Tabs>
        <Box display="flex" alignItems="center" sx={{ mt: -1 }}>
          {tabsRightChildren}
        </Box>
      </Box>

      {/* ROUTES */}
      <Routes>
        <Route index element={<Navigate replace to={tabs[0].to} />} />
        {tabs.map(({ to, Element }) => (
          <Route path={to} element={<Element {...RouteElementProps} />} key={to} />
        ))}
      </Routes>
    </>
  )
}

const Tabs = styled(MuiTabs)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  alignItems: "center",

  [`&:has(.${tabsClasses.scrollButtons})`]: {
    padding: 0, // Remove padding to give scroll buttons free space
  },

  [`& .${tabsClasses.flexContainer}`]: {
    alignItems: "center",
    height: "100%",
  },
  [`& .${tabsClasses.indicator}`]: {
    height: "2px",
  },
  [`& .${tabsClasses.scrollButtons}`]: {
    width: theme.spacing(4),
    color: theme.palette.grey.dark,
  },
  // TAB
  [`& .${tabClasses.root}`]: {
    height: "0",
  },
}))

const TopChip = styled(Chip)(({ theme }) => ({
  position: "absolute",
  height: theme.typography.pxToRem(20),
  top: theme.typography.pxToRem(-10),
  left: theme.spacing(4),
  fontSize: theme.typography.pxToRem(12),
  lineHeight: theme.typography.pxToRem(20),
  backgroundColor: "white",
}))
TopChip.defaultProps = {
  variant: "outlined",
  size: "small",
}
