import { PracticalInformation } from "./PracticalInformation"
import { practicalInformationSchema } from "./practicalInformation.schema"

export const PracticalInformationTab = {
  label: "Infos pratiques",
  to: "infos-pratiques",
  Element: PracticalInformation,
  schema: practicalInformationSchema,
  defaultValues: {},
}
