import { Box, Divider, Stack } from "@mui/material"
import {
  NumberFormat,
  PaperContainer,
  PaperContainerTitle,
  Percentage,
  Price,
} from "@pharmupp/p3-front-commons"
import { pick } from "lodash-es"
import { useMemo } from "react"
import { ShoppingCart, Users } from "react-feather"
import { KeyFigure, LoyaltyProgramFigures } from "../../kpi.models"
import {
  BigEvolution,
  BigKPI,
  HighlightedStatCard,
  MyDatavizCTA,
  NoData,
  StatCard,
  SyncDate,
  ValueDetail,
} from "../layouts"
import { GiftCardIcon } from "./GiftCardIcon"

interface LoyaltyProgramProps {
  loading: boolean
  loyaltyProgramFigures: LoyaltyProgramFigures
  lastSyncDate?: string
}

export const LoyaltyProgram = ({
  loyaltyProgramFigures,
  lastSyncDate,
  loading,
}: LoyaltyProgramProps) => {
  const hasData = useMemo(
    () =>
      Object.values(
        pick(loyaltyProgramFigures, [
          "averageBasketWithCheques",
          "averageBasketWithoutCheques",
          "numCardHolders",
          "loyaltyChequesCashingRate",
          "saleStats",
          "visitorStats",
        ]),
      ).some((value) => {
        if (value && typeof value === "object" && !Array.isArray(value)) {
          const stat = value as KeyFigure
          return stat.mainStat != null
        }
        return value != null
      }),
    [loyaltyProgramFigures],
  )

  return (
    <PaperContainer loading={loading}>
      <Stack spacing={5}>
        <PaperContainerTitle>
          Programme fidélité - Carte privilège
        </PaperContainerTitle>
        <Stack divider={<Divider />}>
          {hasData ? (
            <>
              <Box display="flex" gap={8}>
                <Stack>
                  <BigKPI
                    label="NOMBRE DE PORTEURS"
                    value={
                      <NumberFormat value={loyaltyProgramFigures.numCardHolders} />
                    }
                    hasValue={loyaltyProgramFigures.numCardHolders != null}
                  />
                </Stack>
                <Divider orientation="vertical" flexItem sx={{ my: 2 }} />
                <Stack direction="row" spacing={6}>
                  <BigKPI
                    label="CHIFFRE AFFAIRES"
                    value={
                      <Price
                        value={loyaltyProgramFigures.saleStats.mainStat}
                        decimalScale={0}
                      />
                    }
                    hasValue={loyaltyProgramFigures.saleStats.mainStat != null}
                  />
                  <BigEvolution
                    title="ÉVOLUTION GLOBALE"
                    subtitle="par rapport à l'année précédente"
                    evolution={loyaltyProgramFigures.saleStats.evolution}
                  />
                </Stack>
              </Box>

              <Box
                px={0}
                py={4}
                display="grid"
                gridTemplateColumns="1fr 1fr 1fr auto 1fr"
                gap={3}
              >
                <HighlightedStatCard
                  Icon={GiftCardIcon}
                  title="Encaissement"
                  subtitle="Chèques cadeaux"
                  mainStat={
                    <Percentage
                      value={loyaltyProgramFigures.loyaltyChequesCashingRate}
                      decimalScale={1}
                    />
                  }
                  hasValue={loyaltyProgramFigures.loyaltyChequesCashingRate != null}
                />
                <StatCard
                  Icon={ShoppingCart}
                  title="Panier moyen"
                  subtitle="avec chèques"
                  mainStat={
                    <>
                      <Price
                        value={loyaltyProgramFigures.averageBasketWithCheques}
                        suffix="€"
                      />
                      <ValueDetail>TTC</ValueDetail>
                    </>
                  }
                  hasValue={loyaltyProgramFigures.averageBasketWithCheques != null}
                  // POSTPONED
                  // evolution={0}
                />
                <StatCard
                  Icon={ShoppingCart}
                  title="Panier moyen"
                  subtitle="sans chèque"
                  mainStat={
                    <>
                      <Price
                        value={loyaltyProgramFigures.averageBasketWithoutCheques}
                        suffix="€"
                      />
                      <ValueDetail>TTC</ValueDetail>
                    </>
                  }
                  hasValue={
                    loyaltyProgramFigures.averageBasketWithoutCheques != null
                  }
                  // POSTPONED
                  // evolution={3}
                />
                <Divider orientation="vertical" flexItem sx={{ m: "20px 16px" }} />
                <StatCard
                  Icon={Users}
                  title="Fréquentation"
                  subtitle="moyenne"
                  mainStat={
                    <>
                      <NumberFormat
                        value={loyaltyProgramFigures.visitorStats.mainStat}
                        decimalScale={1}
                      />
                      <ValueDetail>clients/jour</ValueDetail>
                    </>
                  }
                  evolution={loyaltyProgramFigures.visitorStats.evolution}
                  hasValue={loyaltyProgramFigures.visitorStats.mainStat != null}
                />
              </Box>

              <SyncDate date={lastSyncDate} />
            </>
          ) : (
            <NoData />
          )}
          <MyDatavizCTA mt={1} />
        </Stack>
      </Stack>
    </PaperContainer>
  )
}
