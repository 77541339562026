import { Divider, DividerProps, styled } from "@mui/material"
import * as React from "react"

const AroundHeaderDivider: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<DividerProps>>
> = styled(Divider)(({ theme }) => ({
  "&:before, &:after": {
    borderTop: `0.2rem solid ${theme.palette.grey.extraLight}`,
    transform: "translateY(45%)",
  },
  "& .MuiDivider-wrapper": {
    paddingLeft: "2.5rem",
    paddingRight: "2.5rem",
  },
}))

const UnderHeaderDivider: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<DividerProps>>
> = styled(Divider)(({ theme }) => ({
  borderBottomWidth: "0.2rem",
  borderRadius: "0.2rem",
  borderColor: theme.palette.secondary.main,
  width: "109px",
}))

export const HeaderDivider = ({
  variant,
  children,
}: React.PropsWithChildren<{ variant: "around" | "under" }>) => {
  switch (variant) {
    case "around":
      return <AroundHeaderDivider sx={{ mb: 5 }}>{children}</AroundHeaderDivider>
    case "under":
      return <UnderHeaderDivider sx={{ mb: 5 }}>{children}</UnderHeaderDivider>
  }
}
