import { Yup } from "@pharmupp/p3-front-commons"
import { DocumentValidationStatus } from "../types"

const proofDocumentSchema = Yup.object({
  validationStatus: Yup.mixed<DocumentValidationStatus>().required(),
})

export const proofDocumentsSchema = Yup.object({
  proofDocuments: Yup.array().of(proofDocumentSchema).required(),
})
