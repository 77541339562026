/** eg: 01.12.2014 */
export const formatDateWithDots = (date: string | Date) =>
  parseStringOrDate(date).toLocaleDateString("fr-CH")

/** eg: 01/12/2024 */
export const formatDateWithSlashes = (date: string | Date) =>
  parseStringOrDate(date).toLocaleDateString("fr-FR")

/** eg: 01-12-2024 */
export const formatDateWithCarets = (date: string | Date) =>
  parseStringOrDate(date).toLocaleDateString("fr-CA")

const parseStringOrDate = (date: string | Date) =>
  typeof date === "string" ? new Date(date) : date
