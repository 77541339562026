import { Theme } from "@mui/material/styles"
import { Check, MoreHorizontal, X } from "react-feather"
import { ParticipationStatusEnum } from "./models/participation-status.enum"

export const getDateYear = (dateStr: string) => new Date(dateStr).getFullYear()

export const getParticipationStatusOptions = (theme: Theme) => ({
  [ParticipationStatusEnum.VALIDATED.code]: {
    icon: Check,
    color: theme.palette.success.main,
  },
  [ParticipationStatusEnum.SUBMITTED.code]: {
    icon: MoreHorizontal,
    color: theme.palette.common.orange,
  },
  [ParticipationStatusEnum.REFUSED.code]: {
    icon: X,
    color: theme.palette.grey.light,
  },
})
