import {
  Divider,
  Grid,
  InputLabel,
  Stack,
  Typography,
  alpha,
  inputBaseClasses,
  styled,
} from "@mui/material"
import { ReactNode, useCallback, useContext, useState } from "react"
import { AlertCircle } from "react-feather"
import { FormRoleConfContext } from "../../../context"
import { FieldsColumn } from "../FieldsColumn"

export type FieldSectionProps = {
  title: string
  subtitle?: string
  required?: boolean
  /** Used by role conf to hides a section */
  name?: string
  children: ReactNode
} & ({ highlighted?: boolean } | { danger?: boolean })

export const FieldSection = ({
  title,
  subtitle,
  required = false,
  name,
  children,
  ...props
}: FieldSectionProps) => {
  const isDanger = ("danger" in props && props.danger) || false
  const isHighlighted = ("highlighted" in props && props.highlighted) || false
  const [isRequired, setIsRequired] = useState(required)

  const childrenWrapperRef = useCallback(
    (ref: HTMLElement | null) => {
      if (ref && !isRequired) {
        const containsRequiredField = !!ref.querySelector(
          "[required],[aria-required='true']",
        )
        if (containsRequiredField) setIsRequired(containsRequiredField)
      }
    },
    [isRequired],
  )

  const conf = useContext(FormRoleConfContext)
  const { hide } = (name && conf[name]) || {}

  if (hide) return null

  return (
    <>
      <FieldSectionGrid
        container
        columnSpacing={4}
        rowGap={4}
        py={4}
        highlighted={isHighlighted}
        danger={isDanger}
      >
        <Grid item xs={12} md={4}>
          <Stack
            direction="row"
            spacing={0.6}
            sx={{
              color: (theme) =>
                isDanger ? theme.palette.error.main : theme.palette.grey.dark,
            }}
          >
            {!!isDanger && <AlertCircle width="17px" />}
            <Title required={isRequired}>{title}</Title>
          </Stack>
          {!!subtitle && <SubTitle>{subtitle}</SubTitle>}
        </Grid>
        <Grid item xs={12} md={8} pr={4}>
          <Fields danger={isDanger} ref={childrenWrapperRef}>
            {children}
          </Fields>
        </Grid>
      </FieldSectionGrid>

      {/* Hidden by CSS if last */}
      <Divider className="fieldSectionDivider" />
    </>
  )
}

const FieldSectionGrid = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "highlighted" && prop !== "danger",
})<{ highlighted: boolean; danger: boolean }>(({ highlighted, theme }) => ({
  width: "100%",
  ...(highlighted && {
    backgroundColor: alpha(theme.palette.common.blueGreen2, 0.25),
  }),
  // Hides last divider
  "&:last-of-type + .fieldSectionDivider": {
    display: "none",
  },
}))

const Title = styled(InputLabel)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "bold",
  color: "inherit",
  "& .MuiInputLabel-asterisk": {
    fontWeight: "normal",
    color: theme.palette.error.main,
  },
}))

const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: "0.75rem",
  color: theme.palette.grey.dark,
}))

const Fields = styled(FieldsColumn, {
  shouldForwardProp: (prop) => prop !== "danger",
})<{ danger: boolean }>(({ theme, danger }) => ({
  ...(danger && {
    [`.${inputBaseClasses.root}`]: {
      color: theme.palette.error.main,
    },
  }),
}))
