import { Yup } from "@pharmupp/p3-front-commons"

export const crisisContactsSchema = Yup.object({
  crisisContacts: Yup.array().of(
    Yup.object({
      name: Yup.string(),
      function: Yup.string(),
      phone: Yup.string(),
      email: Yup.string().email(),
    }),
  ),
})
