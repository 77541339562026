import {
  api,
  useMutation,
  useRoleConf,
  useToaster,
} from "@pharmupp/p3-front-commons"
import { apiRoleConf } from "../apiRoleConf"

export const useMail = () => {
  const toaster = useToaster()
  const apiConf = useRoleConf(apiRoleConf)
  const { mutate: resendConfirmation } = useMutation({
    mutationFn: (userId: string) => api.put(apiConf.sendVerifyMailEndpoint(userId)),
    onMutate: () => toaster.info("Requête en cours..."),
    onError: () => toaster.error("Une erreur est survenue"),
    onSuccess: () => toaster.success("Le lien de connexion a bien été envoyé"),
  })

  return {
    resendConfirmation,
  }
}
