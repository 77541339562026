import {
  api,
  useMutation,
  useQuery,
  useQueryClient,
  useToaster,
} from "@pharmupp/p3-front-commons"
import { ApiParticipant, ProofDocument } from "../types"

export const useParticipantDetailsApi = ({
  operationId,
  adherentId,
}: {
  operationId: string
  adherentId: string
}) => {
  const endpoint = `/api/operation/marketing/${operationId}/participants/${adherentId}`
  const queryKey = [
    "bonus-operations",
    "marketing-operations",
    operationId,
    "participants",
    adherentId,
  ]
  const queryClient = useQueryClient()
  const toaster = useToaster()

  const { data: participant, isLoading } = useQuery({
    queryKey,
    queryFn: () => api.get<ApiParticipant>(endpoint),
    select: (data) => ({
      ...data,
      proofDocuments: data?.proofDocuments?.map((pd) => ({
        ...pd,
        validationStatus:
          pd.validationStatus === "WAITING" ? undefined : pd.validationStatus,
      })),
      isJustifiableByAdmin:
        data.validationStatus === "NO_DOCUMENTS_SENT" ||
        data.validationStatus === "NO_DOCUMENTS_SENT_IN_TIME" ||
        data.validationStatus === "REFUSED",
    }),
  })

  const { mutateAsync: save, isPending: isSaving } = useMutation({
    mutationFn: (formValues: ApiParticipantFormData) =>
      api.put<ApiParticipantFormData>(endpoint, {
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formValues),
      }),
    onSuccess: (data) => {
      queryClient.setQueryData(queryKey, data)
    },
    onError: () => {
      toaster.error("Erreur lors de l'envoi")
    },
  })

  const refreshParticipant = () => queryClient.invalidateQueries({ queryKey })

  return {
    participant,
    isLoading,
    refreshParticipant,
    save,
    isSaving,
  }
}

export interface ApiParticipantFormData {
  proofDocuments: Pick<ProofDocument, "validationStatus" | "rejectionReason">[]
}
