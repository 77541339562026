import { yupResolver } from "@hookform/resolvers/yup"
import {
  Box,
  Chip,
  Divider,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import {
  ListNegativeSegmentationChip,
  ListUltraChip,
} from "@pharmupp/p3-adherent-domain"
import {
  BackLink,
  DirtyExitDialog,
  FullScreenPictureModal,
  LoadingButton,
  PageHeader,
  PaperContent,
  SideNav,
  SideNavLink,
  formatDateWithSlashes,
  getPublicFileUrl,
} from "@pharmupp/p3-front-commons"
import { useEffect, useState } from "react"
import { Check, MapPin, X } from "react-feather"
import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import {
  OperationStatus,
  ValidationStatus as ValidationStatusDisplay,
} from "../ParticipantList/columns"
import { TopChip } from "../commons"
import { ValidationStatus } from "../types"
import { AddDocumentCard } from "./AddDocumentCard"
import { Document } from "./Document"
import { RejectionMessageModal } from "./RejectionMessageModal"
import { proofDocumentsSchema as schema } from "./proofDocuments.schema"
import {
  ApiParticipantFormData,
  useParticipantDetailsApi,
} from "./useParticipantDetailsApi"

export const ParticipantDetails = ({ adherentName }: { adherentName?: string }) => {
  const { id: operationId, adherentId } = useParams()
  const theme = useTheme()

  if (!operationId || !adherentId) {
    throw new Error("Missing operationId or adherentId")
  }

  const { participant, isLoading, save, isSaving } = useParticipantDetailsApi({
    operationId,
    adherentId,
  })

  const formMethods = useForm<ApiParticipantFormData>({
    resolver: yupResolver(schema),
  })

  const {
    formState: {
      isDirty,
      isSubmitting,
      errors: { proofDocuments: proofDocumentErrors },
    },
  } = formMethods

  const { fields, update, replace } = useFieldArray({
    control: formMethods.control,
    name: "proofDocuments",
  })

  useEffect(() => {
    if (participant) {
      formMethods.reset({ proofDocuments: participant.proofDocuments })
      replace(participant.proofDocuments || [])
    }
  }, [formMethods, participant, replace])

  const [currRejectedDocIndex, setCurrRejectedDocIndex] = useState<number | null>(
    null,
  )

  const [fullScreenPictureSrc, setFullScreenPictureSrc] = useState<string | null>()

  return (
    <>
      <BackLink to="./../">Retour à la liste</BackLink>

      <Stack gridArea="sidebar" spacing={2}>
        <SideNav>
          <SideNavLink label="Détails du participant" to="./" />
        </SideNav>

        {participant?.isJustifiableByAdmin && (
          <AddDocumentCard operationId={operationId} adherentId={adherentId} />
        )}
      </Stack>

      {/* HEADER */}
      <PageHeader title={adherentName} level={2}>
        {!!participant && (
          <TopCTA
            status={participant?.validationStatus}
            onSubmit={formMethods.handleSubmit(async function onValid(formValues) {
              await save(formValues)
            })}
            loading={isSaving}
          />
        )}
      </PageHeader>

      <PaperContent loading={isLoading}>
        {!!participant && (
          <form>
            <Box px={3} py={4}>
              <Stack spacing={2} divider={<Divider />}>
                {/* INFOS HEADER */}
                <Stack direction="row" justifyContent="space-between">
                  {/* ADHERENT INFOS */}
                  <Box>
                    <AdherentInfo fontWeight={600}>
                      {participant?.pharmacy.name}{" "}
                      {!!participant?.pharmacy.uppId && (
                        <AdherentUppInfo>
                          <MapPin
                            color={theme.palette.common.electricBlue}
                            size={14}
                          />{" "}
                          {participant?.pharmacy.uppName}
                        </AdherentUppInfo>
                      )}
                    </AdherentInfo>
                    <AdherentInfo>
                      {`${participant.pharmacy.primaryHolder?.firstName} ${participant.pharmacy.primaryHolder?.lastName}`}
                    </AdherentInfo>
                    <AdherentSmallInfo mt={1}>
                      {`ID : ${participant.pharmacy.centralBusinessId} - CIP : ${participant.pharmacy.cipCode}`}
                    </AdherentSmallInfo>
                    <Box display="flex" gap={1} mt={2}>
                      {!!participant?.pharmacy.ultra && <ListUltraChip />}
                      {!!participant?.pharmacy.negativeSegmentation && (
                        <ListNegativeSegmentationChip />
                      )}
                      {!!participant.pharmacy.entryDate && (
                        <Chip
                          sx={{
                            border: "1px solid rgb(193, 223, 255)",
                            color: "#0c233d",
                          }}
                          icon={<Check color={theme.palette.success.main} />}
                          label={`UPP depuis le ${formatDateWithSlashes(participant.pharmacy.entryDate)}`}
                          variant="outlined"
                        />
                      )}
                    </Box>
                    <Box display="flex" gap={1} mt={2}>
                      <AdherentInfo>
                        Secteur CDO : {participant.pharmacy.sectorName}
                      </AdherentInfo>
                    </Box>
                  </Box>
                  {/* STATUS */}
                  <Stack justifyContent="space-between">
                    <Box>
                      <RegisterInfo fontWeight={500}>
                        Date inscription :{" "}
                      </RegisterInfo>
                      <RegisterInfo>
                        {new Date(participant.subscribeDate).toLocaleDateString()}
                      </RegisterInfo>
                    </Box>
                    <OperationStatus status={participant.validationStatus} />
                  </Stack>
                </Stack>

                {/* HISTORY */}
                <Box display="flex" gap={2}>
                  <DocumentCount>
                    {participant.proofDocuments?.length || "Aucun"}{" "}
                    {(participant.proofDocuments?.length || 0) > 1
                      ? "visuels"
                      : "visuel"}
                  </DocumentCount>

                  {!!participant.validationStatusHistory.length && (
                    <Stack gap={1}>
                      {participant.validationStatusHistory.map((status, index) => (
                        <Stack key={index}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            divider={<>&nbsp;-&nbsp;</>}
                          >
                            <Stack direction="row" alignItems="center">
                              <ValidationStatusDisplay status={status.status} />
                              {!!status.reviewer && (
                                <HistoryLine>
                                  &nbsp;par&nbsp;
                                  <span
                                    style={{ textDecoration: "underline" }}
                                  >{`${status.reviewer.firstName} ${status.reviewer.lastName}`}</span>
                                </HistoryLine>
                              )}
                            </Stack>
                            <HistoryLine>
                              Le {new Date(status.date).toLocaleDateString()}
                            </HistoryLine>
                          </Stack>
                          {!!status.justificationReason && (
                            <JustificationReason>
                              {status.justificationReason}
                            </JustificationReason>
                          )}
                        </Stack>
                      ))}
                    </Stack>
                  )}
                </Box>

                {/* DOCUMENTS */}
                <FormProvider {...formMethods}>
                  {!!participant.proofDocuments?.length && (
                    <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={5}>
                      {fields.map((field, index) => {
                        const document = participant.proofDocuments?.[index]!
                        return (
                          <Document
                            key={field.id}
                            onRefuse={() => setCurrRejectedDocIndex(index)}
                            onValidate={() =>
                              update(index, {
                                ...field,
                                validationStatus: "VALIDATED",
                              })
                            }
                            src={getPublicFileUrl(document.document)}
                            operationStatus={participant.validationStatus}
                            validationStatus={field.validationStatus}
                            rejectionReason={field.rejectionReason}
                            onPictureClick={() =>
                              setFullScreenPictureSrc(
                                getPublicFileUrl(document.document),
                              )
                            }
                            errorMessage={
                              field.validationStatus
                                ? undefined
                                : proofDocumentErrors?.[index]?.validationStatus
                                    ?.message
                            }
                          />
                        )
                      })}
                    </Box>
                  )}
                </FormProvider>
              </Stack>
            </Box>
          </form>
        )}
      </PaperContent>

      {/* MODALS */}

      {/* REJECTION MESSAGE */}
      <RejectionMessageModal
        open={currRejectedDocIndex !== null}
        proofDocument={
          currRejectedDocIndex != null
            ? participant?.proofDocuments?.[currRejectedDocIndex]?.document
            : undefined
        }
        onClose={() => setCurrRejectedDocIndex(null)}
        onConfirm={(rejectionMessage) => {
          if (currRejectedDocIndex !== null) {
            update(currRejectedDocIndex, {
              ...fields[currRejectedDocIndex],
              validationStatus: "REFUSED",
              rejectionReason: rejectionMessage,
            })
          }
        }}
      />

      {/* LIGHTBOX */}
      <FullScreenPictureModal
        open={!!fullScreenPictureSrc}
        onClose={() => {
          setFullScreenPictureSrc(null)
        }}
        imageSrc={fullScreenPictureSrc!}
      />

      {/* DIRTY EXIT */}
      <DirtyExitDialog
        when={!isSubmitting && isDirty}
        allowSameLevelRouting={false}
      />
    </>
  )
}

const TopCTA = ({
  status,
  onSubmit,
  loading,
}: {
  status: ValidationStatus
  onSubmit: () => void
  loading: boolean
}) => {
  const { palette } = useTheme()
  switch (status) {
    case "WAITING":
    case "WAITING_FOR_MANUAL_ACTION":
    case "VALIDATED_AUTOMATICALLY":
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <LoadingButton variant="contained" onClick={onSubmit} loading={loading}>
            Enregistrer
          </LoadingButton>
        </Stack>
      )
    case "REFUSED":
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <TopChip
            startIcon={<X color={palette.error.main} />}
            sx={{ borderColor: palette.error.main, color: palette.error.main }}
          >
            Refusé
          </TopChip>
        </Stack>
      )
    case "VALIDATED_MANUALLY":
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <TopChip startIcon={<Check />}>Validée</TopChip>
        </Stack>
      )
    default:
      return null
  }
}

const AdherentInfo = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  lineHeight: theme.typography.pxToRem(26),
  color: theme.palette.common.darkBlue,
}))
const AdherentSmallInfo = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  color: theme.palette.grey.light,
}))
const AdherentUppInfo = styled("span")(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  color: theme.palette.common.darkBlue,
  marginLeft: theme.spacing(1),
  fontWeight: 600,
}))
const RegisterInfo = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  color: theme.palette.common.darkBlue,
}))
const DocumentCount = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  fontWeight: 600,
  color: theme.palette.common.darkBlue,
}))
const HistoryLine = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  color: theme.palette.common.darkBlue,
}))
const JustificationReason = styled(Typography)(({ theme }) => ({
  marginLeft: "32px",
  fontSize: theme.typography.pxToRem(12),
  color: theme.palette.primary.main,
  fontStyle: "italic",
}))
