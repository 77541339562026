import { Box, CircularProgress } from "@mui/material"
import {
  ChallengeTrackingRow,
  GoalTrackingContainer,
} from "@pharmupp/p3-front-commons"
import { getStatusProp } from "../commons/trackingHelpers"
import { ApiLoyaltyCategoryEnum } from "../types"
import { useLoyaltyCategoryListApi } from "./useLoyaltyCategoryListApi"

export interface LoyaltyCategoryListProps {
  categoryLink: (category: ApiLoyaltyCategoryEnum) => string
  year: number
}
export const LoyaltyCategoryList = ({
  categoryLink,
  year,
}: LoyaltyCategoryListProps) => {
  const { challengeCategories, loading } = useLoyaltyCategoryListApi({ year })

  return (
    <Box width="100%" minHeight="300px">
      {loading || !challengeCategories ? (
        <CircularProgress sx={{ display: "block", m: "150px auto" }} />
      ) : (
        <GoalTrackingContainer>
          {challengeCategories.map((category) => (
            <ChallengeTrackingRow
              key={category.name}
              link={categoryLink(category.categoryEnum)}
              title={category.name}
              status={getStatusProp({
                quantity: category.unlockedChallenge,
                category: category.categoryEnum,
              })}
              currentValue={category.earnPoint}
              lastYearValue={category.lastYearEarnedPoints}
              goal={category.expectedPoint}
            />
          ))}
        </GoalTrackingContainer>
      )}
    </Box>
  )
}
