import { api, useQuery } from "@pharmupp/p3-front-commons"

export const useStocksSummaryApi = ({ labId }: { labId: string }) => {
  const endpoint = `/api/laboratories/adherent/${labId}/stocks/summary`
  const queryKey = ["laboratories", labId, "adherent", "stocks", "summary"]

  // GET
  const { data: stocksSummary, isLoading } = useQuery({
    queryKey,
    queryFn: () => api.get<ApiStocksSummary>(endpoint),
  })

  return { stocksSummary, isLoading }
}

export type ApiStocksSummary = {
  amount: number
}
