export class UserTypeEnum {
  static readonly HOLDER = new UserTypeEnum("HOLDER", "Titulaire Pharma")
  static readonly TEAM = new UserTypeEnum("TEAM", "Equipe Pharma")
  static readonly ADVISOR = new UserTypeEnum("ADVISOR", "CDO")
  static readonly EXTERN = new UserTypeEnum("EXTERN", "Intervenant Ext")
  static readonly ADMINISTRATOR = new UserTypeEnum(
    "ADMINISTRATOR",
    "Administrateur Pharm-UPP",
  )

  private constructor(
    public readonly code: string,
    public readonly label: string,
  ) {}

  static values(): UserTypeEnum[] {
    return [
      { ...UserTypeEnum.HOLDER },
      { ...UserTypeEnum.TEAM },
      { ...UserTypeEnum.ADVISOR },
      { ...UserTypeEnum.EXTERN },
      { ...UserTypeEnum.ADMINISTRATOR },
    ]
  }

  static getFromCode(code?: string): UserTypeEnum | undefined {
    return UserTypeEnum.values().find((enumValue) => enumValue.code === code)
  }
}
