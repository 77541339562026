export const GiftCardIcon = () => (
  <svg
    width="54px"
    height="30px"
    viewBox="0 0 54 30"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Tableau-de-bord"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Mon-Officine-Dashboard-V2---Desktop-Copy"
        transform="translate(-875.000000, -4193.000000)"
        fillRule="nonzero"
      >
        <g id="Group-5" transform="translate(80.000000, 3202.000000)">
          <g id="Programme-fidélité" transform="translate(0.000000, 681.000000)">
            <g
              id="Panier-Moyen-Hors-ordonnance"
              transform="translate(773.000000, 287.000000)"
            >
              <g id="Group-6" transform="translate(22.000000, 18.000000)">
                <g
                  id="noun-voucher-2344934"
                  transform="translate(0.000000, 5.000000)"
                >
                  <path
                    d="M49.5,0 L4.5,0 C2.01595216,0.00291716147 0.0029755047,1.97642369 0,4.41176471 L0,25.5882353 C0.0029755047,28.0235763 2.01595216,29.9970828 4.5,30 L49.5,30 C51.9840478,29.9970828 53.9970245,28.0235763 54,25.5882353 L54,4.41176471 C53.9970245,1.97642369 51.9840478,0.00291716147 49.5,0 Z M4.5,28.2352941 C3.00883118,28.2352941 1.8,27.0501655 1.8,25.5882353 L1.8,18.8947059 L11.3274,28.2352941 L4.5,28.2352941 Z M13.8726,28.2352941 L1.8,16.3994118 L1.8,13.6005882 L16.7274,28.2352941 L13.8726,28.2352941 Z M52.2,25.5882353 C52.2,27.0501655 50.9911688,28.2352941 49.5,28.2352941 L19.2726,28.2352941 L1.8,11.1052941 L1.8,4.41176471 C1.8,2.94983449 3.00883118,1.76470588 4.5,1.76470588 L34.7274,1.76470588 L52.2,18.8947059 L52.2,25.5882353 Z M52.2,16.3994118 L37.2726,1.76470588 L40.1274,1.76470588 L52.2,13.6005882 L52.2,16.3994118 Z M52.2,11.1052941 L42.6726,1.76470588 L49.5,1.76470588 C50.9911688,1.76470588 52.2,2.94983449 52.2,4.41176471 L52.2,11.1052941 Z"
                    id="Shape"
                    fill="#6679AE"
                  />
                  <path
                    d="M32.4,5.29411765 C32.4000552,5.02912703 32.2786298,4.77814258 32.0692811,4.61052685 C31.8599325,4.44291112 31.5848295,4.37641363 31.32,4.42941176 L27,5.27647059 L22.68,4.42941176 C22.4151705,4.37641363 22.1400675,4.44291112 21.9307189,4.61052685 C21.7213702,4.77814258 21.5999448,5.02912703 21.6,5.29411765 L21.6,7.94117647 L18.9,7.94117647 C18.4029437,7.94117647 18,8.33621934 18,8.82352941 L18,22.9411765 C18,23.4284865 18.4029437,23.8235294 18.9,23.8235294 L35.1,23.8235294 C35.5970563,23.8235294 36,23.4284865 36,22.9411765 L36,8.82352941 C36,8.33621934 35.5970563,7.94117647 35.1,7.94117647 L32.4,7.94117647 L32.4,5.29411765 Z M24.3,22.0588235 L19.8,22.0588235 L19.8,18.5294118 L24.3,18.5294118 L24.3,22.0588235 Z M24.3,16.7647059 L19.8,16.7647059 L19.8,15 L24.3,15 L24.3,16.7647059 Z M24.3,13.2352941 L19.8,13.2352941 L19.8,9.70588235 L24.3,9.70588235 L24.3,13.2352941 Z M23.4,7.94117647 L23.4,6.37058824 L26.1,6.9 L26.1,7.94117647 L23.4,7.94117647 Z M27.9,14.1176471 L27.9,22.0588235 L26.1,22.0588235 L26.1,9.70588235 L27.9,9.70588235 L27.9,14.1176471 Z M27.9,7.94117647 L27.9,6.89911765 L30.6,6.36970588 L30.6,7.94117647 L27.9,7.94117647 Z M34.2,22.0588235 L29.7,22.0588235 L29.7,18.5294118 L34.2,18.5294118 L34.2,22.0588235 Z M34.2,16.7647059 L29.7,16.7647059 L29.7,15 L34.2,15 L34.2,16.7647059 Z M34.2,9.70588235 L34.2,13.2352941 L29.7,13.2352941 L29.7,9.70588235 L34.2,9.70588235 Z"
                    id="Shape"
                    fill="#951B81"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
