import { ROLES } from "@pharmupp/p3-front-commons"

export const apiRoleConf = {
  [ROLES.ADMIN]: {
    resourceUrl: "/api/operation/bonus",
    referentialUrl: "/api/operation/bonus/referential/list",
  },
  [ROLES.UPP_PRESIDENT]: {
    resourceUrl: "/api/operation/bonus/upp",
    referentialUrl: "/api/operation/bonus/upp/referential/list",
  },
}
