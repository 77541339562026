export const Money = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="31" height="18">
    <g id="Fiche-labo" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g
        id="Catalogues---Détail-laboratoire-Copy-7"
        fill="#FFF"
        fillRule="nonzero"
        transform="translate(-713 -307)"
      >
        <g id="Group-26-Copy" transform="translate(705 293)">
          <g id="Group" transform="translate(8 14)">
            <path
              id="Path"
              d="M17.905 9.106a2.336 2.336 0 0 0 2.342-2.33 2.336 2.336 0 0 0-2.342-2.33 2.336 2.336 0 0 0-2.342 2.33 2.336 2.336 0 0 0 2.342 2.33ZM25.517 7.668a.893.893 0 1 0 0-1.785c-.495 0-.898.4-.898.893s.403.892.898.892ZM10.293 7.668a.893.893 0 1 0 0-1.785c-.496 0-.898.4-.898.893s.402.892.898.892Z"
            />
            <path
              id="Shape"
              d="M1.491 18h23.207c.824 0 1.492-.664 1.492-1.483v-2.961h3.319c.823 0 1.491-.665 1.491-1.483V1.483C31 .665 30.332 0 29.509 0H6.302C5.478 0 4.81.664 4.81 1.483v2.961H1.491C.668 4.444 0 5.11 0 5.927v10.59C0 17.335.668 18 1.491 18ZM6.724 3.679a1.67 1.67 0 0 0 1.678-1.67h19.006c0 .925.748 1.67 1.673 1.67v6.193c-.925 0-1.673.75-1.673 1.669H8.402c0-.92-.748-1.67-1.678-1.67V3.68Zm-4.81 4.45c.93 0 1.683-.75 1.683-1.67h1.214v5.614c0 .818.668 1.483 1.49 1.483h17.975v.765c-.924 0-1.678.744-1.678 1.67h-19c0-.926-.754-1.67-1.684-1.67V8.128Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
