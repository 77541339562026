import { Grid, Stack } from "@mui/material"
import { ExternalLinkBox } from "@pharmupp/p3-front-commons"
import { MyAgenda } from "@pharmupp/p3-news-domain"
import { Clipboard, Shield } from "react-feather"
import { NotificationsWidget } from "../Notifications"
import { BarChartIcon } from "./widgets/icons/BarChartIcon"

export const SideBlock = ({
  adherentId,
  isAdherent,
}: {
  adherentId: string | undefined
  isAdherent: boolean
}) => {
  return (
    <Grid item xs={4}>
      <Stack spacing={3}>
        <MyAgenda
          viewOneLink="../../actualites"
          viewAllLink="../../evenement-a-venir"
          adherentId={adherentId}
        />
        <NotificationsWidget adherentId={adherentId} />
        {!!isAdherent && (
          <>
            <ExternalLinkBox
              leftIcon={Clipboard}
              linkUrl="https://app.digipharmacie.fr/account/keycloak/login/?process=login"
              title="Mes Factures"
              topLabel="Raccourci vers"
            />
            <ExternalLinkBox
              leftIcon={Shield}
              linkUrl="https://app.digipharmacie.fr/account/keycloak/login/?process=login&next=/documents"
              title="Mon coffre-fort"
              topLabel="Raccourci vers"
            />
            <ExternalLinkBox
              leftIcon={BarChartIcon}
              linkUrl="https://upp.ospharm.org/"
              title="Ospharm"
              topLabel="Raccourci vers"
            />
          </>
        )}
      </Stack>
    </Grid>
  )
}
