import { api, useQuery, useRoleConf } from "@pharmupp/p3-front-commons"
import { useParams } from "react-router-dom"
import UserForm from "../UserForm"
import { ApiUserModel } from "../UserForm/models/apiUser.model"
import { UserTypeEnum } from "../UsersList/models/UserType.enum"
import { apiRoleConf } from "../apiRoleConf"
import { serverToForm } from "./userForm.adapter"

export const getUpdateUserQueryKey = (id?: string) => ["users", "form", id]

export const UpdateMyUser = () =>
  UpdateUser({ isReadOnly: (type) => type === UserTypeEnum.HOLDER.code })

export const UpdateUser = ({
  isReadOnly,
}: {
  isReadOnly?: (type?: string) => boolean
}) => {
  const { id } = useParams()

  const { resourceEndpoint } = useRoleConf(apiRoleConf)

  const { data: user, isLoading: loading } = useQuery({
    queryKey: getUpdateUserQueryKey(id),
    queryFn: () => api.get<ApiUserModel>(`${resourceEndpoint}/${id}`),
    select: serverToForm,
  })

  return (
    <UserForm
      defaultValues={{
        ...user,
        isUpdate: true,
        isReadOnly: isReadOnly?.(user?.type),
      }}
      loading={loading}
    />
  )
}
