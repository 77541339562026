import { api, useQuery } from "@pharmupp/p3-front-commons"

export const useMyAdherentSummary = () =>
  useQuery({
    queryKey: ["adherents", "my-adherent", "summary"],
    queryFn: () =>
      api.get<MyAdherentSummaryApi>("/api/adherents/my-adherent/summary"),
  })

export interface MyAdherentSummaryApi {
  address: {
    line1: string
    line2: string
    postalCode: string
    city: string
  }
  centralBusinessId: string
  cerpCode: string
  chayallId: null
  cipCode: string
  finessCode: null
  id: number
  name: string
}
