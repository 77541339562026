import { SVGProps } from "react"

export const BarChartIcon = ({
  width = "34px",
  height = "34px",
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 35 31"
  >
    <defs>
      <path
        id="a"
        d="M15.417 27.75H18.5V3.083h-3.083V27.75Zm4.625 3.083h-6.167a1.541 1.541 0 0 1-1.542-1.541V1.542C12.333.69 13.024 0 13.875 0h6.167c.852 0 1.541.69 1.541 1.542v27.75a1.54 1.54 0 0 1-1.541 1.541Zm7.708-3.083h3.083V10.792H27.75V27.75Zm4.625 3.083h-6.167a1.54 1.54 0 0 1-1.541-1.541V9.25c0-.851.689-1.542 1.541-1.542h6.167c.853 0 1.542.691 1.542 1.542v20.042a1.54 1.54 0 0 1-1.542 1.541ZM3.083 27.75h3.084V18.5H3.083v9.25Zm4.625 3.083H1.542A1.54 1.54 0 0 1 0 29.292V16.958c0-.85.69-1.541 1.542-1.541h6.166c.851 0 1.542.69 1.542 1.541v12.334a1.54 1.54 0 0 1-1.542 1.541Z"
      />
    </defs>
    <use fill="#466CAE" href="#a" transform="translate(.542 .083)" />
  </svg>
)
