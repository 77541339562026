import { Yup, type YupTypes } from "@pharmupp/p3-front-commons"

export const assignmentSchema = Yup.object({
  uppId: Yup.number().required("L'UPP de rattachement est requis"),
  sectorId: Yup.number(),
  entryDate: Yup.date().required("La date d'ancienneté est requise"),
  cerpCode: Yup.string()
    .required("Le code CERP est requis")
    .matches(/^\d{5}/g, "Le code CERP est invalide"),
  ultra: Yup.boolean().required(),
  negativeSegmentation: Yup.boolean().required(),
  status: Yup.string().required("Le status de la pharmacie est requis"),
})

export type AssignmentFormValues = YupTypes.InferType<typeof assignmentSchema>
