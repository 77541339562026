import { Role, useRoleConf } from "@pharmupp/p3-front-commons"
import { RoleConfShape } from "@pharmupp/p3-front-commons/src/components/context/FormRoleConfContext"

type ConfShape = Partial<Record<Role, RoleConfShape | Record<string, RoleConfShape>>>

export const useUserRoleConf = ({
  roleConf,
  isUpdate,
  readOnlyConf,
  isReadOnly,
}: {
  roleConf: ConfShape
  isUpdate?: boolean
  readOnlyConf?: RoleConfShape
  isReadOnly?: boolean
}) => {
  let conf: RoleConfShape = useRoleConf(roleConf)

  if (isReadOnly && readOnlyConf) {
    conf = readOnlyConf
  } else if (isUpdate && conf.update) {
    conf = conf.update as RoleConfShape
  } else if (!isUpdate && conf.create) {
    conf = conf.create as RoleConfShape
  }

  return conf
}
