import { Yup, YupTypes, inputFileSchema } from "@pharmupp/p3-front-commons"
import { LabOrExternChoice } from "../../../../../core"
import { EventRubric } from "../../../../model"

export const schema = Yup.object({
  publishLater: Yup.boolean(),
  publicationDate: Yup.date()
    .when("$isCreate", ([isCreate], schemaRef) =>
      isCreate
        ? schemaRef.min(new Date(), "La date de début ne peut être dans le passé")
        : schemaRef,
    )
    .when("publishLater", ([publishNow]: boolean[], schemaRef) =>
      publishNow === true ? schemaRef.required() : schemaRef,
    ),
  cancellationReason: Yup.string(),
  rubric: Yup.string<EventRubric>().required(),
  labOrExtern: Yup.string<LabOrExternChoice>().when(
    "rubric",
    ([rubric], schemaRef) =>
      rubric === EventRubric.LAB_EXTERN ? schemaRef.required() : schemaRef,
  ),
  laboratoryId: Yup.number().when(
    ["rubric", "labOrExtern"],
    ([rubric, labOrExtern], schemaRef) =>
      rubric === EventRubric.LAB_EXTERN && labOrExtern === "laboratory"
        ? schemaRef.required()
        : schemaRef,
  ),
  solutionId: Yup.number().when(
    ["rubric", "labOrExtern" as const],
    ([rubric, labOrExtern], schemaRef) =>
      rubric === EventRubric.LAB_EXTERN && labOrExtern === "solution"
        ? schemaRef.required()
        : schemaRef,
  ),
  title: Yup.string().max(100).required(),
  image: inputFileSchema.default(undefined).notRequired(),
  description: Yup.string().required(),
  restrictedUppIds: Yup.array().of(Yup.number().required()),
})

export type GeneralInfosValues = YupTypes.InferType<typeof schema>
