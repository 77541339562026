import { useCallback, useEffect, useRef, useState } from "react"
import { MINI_HEADER_ID } from "../../components"
import { useThrottledOnScroll } from "./useThrottledOnScroll"

export interface UseScrollSpyArgs {
  items: ScrollItem[]
  /** in px */
  itemsSpacing?: number
  /** in px */
  detectionMargin?: number
}

export function useScrollSpy({
  items,
  itemsSpacing = 0,
  detectionMargin = 0,
}: UseScrollSpyArgs) {
  const scrollDirection = useScrollDirection()
  const itemsWithNodeRef = useRef<ScrollNodeItem[]>([])
  useEffect(() => {
    itemsWithNodeRef.current = getItemsClient(items)
  }, [items])

  const [activeState, setActiveState] = useState<string | null>(null)

  const findActiveIndex = useCallback(() => {
    const pageHeight = document.documentElement.offsetHeight
    const pageScrollTop = document.documentElement.scrollTop
    const viewportHeight = document.documentElement.clientHeight

    const active = itemsWithNodeRef.current.find((item, index) => {
      if (!item.node) {
        if (!import.meta.env.PROD) {
          /** Beware of rerenders removing the node, so set id on a non rerendered element */
          console.error(`Cannot find item ${JSON.stringify(item, null, 2)}`)
        }
        return
      }

      const isTopReached = pageScrollTop < EDGE_DETECTION_THRESHOLD
      const isFirstItem = index === 0
      if (isTopReached && isFirstItem) {
        return item
      }

      const isBottomReached =
        pageHeight - pageScrollTop - viewportHeight < EDGE_DETECTION_THRESHOLD
      const isLastItem = index === itemsWithNodeRef.current.length - 1
      if (isBottomReached && isLastItem) {
        return item
      }

      const itemRect = item.node.getBoundingClientRect()
      const itemScrollTop =
        scrollDirection === "down" ? itemRect.top : itemRect.bottom
      const marginDirection = scrollDirection === "down" ? 1 : -1
      const margin = marginDirection * (detectionMargin + itemsSpacing)
      if (itemScrollTop + margin >= 0) {
        return item
      }
    })

    if (active && activeState !== active.id) {
      setActiveState(active.id)
    }
  }, [activeState, detectionMargin, itemsSpacing, scrollDirection])

  useThrottledOnScroll(items.length > 0 ? findActiveIndex : null, 166)

  return activeState
}

export interface ScrollItem {
  id: string
  label: string
}

interface ScrollNodeItem {
  id: string
  node: HTMLElement | null
}

const getItemsClient = (items: ScrollItem[]): ScrollNodeItem[] =>
  items.map(({ id }) => ({ id, node: document.getElementById(id) }))

const EDGE_DETECTION_THRESHOLD = 200

const useScrollDirection = () => {
  const prevScrollY = useRef(0)
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>("down")
  useEffect(() => {
    function handleScroll() {
      setScrollDirection(window.scrollY - prevScrollY.current >= 0 ? "down" : "up")
      prevScrollY.current = window.scrollY
    }
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return scrollDirection
}
type ScrollDirection = "down" | "up"

export const scrollToId = (id: string) => {
  const target = document.getElementById(id)
  if (target) {
    const headerHeight = document.getElementById(MINI_HEADER_ID)?.offsetHeight || 0

    window.scrollTo({
      top: target.getBoundingClientRect().top + window.scrollY - headerHeight,
      behavior: "smooth",
    })
  }
}
