import { Box, Divider, Stack, Typography } from "@mui/material"
import { LoadingButton, isDefined } from "@pharmupp/p3-front-commons"
import { useParams } from "react-router-dom"
import { LocationType } from "../../../Events/model"
import { FormattedNews } from "../useNewsDisplayApi"
import {
  EventCancelled,
  EventDate,
  EventIrl,
  EventRemote,
  RemainingSubscription,
} from "./Details"
import { Subscription } from "./Subscription"
import { useSubscriptionApi } from "./useSubscriptionApi"

export const EventSection = ({
  remainingSubscriptions,
  startDate,
  endDate,
  isRegistered,
  canSubscribe,
  canUnsubscribe,
  registrationDeadline,
  cancelled,
  cancellationReason,
  ...props
}: FormattedNews) => {
  const { id } = useParams()
  const { unsubscribe, unsubscribeLoading } = useSubscriptionApi({ id })

  return (
    <Box
      p={5}
      my={5}
      bgcolor="grey.extraLight"
      display="flex"
      flexDirection="column"
      gap={3}
    >
      <Typography variant="h5" color="common.darkBlue">
        Détails de l’évènement
        {!!cancelled && (
          <>
            {" "}
            :{" "}
            <Typography color="common.red" component="span" variant="h5">
              Annulé
            </Typography>
          </>
        )}
      </Typography>

      <Stack direction="row" justifyContent="space-between" mt={3}>
        <Stack spacing={3} divider={<Divider />}>
          <Stack direction="row" spacing={3}>
            <EventDate
              cancelled={cancelled}
              startDate={startDate}
              endDate={endDate}
            />

            {isDefined(remainingSubscriptions) && !cancelled && (
              <RemainingSubscription count={remainingSubscriptions} />
            )}
          </Stack>

          {cancelled ? (
            !!cancellationReason && (
              <EventCancelled cancellationReason={cancellationReason} />
            )
          ) : props.locationType === LocationType.REMOTE ? (
            <EventRemote meetUrl={props.meetUrl} isRegistered={isRegistered} />
          ) : (
            <EventIrl {...props.address} />
          )}
        </Stack>
        {!cancelled && (
          <Subscription
            isRegistered={isRegistered}
            canSubscribe={canSubscribe}
            registrationDeadline={registrationDeadline}
            remainingSubscriptions={remainingSubscriptions ?? 1}
          />
        )}
      </Stack>

      {!!canUnsubscribe && (
        <>
          <Divider />

          <Box display="flex" justifyContent="center">
            <LoadingButton
              variant="outlined"
              onClick={() => unsubscribe()}
              loading={unsubscribeLoading}
            >
              Se désinscrire
            </LoadingButton>
          </Box>
        </>
      )}
    </Box>
  )
}
