import { Typography, useTheme } from "@mui/material"
import { Alert, BellWithCircleIcon } from "@pharmupp/p3-front-commons"
import { useMemo } from "react"

const lastYearMessage = (
  <>
    <Typography fontWeight="bold" display="inline">
      Clôture de fin d’année :
    </Typography>{" "}
    les données sont en cours de consolidation. Mise à jour très prochainement !
  </>
)
const nextYearMessage = (
  <>
    <Typography fontWeight="bold" display="inline">
      Nouvelle année :
    </Typography>{" "}
    les données sont progressivement mises à jour d’ici fin février 2024, un peu de
    patience...
  </>
)
const currentDate = new Date()
const currentYear = currentDate.getFullYear()
const currentMonth = currentDate.getMonth() // careful, it's 0-based

export const LoyaltyCategoryAlert = ({ year }: { year: number }) => {
  const { typography } = useTheme()

  const message = useMemo(() => {
    if (currentMonth === 0) {
      // january
      if (year === currentYear - 1) {
        return lastYearMessage
      }
      if (year === currentYear) {
        return nextYearMessage
      }
    } else if (currentMonth === 11) {
      // december
      if (year === currentYear) {
        return lastYearMessage
      }
      if (year === currentYear + 1) {
        return nextYearMessage
      }
    }

    return undefined
  }, [year])

  return (
    <>
      {!!message && (
        <Alert
          severity="warning"
          icon={
            <BellWithCircleIcon
              width={typography.pxToRem(35)}
              height={typography.pxToRem(35)}
            />
          }
          variant="standard"
          sx={{
            mt: 3,
            color: "#000",
            "&.MuiPaper-root": {
              backgroundColor: "#fce2d0",
            },
            "& .MuiAlert-message, & .MuiAlert-icon": {
              display: "flex",
              alignItems: "center",
            },
          }}
        >
          <Typography fontSize={typography.pxToRem(16)}>{message}</Typography>
        </Alert>
      )}
    </>
  )
}
