import { api, useQuery } from "@pharmupp/p3-front-commons"

export const useLoyaltySummaryApi = ({ year }: { year: number }) => {
  const { data: summary, isLoading } = useQuery({
    queryKey: ["loyalty", "my-adherent", "summary", year],
    queryFn: () =>
      api.get<ApiLoyaltySummary>(`/api/loyalties/my-adherent/summary?year=${year}`),
  })

  return { summary, isLoading }
}

interface ApiLoyaltySummary {
  earnedPoint: number
  expectedPoint: number
  lastYearEarnedPoints: number
  ratio: number
}
