import { yupResolver } from "@hookform/resolvers/yup"
import { Stack, Typography } from "@mui/material"
import {
  FormNumberField,
  Modal,
  ModalBasicActions,
  ModalContent,
  ModalTitle,
  Price,
  Yup,
} from "@pharmupp/p3-front-commons"
import { FormProvider, useForm } from "react-hook-form"
import { ApiBonusOperationDisplay } from "../useBonusOperationDisplayApi"
import { useOperationActivationApi } from "./useOperationActivationApi"

interface ActivateOperationModalProps {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  operation?: Pick<
    ApiBonusOperationDisplay,
    | "bonusOperationId"
    | "title"
    | "minBonus"
    | "maxBonus"
    | "validityStartDate"
    | "validityEndDate"
  >
}
export function ActivateOperationModal({
  open,
  onClose,
  onSuccess,
  operation,
}: ActivateOperationModalProps) {
  const { activate } = useOperationActivationApi()

  const schema = Yup.object({
    bonus: Yup.number()
      .typeError("La prime doit être de type nombre")
      .required("La prime est un champ obligatoire")
      .positive()
      .min(
        operation?.minBonus || 0,
        `La prime doit être supérieure ou égal à ${operation?.minBonus}`,
      )
      .max(
        operation?.maxBonus || 999999999,
        `La prime doit être inférieure ou égale à ${operation?.maxBonus}`,
      ),
  })

  const formMethods = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onBlur",
  })

  if (!operation) return null

  const validateAction = formMethods.handleSubmit(async (data) => {
    try {
      await activate({ id: operation.bonusOperationId, bonus: data.bonus })
      onSuccess()
    } finally {
      onClose()
    }
  })

  // TODO: delete when this modal won't be used from the list anymore
  const validityStartDate =
    typeof operation.validityStartDate !== "string"
      ? new Date(operation.validityStartDate).toLocaleDateString("fr-FR")
      : operation.validityStartDate
  const validityEndDate =
    typeof operation.validityEndDate !== "string"
      ? new Date(operation.validityEndDate).toLocaleDateString("fr-FR")
      : operation.validityEndDate

  return (
    <Modal open={open} onClose={onClose}>
      <ModalTitle>{operation.title}</ModalTitle>

      <ModalContent>
        <FormProvider {...formMethods}>
          <Stack spacing={2} sx={{ minWidth: "350px" }}>
            <Typography align="center">{`Du ${validityStartDate} au ${validityEndDate}`}</Typography>
            <Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography color="grey.light">Prime minimum</Typography>
                <Price value={operation.minBonus} />
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography color="grey.light">Prime maximum</Typography>
                <Price value={operation.maxBonus} />
              </Stack>
            </Stack>
            <FormNumberField
              name="bonus"
              label="Prime accordée par pharmacie (HT)"
              fullWidth
              required
            />
          </Stack>
        </FormProvider>
      </ModalContent>

      {/* Actions */}
      <ModalBasicActions
        cancelAction={() => {
          formMethods.clearErrors()
          formMethods.reset()
          onClose()
        }}
        validateAction={validateAction}
      />
    </Modal>
  )
}
