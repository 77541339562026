import { Box, Typography } from "@mui/material"

export const NotFound = () => (
  <Box p={2}>
    {window.KONAMI_ACTIVATED === true ? (
      <Box display="flex" justifyContent="center" alignItems="center">
        {/* biome-ignore lint/a11y/useMediaCaption: it's just a Konami code */}
        <video controls autoPlay>
          <source src="/illustration/rSUB4pqUsQnXUSLG.mp4" type="video/mp4" />
        </video>
      </Box>
    ) : (
      <Typography>There&apos; s nothing here!</Typography>
    )}
  </Box>
)
