import { Yup, YupTypes } from "@pharmupp/p3-front-commons"

export const schema = Yup.object({
  documents: Yup.array()
    .of(Yup.mixed<File>())
    .required()
    .min(
      Yup.ref("$minFiles"),
      ({ min }) => `Minimum ${min} ${min > 1 ? "fichiers" : "fichier"} requis`,
    )
    .max(
      Yup.ref("$maxFiles"),
      ({ max }) => `Maximum ${max} ${max > 1 ? "fichiers" : "fichier"} requis`,
    ),
  justificationReason: Yup.string().max(255),
})

export type FormValues = YupTypes.InferType<typeof schema>
