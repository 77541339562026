import {
  Box,
  Button,
  Card,
  CardActionArea,
  Skeleton,
  Stack,
  Typography,
  alpha,
  cardActionAreaClasses,
  lighten,
  styled,
  touchRippleClasses,
  useTheme,
} from "@mui/material"
import { NumberFormat } from "@pharmupp/p3-front-commons"
import { ReactNode } from "react"
import { Link } from "react-router-dom"
import { ChevronDivider } from "../ChevronDivider"

type Variant = "neutral" | "info" | "warning" | "success"
export interface StatusCardProps {
  Icon: ReactNode
  title: string
  count?: number
  pointsCount?: number
  variant?: Variant
  bottomInfo?: ReactNode
  link: string
  divider?: boolean
  dividerAccent?: boolean
  isLoading?: boolean
  compact?: boolean
}
export const StatusCard = ({
  Icon,
  title,
  count,
  pointsCount,
  variant = "neutral",
  link,
  divider = true,
  dividerAccent = false,
  isLoading = false,
  compact = false,
}: StatusCardProps) => {
  const { typography } = useTheme()
  const { color, bgcolor } = useVariantStyle(variant)
  const selectedBorderColor = color
  const minHeight = compact ? COMPACT_MIN_HEIGHT : MIN_HEIGHT
  const hasDivider = !compact && divider

  return (
    <Box position="relative">
      {isLoading ? (
        <Skeleton variant="rectangular" height={minHeight} width="100%" />
      ) : (
        <>
          {/* CARD */}
          <Card
            sx={{
              position: "relative",
              overflow: "visible",
              textDecoration: "none",
              height: "100%",
              minHeight,
              ...(compact
                ? {
                    border: "1px solid rgb(225, 228, 232)",
                  }
                : {
                    border: "none",
                    bgcolor,
                    boxShadow: "0px 0px 15px 0px rgba(0,0,0, 0.1)",
                  }),
              "&:hover": {
                ".hoverBorder": {
                  display: "block",
                },
              },
            }}
          >
            <CardActionArea
              component={Link}
              role="button"
              to={link}
              sx={{
                height: "100%",
                borderRadius: "6px",
                [`& > .${cardActionAreaClasses.focusHighlight},.${touchRippleClasses.child}`]:
                  { bgcolor },
              }}
            >
              <Box p={compact ? 2 : 3} height="100%">
                <Box
                  display="flex"
                  flexDirection={compact ? "row" : "column"}
                  alignItems={compact ? "center" : "flex-start"}
                  gap={compact ? 2 : 1}
                  color="common.darkBlue"
                  height="100%"
                  sx={{ svg: { height: "40px", minWidth: "45px" } }}
                >
                  {Icon}
                  <Stack
                    direction={compact ? "column-reverse" : "column"}
                    alignItems="flex-start"
                    width={compact ? "100%" : "60%"}
                  >
                    <Typography
                      fontSize={typography.pxToRem(compact ? 14 : 29)}
                      fontWeight={compact ? 600 : 500}
                    >
                      {count || 0}
                    </Typography>
                    <Typography
                      fontSize={typography.pxToRem(compact ? 14 : 16)}
                      fontWeight={500}
                      sx={{ mb: "auto" }}
                    >
                      {title}
                    </Typography>

                    {!compact && !!pointsCount && (
                      <Button
                        variant="outlined"
                        color="success"
                        size="small"
                        sx={{ pointerEvents: "none" }}
                      >
                        <NumberFormat
                          value={pointsCount}
                          prefix="+ "
                          suffix=" pts"
                        />
                      </Button>
                    )}
                  </Stack>
                </Box>
              </Box>
            </CardActionArea>

            {/* HOVER BORDER */}
            {!compact && (
              <HoverBorder
                className="hoverBorder"
                borderColor={lighten(color, 0.6)}
              />
            )}

            {/* BOTTOM BORDER */}
            {!compact && <BottomBorder bgcolor={selectedBorderColor} />}
          </Card>
        </>
      )}

      {/* DIVIDER */}
      {!!hasDivider && <ChevronDivider accent={dividerAccent} />}
    </Box>
  )
}

const MIN_HEIGHT = "200px"
const COMPACT_MIN_HEIGHT = "70px"

const HoverBorder = styled(Box)({
  position: "absolute",
  top: "-2px",
  left: "-2px",
  bottom: "-2px",
  right: "-2px",
  borderRadius: "6px",
  display: "none",
  cursor: "pointer",
  borderWidth: "3px",
  borderStyle: "solid",
  pointerEvents: "none",
  "&:hover": { display: "block" },
})

// Avoid a border on the card to prevent height to be reduced
const BottomBorder = styled(Box)({
  width: "100%",
  height: "7px",
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  borderBottomLeftRadius: "6px",
  borderBottomRightRadius: "6px",
})

const useVariantStyle = (variant: Variant) => {
  const { palette } = useTheme()

  switch (variant) {
    case "neutral":
      return {
        color: palette.common.darkBlue,
        bgcolor: "#bad3f8",
      }
    case "info":
      return {
        color: "#176ae5",
        bgcolor: alpha("#176ae5", 0.15),
      }
    case "warning":
      return {
        color: "#f67848",
        bgcolor: alpha("#f67848", 0.25),
      }
    case "success":
      return {
        color: palette.success.main,
        bgcolor: alpha(palette.success.main, 0.25),
      }
  }
}
