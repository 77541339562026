import {
  ROLES,
  RoleStrategy,
  api,
  useQuery,
  useRoleConf,
} from "@pharmupp/p3-front-commons"
import { useParams } from "react-router-dom"
import { OspharmResponse } from "./kpi.models"

export const useMyPharmacyApi = () => {
  const { id: adherentId } = useParams()
  const getEndpoint = useRoleConf(apiRoleConf)
  const endpoint = getEndpoint(adherentId)

  // STATS
  const year = getYearToFetch(now)
  const { data: stats, isInitialLoading: statsLoading } = useQuery({
    queryKey: ["adherents", adherentId || "my-adherent", "dashboard"],
    queryFn: () => api.get<OspharmResponse>(`${endpoint}?year=${year}`),
  })

  if (!stats) return { loading: true }

  const ospharm = stats.ospharmKeyFigures

  return {
    activities: {
      attendance: ospharm?.attendance,
      turnover: {
        ...ospharm?.turnover,
        year: stats?.serviceTurnover?.year,
      },
      globalCart: ospharm?.globalCart,
      withoutPrescriptionCart: ospharm?.withoutPrescriptionCart,
      categories: ospharm?.categories,
      specificMedications: ospharm?.specificMedications,
    },
    healthMissions: ospharm?.healthMissions,
    adherentCentralBusinessId: stats?.centralBusinessId,
    serviceTurnover: stats?.serviceTurnover,
    objectives: stats?.objectiveCoalia,
    progressions: stats?.progressionCoalia,
    marketShares: stats?.marketShares,
    loyaltyProgramFigures: stats?.loyaltyProgramFigures,
    // Dates
    lastLoyaltyProgramSync: stats?.lastLoyaltyProgramSync || "",
    lastOspharmDashboardSync: stats?.lastOspharmDashboardSync || "",
    lastOspharmPDMSync: stats?.lastOspharmPDMSync || "",
    // Loading
    loading: statsLoading,
  }
}

export type DashboardData = ReturnType<typeof useMyPharmacyApi>

const apiRoleConf: RoleStrategy<(id?: string) => string> = {
  [ROLES.ADHERENT_HOLDER]: () => "/api/adherents/my-adherent/my-pharmacy",
  [ROLES.UPP_PRESIDENT]: (id) =>
    id
      ? `/api/adherents/president/${id}/dashboard`
      : "/api/adherents/my-adherent/my-pharmacy",
  [ROLES.ADHERENT_ADVISOR]: (id) => `/api/adherents/my-sector/${id}/dashboard`,
  [ROLES.ADMIN]: (id) => `/api/adherents/${id}/dashboard`,
}

const now = new Date()
const getYearToFetch = (currentDate: Date): number => {
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()

  //call the previous year if we are in january or february
  return currentMonth < 2 ? currentYear - 1 : currentYear
}
