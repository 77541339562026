import {
  Box,
  Slide,
  Stack,
  Theme,
  Toolbar,
  useMediaQuery,
  useScrollTrigger,
} from "@mui/material"
import { ReactNode, useRef } from "react"
import { Link } from "react-router-dom"
import { AppBar } from "../AppBar"

const TOOLBAR_HEIGHT = "99px"
const HEADER_NAV_HEIGHT = 40

export interface RenderPartConf {
  isFixed: boolean
  isCompact: boolean
}

interface AppHeaderProps {
  renderNav: (conf: RenderPartConf & { height: number }) => ReactNode
  renderActions: (conf: RenderPartConf) => ReactNode
}

export function AppHeader({ renderActions, renderNav }: AppHeaderProps) {
  const isCompact = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"))
  const appBarRef = useRef<HTMLDivElement>(null)
  const showMiniAppBar = useScrollTrigger({
    disableHysteresis: true,
    threshold: appBarRef.current?.offsetHeight,
  })

  return (
    <>
      {/* FULL (NOT FIXED/MINIFIED) */}
      <AppBar position="static" elevation={0} ref={appBarRef}>
        <Toolbar sx={{ height: TOOLBAR_HEIGHT, padding: "0!important" }}>
          <Stack
            direction="row"
            spacing={4}
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%" }}
          >
            {/* LOGO */}
            <Link to="/">
              <img
                alt="pharm_upp"
                src="/Mono.svg"
                width={TOOLBAR_HEIGHT}
                height={TOOLBAR_HEIGHT}
              />
            </Link>
            {/* ACTIONS */}
            {renderActions({ isFixed: false, isCompact })}
          </Stack>
        </Toolbar>

        {/* NAV */}
        {renderNav({ isFixed: false, isCompact, height: HEADER_NAV_HEIGHT })}
      </AppBar>

      {/* FIXED + MINIFIED */}
      <HeaderMini
        visible={showMiniAppBar}
        isCompact={isCompact}
        renderActions={renderActions}
        renderNav={renderNav}
      />
    </>
  )
}

const HEADER_MINI_HEIGHT = 64
export const MINI_HEADER_ID = "appHeader"
export const SUB_HEADER_PORTAL_ID = "subHeaderPortalAnchor"

const HeaderMini = ({
  visible,
  isCompact,
  renderActions,
  renderNav,
}: { visible: boolean; isCompact: boolean } & AppHeaderProps) => (
  <Slide in={visible}>
    <Box position="fixed" zIndex={10} width="100%" id={MINI_HEADER_ID}>
      <AppBar
        position="relative"
        elevation={0}
        sx={{ height: `${HEADER_MINI_HEIGHT}px` }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          height="100%"
        >
          <Stack direction="row" alignItems="center" spacing={4} height="100%">
            <Link to="/" style={{ display: "flex" }}>
              <img
                alt="pharm_upp"
                src="/cross-mono.svg"
                width="31px"
                height="31px"
              />
            </Link>
            {renderNav({ isFixed: true, isCompact, height: HEADER_MINI_HEIGHT })}
          </Stack>

          {renderActions({ isFixed: true, isCompact })}
        </Stack>
      </AppBar>

      <Box id={SUB_HEADER_PORTAL_ID} />
    </Box>
  </Slide>
)
