import {
  FormControlLabel,
  FormControlLabelProps,
  Switch,
  formControlLabelClasses,
  styled,
} from "@mui/material"
import { Controller, FieldPath, FieldValues } from "react-hook-form"
import { useFieldRoleConf } from "../../../context/FormRoleConfContext"

export type FormSwitchFieldProps<TFieldValues extends FieldValues = FieldValues> = {
  name: FieldPath<TFieldValues>
  label?: string
  labelPlacement?: FormControlLabelProps["labelPlacement"]
  /** ["falseLabel", "trueLabel"] */
  toggleLabels?: [string, string]
  defaultValue?: boolean
} & Omit<FormControlLabelProps, "label" | "control" | "defaultValue">

export const FormSwitchField = <TFieldValues extends FieldValues = FieldValues>({
  name,
  label = "",
  labelPlacement = "end",
  toggleLabels,
  defaultValue,
  ...otherProps
}: FormSwitchFieldProps<TFieldValues>) => {
  const conf = useFieldRoleConf(name)

  return (
    <Controller<FieldValues>
      name={name}
      defaultValue={defaultValue}
      render={({ field: { value, ...field } }) => (
        <Label
          {...field}
          {...otherProps}
          {...conf}
          label={toggleLabels ? toggleLabels[value ? 1 : 0] : label}
          labelPlacement={labelPlacement}
          checked={!!value}
          onChange={(event, checked) => {
            otherProps.onChange?.(event, checked)
            field.onChange(checked)
          }}
          control={<Switch />}
        />
      )}
    />
  )
}

const Label = styled(FormControlLabel)(({ theme, checked }) => ({
  fontWeight: 500,
  ...(checked && {
    [`.${formControlLabelClasses.label}`]: {
      color: theme.palette.primary.main,
    },
  }),
}))
