import { ApiOffer, Offer } from "../types"

export const openOffer = ({
  id,
  offerSource,
}: Pick<Offer, "offerSource" | "id">) => {
  const url = PROXY_ENDPOINT[offerSource] + OFFER_URLS[offerSource] + id
  window.open(url, "_blank")
}

const PROXY_ENDPOINT: Record<ApiOffer["offerSource"], string> = {
  SDAV: window.CONTEXT?.sdavProxyEndpoint,
  COALIA: window.CONTEXT?.coaliaProxyEndpoint,
}

const OFFER_URLS: Record<ApiOffer["offerSource"], string> = {
  SDAV: "/web/sdav/pagesimple.asp?mainCall=true&classe=42&cmd=editfiche&param=offre=",
  COALIA: "/web/coalia/web/bc?mainCall=true&offerId=",
}

export const openInvoice = (viewLink?: string | null) => {
  if (viewLink) {
    window.open(viewLink, "_blank")
  }
}
