import { api, keepPreviousData, useQuery } from "../../../hooks/http"

export interface SimpleResourceListApiConfig<
  QueryResource = unknown,
  Resource = QueryResource,
> {
  /** Without api domain (eg: /api/adherents) */
  resourceEndpoint: string
  queryKey: unknown[]
  select?: (data: QueryResource[]) => Resource[]
}

/**
 * Retrieve a resource list from API
 * Without pagination, sorting nor filtering,
 * It's just a list with refetch and loaders
 * TODO: implement search and filters or remove and use useQuery directly
 */
export const useSimpleResourceListApi = <
  QueryResource = unknown,
  Resource = QueryResource,
>({
  resourceEndpoint,
  queryKey,
  ...config
}: SimpleResourceListApiConfig<QueryResource, Resource>) => {
  const {
    data: list,
    isLoading: initialLoading,
    isPlaceholderData: contentLoading,
    refetch: refresh,
  } = useQuery({
    queryKey: [...(queryKey ? queryKey : [resourceEndpoint]), "simpleResourceList"],
    queryFn: () => api.get<Resource[]>(resourceEndpoint),
    select: (data) => {
      if (config.select) {
        // @ts-ignore
        return config.select(data)
      }
      return data
    },
    placeholderData: keepPreviousData,
  })

  return {
    // Loaders
    initialLoading,
    contentLoading,
    // Values
    list,
    // Actions
    refresh,
  }
}
