import { SVGProps } from "react"

export const CapsulesIcon = ({
  width = "27px",
  height = "27px",
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 27 27"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Tableau-de-bord"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Mon-Officine-Dashboard-Plus-de-categories---Desktop-Copy-2"
        transform="translate(-544.000000, -1527.000000)"
        fill="#C3CBD2"
        fillRule="nonzero"
        stroke="#C3CBD2"
        strokeWidth="0.5"
      >
        <g id="Group-9" transform="translate(111.000000, 308.000000)">
          <g id="Group-8" transform="translate(0.000000, 430.000000)">
            <g id="Group-5-Copy-5" transform="translate(409.000000, 742.000000)">
              <g id="Group" transform="translate(25.000000, 48.000000)">
                <path
                  d="M8.76208366,21.25 C8.75833366,21.25 8.75458366,21.25 8.75083366,21.25 C3.84500075,21.2475 0.00125108356,19.5983333 5.41127951e-07,17.4958333 C-0.000415582964,16.9575 0.23916773,16.4445833 0.712501022,15.97125 C2.06208424,14.6216667 5.21083397,13.75 8.73625033,13.75 C8.74000033,13.75 8.74375033,13.75 8.74750033,13.75 C13.6533332,13.7525 17.4970829,15.4016667 17.4979168,17.5041667 C17.4983329,18.0425 17.2587496,18.5554167 16.7854163,19.02875 C15.4358331,20.3783333 12.2870834,21.25 8.76208366,21.25 Z M8.73625033,14.5833333 C5.47125061,14.5833333 2.48500087,15.3775 1.30166764,16.5608333 C0.990834331,16.8716667 0.833334345,17.18625 0.833334345,17.4954167 C0.834167678,18.875 4.08583406,20.4141667 8.75083366,20.4166667 C8.75416699,20.4166667 8.75833366,20.4166667 8.76166699,20.4166667 C12.0266667,20.4166667 15.0129164,19.6225 16.1962497,18.4391667 C16.507083,18.1283333 16.6649996,17.81375 16.6645838,17.5045833 C16.6637496,16.125 13.4120833,14.5858333 8.74708366,14.5833333 C8.74333366,14.5833333 8.73958366,14.5833333 8.73625033,14.5833333 Z"
                  id="Shape"
                />
                <path
                  d="M8.76416699,25 C8.76041699,25 8.75666699,25 8.75250032,25 C3.84708408,24.9975 0.00333441671,23.3483333 0.00208441682,21.2458333 L1.08366698e-06,17.4958333 L1.08366698e-06,17.4958333 L1.08366698e-06,17.4958333 C1.08366698e-06,17.2658333 0.186251068,17.0791667 0.416667714,17.0791667 C0.416667714,17.0791667 0.416667714,17.0791667 0.416667714,17.0791667 C0.646667694,17.0791667 0.833334345,17.2654167 0.833334345,17.4958333 C0.834167678,18.875 4.08583406,20.4141667 8.75083366,20.4166667 C8.75416699,20.4166667 8.75833366,20.4166667 8.76166699,20.4166667 C12.0266667,20.4166667 15.0129164,19.6225 16.1962497,18.4391667 C16.507083,18.1283333 16.6649996,17.81375 16.664583,17.5045833 L16.664583,17.5045833 C16.664583,17.2745833 16.850833,17.0879167 17.0812496,17.0879167 C17.0812496,17.0879167 17.0812496,17.0879167 17.0812496,17.0879167 C17.3112496,17.0879167 17.4979162,17.2741667 17.4979162,17.5045833 L17.5000011,21.2545833 C17.5000011,21.2766667 17.4983329,21.2983333 17.4949996,21.32 C17.3887496,23.38875 13.5799999,25 8.76416699,25 Z M0.834167678,19.1204167 L0.835417678,21.2454167 C0.836251011,22.625 4.0879174,24.1641667 8.75291699,24.1666667 C8.75666699,24.1666667 8.76041699,24.1666667 8.76416699,24.1666667 C13.3891666,24.1666667 16.6387496,22.62875 16.6637496,21.2470833 C16.6637496,21.2325 16.6649996,21.2183333 16.6666663,21.2041667 L16.6654163,19.1429167 C15.2558331,20.4275 12.1866667,21.25 8.76208366,21.25 C8.75791699,21.25 8.75458366,21.25 8.75083366,21.25 C5.2104173,21.2483333 2.22333422,20.38875 0.834167678,19.1204167 Z"
                  id="Shape"
                />
                <path
                  d="M17.0833329,17.9166667 L0.416667714,17.9166667 C0.186667734,17.9166667 1.08366698e-06,17.73 1.08366698e-06,17.5 C1.08366698e-06,17.27 0.186667734,17.0833333 0.416667714,17.0833333 L17.0833329,17.0833333 C17.3133329,17.0833333 17.5000011,17.27 17.5000011,17.5 C17.5000011,17.73 17.3133329,17.9166667 17.0833329,17.9166667 Z"
                  id="Path"
                />
                <path
                  d="M8.60708367,4.30958333 C8.50041701,4.30958333 8.39375036,4.26875 8.31250036,4.1875 C7.17541713,3.05041667 5.32500062,3.05041667 4.18791739,4.1875 C4.0254174,4.35041667 3.76125076,4.35041667 3.59875077,4.1875 C3.43583412,4.02458333 3.43583412,3.76125 3.59875077,3.59833333 C5.06083398,2.13625 7.44000044,2.13625 8.90208365,3.59833333 C9.0650003,3.76125 9.0650003,4.02458333 8.90208365,4.1875 C8.82041699,4.26916667 8.71375033,4.30958333 8.60708367,4.30958333 Z"
                  id="Path"
                />
                <path
                  d="M10.6212502,14.67 C10.5145835,14.67 10.4079168,14.6291667 10.3266669,14.5479167 C10.1637502,14.385 10.1637502,14.1216667 10.3266669,13.95875 L16.2887497,7.99625 C16.4512497,7.83333333 16.7149996,7.83333333 16.8779163,7.99625 C17.0408329,8.15916667 17.0408329,8.4225 16.8779163,8.58541667 L10.9158335,14.5479167 C10.8345835,14.6291667 10.7279168,14.67 10.6212502,14.67 Z"
                  id="Path"
                />
                <path
                  d="M18.7191661,24.9929167 C17.3758329,24.9929167 16.0274997,24.5658333 14.8983331,23.6966667 C14.7837498,23.60875 14.7233331,23.4675 14.7379165,23.32375 C14.7524998,23.18 14.8408331,23.0545833 14.9708331,22.99125 C16.0362497,22.4745833 16.653333,21.83875 16.6641663,21.24625 C16.6641663,21.2316667 16.6654163,21.2175 16.667083,21.2033333 L16.6649996,17.50375 C16.6637496,16.125 13.4120833,14.5858333 8.74708366,14.5833333 C7.8712504,14.5725 6.97333381,14.6425 6.11166722,14.7604167 C5.98166723,14.7783333 5.85250058,14.7345833 5.76041725,14.6420833 L1.83958426,10.72125 C0.65333436,9.535 1.08366698e-06,7.95791667 1.08366698e-06,6.28041667 C1.08366698e-06,4.60291667 0.65333436,3.02541667 1.83958426,1.83958333 C3.02583415,0.653333333 4.60291735,0 6.28041721,0 C7.95791706,0 9.53500026,0.653333333 10.7212502,1.83958333 L23.1604157,14.27875 L23.1604157,14.27875 C24.3466656,15.465 25.0000011,17.0420833 25.0000011,18.7195833 C25.0000011,20.3970833 24.3466656,21.9745833 23.1604157,23.1604167 C21.9470825,24.37375 20.336666,24.9929167 18.7191661,24.9929167 Z M15.950833,23.4054167 C18.0587495,24.6529167 20.8016659,24.3404167 22.5708325,22.57125 C23.599999,21.5425 24.1666657,20.1745833 24.1666657,18.7195833 C24.1666657,17.2645833 23.599999,15.8966667 22.5712491,14.8679167 L10.1320835,2.42875 C9.10333363,1.4 7.73541708,0.833333333 6.28041721,0.833333333 C4.82541733,0.833333333 3.45750078,1.4 2.42875087,2.42875 C1.40000096,3.4575 0.833334345,4.82541667 0.833334345,6.28041667 C0.833334345,7.73541667 1.40000096,9.10333333 2.42875087,10.1320833 L6.20458388,13.9079167 C7.03583381,13.8029167 7.88708373,13.75 8.73875033,13.75 C8.74166699,13.75 8.74458366,13.75 8.74750033,13.75 C13.6533332,13.7525 17.4970829,15.4016667 17.4979162,17.5041667 L17.4999996,21.2541667 C17.4999996,21.2758333 17.4983329,21.2975 17.4949996,21.31875 C17.4566662,22.0916667 16.9270829,22.80375 15.950833,23.4054167 Z"
                  id="Shape"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
