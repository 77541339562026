export const Flag = ({
  width = "17",
  height = "28",
  color,
}: {
  width?: string
  height?: string
  color: string
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
    <path
      fill={color}
      fillRule="nonzero"
      d="M15.947 2.49H2.095V1c0-.55-.471-1-1.047-1C.46 0 0 .45 0 1v26c0 .55.461 1 1.048 1 .576 0 1.047-.45 1.047-1V15.15h13.852c.398 0 .765-.21.943-.55a.964.964 0 0 0-.095-1.04l-3.698-4.74 3.698-4.74c.23-.3.273-.7.095-1.04a1.058 1.058 0 0 0-.943-.55Z"
    />
  </svg>
)
