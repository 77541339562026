import {
  Badge,
  Box,
  Button,
  Stack,
  TableCell,
  Typography,
  badgeClasses,
  styled,
  useTheme,
} from "@mui/material"
import {
  ListNegativeSegmentationChip,
  ListUltraChip,
} from "@pharmupp/p3-adherent-domain"
import {
  ColumnRenderDefinition,
  LogoCircle,
  SortedTableCell,
  formatDateWithSlashes,
  getPublicFileUrl,
} from "@pharmupp/p3-front-commons"
import { Check, Clock, Eye, Image, MapPin, Plus, RefreshCw, X } from "react-feather"
import { OperationValidationStatus } from "../operationMarketing.model"
import { ApiParticipant } from "../types"
import { ApiParticipantListReferential } from "./useParticipantListReferentialApi"

export const columns = {
  picture: {
    renderHeader: ({ width }) => <TableCell width={width}>Photo</TableCell>,
    renderRow: ({ row }) => (
      <TableCell>
        {row.proofDocuments?.length ? (
          <Box position="relative" display="inline-block">
            <ProofDocument
              src={getPublicFileUrl(row.proofDocuments?.[0].document)}
            />
            <Badge
              badgeContent={row.proofDocuments.length}
              sx={({ palette }) => ({
                position: "absolute",
                top: 0,
                right: 0,
                color: "white",
                [`.${badgeClasses.badge}`]: {
                  transform: "translate(30%, -30%)",
                  bgcolor:
                    row.validationStatus === OperationValidationStatus.REFUSED
                      ? palette.error.main
                      : palette.common.darkBlue,
                },
              })}
            />
          </Box>
        ) : (
          <ProofDocumentPlaceholder />
        )}
      </TableCell>
    ),
  },
  pharmacy: {
    renderHeader: ({ sortProps, width }) => (
      <SortedTableCell {...sortProps} columnName="adherentName" width={width}>
        Pharmacie
      </SortedTableCell>
    ),
    renderRow: ({ row, api }) => (
      <TableCell>
        <Stack>
          <Box display="flex" gap={2}>
            <Typography fontSize="inherit" fontWeight={600}>
              {row.pharmacy.name} {!!row.pharmacy.ultra && <ListUltraChip />}
              {!!row.pharmacy.negativeSegmentation && (
                <ListNegativeSegmentationChip />
              )}
            </Typography>
          </Box>
          <Stack>
            <Box>
              {row.pharmacy.primaryHolder?.firstName}{" "}
              {row.pharmacy.primaryHolder?.lastName}
            </Box>
            {!!row.pharmacy.uppId && (
              <Box>
                <UppIcon />{" "}
                {row.pharmacy.uppName
                  ? row.pharmacy.uppName
                  : api.referential?.uppNameById[row.pharmacy.uppId]}
              </Box>
            )}
          </Stack>
          <Stack
            direction="row"
            divider={<span>-</span>}
            spacing={0.5}
            color="grey.light"
            flexWrap="wrap"
          >
            <Typography fontSize="inherit" whiteSpace="nowrap">
              ID : {row.pharmacy.centralBusinessId}
            </Typography>
            <Typography fontSize="inherit" whiteSpace="nowrap">
              CIP : {row.pharmacy.cipCode}
            </Typography>
          </Stack>
        </Stack>
      </TableCell>
    ),
  },
  subscriptionDate: {
    renderHeader: ({ sortProps, width }) => (
      <SortedTableCell
        {...sortProps}
        columnName="subscribeDate"
        align="center"
        width={width}
      >
        Inscription
      </SortedTableCell>
    ),
    renderRow: ({ row }) => (
      <TableCell align="center">
        {formatDateWithSlashes(row.subscribeDate)}
      </TableCell>
    ),
  },
  validationStatus: {
    renderHeader: ({ width }) => (
      <TableCell width={width}>Suivi justification</TableCell>
    ),
    renderRow: ({ row }) => (
      <TableCell>
        <ValidationStatus status={row.validationStatus} />
      </TableCell>
    ),
  },
  operationStatus: {
    renderHeader: ({ width }) => <TableCell width={width}>Statut OP</TableCell>,
    renderRow: ({ row }) => (
      <TableCell>
        <OperationStatus status={row.validationStatus} />
      </TableCell>
    ),
  },
} satisfies Record<string, ColumnRenderDefinition<ApiParticipant, ExtraRowApi>>

export type ExtraRowApi = {
  referential?: ApiParticipantListReferentialSelected
}
export interface ApiParticipantListReferentialSelected
  extends ApiParticipantListReferential {
  uppNameById: Record<number, string>
  sectorNameById: Record<string, string>
}

const PROOF_DOC_SIZE = "65px"

const ProofDocument = styled("img")({
  width: PROOF_DOC_SIZE,
  height: PROOF_DOC_SIZE,
  borderRadius: "5px",
  border: "1px solid #e5e6e9",
  backgroundColor: "#f2f2f3",
  overflow: "hidden",
  objectFit: "cover",
})

const ProofDocumentPlaceholder = styled(Box)(({ theme }) => ({
  width: PROOF_DOC_SIZE,
  height: PROOF_DOC_SIZE,
  borderRadius: "5px",
  border: "1px solid #e5e6e9",
  backgroundColor: "#f2f2f3",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.grey.ultraLight,
}))
ProofDocumentPlaceholder.defaultProps = {
  children: <Image />,
}

export const ValidationStatus = ({
  status,
}: {
  status: ApiParticipant["validationStatus"]
}) => {
  const { palette } = useTheme()
  switch (status) {
    case "NO_DOCUMENTS_SENT_IN_TIME":
      return (
        <ProofStatusLayout>
          <LogoCircle
            borderColor={palette.error.main}
            color={palette.error.main}
            size="24px"
          >
            <X />
          </LogoCircle>
          <Typography color="grey.light" fontSize="inherit">
            Non justifié
          </Typography>
        </ProofStatusLayout>
      )
    case "WAITING":
    case "WAITING_FOR_MANUAL_ACTION":
      return (
        <ProofStatusLayout>
          <LogoCircle
            borderColor={palette.success.main}
            color={palette.success.main}
            size="24px"
          >
            <RefreshCw />
          </LogoCircle>
          <Typography fontSize="inherit">Justificatif transmis</Typography>
        </ProofStatusLayout>
      )
    case "REFUSED":
      return (
        <ProofStatusLayout>
          <LogoCircle
            borderColor={palette.error.main}
            color={palette.error.main}
            size="24px"
          >
            <X />
          </LogoCircle>
          <Typography fontSize="inherit">Justificatif refusé</Typography>
        </ProofStatusLayout>
      )
    case "JUSTIFIED_BY_ADMIN":
      return (
        <ProofStatusLayout>
          <LogoCircle
            borderColor={palette.tertiary.main}
            color={palette.tertiary.main}
            size="24px"
          >
            <Plus />
          </LogoCircle>
          <Typography fontSize="inherit">
            Justificatif ajouté manuellement
          </Typography>
        </ProofStatusLayout>
      )
    case "VALIDATED_MANUALLY":
      return (
        <ProofStatusLayout>
          <LogoCircle
            borderColor={palette.common.electricBlue}
            color={palette.common.electricBlue}
            size="24px"
          >
            <Eye />
          </LogoCircle>
          <Typography fontSize="inherit">Justificatif vérifié</Typography>
        </ProofStatusLayout>
      )
    case "VALIDATED_AUTOMATICALLY":
      return (
        <ProofStatusLayout>
          <LogoCircle
            borderColor={palette.success.main}
            color={palette.success.main}
            size="24px"
          >
            <Check />
          </LogoCircle>
          <Typography fontSize="inherit">Validé auto</Typography>
        </ProofStatusLayout>
      )
    default:
      return (
        <ProofStatusLayout>
          <LogoCircle borderColor="rgb(232, 107, 3)" color="#e86b03" size="24px">
            <Clock />
          </LogoCircle>
          <Typography color="grey.light" fontSize="inherit">
            Attente justificatif
          </Typography>
        </ProofStatusLayout>
      )
  }
}

const ProofStatusLayout = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  color: theme.palette.common.darkBlue,
  fontSize: theme.typography.pxToRem(12),
}))

export const OperationStatus = ({
  status,
}: {
  status: ApiParticipant["validationStatus"]
}) => {
  const { palette, spacing, typography } = useTheme()
  switch (status) {
    case "NO_DOCUMENTS_SENT_IN_TIME":
      return (
        <StatusChip variant="outlined" sx={{ color: palette.primary.main }}>
          Cloturée
        </StatusChip>
      )
    case "WAITING":
      return (
        <StatusChip variant="outlined" sx={{ color: palette.primary.main }}>
          Attente validation
        </StatusChip>
      )
    case "WAITING_FOR_MANUAL_ACTION":
      return (
        <Box position="relative">
          <StatusChip
            variant="contained"
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              transform: "translate(0%, -80%)",
              bgcolor: palette.common.orangeDark2,
              zIndex: 1,
              color: "white",
              fontSize: typography.pxToRem(9),
              padding: spacing(0.2, 0.8),
              textTransform: "uppercase",
            }}
          >
            Validation manuelle
          </StatusChip>
          <StatusChip variant="outlined" sx={{ color: palette.primary.main }}>
            Attente validation
          </StatusChip>
        </Box>
      )
    case "VALIDATED_AUTOMATICALLY":
      return (
        <StatusChip variant="contained" sx={{ bgcolor: palette.success.main }}>
          Validée
        </StatusChip>
      )
    case "VALIDATED_MANUALLY":
      return (
        <StatusChip variant="contained" sx={{ bgcolor: palette.success.main }}>
          Validée
        </StatusChip>
      )
    case "REFUSED":
      return (
        <StatusChip variant="outlined" sx={{ color: palette.primary.main }}>
          À rejustifier
        </StatusChip>
      )
    default:
      return (
        <StatusChip variant="outlined" sx={{ color: palette.primary.main }}>
          À justifier
        </StatusChip>
      )
  }
}

const StatusChip = styled(Button)({
  pointerEvents: "none",
})
StatusChip.defaultProps = {
  size: "small",
}

const UppIcon = styled(MapPin)(({ theme }) => ({
  color: theme.palette.common.electricBlue,
  width: theme.typography.pxToRem(12),
  height: theme.typography.pxToRem(12),
}))
