import { Box, Card, Divider, Stack, Typography, useTheme } from "@mui/material"
import {
  HalfCircularProgress,
  NumberFormat,
  PaperContainer,
  PaperContainerTitle,
} from "@pharmupp/p3-front-commons"
import { DashboardData } from "../../useMyPharmacyApi"
import {
  CategoryStats,
  NoData,
  OspharmCTA,
  SectionTitle,
  SyncDate,
} from "../layouts"

export const HealthMissions = ({
  loading,
  healthMissions,
  lastSyncDate,
}: {
  loading: boolean
  healthMissions: Required<DashboardData>["healthMissions"]
  lastSyncDate?: string
}) => {
  const { palette, typography } = useTheme()

  return (
    <PaperContainer loading={loading}>
      {!loading && (
        <Stack spacing={1} divider={<Divider />}>
          <Stack spacing={5} pb={5}>
            <PaperContainerTitle>Missions de santé</PaperContainerTitle>

            {healthMissions ? (
              <>
                <Box>
                  <SectionTitle
                    title="ACTES DE SANTÉ"
                    caption="Nombre d’actes à J-7 & évolution par rapport à l’année précédente"
                  />
                  <Box
                    display="grid"
                    gridTemplateColumns={{
                      xs: "repeat(2, 1fr)",
                      md: "repeat(3, 1fr)",
                    }}
                    columnGap={4}
                    rowGap={3}
                  >
                    {healthMissions?.acts.map((act) => (
                      <ActCard
                        key={act.label}
                        label={act.label}
                        value={act.mainStat}
                        growth={act.evolution!}
                      />
                    ))}
                  </Box>
                </Box>

                {!!(
                  healthMissions?.genericSubstitution?.mainStat != null ||
                  healthMissions?.bioSimilar?.mainStat != null
                ) && (
                  <Box pt={3}>
                    <Stack direction="row" spacing={[5, 5, 5, 10, 10]}>
                      <Box width={typography.pxToRem(200)}>
                        <SectionTitle title="Substitution génériques et biosimilaires à J-7" />
                      </Box>
                      <Stack direction="row" spacing={[4, 4, 4, 12, 12]}>
                        {healthMissions.genericSubstitution?.mainStat != null && (
                          <ShareProgress
                            title="GÉNÉRIQUES"
                            percentage={healthMissions.genericSubstitution.mainStat}
                            chartColor={palette.common.violet}
                            referenceLabel="Objectif France :"
                            referenceValue={
                              healthMissions.genericSubstitution.secondaryStat
                            }
                          />
                        )}
                        {healthMissions.bioSimilar?.mainStat != null && (
                          <ShareProgress
                            title="BIOSIMILAIRES"
                            percentage={healthMissions.bioSimilar.mainStat}
                            chartColor={palette.common.pinky}
                            referenceLabel="Moyenne France :"
                            referenceValue={healthMissions.bioSimilar.secondaryStat}
                          />
                        )}
                      </Stack>
                    </Stack>
                  </Box>
                )}
              </>
            ) : (
              <NoData />
            )}

            <SyncDate date={lastSyncDate} />
          </Stack>
          <OspharmCTA
            href="https://upp.ospharm.org/#!/top/sellout.honoraires"
            mt={4}
          />
        </Stack>
      )}
    </PaperContainer>
  )
}

const HALF_DONUT_WIDTH_IN_PX = 200

const ActCard = ({
  label,
  value,
  growth,
}: {
  label: string
  value?: number | null
  growth: number
}) => {
  const { palette, typography } = useTheme()
  return (
    <Card
      p={2}
      component={Stack}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      <Typography fontSize={typography.pxToRem(14)} color={palette.common.darkBlue}>
        {label}
      </Typography>
      <Stack direction="row" alignItems="center" spacing={3}>
        <Typography
          fontSize={typography.pxToRem(18)}
          fontWeight={600}
          color={palette.common.darkBlue}
        >
          <NumberFormat value={value} />
        </Typography>
        <CategoryStats evol={growth} />
      </Stack>
    </Card>
  )
}

const ShareProgress = ({
  title,
  percentage,
  chartColor,
  referenceLabel,
  referenceValue,
}: {
  title: string
  percentage: number | null
  referenceLabel: string
  referenceValue: number | null
  chartColor: string
}) => {
  const { palette, typography } = useTheme()
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <HalfCircularProgress
        title={title}
        value={percentage}
        size={HALF_DONUT_WIDTH_IN_PX}
        color={chartColor}
        progressBgColor="grey.extraLight"
      />
      {!!referenceValue && (
        <Stack spacing={2} alignItems="flex-start" maxWidth={typography.pxToRem(80)}>
          <Typography fontSize={typography.pxToRem(14)} color={palette.grey.light}>
            {referenceLabel}
          </Typography>
          <CategoryStats evol={referenceValue} unit=" %" neutral />
        </Stack>
      )}
    </Stack>
  )
}
