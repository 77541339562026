import { ColumnDefinition, ROLES, RoleStrategy } from "@pharmupp/p3-front-commons"
import { BonusOperation } from "../models/BonusOperation.model"
import { BonusOpColumnApi, columns } from "./columns"

export const listRoleConf: RoleStrategy<{
  canCreate: boolean
  columns: ColumnDefinition<BonusOperation, BonusOpColumnApi>[]
}> = {
  [ROLES.ADMIN]: {
    canCreate: true,
    columns: [
      { renderDefinition: columns.date, width: "8%" },
      { renderDefinition: columns.category, width: "15%" },
      { renderDefinition: columns.title, width: "27%" },
      { renderDefinition: columns.submittedRequests, width: "10%" },
      { renderDefinition: columns.maxBonus, width: "15%" },
      { renderDefinition: columns.status, width: "15%" },
      { renderDefinition: columns.crudActions, width: "10%" },
    ],
  },
  [ROLES.UPP_PRESIDENT]: {
    canCreate: false,
    columns: [
      { renderDefinition: columns.date, width: "10%" },
      { renderDefinition: columns.title, width: "20%" },
      { renderDefinition: columns.maxBonus, width: "10%" },
      { renderDefinition: columns.validatedBonuses, width: "15%" },
      { renderDefinition: columns.participantCount, width: "10%" },
      { renderDefinition: columns.uppBonus, width: "10%" },
      { renderDefinition: columns.state, width: "15%" },
      { renderDefinition: columns.enablingActions, width: "10%" },
    ],
  },
}
