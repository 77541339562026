import { Divider } from "@mui/material"
import { TableToolbar, TableToolbarProps } from "./TableToolbar"

export interface BottomPaginationProps {
  paginationProps: TableToolbarProps["paginationProps"]
  listLength: number
}
export const BottomPagination = ({
  paginationProps,
  listLength,
}: BottomPaginationProps) =>
  listLength > 0 ? (
    <>
      {listLength % 2 === 0 && (
        <Divider variant="middle" sx={{ mx: 4, borderColor: "#EDEFF0" }} />
      )}

      {/* BOTTOM PAGINATION */}
      <TableToolbar
        paginationProps={
          paginationProps
            ? {
                ...paginationProps,
                onPageChange: (pageIndex: number) => {
                  paginationProps?.onPageChange(pageIndex)
                  // Scroll to the top for the user to read the new page
                  document.body.scrollIntoView()
                },
              }
            : undefined
        }
        justifyContent="center"
      />
    </>
  ) : null
