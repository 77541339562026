const getMaxDate = (date1: Date, date2: Date) => {
  if (!isValidDate(date1)) {
    return date2
  }

  if (!isValidDate(date2)) {
    return date1
  }

  if (date1 > date2) {
    return date1
  }

  return date2
}

const isValidDate = (date: any) => !!date && !Number.isNaN(date)

const addDays = (date: Date, days: number) => {
  const dateCopy = new Date(date.valueOf())
  dateCopy.setDate(dateCopy.getDate() + days)
  return dateCopy
}

const formatToIso8601 = (date: Date) => {
  // toISOString doesn't take timezones into account, so before we use a date library, we'll use this
  // return format yyyy/mm/dd
  const month = date.toLocaleString("en-US", { month: "2-digit" })
  const day = date.toLocaleString("en-US", { day: "2-digit" })
  const year = date.getFullYear()
  return `${year}-${month}-${day}`
}

export { addDays, formatToIso8601, getMaxDate, isValidDate }
