export const ClockWarning = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="30">
    <defs>
      <path
        id="clock-warning"
        d="M13.75 0C21.331 0 27.5 6.169 27.5 13.75S21.331 27.5 13.75 27.5 0 21.331 0 13.75 6.169 0 13.75 0Zm0 2.5C7.548 2.5 2.5 7.548 2.5 13.75 2.5 19.954 7.548 25 13.75 25 19.954 25 25 19.954 25 13.75 25 7.548 19.954 2.5 13.75 2.5Zm0 2.5c.69 0 1.25.56 1.25 1.25v6.982l3.384 3.384a1.249 1.249 0 1 1-1.768 1.768l-3.75-3.75a1.247 1.247 0 0 1-.366-.884v-7.5c0-.69.56-1.25 1.25-1.25Z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use
        xlinkHref="#clock-warning"
        fill="#176AE5"
        transform="translate(1.25 1.25)"
      />
      <g fill="#176AE5">
        <path d="M35 22.286c-.552 0-1-.47-1-1.048v-4.19c0-.579.448-1.048 1-1.048s1 .47 1 1.048v4.19c0 .578-.448 1.048-1 1.048M35 27c-.27 0-.52-.13-.71-.353a1.226 1.226 0 0 1-.29-.824c0-.153.03-.306.08-.448.05-.153.12-.282.21-.388.1-.106.2-.2.33-.26.36-.176.81-.07 1.09.26.09.106.16.235.21.388.06.142.08.295.08.448 0 .318-.1.612-.29.824-.19.224-.44.353-.71.353" />
      </g>
    </g>
  </svg>
)
