import { useEffect, useRef, useState } from "react"

export const DEFAULT_TABLE_HEIGHT = "35rem"

interface UseTableResize<T> {
  contentLoading: boolean
  initialLoading: boolean
  entities: T[] | undefined
}

export const useTableResize = <T>({
  contentLoading,
  initialLoading,
  entities,
}: UseTableResize<T>) => {
  // Table height calculation
  const elementRef = useRef<HTMLTableElement>(null)
  const observer = useRef<ResizeObserver | null>(null)
  const [filledTableHeight, setFilledTableHeight] =
    useState<string>(DEFAULT_TABLE_HEIGHT)

  useEffect(() => {
    const currentRef = elementRef.current
    if (!currentRef) return
    if (observer) {
      observer.current = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          if (!initialLoading && !contentLoading && (entities || []).length > 0) {
            setFilledTableHeight(`${(entry.target as HTMLElement).offsetHeight}px`)
          }
        })
      })
      observer.current.observe(currentRef)

      return () => observer.current?.unobserve(currentRef)
    }
  }, [contentLoading, entities, initialLoading, elementRef])

  return {
    elementRef,
    filledTableHeight,
  }
}
