import { Yup } from "@pharmupp/p3-front-commons"

export const informationsSchema = Yup.object({
  generalRate: Yup.string().url().nullable(),
  productCatalog: Yup.string().url().nullable(),
  fdlConditions: Yup.string().url().nullable(),
  coaliaConditions: Yup.string().url().nullable(),
  myUppConditions: Yup.string().url().nullable(),
  directConditions: Yup.string().url().nullable(),
  ultraConditions: Yup.string().url().nullable(),
  mediathequeLink: Yup.string().url().nullable(),
})
