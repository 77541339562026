export class StatusEnum {
  static readonly ACTIVE = new StatusEnum("ACTIVE", "Actif")
  static readonly DEACTIVATED = new StatusEnum("DEACTIVATED", "Désactivé")

  private constructor(
    public readonly code: string,
    public readonly label: string,
  ) {}

  static values(): StatusEnum[] {
    return [StatusEnum.ACTIVE, StatusEnum.DEACTIVATED]
  }

  static getFromCode(code?: string): StatusEnum | undefined {
    return StatusEnum.values().find((enumValue) => enumValue.code === code)
  }
}
