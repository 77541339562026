import { SVGProps } from "react"

export type BellWithCircleIconProps = SVGProps<SVGSVGElement> & {
  circleColor?: string
  iconColor?: string
  outlineColor?: string
}

export const BellWithCircleIcon = ({
  width = "29px",
  height = "29px",
  circleColor = "#1587FF",
  iconColor = "#FF8110",
  outlineColor = "#FF8110",
  ...props
}: BellWithCircleIconProps) => (
  <svg
    {...props}
    width={width}
    height={height}
    viewBox="0 0 30 30"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <path
        d="M3.16129167,10.625 L12.4220417,10.625 C12.1797917,10.2077917 12.0416667,9.724 12.0416667,9.20833333 L12.0416667,5.66666667 C12.0416667,3.32279167 10.1348333,1.41666667 7.79166667,1.41666667 C5.44779167,1.41666667 3.54166667,3.32279167 3.54166667,5.66666667 L3.54166667,9.20833333 C3.54166667,9.724 3.40283333,10.2077917 3.16129167,10.625 M14.875,12.0416667 L0.708333333,12.0416667 C0.316625,12.0416667 8.8817842e-16,11.7250417 8.8817842e-16,11.3333333 C8.8817842e-16,10.941625 0.316625,10.625 0.708333333,10.625 C1.489625,10.625 2.125,9.989625 2.125,9.20833333 L2.125,5.66666667 C2.125,2.54220833 4.6665,-8.8817842e-16 7.79166667,-8.8817842e-16 C10.916125,-8.8817842e-16 13.4583333,2.54220833 13.4583333,5.66666667 L13.4583333,9.20833333 C13.4583333,9.989625 14.0937083,10.625 14.875,10.625 C15.266,10.625 15.5833333,10.941625 15.5833333,11.3333333 C15.5833333,11.7250417 15.266,12.0416667 14.875,12.0416667 M7.78741667,15.5797917 C7.42616667,15.5797917 7.05995833,15.4877083 6.725625,15.2943333 C6.40545833,15.1080417 6.13841667,14.841 5.95283333,14.5215417 C5.75733333,14.1829583 5.87279167,13.7494583 6.21066667,13.5539583 C6.55066667,13.3563333 6.98275,13.4732083 7.17895833,13.8117917 C7.24058333,13.9173333 7.329125,14.0065833 7.43608333,14.0682083 C7.77395833,14.2658333 8.20816667,14.14825 8.404375,13.8110833 C8.60129167,13.4732083 9.03479167,13.3584583 9.37266667,13.5546667 C9.71125,13.750875 9.82670833,14.1836667 9.62908333,14.52225 C9.23595833,15.2008333 8.52125,15.5797917 7.78741667,15.5797917"
        id="path-1"
      />
    </defs>
    <g
      id="Opération-vie-des-UPP-(Adhérent)"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Challenge---Detail-QUALITATIF-2024---Desktop-Copy-2"
        transform="translate(-489.000000, -374.000000)"
      >
        <g id="Info-Produits-AMM" transform="translate(470.000000, 362.000000)">
          <g id="Icon-Notif-" transform="translate(20.000000, 12.000000)">
            <g id="Group-6-Copy">
              <circle id="Oval" stroke={outlineColor} cx="13.5" cy="14.5" r="13.5" />
              <circle id="Oval" fill={circleColor} cx="22.5" cy="3.5" r="3.5" />
            </g>
            <g
              id="Icon/Notifications/Notifications-2"
              transform="translate(5.000000, 6.000000)"
            >
              <g
                id="Icon/Notifications/Notifications"
                transform="translate(0.708617, 0.708333)"
              >
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1" />
                </mask>
                <use id="Icon-Notifications" fill={iconColor} xlinkHref="#path-1" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
