import {
  Box,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  menuItemClasses,
  styled,
  useTheme,
} from "@mui/material"
import React, { useMemo, useState } from "react"
import { Check, ChevronDown, X } from "react-feather"
import { Controller, useWatch } from "react-hook-form"
import { ReferentialCode, ReferentialValue } from "../../../../types"
import { useSelectedOptionsRegistration } from "./SelectedOptions"
import { ClearFieldButton } from "./common"

export type FilterSearchOptions = (ReferentialCode | ReferentialValue)[]
interface FilterSearchAndSelectProps {
  name: string
  label: string
  options: FilterSearchOptions
  multiple?: boolean
  sorted?: boolean
}

export const FilterSearchAndSelect = ({
  name,
  label,
  options,
  multiple = false,
  sorted = true,
}: FilterSearchAndSelectProps) => {
  const { palette, typography } = useTheme()

  // LABEL
  const currentValue = useWatch({ name })
  const currentLabel = useMemo(() => {
    if (currentValue == null || multiple) return label
    const selectedOption = options.find((option) =>
      isOptionSelected(option, currentValue),
    )
    return selectedOption?.label || label
  }, [currentValue, label, options, multiple])

  // OPEN/CLOSE OPTIONS
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) =>
    setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  // SEARCH
  const [search, setSearch] = useState<string>("")
  const filteredOptions = useMemo(() => {
    const newFiletredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(search.toLowerCase()),
    )
    if (sorted) {
      return newFiletredOptions.sort((a, b) => a.label.localeCompare(b.label))
    }
    return newFiletredOptions
  }, [options, search, sorted])

  // Show selected options in dedicated row
  useSelectedOptionsRegistration({ name, options, disabled: !multiple })

  return (
    <Controller<Record<string, Value>>
      name={name}
      render={({ field }) => {
        const valueCount = Array.isArray(field.value)
          ? field.value.length
          : field.value
            ? 1
            : 0

        const onChange = (option?: Option) => {
          if (!option) return field.onChange(null)

          const value = getOptionValue(option)

          if (multiple) {
            // Ensure currentValues is an array
            const currentValues = Array.isArray(field.value)
              ? field.value
              : field.value == null
                ? []
                : [field.value]

            const newValues = currentValues.includes(value)
              ? currentValues.filter((v) => v !== value)
              : [...currentValues, value]
            return field.onChange(newValues)
          }
          field.onChange(field.value === value ? null : value)
          return handleClose()
        }

        return (
          <Box>
            {/* SELECT */}
            <Select hasValue={!!valueCount} onClick={handleClick}>
              <Box display="flex" alignItems="center" gap={1}>
                {!!valueCount &&
                  (multiple ? (
                    <Counter>{valueCount}</Counter>
                  ) : (
                    <Check size="15px" color="white" />
                  ))}
                <span>{currentLabel}</span>
                {valueCount ? (
                  <ClearFieldButton
                    onClick={(e) => {
                      e.stopPropagation()
                      onChange(undefined)
                    }}
                    sx={{ m: 0, p: 0 }}
                  />
                ) : (
                  <ChevronDown
                    size={typography.pxToRem(15)}
                    color={palette.grey.dark}
                    style={{ ...(!!open && { transform: "rotate(180deg)" }) }}
                  />
                )}
              </Box>
            </Select>

            {/* OPTIONS */}
            <Menu
              id={name}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              autoFocus={false}
              disableAutoFocusItem
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              {/* SEARCH */}
              {options.length > 8 && (
                <SearchMenuItem key={-1}>
                  <SearchInput
                    placeholder="Rechercher"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    autoFocus
                    /** Disable menu item selection by keypress */
                    onKeyDown={(e) => e.stopPropagation()}
                    endAdornment={
                      <IconButton
                        onClick={() => setSearch("")}
                        size="small"
                        disabled={!search}
                        sx={{ opacity: search ? 1 : 0 }}
                      >
                        <X size="16px" />
                      </IconButton>
                    }
                  />
                </SearchMenuItem>
              )}

              {/* LIST */}
              {filteredOptions.map((option) => (
                <MenuItem
                  key={getOptionValue(option)}
                  onClick={() => onChange(option)}
                  selected={isOptionSelected(option, field.value)}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        )
      }}
    />
  )
}

const Select = styled(Box, { shouldForwardProp: (prop) => prop !== "hasValue" })<{
  hasValue?: boolean
}>(({ theme, hasValue }) => ({
  borderRadius: "100px",
  border: "1px solid rgb(219, 225, 231)",
  padding: `${theme.spacing(0.5)} ${theme.spacing(1.25)}`,
  outline: "none",
  cursor: "pointer",
  textTransform: "uppercase",
  fontSize: theme.typography.pxToRem(hasValue ? 13 : 12),
  fontWeight: 500,
  height: "30px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  ...(hasValue
    ? {
        backgroundColor: "#93b4ed",
        color: "white",
        borderColor: "transparent",
      }
    : {
        backgroundColor: "white",
        "&:hover": {
          backgroundColor: "#f5f8fa",
        },
      }),
}))

const Counter = styled(Box)(({ theme }) => ({
  width: "20px",
  height: "20px",
  borderRadius: "50%",
  backgroundColor: theme.palette.primary.main,
  color: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: theme.typography.pxToRem(12),
}))

const SearchMenuItem = styled(MenuItem)({
  [`&.${menuItemClasses.focusVisible}`]: {
    background: "white!important",
  },
})
const SearchInput = styled(InputBase)(({ theme }) => ({
  border: "1px solid rgb(219, 225, 231)",
  borderRadius: "5px",
  width: "100%",
  padding: theme.spacing(0.5, 1.5),
  fontSize: theme.typography.pxToRem(13),
}))

type SingleValue = string | number
type MultipleValue = (string | number)[]
type Value = SingleValue | MultipleValue
type Option = FilterSearchOptions[number]

const getOptionValue = (option: Option) =>
  "code" in option ? option.code : option.value

const isOptionSelected = (option: Option, value: Value) => {
  const optionValue = getOptionValue(option)
  return Array.isArray(value) ? value.includes(optionValue) : value === optionValue
}
