import { Chip, styled } from "@mui/material"
import {
  TinyChip,
  ChipVariation as TinyChipVariation,
} from "@pharmupp/p3-front-commons"
import { TagObject } from "../Offers/types"

export const OfferChip = TinyChip
export type ChipVariation = TinyChipVariation

export const TagChip = ({
  tag,
  isCatalog,
}: {
  tag?: TagObject
  isCatalog: boolean
}) =>
  tag ? (
    <TinyChip label={tag.label} variation={tag.chipVariation} />
  ) : (
    <>{!!isCatalog && <TinyChip label="CATALOGUE" variation="blue" />}</>
  )

export const LaboratoryChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.common.darkBlue,
  textTransform: "uppercase",
}))
LaboratoryChip.defaultProps = {
  variant: "outlined",
}
