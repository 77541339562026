import type { AlertProps as MuiAlertProps } from "@mui/material"
import {
  AlertTitle,
  Box,
  Collapse,
  Alert as MuiAlert,
  alertClasses,
  styled,
} from "@mui/material"
import { useState } from "react"
import { ChevronDown } from "react-feather"

export { AlertTitle } from "@mui/material"

export type AlertProps = MuiAlertProps &
  ({ title?: string; accordion?: false } | { title: string; accordion: true })

export const Alert = ({
  title,
  accordion = false,
  children,
  ...props
}: AlertProps) =>
  accordion ? (
    <AlertAccordion title={title!} {...props}>
      {children}
    </AlertAccordion>
  ) : (
    <StyledAlert {...props}>
      {!!title && <AlertTitle>{title}</AlertTitle>}
      {children}
    </StyledAlert>
  )

const AlertAccordion = ({
  title,
  children,
  ...props
}: MuiAlertProps & { title: string }) => {
  const [open, setOpen] = useState(false)

  return (
    <StyledAlert
      {...props}
      onClick={() => setOpen((current) => !current)}
      sx={{ ...props.sx, cursor: "pointer" }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ overflow: "hidden" }}
      >
        <AlertTitle sx={{ m: 0 }}>{title}</AlertTitle>
        <ChevronDown
          style={{
            transform: open ? "rotate(180deg)" : "initial",
            transition: "transform 150ms",
          }}
        />
      </Box>
      <Collapse in={open}>{children}</Collapse>
    </StyledAlert>
  )
}

const StyledAlert = styled(MuiAlert)(({ variant }) => ({
  ...(variant !== "outlined" && { border: "none" }),
  [`& .${alertClasses.message}`]: {
    width: "100%",
  },
}))
