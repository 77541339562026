import { Box, Collapse, Divider, IconButton, Stack, styled } from "@mui/material"
import { mapValues } from "lodash-es"
import {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react"
import { ChevronDown } from "react-feather"
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  UnpackNestedValue,
  useForm,
} from "react-hook-form"
import { OUTER_PADDING_X } from "../TableList"
import { SelectedOptions, SelectedOptionsCtxProvider } from "./SelectedOptions"
import { ClearAllButton } from "./common"

export function TableFiltersSection<FormValues extends Partial<FieldValues>>({
  children,
  hiddenFilters,
  defaultValues,
  values,
  onChange,
}: PropsWithChildren<{
  defaultValues?: DefaultValues<FormValues>
  values: UnpackNestedValue<FormValues>
  onChange: (newValues: UnpackNestedValue<FormValues>) => void
  hiddenFilters?: ReactNode
}>) {
  const formApi = useForm<FormValues>()

  // Is empty computation
  const [isFormEmpty, setIsFormEmpty] = useState(true)
  const checkIsEmpty = useCallback((formValues: typeof values) => {
    setIsFormEmpty(
      !Object.values(formValues).some(
        (value) =>
          value === true ||
          typeof value === "number" ||
          (typeof value === "string" && value !== "") ||
          (Array.isArray(value) && value.length > 0),
      ),
    )
  }, [])

  useEffect(() => {
    // Double reset to ensure the form is empty https://github.com/orgs/react-hook-form/discussions/7589#discussioncomment-8295031
    formApi.reset(values)
    formApi.reset(values)
    checkIsEmpty(values)
  }, [checkIsEmpty, formApi, values])

  const handleErase = () => {
    // @ts-ignore
    formApi.reset(defaultValues || {})
    setIsFormEmpty(true)
  }

  // Submit on every change
  useEffect(() => {
    const subscription = formApi.watch(() =>
      formApi.handleSubmit((formValues) => {
        checkIsEmpty(formValues)

        // Cleanup null values to send clean/simpler objects on change
        const cleanedValues = mapValues(formValues, (value) =>
          value === null ? undefined : value,
        ) as typeof formValues
        onChange(cleanedValues)
      })(),
    )
    return () => subscription.unsubscribe()
  })

  // Open hidden filters
  const [openHiddenFilters, setOpenHiddenFilters] = useState(false)
  const toggleOpen = () => setOpenHiddenFilters((current) => !current)

  return (
    <FormProvider {...formApi}>
      <SelectedOptionsCtxProvider>
        <Box px={OUTER_PADDING_X}>
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            borderRadius="5px"
            p={3}
            width="100%"
            bgcolor="#e9f1ff"
          >
            {/* INPUTS */}
            <Box display="flex" justifyContent="space-between" gap={2}>
              <FiltersList>
                {children}
                {!isFormEmpty && (
                  <ClearAllButton onClick={handleErase}>Tout effacer</ClearAllButton>
                )}
              </FiltersList>

              {/* HIDDEN FILTERS TOGGLE */}
              {!!hiddenFilters && (
                <ToggleHiddenFiltersButton
                  onClick={toggleOpen}
                  size="small"
                  sx={{
                    transform: openHiddenFilters ? "rotate(180deg)" : "initial",
                  }}
                >
                  <ChevronDown />
                </ToggleHiddenFiltersButton>
              )}
            </Box>

            {/* SELECTED OPTIONS */}
            <SelectedOptions />

            {/* HIDDEN FILTERS */}
            <Collapse in={openHiddenFilters}>
              <Stack gap={2} mt={1}>
                <Divider sx={{ borderColor: "white" }} />
                <FiltersList>{hiddenFilters}</FiltersList>
              </Stack>
            </Collapse>
          </Box>
        </Box>
      </SelectedOptionsCtxProvider>
    </FormProvider>
  )
}

const FiltersList = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(1),
}))

const ToggleHiddenFiltersButton = styled(IconButton)(({ theme }) => ({
  color: "white",
  backgroundColor: theme.palette.grey.extraLight,
  opacity: 0.4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  transition: theme.transitions.create(["transform", "opacity"]),
  "&, &:hover": {
    backgroundColor: theme.palette.grey.light,
  },
  "&:hover": {
    opacity: 0.8,
  },
}))
