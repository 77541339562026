export class ParticipationStatusEnum {
  static readonly VALIDATED = new ParticipationStatusEnum("VALIDATED", "validé")
  static readonly SUBMITTED = new ParticipationStatusEnum("SUBMITTED", "en cours")
  static readonly REFUSED = new ParticipationStatusEnum("REFUSED", "renvoyer")

  private constructor(
    public readonly code: string,
    public readonly label: string,
  ) {}

  static values(): ParticipationStatusEnum[] {
    return [
      ParticipationStatusEnum.VALIDATED,
      ParticipationStatusEnum.SUBMITTED,
      ParticipationStatusEnum.REFUSED,
    ]
  }

  static getFromCode(code?: string | null): ParticipationStatusEnum | undefined {
    return ParticipationStatusEnum.values().find(
      (enumValue) => enumValue.code === code,
    )
  }
}
