import { CircularProgress, Paper, PaperProps, styled } from "@mui/material"
import * as React from "react"
import { Suspense } from "react"

type PaperContentProps = {
  loading?: boolean
  children: React.ReactNode
} & PaperProps

export const PaperContent = React.forwardRef<HTMLDivElement, PaperContentProps>(
  ({ children, loading = false, ...props }, ref) => (
    <StyledPaper {...props} ref={ref} className="paper-content">
      <Suspense fallback={<Spinner />}>{loading ? <Spinner /> : children}</Suspense>
    </StyledPaper>
  ),
)

PaperContent.displayName = "PaperContent"

const StyledPaper = styled(Paper)({
  minHeight: "600px",
  gridArea: "main",
  display: "flex",
  flexDirection: "column",
  "& .MuiBox": {
    overflowY: "auto",
  },
})

const Spinner = styled(CircularProgress)({
  margin: "auto",
  alignSelf: "center",
  justifySelf: "center",
})
