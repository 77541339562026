import { Box, Typography } from "@mui/material"
import * as Sentry from "@sentry/react"
import { useEffect } from "react"
import { useRouteError } from "react-router-dom"

export const AppErrorElement = () => {
  const error = useRouteError()

  useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  return (
    <Box
      width="100%"
      height="90vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box width="950px">
        {import.meta.env.DEV && error instanceof Error ? (
          <>
            <Typography variant="h4" fontWeight="bold" mb={1}>
              {error.message}
            </Typography>
            <Typography component="pre" sx={{ whiteSpace: "pre-wrap" }}>
              {error.stack}
            </Typography>
          </>
        ) : (
          <Typography>
            Une erreur est survenue, merci de contacter un administrateur.
          </Typography>
        )}
      </Box>
    </Box>
  )
}
