import { Button, Stack, Typography, styled } from "@mui/material"
import {
  FieldSection,
  FieldsColumn,
  FieldsRow,
  FormContainer,
  FormSwitchField,
  FormTimeField,
  FormTimeFieldProps,
} from "@pharmupp/p3-front-commons"
import * as React from "react"
import { Plus, X } from "react-feather"
import { useFormContext } from "react-hook-form"
import { OpeningDay } from "../../../../../../types"
import { DAYS, openingHoursFields as fields } from "./openingHours.const"

export function OpeningHours() {
  return (
    <FormContainer>
      <FieldSection
        title="Horaires d’ouverture"
        subtitle="Renseigner les jours et horaires d’ouverture de l'officine"
      >
        <Stack spacing={4}>
          {DAYS.map((DAY, index) => (
            <DayRow {...DAY} dayIndex={index} key={DAY.value} />
          ))}
        </Stack>
      </FieldSection>
    </FormContainer>
  )
}

const DayRow = ({
  label,
  value,
  dayIndex,
}: {
  label: string
  value: keyof Omit<typeof fields.openingWeek, "root">
  dayIndex: number
}) => {
  const field = fields.openingWeek[value]

  const { watch, setValue, getValues } = useFormContext()

  // On enable a day
  const [isEnabled, hasBreak]: [boolean, boolean] = watch([
    field.enabled,
    field.hasBreak,
  ])
  React.useEffect(() => {
    // Copy last filled day when enabling a new day
    if (isEnabled && dayIndex > 0) {
      const currValues = getValues(field.root)
      if (!currValues.opening.start && !currValues.opening.end) {
        const days = getValues(fields.openingWeek.root)
        const prevFieldValue = findLastEnabledDay(days, dayIndex)
        if (
          prevFieldValue?.enable &&
          prevFieldValue?.opening.start &&
          prevFieldValue?.opening.end
        ) {
          setValue(field.openingStart, prevFieldValue.opening.start)
          setValue(field.openingEnd, prevFieldValue.opening.end)
          if (prevFieldValue.hasBreak) {
            setValue(field.hasBreak, true)
            setValue(field.breakStart, prevFieldValue.workBreak.start)
            setValue(field.breakEnd, prevFieldValue.workBreak.end)
          }
        }
      }
    }
  }, [dayIndex, field, getValues, isEnabled, setValue])

  // On set break
  const setBreak = (newValue: boolean) => {
    setValue(field.hasBreak, newValue)
    setValue(field.breakStart, undefined)
    setValue(field.breakEnd, undefined)
  }

  return (
    <Stack direction="row" spacing={6}>
      {/* DAY ENABLED */}
      <FieldsColumn spacing={2} sx={{ width: "80px" }}>
        <DayName>{label}</DayName>
        <FormSwitchField name={field.enabled} />
      </FieldsColumn>

      {/* OPENING HOURS */}
      <FieldsColumn>
        <Text>Horaires d’ouverture</Text>
        <FieldsRow spacing={2} alignItems="self-start">
          <HourField name={field.openingStart} />
          <Text sx={{ alignSelf: "center" }}>à</Text>
          <HourField name={field.openingEnd} />
        </FieldsRow>
      </FieldsColumn>

      {/* BREAK HOURS */}
      <div>
        {!hasBreak ? (
          <Stack justifyContent="flex-start" alignItems="center">
            <SmallButton
              startIcon={<Plus size="18px" />}
              size="small"
              onClick={() => setBreak(true)}
            >
              Ajouter une pause
            </SmallButton>
          </Stack>
        ) : (
          <FieldsColumn>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text>Pause</Text>
              <SmallButton
                startIcon={<X size="18px" />}
                size="small"
                onClick={() => setBreak(false)}
              >
                Retirer
              </SmallButton>
            </Stack>
            <FieldsRow spacing={2} alignItems="center">
              <HourField name={field.breakStart} />
              <Text>à</Text>
              <HourField name={field.breakEnd} />
            </FieldsRow>
          </FieldsColumn>
        )}
      </div>
    </Stack>
  )
}

const findLastEnabledDay = (days: OpeningDay[], index: number) =>
  days
    .slice(0, index)
    .reverse()
    .find((day) => day.enable)

const SmallButton = styled(Button)(({ theme }) => ({
  padding: 0,
  fontSize: theme.typography.pxToRem(14),
  "&:hover": {
    background: "none",
  },
}))

const DayName = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  color: theme.palette.grey.dark,
}))

const Text = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  color: theme.palette.grey.light,
}))

const HourField = (props: FormTimeFieldProps) => (
  <FormTimeField {...props} minutesStep={5} />
)
