import { Stack, useTheme } from "@mui/material"
import { AppHeader, ROLES, useRoleCheck } from "@pharmupp/p3-front-commons"
import { useKonami } from "react-konami-code"
import { useNavigate } from "react-router-dom"
import { AccountMenu } from "./AccountMenu"
import { AdhLoyaltyPopover } from "./AdhLoyaltyPopover"
import { NavBar } from "./Navbar"
import { TOOLBAR_BTN_SIZE } from "./layouts"

export default function Header() {
  const navigate = useNavigate()
  useKonami(() => {
    window.KONAMI_ACTIVATED = true
    navigate("404")
  })

  return (
    <AppHeader
      renderActions={({ isFixed, isCompact }) => (
        <HeaderActions isFixed={isFixed} isCompact={isCompact} />
      )}
      renderNav={({ height, isFixed }) => (
        <NavBar height={height} isFixed={isFixed} />
      )}
    />
  )
}

const HeaderActions = ({
  isFixed,
  isCompact,
}: {
  isFixed: boolean
  isCompact: boolean
}) => {
  const theme = useTheme()
  const isAdherent = useRoleCheck(ROLES.ADHERENT_HOLDER)

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={3}
      height={theme.typography.pxToRem(TOOLBAR_BTN_SIZE)}
    >
      {/* V2 ou 3 */}
      {/* <IconButton
        edge="end"
        sx={{ width: TOOLBAR_BTN_SIZE, height: TOOLBAR_BTN_SIZE }}
      >
        <Search />
      </IconButton>
      {!!hasDivider && (
          <Divider
              orientation="vertical"
              flexItem
              variant="middle"
              sx={{ borderColor: theme.palette.common.darkBlue }}
          />
      )} */}
      {!(isFixed && isCompact) && (
        <>
          {!!isAdherent && <AdhLoyaltyPopover />}
          <AccountMenu isFixed={isFixed} />
        </>
      )}
    </Stack>
  )
}
