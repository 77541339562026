import { useQuery } from "@tanstack/react-query"
import { useCallback } from "react"
import { useToaster } from "../components"
import { triggerFileDownload } from "../utils"
import { api } from "./http"

export interface ExportOptions {
  /** ⚠️ Without file extension / date */
  name: string
  endpoint: string
  /** Without dot */
  extension?: string
}

export function useExportDownload({
  name,
  endpoint,
  extension = "csv",
}: ExportOptions) {
  const toaster = useToaster()

  const { refetch, isFetching } = useQuery({
    queryKey: ["export", name],
    queryFn: () =>
      api.get<Blob>(endpoint, { headers: { Accept: "application/octet-stream" } }),
    refetchOnWindowFocus: false,
    enabled: false,
  })

  const startDownload = useCallback(async () => {
    try {
      const res = await refetch({ throwOnError: true })

      if (res.data) {
        const blob = res.data
        const blobUrl = URL.createObjectURL(blob)
        const date = new Date().toLocaleDateString("fr-FR")
        const time = new Date().toLocaleTimeString("fr-FR")
        triggerFileDownload(blobUrl, `${name}_${date}_${time}.${extension}`)
      }
    } catch (err) {
      toaster.error("Erreur lors du téléchargement")
      console.error(err)
    }
  }, [extension, name, refetch, toaster])

  return {
    startDownload,
    isDownloading: isFetching,
  }
}
