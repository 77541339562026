import {
  Box,
  CardActionArea,
  Paper,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import {
  Chip,
  Link,
  MarkdownDisplay,
  formatDateWithDots,
} from "@pharmupp/p3-front-commons"
import { ChevronRight } from "react-feather"
import { UpcomingEvents } from "../useFutureEvent"

export const EventItem = ({
  eventPath,
  ...item
}: { eventPath: string } & UpcomingEvents) => {
  const { typography } = useTheme()
  return (
    <Paper component={Box} width="100%" height="100%" borderRadius={0}>
      <CardActionArea
        component={Link}
        to={`${eventPath}${String(item.id)}`}
        sx={{ height: "100%", padding: 2 }}
      >
        <Stack spacing={2} height="100%">
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Stack direction="row" spacing={0.5} color="common.darkBlue">
              <Chip
                label={formatDateWithDots(item.startDate)}
                size="small"
                fontColor="common.white"
                backgroundColor="primary.dark"
                sx={{ fontSize: typography.pxToRem(11) }}
              />
            </Stack>
          </Stack>

          <Stack justifyContent="space-between" height="100%" spacing={2}>
            <Stack>
              <Typography
                fontSize="0.9rem"
                fontWeight="bold"
                color="common.darkBlue"
              >
                {item.title}
              </Typography>

              {!!item.description && (
                <Description mt={1}>
                  <MarkdownDisplay ignoreFormatting>
                    {item.description}
                  </MarkdownDisplay>
                </Description>
              )}
            </Stack>
            <Stack direction="row" color="common.electricBlue">
              <Typography
                color="inherit"
                fontWeight="600"
                sx={{ fontSize: typography.pxToRem(11) }}
              >
                S&apos;inscrire
              </Typography>
              <ChevronRight size="1.2em" />
            </Stack>
          </Stack>
        </Stack>
      </CardActionArea>
    </Paper>
  )
}

const Description = styled(Box)({
  fontSize: "0.75rem",
  color: "common.darkBlue",
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  /* number of lines to show */
  WebkitLineClamp: "2",
  lineClamp: "2",
})
