import { Tab, Tabs, styled, tabClasses, tabsClasses } from "@mui/material"
import { getYearsToDisplay } from "./utils"

interface YearTabsProps {
  selectedYear: number
  onChange: (year: number) => void
  years?: number[]
}

export const YearTabs = ({
  selectedYear,
  onChange,
  years = defaultYears,
}: YearTabsProps) => (
  <StyledTabs value={selectedYear} onChange={(_e, newValue) => onChange(newValue)}>
    {years.map((year) => (
      <Tab label={year} key={year} value={year} />
    ))}
  </StyledTabs>
)

const StyledTabs = styled(Tabs)(({ theme: { palette, typography, spacing } }) => ({
  borderBottom: "1px solid #dce4f3",
  overflow: "visible",
  minHeight: "auto",
  [`& .${tabClasses.root}`]: {
    padding: spacing(0.8, 2),
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    fontSize: typography.pxToRem(21),
    color: `${palette.primary.light1} !important`,
    minHeight: "auto",
    lineHeight: "normal",
  },
  [`& .${tabClasses.selected}`]: {
    backgroundColor: "#f2f5f8",
    color: `${palette.primary.dark1} !important`,
  },
  [`& .${tabsClasses.scroller}`]: {
    overflow: "visible!important",
  },
  [`& .${tabsClasses.indicator}`]: {
    bottom: "-3px",
    height: "2px",
    borderBottomLeftRadius: "6px",
    borderBottomRightRadius: "6px",
    backgroundColor: "#b5c4de",
  },
}))

const defaultYears = getYearsToDisplay(new Date())
