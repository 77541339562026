import { ROLES, useRoleCheck } from "@pharmupp/p3-front-commons"
import { SummaryModel } from "../../types"
import { useAdherentOrdersReferential } from "../../useAdherentOrdersReferential"
import { OrderTable } from "../commons"
import { columns } from "../commons/columns"

type PastOrdersProps = {
  summary: SummaryModel
} & ReturnType<typeof useAdherentOrdersReferential> & {
    timeRange:
      | "non-validated"
      | "last-three-months"
      | "this-year"
      | "last-year"
      | "two-years-ago"
      | "three-years-ago"
  }

export const SpecificTimeRange = ({
  timeRange,
  ...referentialProps
}: PastOrdersProps) => {
  const isAdmin = useRoleCheck(ROLES.ADMIN)

  return (
    <OrderTable
      queryConfig={{
        queryKey: ["offers", "my-orders", "period", timeRange],
        resourceEndpoint:
          timeRange === "non-validated"
            ? "/api/offers/my-orders/non-validated"
            : `/api/offers/my-orders/period/${timeRange}`,
      }}
      emptyMessage={
        timeRange === "non-validated"
          ? "Vous n'avez aucune commande en cours"
          : "Vous n'avez aucune commande passée"
      }
      alertText={
        !isAdmin &&
        timeRange === "non-validated" &&
        "Les bons de commandes ci-dessous restent modifiables jusqu’à validation de votre commande"
      }
      columns={
        timeRange === "non-validated"
          ? [
              { renderDefinition: columns.dateAndNumber, width: "15%" },
              { renderDefinition: columns.laboratory, width: "15%" },
              { renderDefinition: columns.offer, width: "45%" },
              { renderDefinition: columns.total, width: "10%" },
              { renderDefinition: columns.action, width: "15%" },
            ]
          : [
              { renderDefinition: columns.dateAndNumber, width: "15%" },
              { renderDefinition: columns.laboratory, width: "20%" },
              { renderDefinition: columns.offer, width: "55%" },
              { renderDefinition: columns.total, width: "10%" },
            ]
      }
      {...referentialProps}
    />
  )
}
