import { TextField, TextFieldProps } from "@mui/material"
import { ComponentType, PropsWithChildren, ReactNode } from "react"
import { Controller, FieldPath, FieldValues } from "react-hook-form"
import {
  NumericFormat,
  NumericFormatProps,
  PatternFormat,
  PatternFormatProps,
} from "react-number-format"
import { useFieldRoleConf } from "../../../context/FormRoleConfContext"
import { FieldText } from "../../layout/FieldText"

export type FormNumberFieldProps<TFieldValues extends FieldValues = FieldValues> = {
  name: FieldPath<TFieldValues>
} & (NumericFormatProps<TextFieldProps> | PatternFormatProps<TextFieldProps>)

export const FormNumberField = <TFieldValues extends FieldValues = FieldValues>({
  name,
  ...otherProps
}: FormNumberFieldProps<TFieldValues>) => {
  const { displayOnlyValue, emptyDisplayValue, ...fieldConf } =
    useFieldRoleConf(name)
  const Component = "format" in otherProps ? PatternFormat : NumericFormat

  return (
    <Controller
      name={name}
      render={({ field: { ref, value, ...field }, fieldState: { error } }) => (
        <Component<TextFieldProps>
          // Link label to input
          id={name}
          {...field}
          {...otherProps}
          {...fieldConf}
          // @ts-expect-error type narrowing is hard
          format={otherProps.format || ""}
          value={value}
          getInputRef={ref}
          error={!!error}
          helperText={error?.message}
          customInput={
            displayOnlyValue
              ? ((({ value: fieldValue }: TextFieldProps & { value: string }) => (
                  <FieldText>
                    {fieldValue || (emptyDisplayValue as ReactNode)}
                  </FieldText>
                )) as ComponentType<PropsWithChildren<TextFieldProps>>)
              : TextField
          }
        />
      )}
    />
  )
}
