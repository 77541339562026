export const Flag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="30">
    <g id="Fiche-labo" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g
        id="Catalogues---Détail-laboratoire-Copy-7"
        fill="#FFF"
        transform="translate(-504 -304)"
      >
        <g id="Group-26" transform="translate(458 260)">
          <g id="Group-3" transform="translate(28 34)">
            <g id="Icone-Drapeau-Challenge" transform="translate(18 10)">
              <circle id="Oval-Copy-2" cx="2" cy="28" r="2" />
              <g id="noun-flag-6616957" fillRule="nonzero" transform="translate(1)">
                <path
                  id="Path"
                  d="M14.07 2.223H1.85V.893C1.849.402 1.433 0 .924 0 .407 0 0 .402 0 .893v23.214c0 .491.407.893.924.893.509 0 .925-.402.925-.893v-10.58h12.222a.931.931 0 0 0 .832-.491.87.87 0 0 0-.084-.929l-3.263-4.232 3.263-4.232a.868.868 0 0 0 .084-.929.932.932 0 0 0-.832-.49Z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
