export enum OperationType {
  PROMOTIONAL = "PROMOTIONAL",
  DISPLAY_BASIN = "DISPLAY_BASIN",
  BUSINESS = "BUSINESS",
}

export enum OperationValidationStatus {
  WAITING = "WAITING",
  REFUSED = "REFUSED",
  VALIDATED = "VALIDATED",
}
