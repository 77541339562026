import { Link, Stack, Typography } from "@mui/material"
import { format, isSameDay } from "date-fns"
import { fr as frLocale } from "date-fns/locale"
import { Calendar, Clock, MapPin, Users, Video } from "react-feather"

export const EventDate = ({
  startDate,
  endDate,
  cancelled,
}: {
  startDate: Date
  endDate: Date
  cancelled: boolean
}) => {
  const start = new Date(startDate)
  const end = new Date(endDate)
  const formatDay = (date: Date) => format(date, "PPPP", { locale: frLocale })
  const formatHour = (date: Date) => format(date, "p", { locale: frLocale })
  const stackSx = {
    color: cancelled ? "text.secondary" : "common.electricBlue",
  }

  const typoSx = {
    color: cancelled ? "common.red" : "inherit",
    ...(cancelled && { textDecoration: "line-through" }),
    textTransform: "capitalize",
  }

  return isSameDay(start, end) ? (
    <>
      <Stack sx={stackSx} spacing={1} direction="row">
        <Calendar />
        <Typography sx={typoSx}>{formatDay(start)}</Typography>
      </Stack>

      <Stack sx={stackSx} spacing={1} direction="row">
        <Clock />
        <Typography sx={typoSx}>{`${formatHour(start)} - ${formatHour(
          end,
        )}`}</Typography>
      </Stack>
    </>
  ) : (
    <>
      <Stack sx={stackSx} spacing={1} direction="row">
        <Calendar />
        <Stack>
          <Typography sx={typoSx}>{formatDay(start)}</Typography>
          <Typography sx={typoSx}>{formatDay(end)}</Typography>
        </Stack>
      </Stack>

      <Stack sx={stackSx} spacing={1} direction="row">
        <Clock />
        <Stack>
          <Typography sx={typoSx}>{formatHour(start)}</Typography>
          <Typography sx={typoSx}>{formatHour(end)}</Typography>
        </Stack>
      </Stack>
    </>
  )
}

export const EventRemote = ({
  meetUrl,
  isRegistered,
}: {
  meetUrl: string
  isRegistered: boolean
}) => (
  <Stack direction="row" spacing={1} color="common.darkBlue">
    <Video />
    {isRegistered ? (
      <Typography>
        <b>Participer à l’évènement : </b>
        <Link href={meetUrl} underline="always">
          {meetUrl}
        </Link>
      </Typography>
    ) : (
      <Typography>Visioconférence</Typography>
    )}
  </Stack>
)

export const EventIrl = ({
  line1,
  line2,
  postalCode,
  city,
}: {
  line1: string
  line2?: string
  postalCode: number
  city: string
}) => (
  <Stack direction="row" spacing={1} color="common.darkBlue">
    <MapPin />
    <Stack>
      <Typography>
        <>
          <b>Lieu : </b>
          {line2}
        </>
      </Typography>
      <Typography>{`${line1}, ${postalCode} ${city}`}</Typography>
    </Stack>
  </Stack>
)

export const EventCancelled = ({
  cancellationReason,
}: {
  cancellationReason: string
}) => {
  return (
    <Typography>
      <Typography component="span" color="text.secondary">
        Raison :{" "}
      </Typography>
      <Typography component="span" color="common.red">
        {cancellationReason}
      </Typography>
    </Typography>
  )
}

export const RemainingSubscription = ({ count }: { count: number }) => (
  <Stack
    direction="row"
    spacing={1}
    color={count <= 0 ? "common.orange" : "common.darkBlue"}
  >
    <Users />
    {count <= 0 ? (
      <Typography>Complet</Typography>
    ) : (
      <Typography>{`${count} ${
        count > 1 ? "places restantes" : "place restante"
      }`}</Typography>
    )}
  </Stack>
)
