import { Yup } from "@pharmupp/p3-front-commons"

export const accountProfileSchema = Yup.object({
  categories: Yup.array().of(Yup.string()).max(2).min(0).nullable(),
  name: Yup.string().max(100).required(),
  logo: Yup.object(),
  partnershipType: Yup.string().required(),
  catalogType: Yup.object({
    fdl: Yup.boolean(),
    fdlPlus: Yup.boolean(),
    sagitta: Yup.boolean(),
    coalia: Yup.boolean(),
    sdav: Yup.boolean(),
  }),
  address: Yup.object({
    line1: Yup.string(),
    line2: Yup.string(),
    city: Yup.string(),
    postalCode: Yup.string(),
    webSite: Yup.string().url(),
    phone: Yup.string(),
    fax: Yup.string(),
  }),
  ospharmCode: Yup.string(),
  cerpCode: Yup.string(),
})
