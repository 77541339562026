import { AlertCircle } from "react-feather"
import { api, useMutation } from "../../../../hooks"
import { useToaster } from "../../Toaster"
import { Dialog } from "../Dialog"

export type ActionDialogProps<SuccessData = unknown, ErrorData = unknown> = {
  open: boolean
  title: string
  message: string
  validateButtonText?: string
  method: string
  body?: string
  headers?: Record<string, string>
  successMessage: string
  onSuccess?: (data: SuccessData) => void
  onError?: (error: ErrorData) => void
  onClickClose: () => void
} & (
  | {
      /** @deprecated use endpoint instead */
      url: string
    }
  | { endpoint: string }
)

const loadingMessage = "Requête en cours..."

export const ActionDialog = <SuccessData = unknown>({
  open,
  title,
  message,
  validateButtonText,
  method,
  body,
  headers,
  successMessage,
  onSuccess,
  onError,
  onClickClose,
  ...otherProps
}: ActionDialogProps<SuccessData>) => {
  const toaster = useToaster()
  const { mutateAsync: doAction, isPending: isLoading } = useMutation({
    mutationFn: () =>
      api<SuccessData>("url" in otherProps ? otherProps.url : otherProps.endpoint, {
        method,
        body,
        headers,
        mode: "cors" as const,
      }),
    onMutate: () => toaster.info(loadingMessage),
    onSuccess: (data) => {
      onClickClose()
      toaster.success(successMessage)
      onSuccess?.(data)
    },
    onError: (error) => {
      onClickClose()
      if (onError) {
        onError?.(error)
      } else {
        toaster.error("Une erreur est survenue")
      }
    },
  })

  return (
    <Dialog
      open={open}
      Icon={AlertCircle}
      title={title}
      validateAction={doAction}
      validateText={validateButtonText}
      cancelAction={onClickClose}
      loading={isLoading}
      onClose={onClickClose}
    >
      {message}
    </Dialog>
  )
}
