import {
  Box,
  MenuItem,
  TextField,
  Theme,
  inputBaseClasses,
  inputLabelClasses,
  lighten,
  selectClasses,
  styled,
  useTheme,
} from "@mui/material"
import { get } from "lodash-es"
import { ChevronDown } from "react-feather"
import { Controller, FieldPath, FieldValues } from "react-hook-form"

export interface StatusOption {
  code: string
  label: string
  /** Theme color name or code eg: "success.main" or "#fff"  */
  color?: string
}

export interface FormStatusSelectorProps<
  TFieldValues extends FieldValues = FieldValues,
> {
  name: FieldPath<TFieldValues>
  options: StatusOption[]
  defaultValue?: string
  disabled?: boolean
}

/** Used for options without color */
const DEFAULT_COLOR = "success.main"

/**
 * A Select for status display / selection
 * Made for TabbedForm tabs right children
 */
export const FormStatusSelector = <TFieldValues extends FieldValues = FieldValues>({
  name,
  options,
  defaultValue,
  disabled = false,
  ...otherProps
}: FormStatusSelectorProps<TFieldValues>) => {
  const theme = useTheme()

  return (
    <Controller
      name={name}
      defaultValue={defaultValue as TFieldValues[typeof name]}
      render={({ field, fieldState: { error } }) => {
        const selectedItem = options.find((option) => option.code === field.value)
        const selectedColor = getColorSafely(theme, selectedItem?.color)
        return (
          <Select
            select
            id={name}
            label="Statut"
            {...field}
            {...otherProps}
            SelectProps={{ IconComponent: ChevronDown }}
            // Important to have default to "" to avoid a MUI error
            value={field.value ?? ""}
            error={!!error}
            bgcolor={selectedColor}
            variant="outlined"
            disabled={disabled}
          >
            {options.map(({ code, label, color = DEFAULT_COLOR }) => (
              <SelectOption
                key={code}
                value={code}
                color={getColorSafely(theme, color)}
              >
                <Box display="flex" alignItems="center">
                  <Dot bgcolor={color} />
                  {label}
                </Box>
              </SelectOption>
            ))}
          </Select>
        )
      }}
    />
  )
}

const Select = styled(TextField)<{ bgcolor: string }>(
  ({ theme, disabled, bgcolor }) => ({
    [`& .${inputBaseClasses.root}`]: {
      borderRadius: "50px",
      backgroundColor: lighten(bgcolor, 0.9),
      "&:hover fieldset": {
        borderColor: theme.palette.grey.dark,
      },
    },
    [`& .${inputBaseClasses.focused} fieldset`]: {
      borderColor: `${theme.palette.grey.dark}!important`,
    },
    [`& .${inputBaseClasses.input}`]: {
      padding: `7px ${theme.spacing(2)}`,
      ...(disabled && { paddingRight: `${theme.spacing(2)}!important` }),
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.common.darkBlue,
    },
    [`& .${inputLabelClasses.root}`]: {
      color: theme.palette.grey.dark,
      fontSize: theme.typography.pxToRem(14),
      padding: `${theme.spacing(0.1)} ${theme.spacing(0.4)}`,
    },
    "& fieldset legend": {
      marginLeft: "4px",
      width: "40px",
    },
    [`& .${selectClasses.icon}`]: {
      marginTop: "-3px",
      width: "20px",
      ...(disabled && { display: "none" }),
    },
  }),
)

const SelectOption = styled(MenuItem)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
}))

const Dot = styled(Box)({
  display: "inline-block",
  minWidth: "8px",
  height: "8px",
  marginRight: "7px",
  borderRadius: "50%",
})

const getColorSafely = (theme: Theme, colorPath?: string) => {
  const defaultColor = get(theme.palette, DEFAULT_COLOR)
  return colorPath ? get(theme.palette, colorPath, defaultColor) : defaultColor
}
