import { Box, Card, CardActionArea, Typography } from "@mui/material"
import { ReactNode } from "react"
import { ChevronRight } from "react-feather"
import { Link } from "react-router-dom"

export const SmallLinkCard = ({
  icon,
  label,
  to,
}: { icon: ReactNode; label: string; to: string }) => (
  <Card sx={{ border: "none" }}>
    <CardActionArea
      component={Link}
      to={to}
      color="primary.dark1"
      sx={{
        px: 4,
        py: 3,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        bgcolor: "#e2e9fc",
        height: "100%",
      }}
    >
      <Box display="flex" alignItems="center" gap={3}>
        {icon}
        <Typography
          color="inherit"
          fontSize={(theme) => theme.typography.pxToRem(20)}
          fontWeight={500}
        >
          {label}
        </Typography>
      </Box>
      <ChevronRight
        size="20px"
        color="#7d9fd2"
        style={{ justifySelf: "flex-end" }}
      />
    </CardActionArea>
  </Card>
)
