import {
  ROLES,
  RoleStrategy,
  api,
  useQuery,
  useRoleConf,
} from "@pharmupp/p3-front-commons"

export const useCoaliaStatsApi = ({
  year,
  adherentId,
}: {
  year: number
  adherentId?: string
}) => {
  const endpoint = useRoleConf(apiRoleConf)(year, adherentId)

  const { data: coaliaStats, isLoading } = useQuery({
    queryKey: ["adherents", "my-pharmacy", "coalia-stats", year],
    queryFn: () => api.get<ApiCoaliaStats>(endpoint),
  })

  return { coaliaStats, isLoading }
}

const apiRoleConf: RoleStrategy<(year: number, adherentId?: string) => string> = {
  [ROLES.ADMIN]: (year, adherentId) =>
    `/api/adherents/${adherentId}/coalia-stats/${year}`,
  [ROLES.UPP_PRESIDENT]: (year, adherentId) =>
    adherentId
      ? `/api/adherents/my-upp/${adherentId}/coalia-stats/${year}`
      : `/api/adherents/my-adherent/coalia-stats/${year}`,
  [ROLES.ADHERENT_ADVISOR]: (year, adherentId) =>
    `/api/adherents/my-sector/${adherentId}/coalia-stats/${year}`,
  [ROLES.ADHERENT_HOLDER]: (year) =>
    `/api/adherents/my-adherent/coalia-stats/${year}`,
}

interface Laboratory {
  name: string
  priority: boolean
  values: LabValues
}

export type LabValues = Partial<Record<MonthKey, MonthlyValues>>

// Keep this ordered!
export type MonthKey =
  | "JANUARY"
  | "FEBRUARY"
  | "MARCH"
  | "APRIL"
  | "MAY"
  | "JUNE"
  | "JULY"
  | "AUGUST"
  | "SEPTEMBER"
  | "OCTOBER"
  | "NOVEMBER"
  | "DECEMBER"

// Ordered in backend
export interface MonthlyValues {
  turnover: number
  lastYearTurnoverPercent: number | null
  goal: number | null
  goalPercent: number | null
}

type MonthlyTotals = Pick<MonthlyValues, "turnover"> & {
  lastYearTurnoverPercent: null
  goal: null
  goalPercent: null
}

// Ordered in backend
export type ApiCoaliaStats = YearWithData | YearWithoutData

interface YearWithData {
  laboratories: Laboratory[]
  totals: Partial<Record<MonthKey, MonthlyTotals>>
  totalsPriority: Partial<Record<MonthKey, MonthlyTotals>> | null
  totalsNonPriority: Partial<Record<MonthKey, MonthlyTotals>> | null
}
interface YearWithoutData {
  laboratories: never[]
  totals: null
  totalsPriority: null
  totalsNonPriority: null
}
