import { Yup, YupTypes } from "@pharmupp/p3-front-commons"
import { endOfDay, sub } from "date-fns"
import { removeTime } from "../../../../../core"
import { LocationType } from "../../../../model"

export const schema = Yup.object().shape(
  {
    startDate: Yup.date()
      .required()
      .when("$isCreate", ([isCreate], schemaRef) =>
        isCreate
          ? schemaRef.min(new Date(), "La date de début ne peut être dans le passé")
          : schemaRef,
      )
      .when(
        "endDate",
        ([end], schemaRef) =>
          end &&
          schemaRef.max(
            end,
            "La date de début doit être antérieure à la date de fin",
          ),
      ),
    endDate: Yup.date()
      .required()
      .when("$isCreate", ([isCreate], schemaRef) =>
        isCreate
          ? schemaRef.min(new Date(), "La date de fin ne peut être dans le passé")
          : schemaRef,
      )
      .when(
        "startDate",
        ([start], schemaRef) =>
          start &&
          schemaRef.min(
            start,
            "La date de fin doit être ultérieure à la date de début",
          ),
      ),
    locationType: Yup.string<LocationType>().required(),
    address: Yup.object({
      line1: Yup.string().max(60).required(),
      line2: Yup.string().nullable().max(60),
      postalCode: Yup.string().min(5).max(5).required(),
      city: Yup.string().max(30).required(),
    })
      .default(undefined)
      .notRequired()
      .when("locationType", ([locationType], schemaRef) =>
        locationType === LocationType.IRL ? schemaRef.required() : schemaRef,
      ),
    meetUrl: Yup.string().when("locationType", ([locationType], schemaRef) =>
      locationType === LocationType.REMOTE
        ? schemaRef.required().url().max(256)
        : schemaRef,
    ),
    uppList: Yup.array().of(Yup.number().required()),
    maximumGuests: Yup.number().min(1).max(9999),
    registrationDeadline: Yup.date()
      .required()
      .when("$isCreate", ([isCreate], schemaRef) =>
        isCreate
          ? schemaRef.min(
              removeTime(new Date()),
              "La date de cloture des inscriptions ne peut être dans le passé",
            )
          : schemaRef,
      )
      .when("startDate", ([startDate], schemaRef) =>
        startDate
          ? schemaRef.max(
              sub(endOfDay(startDate), { days: 1 }),
              "La date de fin des inscriptions doit être avant la date de début de l'évènement",
            )
          : schemaRef,
      ),
  },
  [["startDate", "endDate"]],
)

export type Details = YupTypes.InferType<typeof schema>
