import { api, useQuery } from "@pharmupp/p3-front-commons"
import { ApiLoyaltyCategory } from "../types"

export const useLoyaltyCategoryListApi = ({ year }: { year: number }) => {
  const { data: challengeCategories, isLoading } = useQuery({
    queryKey: ["loyalty", "my-adherent", "detailed", year],
    queryFn: () =>
      api.get<ApiLoyaltyCategory[]>(
        `/api/loyalties/my-adherent/detailed?year=${year}`,
      ),
  })
  return {
    challengeCategories,
    loading: isLoading,
  }
}
