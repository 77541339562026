import { Button, IconButton, buttonClasses, styled } from "@mui/material"
import { X } from "react-feather"

const SELECTED_BG_COLOR = "#93b4ed"

const BaseFilterButton = styled(Button)(({ theme }) => ({
  boxSizing: "border-box",
  fontSize: theme.typography.pxToRem(12),
  [`&.${buttonClasses.sizeSmall}`]: {
    maxHeight: "30px",
    padding: `${theme.spacing(0.5)} ${theme.spacing(1.3)}`,
  },
  [`.${buttonClasses.startIcon}`]: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(0),
  },
  [`.${buttonClasses.endIcon}`]: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(-1),
    color: theme.palette.primary.dark2,
  },
  [`.${buttonClasses.iconSizeSmall}`]: {
    svg: {
      height: theme.typography.pxToRem(15),
    },
  },
  [`&.${buttonClasses.contained}`]: {
    backgroundColor: SELECTED_BG_COLOR,
    color: "white",
    border: "1px solid transparent",
  },
  [`&.${buttonClasses.outlined}`]: {
    backgroundColor: "white",
    color: theme.palette.common.darkBlue,
    border: "1px solid rgb(219, 225, 231)",
  },
}))
BaseFilterButton.defaultProps = {
  size: "small",
}

export const FilterButton = styled(BaseFilterButton)({
  textTransform: "uppercase",
  transition: "none !important",
})

export const ClearFieldButton = styled(IconButton)(({ theme }) => ({
  marginLeft: theme.spacing(-1.5),
  borderTopLeftRadius: "0px",
  borderBottomLeftRadius: "0px",
  borderTopRightRadius: "100px",
  borderBottomRightRadius: "100px",
  paddingLeft: 0,
  maxHeight: "30px",
  backgroundColor: SELECTED_BG_COLOR,
  "& svg": {
    color: theme.palette.primary.dark2,
    height: theme.typography.pxToRem(15),
  },
  "&:hover": {
    svg: {
      color: theme.palette.primary.dark1,
    },
    backgroundColor: SELECTED_BG_COLOR,
  },
}))
ClearFieldButton.defaultProps = {
  children: <X />,
}

/**
 * Clear all filters
 */
export const ClearAllButton = styled(BaseFilterButton)(({ theme }) => ({
  color: SELECTED_BG_COLOR,
  textDecoration: "underline!important",
  [`&.${buttonClasses.sizeSmall}`]: {
    lineHeight: theme.typography.pxToRem(16),
  },
  [`.${buttonClasses.iconSizeSmall}`]: {
    marginBottom: theme.spacing(-0.2),
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(0),
    svg: {
      color: theme.palette.primary.main,
      marginTop: "-2px",
      height: theme.typography.pxToRem(16),
    },
  },
}))
ClearAllButton.defaultProps = {
  variant: "text",
  size: "small",
  startIcon: <X />,
}
