export const to = "conditions-de-participation" as const
export const label = "Conditions de participation"
export const fields = {
  freeOfCharge: "freeOfCharge",
  kitOrderStartDate: "kitOrderStartDate",
  kitOrderEndDate: "kitOrderEndDate",
  deliveryCarrier: "deliveryCarrier",
  deliveryDate: "deliveryDate",
  challengeCompensation: "challengeCompensation",
  nbLoyaltyPoints: "nbLoyaltyPoints",
  numProofDocuments: "numProofDocuments",
  completionProofDetails: "completionProofDetails",
} as const
