import { Box, IconButton, Stack, Typography, styled } from "@mui/material"
import { Trash } from "react-feather"

interface UploadedPicturesProps {
  count: number
  files: (File & { preview: string })[]
  onRemove: (index: number) => void
}
export const UploadedPictures = ({
  count,
  files = [],
  onRemove,
}: UploadedPicturesProps) => {
  return (
    <Stack spacing={1}>
      <FilesListTitle>Fichiers sélectionnés</FilesListTitle>
      <Box display="grid" gridTemplateColumns={`repeat(${count}, 125px)`} gap={5}>
        {Array.from({ length: count }).map((_, index) => (
          <FilePreview
            key={files[index]?.name ? files[index]?.name + index : index}
            src={files[index]?.preview}
            index={index}
            onDelete={(indexToDelete) => {
              onRemove(indexToDelete)
            }}
          />
        ))}
      </Box>
    </Stack>
  )
}

const FilesListTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  color: theme.palette.grey.dark,
}))

const PreviewImage = styled("img")({
  display: "block",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "5px",
})

const ImgPlaceholder = styled(Box)({
  backgroundColor: "#f2f2f3",
  borderRadius: "5px",
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
})
const ImgPlaceholderIndex = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  color: theme.palette.grey.dark,
  backgroundColor: "#dddde3",
  borderRadius: "50%",
  width: "28px",
  height: "28px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))
const DeleteButton = styled(IconButton)({
  position: "absolute",
  top: "6px",
  right: "6px",
  width: "36px",
  height: "36px",
  borderRadius: "5px",
  border: "1px solid #e5e6e9",
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "#f2f2f3",
  },
})

const FilePreview = ({
  src,
  index,
  onDelete,
}: {
  src?: string
  index: number
  onDelete: (index: number) => void
}) => (
  <Box
    position="relative"
    width="100%"
    border="1px solid #e5e6e9"
    sx={{ aspectRatio: "1", borderRadius: "5px" }}
  >
    {src ? (
      <>
        <PreviewImage src={src} alt="Image chargée" />
        <DeleteButton onClick={() => onDelete(index)}>
          <Trash />
        </DeleteButton>
      </>
    ) : (
      <ImgPlaceholder>
        <ImgPlaceholderIndex>{index + 1}</ImgPlaceholderIndex>
      </ImgPlaceholder>
    )}
  </Box>
)
