export enum EventRubric {
  UPP_GROUP = "UPP_GROUP",
  LAB_EXTERN = "LAB_EXTERN",
}
export const rubricOptions = [
  { code: EventRubric.UPP_GROUP, label: "Pharm-UPP vous informe" },
  { code: EventRubric.LAB_EXTERN, label: "Actualités Labos & Prestataires" },
]

export enum LocationType {
  IRL = "IRL",
  REMOTE = "REMOTE",
}

export enum Status {
  PUBLISHED = "PUBLISHED",
  WAITING = "WAITING",
  ARCHIVED = "ARCHIVED",
  FINISH = "FINISH",
}
