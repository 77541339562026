import { Box, Button, InputLabel, Stack, TextField, styled } from "@mui/material"
import {
  Modal,
  ModalContent,
  ModalDialogActions,
  ModalTitle,
  UploadedFile,
} from "@pharmupp/p3-front-commons"
import { useState } from "react"
import { DocumentPreview } from "../AdherentMarketingOperations/AdhMarketingOPDisplay/components"

export const RejectionMessageModal = ({
  open,
  proofDocument,
  onClose,
  onConfirm,
}: {
  open: boolean
  proofDocument?: UploadedFile
  onClose: () => void
  onConfirm: (rejectionMessage: string) => void
}) => {
  const [message, setMessage] = useState("")
  const [hasError, setHasError] = useState(false)

  return (
    <Modal open={open} onClose={onClose}>
      <ModalTitle>Justificatif refusé</ModalTitle>
      <ModalContent>
        <Stack direction="row" spacing={5}>
          <Box flex={1} p={1}>
            {!!proofDocument && (
              <DocumentPreview
                aspectRatio="1"
                proofDocument={{
                  document: proofDocument,
                  validationStatus: "REFUSED",
                }}
                showStatus
              />
            )}
          </Box>
          <Stack flex={4}>
            <InputLabel>Message de refus</InputLabel>
            <TextArea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              multiline
              rows={5}
              error={hasError}
              helperText={hasError ? "Champ requis" : undefined}
              inputProps={{ maxLength: 255 }}
            />
          </Stack>
        </Stack>
      </ModalContent>
      <ModalDialogActions>
        <Button variant="outlined" onClick={onClose}>
          Annuler
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setHasError(!message)
            if (message) {
              onConfirm(message)
              onClose()
              setMessage("")
            }
          }}
        >
          Enregistrer
        </Button>
      </ModalDialogActions>
    </Modal>
  )
}

const TextArea = styled(TextField)({
  width: "100%",
})
