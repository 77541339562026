export const QrCodeIcon = ({ slot, color }: { slot: string; color: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 49 49"
    width="30px"
    height="30px"
    /** @ts-expect-error: slot attribute has no definition for now */
    slot={slot}
  >
    <g id="Symbols" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g id="symbol" fill={color}>
        <path
          id="Path"
          d="M27.42 20.204C26.355 19.205 25.136 19 23.575 19H5.923C2.49 19-.375 21.977.04 25.435c.343 2.988 2.886 5.176 5.955 5.176 1.307 0 6.95-.098 11.545-.094v11.645c0 3.524 2.78 6.32 6.336 6.035 3.058-.247 5.336-2.995 5.336-6.104V24.3a5.063 5.063 0 0 0-1.788-4.097"
          opacity=".4"
        />
        <path
          id="Path"
          d="M46.42 1.204C45.355.205 44.136 0 42.575 0H24.923c-3.432 0-6.298 2.977-5.883 6.435.343 2.988 2.886 5.176 5.955 5.176 1.307 0 6.95-.098 11.545-.094v11.645c0 3.524 2.78 6.32 6.336 6.035 3.058-.247 5.336-2.995 5.336-6.104V5.3a5.063 5.063 0 0 0-1.788-4.097"
          opacity=".7"
          transform="rotate(180 33.609 14.609)"
        />
        <path
          id="central"
          d="M23.574 19.001c1.561 0 2.78.205 3.847 1.204a5.056 5.056 0 0 1 1.791 4.097v4.916h-4.568c-1.47 0-2.636-.181-3.658-1.035l-.19-.168a5.056 5.056 0 0 1-1.791-4.098V19Z"
        />
      </g>
    </g>
  </svg>
)
