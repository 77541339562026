import { styled } from "@mui/material"
import { Chip } from "@pharmupp/p3-front-commons"

export const ListChip = styled(Chip)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  fontSize: "0.6rem",
  lineHeight: "1rem",
  height: "1rem",
  span: {
    padding: theme.spacing(0.5),
  },
}))
ListChip.defaultProps = {
  size: "small",
  variant: "outlined",
}
