import { PaginatedApiResponse, api, useQueries } from "@pharmupp/p3-front-commons"
import { useCallback } from "react"
import { useApiOffersSelector } from "../../../Offers"
import { ApiOffer } from "../../types"

export const useOpePromoOfferApi = () => {
  const params =
    "&dateFilter=IN_PROGRESS&direction=ASC&page=0&size=14&sort=endDate&unactive=false"

  const apiOffersSelector = useApiOffersSelector()
  const finalSelector = useCallback(
    (data: PaginatedApiResponse<ApiOffer>) => apiOffersSelector(data.content! || []),
    [apiOffersSelector],
  )

  const [sdav, coalia, upcoming] = useQueries({
    queries: [
      {
        queryKey: ["offers", "opePromoWidget", "SDAV", params],
        queryFn: () =>
          api.get<PaginatedApiResponse<ApiOffer>>(
            `/api/offers?source=SDAV${params}`,
          ),
        select: finalSelector,
      },
      {
        queryKey: ["offers", "opePromoWidget", "COALIA", params],
        queryFn: () =>
          api.get<PaginatedApiResponse<ApiOffer>>(
            `/api/offers?source=COALIA${params}`,
          ),
        select: finalSelector,
      },
      {
        queryKey: ["offers", "opePromoWidget", "ALL"],
        queryFn: () =>
          api.get<PaginatedApiResponse<ApiOffer>>(
            "/api/offers?dateFilter=COMING_SOON&size=2",
          ),
        select: finalSelector,
      },
    ],
  })

  return {
    loading: coalia.isLoading || sdav.isLoading || upcoming.isLoading,
    coalia: coalia.data || [],
    sdav: sdav.data || [],
    upcomingOffers: upcoming.data || [],
  }
}
