import { Box, Card, CardActionArea, Stack, Typography, styled } from "@mui/material"
import { ChannelBox } from "../../../../../Catalogs"
import { TagChip } from "../../../../../common"
import { getDisplayedDateInfo } from "../../../../OfferList/OfferCard"
import { TimeType } from "../../../../api"
import { TagObject } from "../../../../types"

interface OfferCardProps {
  logoUrl?: string
  title: string
  timeType: TimeType
  startDate: string
  endDate: string
  tag?: TagObject
  isCatalog: boolean
  onClick: () => void
}

export const OfferCard = ({
  logoUrl,
  title,
  timeType,
  startDate,
  endDate,
  tag,
  isCatalog,
  onClick,
}: OfferCardProps) => (
  <Card sx={{ minHeight: "210px" }}>
    <CardActionArea sx={{ height: "100%" }} onClick={onClick}>
      <Stack spacing={1} p={1} height="100%">
        <Stack height="100%" spacing={1}>
          <Box>
            <Box textAlign="right" height="15px" mb={1}>
              <TagChip tag={tag} isCatalog={isCatalog} />
            </Box>
            <Logo src={logoUrl} loading="lazy" />
          </Box>
          <Description color="common.darkBlue">{title}</Description>
        </Stack>

        <Typography
          color="grey.light"
          sx={(theme) => ({
            fontSize: theme.typography.pxToRem(10),
          })}
        >
          {getDisplayedDateInfo(timeType, startDate, endDate)}
        </Typography>
      </Stack>
    </CardActionArea>
  </Card>
)

const Description = styled(Typography)(({ theme }) => ({
  display: "-webkit-box",
  WebkitLineClamp: "5",
  WebkitBoxOrient: "vertical",
  whiteSpace: "break-spaces",
  overflow: "hidden",
  fontSize: theme.typography.pxToRem(12),
}))

const Logo = styled("img")`
  max-width: 70%;
  height: 45px;
  object-fit: contain;
`

export const OfferCardWithSource = ({
  logoUrl,
  title,
  timeType,
  startDate,
  endDate,
  tag,
  offerSource,
  isCatalog,
  onClick,
}: OfferCardProps & { offerSource: string }) => (
  <Stack spacing={1}>
    <div>
      <ChannelBox px={1} py={0.8}>
        {offerSource}
      </ChannelBox>
    </div>
    <OfferCard
      logoUrl={logoUrl}
      title={title}
      timeType={timeType}
      startDate={startDate}
      endDate={endDate}
      tag={tag}
      isCatalog={isCatalog}
      onClick={onClick}
    />
  </Stack>
)
