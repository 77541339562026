import { TabbedPanel } from "@pharmupp/p3-front-commons"
import { AdherentsLoyaltyList } from "./AdherentsLoyaltyList"
import { columns } from "./columns"

export const AdherentsLoyaltyListByYear = () => <TabbedPanel tabs={tabs} />

const currentYear = new Date().getFullYear()
const years = [currentYear, currentYear - 1, currentYear - 2, currentYear - 3]
const tabs = years.map((year) => ({
  label: year,
  to: String(year),
  Element: () => (
    <AdherentsLoyaltyList
      queryConfig={{
        queryKey: ["adherents", "loyalty", "list", "admin", year],
        resourceEndpoint: "/api/adherents/loyalties",
        queryParams: { year },
        defaultColumnSort: "pharmacy",
        defaultColumnDirection: "asc",
        routeStateEnabled: true,
      }}
      exportButtonProps={{
        endpoint: `/api/adherents/loyalties?year=${year}`,
        name: `suivi-challenges-officines-${year}`,
      }}
      columns={[
        { renderDefinition: columns.uppAndCity, width: "20%" },
        { renderDefinition: columns.pharmacyNameAndHolder, width: "20%" },
        { renderDefinition: columns.separator, width: "1%" },
        { renderDefinition: columns.categorical, width: "13%" },
        { renderDefinition: columns.sdav, width: "13%" },
        { renderDefinition: columns.qualitative, width: "13%" },
        { renderDefinition: columns.total, width: "20%" },
      ]}
    />
  ),
}))
