import { PharmUPP_KeycloackInstance } from "./types"

/**
 * Mock Keycloak to avoid to be redirected to Keycloak auth page
 */
export const getKeycloackMock = (
  mockConf: Partial<PharmUPP_KeycloackInstance> = {},
) =>
  ({
    init() {
      return Promise.resolve(true)
    },
    token: "fake-token",
    updateToken: () => Promise.resolve("fake-token"),
    authenticated: true,
    tokenParsed: {
      name: "Jean Mock",
      given_name: "Jean",
      family_name: "Mock",

      realm_access: {
        roles: ["administrator"],
      },
    },
    ...mockConf,
  }) as PharmUPP_KeycloackInstance
