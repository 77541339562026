import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect, useState } from "react"
import {
  DefaultValues,
  FieldErrors,
  FieldValues,
  FormProvider,
  Resolver,
  UnpackNestedValue,
  UseFormReturn,
  useForm,
} from "react-hook-form"
import { useToaster } from "../../../feedback/Toaster"
import { PaperContent } from "../../../layout/PaperContent"
import type { TabbedPanelProps } from "../../../navigation/TabbedPanel"
import { Tab, TabbedPanel } from "../../../navigation/TabbedPanel"
import type { YupTypes } from "../../yup-custom"
import { DirtyExitDialog } from "../DirtyExitDialog"
import { FormHeader, FormHeaderProps } from "../FormHeader"
import { useTabsValidation } from "./useTabsValidation"

export interface FormTab extends Tab {
  schema: YupTypes.AnyObjectSchema
}

export interface TabbedFormProps<T extends FieldValues, C = any> {
  tabs: FormTab[]
  initialValues?: DefaultValues<T>
  onSubmit: (
    data: UnpackNestedValue<T>,
    formMethods: UseFormReturn<T, C>,
  ) => Promise<void> | void
  onError?: (
    errors: FieldErrors<T>,
    formMethods: UseFormReturn<T, C>,
  ) => Promise<void> | void
  onCancel?: () => void
  /** Props passed to each tab component */
  RouteElementProps?: Record<string, unknown>
  /** Show a spinner */
  loading?: boolean
  /** Context sent to tabs schemas */
  context?: C
  tabsRightChildren?: TabbedPanelProps["tabsRightChildren"]

  // Header
  title: FormHeaderProps["title"]
  submitLabel?: FormHeaderProps["submitLabel"]
  chipLabel?: string
  headerActions?: FormHeaderProps["otherActions"]
}

/**
 * Ensure on all domains:
 * - Consistent validation rules (per tab validation w/ routing)
 * - Consistent implementation of ReactRouter, ReactHookForm, MUI
 * - Consistent UX
 */
export const TabbedForm = <T extends FieldValues, C = any>({
  tabs,
  initialValues,
  onSubmit,
  onError,
  onCancel,
  RouteElementProps,
  loading = false,
  context,
  tabsRightChildren,
  // Header
  title,
  chipLabel,
  submitLabel,
  headerActions,
}: TabbedFormProps<T, C>) => {
  const toaster = useToaster()

  // Validation
  const { currentTabs, schema, validateTabs, resetInvalidTabs } = useTabsValidation({
    tabs,
  })

  // Form
  const formMethods = useForm<T, C>({
    resolver: yupResolver(schema) as unknown as Resolver<T, C>,
    context,
    reValidateMode: "onBlur",
  })
  const { isSubmitting, isDirty } = formMethods.formState

  useEffect(() => {
    if (initialValues) formMethods.reset(initialValues)
  }, [formMethods, initialValues])

  const [submitLoading, setSubmitLoading] = useState(false)
  const formSubmit = formMethods.handleSubmit(
    async (formValues) => {
      setSubmitLoading(true)
      resetInvalidTabs()

      try {
        await onSubmit(formValues, formMethods)
        validateTabs(formMethods.formState.errors)
      } catch (err) {
        console.error("Submit action error", err)
        toaster.error("Une erreur est survenue")
      } finally {
        setSubmitLoading(false)
      }
    },
    (formErrors) => {
      console.error("Errors in form:", {
        errors: formErrors,
        values: formMethods.getValues(),
      })
      toaster.error("Veuillez remplir correctement le formulaire")

      validateTabs(formErrors)

      if (onError) {
        onError(formErrors, formMethods)
      }
    },
  )

  return (
    <>
      {/* HEADER */}
      <FormHeader
        title={title}
        onCancel={onCancel}
        submitLabel={submitLabel}
        submitLoading={submitLoading}
        onSubmit={formSubmit}
        otherActions={headerActions}
      />

      {/* CONTENT */}
      <PaperContent
        loading={loading}
        sx={{ overflow: "visible", position: "relative" }}
      >
        <FormProvider {...formMethods}>
          <TabbedPanel
            tabs={currentTabs}
            RouteElementProps={RouteElementProps}
            tabsRightChildren={tabsRightChildren}
            chipLabel={chipLabel}
          />
        </FormProvider>
      </PaperContent>

      {/* DIALOGS */}
      <DirtyExitDialog when={!isSubmitting && isDirty} allowSameLevelRouting />
    </>
  )
}
