import { Button } from "@mui/material"
import { ReactNode } from "react"
import { LogoCircle } from "../LogoCircle"

export const LogoCircleChip = ({
  color,
  icon,
  children,
}: {
  color: string
  icon: ReactNode
  children: string
}) => (
  <Button
    variant="outlined"
    startIcon={
      <LogoCircle bgcolor={color} borderColor="transparent" size="28px">
        {icon}
      </LogoCircle>
    }
    sx={{ color, borderColor: color, pointerEvents: "none" }}
  >
    {children}
  </Button>
)
