import { Box, Link, Typography, styled, useTheme } from "@mui/material"
import { useMemo } from "react"
import { BookOpen, type Icon, Link2, Tag } from "react-feather"
import { useAdherentLabDetailsV2 } from "../../useAdherentLabDetails"

export const Documents = ({
  labId,
}: {
  labId: string
}) => {
  const { laboratory } = useAdherentLabDetailsV2({ labId })

  const hasDocs = useMemo(
    () => Object.values(laboratory?.documents || {}).some((link) => !!link),
    [laboratory],
  )

  if (!hasDocs) {
    return <Typography variant="14px">Aucun document</Typography>
  }

  return (
    <Box
      display="grid"
      gridTemplateRows="1fr 1fr 1fr"
      gridAutoFlow="column"
      columnGap={6}
    >
      <DocumentLink
        IconCmp={Tag}
        label="Tarif général"
        link={laboratory?.documents.generalRate}
      />
      <DocumentLink
        IconCmp={BookOpen}
        label="Catalogue produits"
        link={laboratory?.documents.productCatalog}
      />
      <DocumentLink
        label="Conditions FDL"
        link={laboratory?.documents.fdlConditions}
      />
      <DocumentLink
        label="Conditions COALIA"
        link={laboratory?.documents.coaliaConditions}
      />
      <DocumentLink
        label="Conditions Ma Boutique UPP"
        link={laboratory?.documents.sagittaConditions}
      />
      <DocumentLink
        label="Conditions DIRECT"
        link={laboratory?.documents.directConditions}
      />
      <DocumentLink
        label="Conditions ULTRA"
        link={laboratory?.documents.ultraConditions}
      />
    </Box>
  )
}

const DocumentLink = ({
  label,
  link,
  IconCmp,
}: {
  label: string
  link?: string | null
  IconCmp?: Icon
}) => {
  const { palette, typography } = useTheme()

  if (!link) {
    return null
  }

  const Icon = IconCmp || Link2

  return (
    <DocumentLinkWrapper
      component={Link}
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      <Icon
        size={typography.pxToRem(22)}
        color={IconCmp ? palette.common.electricBlue : palette.primary.main}
      />
      <Typography variant="14px">{label}</Typography>
    </DocumentLinkWrapper>
  )
}

const DocumentLinkWrapper = styled(Box)(({ theme }) => ({
  /** Target first row elements, CSS is 🐷 */
  "&:nth-of-type(3n-2)": {
    borderTop: "1px solid #dee2e6",
  },
  borderBottom: "1px solid #dee2e6",
  padding: theme.spacing(2, 0),
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  color: theme.palette.common.darkBlue,
  "&:hover": {
    textDecoration: "underline",
  },
})) as typeof Box
