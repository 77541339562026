export const to = "informations-generales" as const
export const label = "Informations générales"
export const fields = {
  programmedPublication: "programmedPublication",
  publicationDate: "publicationDate",
  type: "type",
  name: "name",
  validityStartDate: "validityStartDate",
  validityEndDate: "validityEndDate",
  image: "image",
  description: "description",
  hasAmmProducts: "hasAmmProducts",
  nbAmmProducts: "nbAmmProducts",
  partnerLaboratories: "partnerLaboratories",
  status: "status",
} as const
