import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Typography,
  styled,
} from "@mui/material"
import { Check, Eye, X } from "react-feather"
import { ACCEPT_COLOR, REFUSE_COLOR, VALIDATED_COLOR } from "../commons"
import { DocumentValidationStatus, ProofDocument, ValidationStatus } from "../types"

export const Document = ({
  src,
  operationStatus,
  validationStatus,
  errorMessage,
  rejectionReason,
  onValidate,
  onRefuse,
  onPictureClick,
}: {
  src: string
  operationStatus: ValidationStatus
  errorMessage?: string
  onValidate: () => void
  onRefuse: () => void
  onPictureClick: () => void
} & Pick<ProofDocument, "validationStatus" | "rejectionReason">) => {
  return (
    <div>
      <DocumentBox
        mb={2.5}
        sx={(theme) => ({
          ...(errorMessage && {
            border: `2px solid ${theme.palette.error.main}`,
          }),
        })}
      >
        <Picture
          component="img"
          src={src}
          onClick={onPictureClick}
          sx={{ cursor: "pointer" }}
        />
        <ButtonsBox>
          <DocumentCTA
            operationStatus={operationStatus}
            documentStatus={validationStatus}
            onValidate={onValidate}
            onRefuse={onRefuse}
          />
        </ButtonsBox>
      </DocumentBox>

      {validationStatus === "REFUSED" && (
        <Box pt={2} px={4}>
          <RefusalMessage>{rejectionReason}</RefusalMessage>
        </Box>
      )}

      {!!errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}

      <Divider sx={{ pt: 3 }} />
    </div>
  )
}

const DocumentBox = styled(Box)({
  position: "relative",
  aspectRatio: "16/10",
  border: "1px solid #e5e8ec",
})

export const Picture = styled(Box)({
  display: "block",
  width: "100%",
  height: "100%",
  objectFit: "cover",
}) as typeof Box

// VALIDATION BUTTONS

const ButtonsBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  left: "50%",
  transform: "translate(-50%, 50%)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(1),
}))
const DocValidationButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.8, 2.5),
  color: theme.palette.common.darkBlue,
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 600,
  "&,&:hover": {
    borderColor: "inherit",
    backgroundColor: "white",
  },
}))
DocValidationButton.defaultProps = { variant: "outlined" }
const ValidateButton = ({ onClick }: { onClick: () => void }) => (
  <DocValidationButton
    onClick={onClick}
    startIcon={<Eye size="16px" />}
    sx={{ borderColor: VALIDATED_COLOR, color: VALIDATED_COLOR }}
  >
    Accepter
  </DocValidationButton>
)
const RefuseButton = ({ onClick }: { onClick: () => void }) => (
  <DocValidationButton
    onClick={onClick}
    startIcon={<X size="18px" />}
    sx={{ borderColor: REFUSE_COLOR, color: REFUSE_COLOR }}
  >
    Refuser
  </DocValidationButton>
)

// VALIDATION STATE CHIPS

const DocumentValidationState = styled(Button)(({ theme }) => ({
  border: "2px solid white",
  padding: theme.spacing(1.8, 2.5),
  color: "white",
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 600,
  pointerEvents: "none",
}))
const DocChipValidated = () => (
  <DocumentValidationState
    startIcon={<Check size="16px" />}
    sx={{ bgcolor: ACCEPT_COLOR }}
  >
    OK
  </DocumentValidationState>
)

const DocChipRefused = () => (
  <DocumentValidationState
    startIcon={<X size="16px" />}
    sx={{ bgcolor: REFUSE_COLOR }}
  >
    Refusé
  </DocumentValidationState>
)

const DocumentCTA = ({
  operationStatus,
  documentStatus,
  onValidate,
  onRefuse,
}: {
  operationStatus: ValidationStatus
  documentStatus: DocumentValidationStatus
  onValidate: () => void
  onRefuse: () => void
}) => {
  switch (operationStatus) {
    case "WAITING":
    case "WAITING_FOR_MANUAL_ACTION":
    case "VALIDATED_AUTOMATICALLY": {
      switch (documentStatus) {
        case "REFUSED":
          return <DocChipRefused />
        case "VALIDATED":
          return <DocChipValidated />
        default:
          return (
            <>
              <RefuseButton onClick={onRefuse} />
              <ValidateButton onClick={onValidate} />
            </>
          )
      }
    }
    case "VALIDATED_MANUALLY":
    case "REFUSED":
      return documentStatus === "REFUSED" ? <DocChipRefused /> : <DocChipValidated />
    default:
      return null
  }
}

export const RefusalMessage = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  color: REFUSE_COLOR,
}))
