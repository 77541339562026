import { Link, LinkProps, styled, useTheme } from "@mui/material"
import { PropsWithChildren } from "react"
import { ChevronLeft } from "react-feather"
import { useHistory } from "../../context"

export type BackLinkProps = PropsWithChildren<{ to: string } & LinkProps>

export const BackLink = ({ children, ...props }: BackLinkProps) => {
  const theme = useTheme()
  const history = useHistory()

  return (
    <CustomLink onClick={() => history.goBack(props.to)} {...props}>
      <ChevronLeft size={theme.typography.pxToRem(24)} preserveAspectRatio="none" />
      {children}
    </CustomLink>
  )
}

const CustomLink = styled(Link)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gridArea: "backlink",

  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
  textDecoration: "none",
  color: theme.palette.primary.main,
  "&:hover": {
    cursor: "pointer",
    color: theme.palette.primary.light,
  },
  "&:active,&:focus": {
    color: theme.palette.primary.main,
  },
}))
