import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import {
  AmountChip,
  HighlightBox,
  LoadingButton,
  MarkdownDisplay,
  OperationTrackingRow,
  PaperContainer,
  ROLES,
  useRoleCheck,
} from "@pharmupp/p3-front-commons"
import { useState } from "react"
import { Share } from "react-feather"
import { useNavigate, useParams } from "react-router-dom"
import {
  ActivateOperationModal,
  useOperationActivationApi,
} from "./ActivateOperationModal"
import { BonusOpLayout } from "./Layout"
import { useBonusOperationDisplayApi } from "./useBonusOperationDisplayApi"

export const Details = () => {
  const { palette } = useTheme()
  const isPresident = useRoleCheck(ROLES.UPP_PRESIDENT)

  // ROUTING
  const { id } = useParams()
  const navigate = useNavigate()
  const goBack = () => navigate("../")

  // API
  const { operation, loading } = useBonusOperationDisplayApi({ operationId: id })
  const { activate } = useOperationActivationApi()

  const [activateDialogDisplay, setActivateDialogDisplay] = useState(false)
  const [isActivationLoading, setIsActivationLoading] = useState(false)
  const handleActivate = async () => {
    if (isPresident && operation) {
      if (operation.minBonus === operation.maxBonus) {
        setIsActivationLoading(true)
        await activate({ id: operation.bonusOperationId, bonus: operation.minBonus })
        setIsActivationLoading(false)
        goBack()
      } else {
        setActivateDialogDisplay(true)
      }
    }
  }

  return (
    <BonusOpLayout status={operation?.status}>
      {/* CONTENT */}
      <Box gridArea="main" mb={4}>
        <PaperContainer loading={loading}>
          {!!operation && (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <ContentLabel>Conditions de la prime</ContentLabel>
                <Stack direction="row" spacing={2}>
                  <ContentLabel>Validité</ContentLabel>
                  <Validity
                    start={operation.validityStartDate}
                    end={operation.validityEndDate}
                  />
                </Stack>
              </Stack>

              <Divider sx={{ mt: 2, mb: 7 }} />

              {/* CONDITIONS */}
              <Box mb={8}>
                <MarkdownDisplay>
                  {operation.conditions || "Aucune condition"}
                </MarkdownDisplay>
              </Box>

              {/* MIN/MAX BONUS INFOS */}
              {operation.status !== "ACTIVE" && !!isPresident && (
                <Box mb={6}>
                  <ContentLabel mb={2}>Prime à définir</ContentLabel>
                  <AmountChip
                    variant="outlined"
                    prefix="+"
                    unit="€"
                    color={palette.common.electricBlue}
                    labels={["Entre", "et"]}
                  >
                    {[operation.minBonus, operation.maxBonus]}
                  </AmountChip>
                </Box>
              )}

              {/* PROOFS INFOS */}
              {!!operation.isProofRequired && (
                <Box mb={6}>
                  <HighlightBox
                    Icon={Share}
                    title={`${operation.numProofDocuments} ${
                      operation.numProofDocuments > 1
                        ? "Justificatifs"
                        : "Justificatif"
                    }  à fournir`}
                    description={operation.descriptionProofDocuments}
                  />
                </Box>
              )}

              {/* GOAL TRACKING */}
              {!!(
                !isPresident ||
                (isPresident && operation.status === "ACTIVE")
              ) && (
                <>
                  <ContentTitle mb={2}>Suivi justificatifs validés</ContentTitle>
                  <Divider sx={{ mt: 2, mb: 6 }} />

                  <Box>
                    <OperationTrackingRow
                      currentValue={operation.justifiedAmount || 0}
                      goal={operation.bonus || 0}
                      showTargetChip={false}
                    />
                  </Box>
                </>
              )}

              <Divider sx={{ mt: 9, mb: 2 }} />

              {/* MAIN CTA */}
              <Stack direction="row" justifyContent="center" mt={3} mb={2}>
                {isPresident && operation.status !== "ACTIVE" ? (
                  <LoadingButton
                    variant="contained"
                    onClick={handleActivate}
                    loading={isActivationLoading}
                    disabled={operation.status === "SUBMITTED"}
                  >
                    Activer
                  </LoadingButton>
                ) : (
                  <>
                    {!!operation.bonus &&
                      (operation.justifiedAmount || 0) < operation.bonus && (
                        <Button
                          variant="contained"
                          component="a"
                          href="https://app.digipharmacie.fr/account/keycloak/login/?process=login"
                          target="_blank"
                        >
                          Soumettre vos justificatifs
                        </Button>
                      )}
                  </>
                )}
              </Stack>
            </>
          )}
        </PaperContainer>
      </Box>

      {/* DIALOGS */}
      <ActivateOperationModal
        open={activateDialogDisplay}
        onClose={() => setActivateDialogDisplay(false)}
        onSuccess={goBack}
        operation={operation}
      />
    </BonusOpLayout>
  )
}

const ContentLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.darkBlue,
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 600,
}))
const ContentTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.darkBlue,
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
  textTransform: "uppercase",
  textAlign: "center",
}))

const Validity = ({ start, end }: { start: string; end: string }) => (
  <Typography
    color="common.electricBlue"
    fontWeight={500}
  >{`Du ${start} - Au ${end}`}</Typography>
)
