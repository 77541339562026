import { Stack, Typography, styled } from "@mui/material"
import {
  FieldSection,
  FormContainer,
  FormMultiSelectField,
  FormNumberField,
  FormRadioLine,
  FormSelectField,
} from "@pharmupp/p3-front-commons"
import { ApiAdherentInfosReferential } from "../../../../../../types"
import { practicalInformationFields as fields } from "./practicalInformation.const"

interface PracticalInformationProps {
  referential: ApiAdherentInfosReferential
}

export const PracticalInformation = ({ referential }: PracticalInformationProps) => {
  const turnOverOptions = referential.turnoverValueRanges
  const areaOptions = referential.pharmacyAreas
  const locationOptions = referential.locations

  return (
    <FormContainer>
      <FieldSection title="Chiffre d’affaire N-1" subtitle="Chiffre d’affaires brut">
        <FormSelectField
          label="Chiffre d'affaires"
          name={fields.lastTurnover}
          options={turnOverOptions}
        />
      </FieldSection>
      <FieldSection title="Surface" subtitle="Surface de l’officine">
        <FormSelectField
          label="Surface"
          name={fields.pharmacyArea}
          options={areaOptions}
        />
      </FieldSection>
      <FieldSection title="Implantation" subtitle="Implantation de l’officine">
        <FormMultiSelectField
          label="Implantation"
          name={fields.location}
          options={locationOptions}
        />
        <FormRadioLine
          label="Parking devant l'officine"
          name={fields.frontParking}
          radios={[
            { label: "Oui", value: true },
            { label: "Non", value: false },
          ]}
        />
      </FieldSection>
      <FieldSection
        title="Ecrans"
        subtitle="Renseigner le nombre d’écrans dans l’officine"
      >
        <Stack direction="row" spacing={3}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Text>Comptoir</Text>
            <ScreenCounterInput label="Qté" name={fields.counterScreens} />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Text>Intérieur</Text>
            <ScreenCounterInput label="Qté" name={fields.interiorScreens} />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Text>Vitrine</Text>
            <ScreenCounterInput label="Qté" name={fields.showcaseScreens} />
          </Stack>
        </Stack>
        {/* TODO: in PHARMUPP-578 */}
        {/* <Link href="todo" underline="always">
          Je suis intéressé par des écrans
        </Link> */}
      </FieldSection>
    </FormContainer>
  )
}

const ScreenCounterInput = styled(FormNumberField)({
  width: "80px",
})

const Text = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  // @ts-ignore
  color: theme.palette.grey.light,
}))
