import { Box, IconButton, Stack, styled } from "@mui/material"
import { Check, X } from "react-feather"

export const SelectedOption = ({
  label,
  onDelete,
}: {
  label: string
  onDelete: () => void
}) => (
  <Container>
    <Stack direction="row" alignItems="center" gap={0.5}>
      <Check size="16px" />
      <span>{label}</span>
      <DeleteBtn onClick={() => onDelete()} />
    </Stack>
  </Container>
)

const Container = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.palette.primary.main}`,
  padding: theme.spacing(0.5, 1),
  paddingRight: 0,
  color: "#7099e1",
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 500,
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  height: "25px",
}))

const DeleteBtn = styled(IconButton)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "transparent",
  },
  "& svg": {
    color: theme.palette.primary.dark2,
    height: theme.typography.pxToRem(15),
    width: theme.typography.pxToRem(15),
    "&:hover": {
      color: theme.palette.primary.dark1,
    },
  },
}))
DeleteBtn.defaultProps = {
  children: <X />,
}
