import {
  ReferentialCode,
  ReferentialValue,
  api,
  usePageSize,
  useQuery,
  useResourceListApi,
} from "@pharmupp/p3-front-commons"

export const useCatalogsApi = () => {
  // REFERENTIAL
  const { data: referential } = useQuery<
    ApiCatalogReferential,
    unknown,
    CatalogReferential
  >({
    queryKey: ["offers", "catalogs", "referential"],
    queryFn: () => api.get("/api/offers/referential/catalogs"),
    select: selectReferential,
  })

  // LIST
  const pageSizeApi = usePageSize({
    defaultValue: 50,
    storagePrefix: "catalog",
  })
  const listApi = useResourceListApi<ApiListProduct, ListProduct>({
    queryKey: ["offers", "catalogs", "list"],
    resourceEndpoint: "/api/offers/catalogs",
    select: selectListProduct,
    rowsPerPage: pageSizeApi.pageSize,
    routeStateEnabled: true,
  })

  return {
    referential,
    ...listApi,
    ...pageSizeApi,
  }
}

export type CatalogChannel = "COALIA" | "FDL" | "PHARMAT" | "SAGITTA"

export const channelLabel: Record<CatalogChannel, string> = {
  COALIA: "COALIA",
  FDL: "FDL",
  PHARMAT: "My Pharmat",
  SAGITTA: "Ma boutique",
}

export const shortChannelLabel: Record<CatalogChannel, string> = {
  COALIA: "C",
  FDL: "FDL",
  PHARMAT: "P",
  SAGITTA: "B",
}
export const channelOptions: { label: string; code: CatalogChannel }[] = [
  { label: channelLabel.FDL, code: "FDL" },
  { label: channelLabel.COALIA, code: "COALIA" },
  { label: channelLabel.PHARMAT, code: "PHARMAT" },
  { label: channelLabel.SAGITTA, code: "SAGITTA" },
]

export interface ApiListProduct {
  id: number
  laboratoryName: string
  laboratory: { cerpCode: string; id: number; name: string }
  ean: number
  label: string
  grossPrice: number
  /* Net price */
  negociatedPrice: number
  returnedPrice: number
  discountRate: number
  threshold: number
  lastUpdateDate: string
  cip: number
  lastSyncTime: string
  sourceId?: number | null
  source: CatalogChannel
  offersCount: number
  /** Set only if offersCount === 1  */
  offerCerpRef?: number
}

export type ListProduct = ApiListProduct & { hasOffer: boolean }

export const selectListProduct = (rawProducts: ApiListProduct[]): ListProduct[] =>
  rawProducts.map((rawProduct) => ({
    ...rawProduct,
    hasOffer: rawProduct.offersCount > 0,
  }))

// REFERENTIAL

export interface ApiCatalogReferential {
  categoricalChallenges: ReferentialCode<string>[]
  laboratories: { cerpCode: string; id: number; name: string }[]
  sources: string[]
}

interface CatalogReferential
  extends Pick<ApiCatalogReferential, "categoricalChallenges" | "sources"> {
  laboratories: ReferentialValue<number>[]
}

const selectReferential = (
  referential: ApiCatalogReferential,
): CatalogReferential => ({
  ...referential,
  laboratories: referential.laboratories
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((lab) => ({
      label: lab.name,
      value: lab.id,
    })),
})
