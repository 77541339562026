import * as Sentry from "@sentry/react"
import * as React from "react"
import { createRoot } from "react-dom/client"
import TagManager from "react-gtm-module"
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom"
import App from "./App"

if (window.CONTEXT.sentryDsn) {
  Sentry.init({
    dsn: window.CONTEXT.sentryDsn,
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    environment: window.CONTEXT.environment,
    release: import.meta.env.VITE_APP_VERSION,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  })
}

if (window.CONTEXT.gtmId) {
  TagManager.initialize({
    gtmId: window.CONTEXT.gtmId,
    ...(window.CONTEXT.gtmAuth && { auth: window.CONTEXT.gtmAuth }),
    ...(window.CONTEXT.gtmPreview && { preview: window.CONTEXT.gtmPreview }),
  })
}

async function setupMockServer() {
  // Needed to avoid including mocks in build
  if (!import.meta.env.PROD) {
    if (
      import.meta.env.VITE_SEMI_MOCKED === "true" ||
      import.meta.env.VITE_MOCKED === "true"
    ) {
      const { worker } = await import("./mocks/browser")
      return worker.start({
        onUnhandledRequest: "bypass",
      })
    }
  }
  return Promise.resolve()
}

setupMockServer()
  .then(() => {
    const container = document.getElementById("root")

    if (container) {
      const root = createRoot(container)
      root.render(<App />)
    }
  })
  .catch((e) => console.error(e))
