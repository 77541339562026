import { Box, Divider, Stack, Typography, useTheme } from "@mui/material"
import {
  Doughnut,
  DoughnutProps,
  PaperContainer,
  PaperContainerTitle,
  Percentage,
} from "@pharmupp/p3-front-commons"
import { useAdhLabReferentialApi } from "@pharmupp/p3-laboratory-domain"
import { useMemo } from "react"
import { CheckCircle, EyeOff } from "react-feather"
import { MarketShareData } from "../../kpi.models"
import { InfoTooltip, NoData, OspharmCTA, SectionTitle, SyncDate } from "../layouts"

interface MarketSharesProps {
  loading: boolean
  marketShares?: MarketShareData[]
  lastSyncDate?: string
}

export const MarketShares = ({
  marketShares,
  lastSyncDate,
  loading,
}: MarketSharesProps) => {
  const { referential } = useAdhLabReferentialApi()

  const labCategoryCodeToLabel = useMemo(
    () =>
      Object.fromEntries(
        (referential?.categories || []).map((category) => [
          category.code,
          category.label,
        ]),
      ),
    [referential],
  )

  const formattedMarketShares = useMemo(
    () =>
      (marketShares || []).map((marketShare) => ({
        ...marketShare,
        category:
          labCategoryCodeToLabel?.[marketShare.marketShareCategory] ||
          marketShare.marketShareCategory,
        laboratorySplits: (marketShare.laboratorySplits || [])
          .sort((a, b) => {
            if (!a.categoricalChallenge && b.categoricalChallenge) {
              return 1
            }
            if (a.categoricalChallenge && !b.categoricalChallenge) {
              return -1
            }
            return 0
          })
          .map((split) => ({
            label: split.name,
            value: split.splitPercentage,
            color: split.categoricalChallenge ? COLORS.PURPLE : COLORS.GREY,
          })),
      })),
    [labCategoryCodeToLabel, marketShares],
  )

  return (
    <PaperContainer loading={loading}>
      <Stack spacing={1} divider={<Divider />}>
        <Stack spacing={3} mb={1}>
          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <PaperContainerTitle mb={[2, 2, 2, 4, 4]}>
              Parts de marché catégorielles
            </PaperContainerTitle>

            <Box display="flex" gap={4}>
              <Stack
                component="span"
                direction="row"
                spacing={2}
                alignSelf="flex-start"
              >
                <Legend
                  color={COLORS.PURPLE}
                  title="Laboratoires partenaires avec Challenge catégoriel"
                />
                <Legend color={COLORS.GREY} title="Autres laboratoires" />
              </Stack>
              <InfoTooltip title="Les PDM sont exprimées sur la base des ventes des 4 derniers mois échus (ventes CA HT pour toutes les catégories hors Génériques, Ventes Génériques exprimées sur la base de Qté x Prix Catalogue HT pour les Génériques)" />
            </Box>
          </Stack>

          <>
            {formattedMarketShares.length ? (
              <>
                <SectionTitle
                  title="Données sur les 4 derniers mois échus à J-7"
                  caption="Suivi sur la base des Challenges 2023"
                />

                <Box
                  display="grid"
                  gridTemplateColumns={{
                    sm: "repeat(3, 1fr)",
                    md: "repeat(4, 1fr)",
                  }}
                  columnGap={[4, 8, 8, 10, 10]}
                  rowGap={4}
                >
                  {formattedMarketShares.map((marketShare) => (
                    <MarketShareDoughnut
                      key={marketShare.marketShareCategory}
                      title={marketShare.category}
                      unlocked={marketShare.unlocked}
                      percentage={marketShare.challengePercentage}
                      splits={marketShare.laboratorySplits}
                    />
                  ))}
                </Box>
              </>
            ) : (
              <NoData />
            )}
            <SyncDate date={lastSyncDate} />
          </>
        </Stack>

        <OspharmCTA mt={4} />
      </Stack>
    </PaperContainer>
  )
}

const COLORS = {
  GREY: "#f4f5fa",
  PURPLE: "#c4bbf6",
}

const Legend = ({ color, title }: { color: string; title: string }) => (
  <Stack direction="row" alignItems="center" spacing={1} height="26px">
    <Box width="20px" height="20px" borderRadius="20px" bgcolor={color} />
    <Typography fontSize={(theme) => theme.typography.pxToRem(12)}>
      {title}
    </Typography>
  </Stack>
)

const MarketShareDoughnut = ({
  title,
  unlocked,
  percentage,
  splits,
}: {
  title: string
  unlocked: boolean
  percentage: number
  splits: DoughnutProps["data"]
}) => {
  const { palette, typography } = useTheme()
  return (
    <Stack textAlign="center" alignItems="center" spacing={2}>
      <Typography
        fontSize={typography.pxToRem(13)}
        fontWeight="bold"
        textTransform="uppercase"
      >
        {title}
      </Typography>
      <Doughnut data={splits} height="270px">
        <Stack alignItems="center" justifyContent="center">
          {percentage === 0 && splits.length === 0 ? (
            <Stack
              alignItems="center"
              justifyContent="center"
              spacing={2}
              color="grey.light"
            >
              <EyeOff size="24px" />
              <Typography fontSize={typography.pxToRem(12)}>
                Aucun résultat
              </Typography>
            </Stack>
          ) : (
            <>
              <Typography
                fontSize={typography.pxToRem(30)}
                fontWeight={600}
                color={palette.primary.dark1}
              >
                <Percentage value={percentage} suffix="%" />
              </Typography>

              {unlocked ? (
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  color={palette.success.main}
                >
                  <CheckCircle size={typography.pxToRem(18)} />
                  <Typography fontSize={typography.pxToRem(12)}>
                    Challenge
                  </Typography>
                </Stack>
              ) : (
                <Box>
                  <Stack color={palette.common.orange} spacing={0}>
                    <Stack direction="row" alignItems="baseline">
                      <Typography
                        fontSize={typography.pxToRem(20)}
                        lineHeight={typography.pxToRem(12)}
                      >
                        ...
                      </Typography>
                      <Typography fontSize={typography.pxToRem(12)}>
                        Challenge
                      </Typography>
                    </Stack>
                    <Typography fontSize={typography.pxToRem(12)}>
                      en cours
                    </Typography>
                  </Stack>
                </Box>
              )}
            </>
          )}
        </Stack>
      </Doughnut>
    </Stack>
  )
}
