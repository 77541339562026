import { buildTheme } from "../const"

export const adherentTheme = buildTheme(({ palette }) => ({
  palette: {
    header: {
      containedButton: {
        backgroundColor: palette.primary.main,
        primaryColor: palette.common.blueGreen2,
        secondaryColor: palette.common.white,
        hover: {
          backgroundColor: palette.primary.dark2,
        },
      },
      gradient:
        "linear-gradient(-180deg, rgb(23, 62, 130) 0%, rgb(40, 84, 161) 100%)",
    },
    background: {
      default: "#f7f7f7",
    },
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          "&.header-menu .MuiTab-root:hover": {
            color: palette.primary.light1,
          },
          "&.header-menu .MuiTab-root.Mui-selected": {
            color: palette.common.blueGreen2,
          },
        },
      },
    },
  },
}))
