export const TakePictureIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="37" height="43">
    <g id="Page-1" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g
        id="Operations-marketing---Justification---Desktop-Copy-2"
        fill="#951B81"
        fillRule="nonzero"
        transform="translate(-106 -621)"
      >
        <g id="noun-smartphone-1007448" transform="translate(106 621)">
          <path
            id="Shape"
            d="M36.76 25.223s-2.422-2.54-3.952-5.423c-2.082-3.874-2.422-4.218-4.462-5.811-.85-.689-2.04-1.593-3.91-3.228-.425-.388-1.105-.689-1.997-.904V4.175C22.439 1.894 20.61 0 18.317 0H7.055C4.802 0 2.932 1.85 2.932 4.175v5.338A3.023 3.023 0 0 0 0 12.526c0 1.162.637 2.152 1.572 2.625A3.034 3.034 0 0 0 0 17.82c0 1.162.637 2.152 1.572 2.625A3.034 3.034 0 0 0 0 23.115c0 1.204.722 2.237 1.742 2.71a2.954 2.954 0 0 0-1.402 2.54 2.95 2.95 0 0 0 2.72 2.97 4.074 4.074 0 0 0 3.995 3.186h4.844c1.998 1.85 5.568 4.304 13.175 6.155v1.42c0 .517.382.904.892.904s.893-.387.893-.904V40.03a.877.877 0 0 0-.68-.86c-5.823-1.378-9.222-3.057-11.39-4.563h3.57c2.21 0 4.037-1.808 4.122-4.046 2.805 3.615 6.545 4.95 6.758 5.036.085.043.17.043.297.043a.868.868 0 0 0 .85-.603c.17-.473-.085-.99-.552-1.12-.043 0-5.738-2.065-7.82-7.919-.085-.215-.213-.387-.425-.473-.085-.044-2.167-1.163-4.462-1.163h-.17c-1.36.216-5.313.474-5.78-1.463-.213-.947-.255-.99 1.487-1.55.468-.172 1.02-.344 1.615-.602.298-.13.553-.302.765-.43.425-.302.808-.56 2.04-.56h5.227c3.783 0 6.885 2.884 6.928 2.927.34.344.935.301 1.232-.043.34-.345.297-.947-.043-1.249-.127-.129-3.57-3.314-7.947-3.4v-6.241c.51.172.723.301.85.43 1.913 1.679 3.103 2.626 3.995 3.314 1.828 1.42 1.998 1.55 3.995 5.252 1.615 3.056 4.207 5.768 4.207 5.768.17.172.425.3.638.3a.834.834 0 0 0 .595-.257c.297-.388.34-.947 0-1.335ZM2.933 29.571c-.467-.173-.85-.603-.85-1.163s.34-.99.85-1.162v2.325Zm0-5.252a1.217 1.217 0 0 1-1.147-1.205c0-.646.51-1.205 1.147-1.205v2.41Zm0-5.294a1.217 1.217 0 0 1-1.147-1.205c0-.646.51-1.205 1.147-1.205v2.41Zm0-5.294a1.217 1.217 0 0 1-1.147-1.205c0-.646.51-1.206 1.147-1.206v2.41Zm11.05 18.25a.292.292 0 0 1-.298.301h-2.04a.292.292 0 0 1-.297-.301v-.258c0-.172.127-.302.297-.302h2.04c.17 0 .298.13.298.302v.258Zm.042-13.128c-.17.129-1.487.645-1.912.775-1.615.516-3.272 1.076-2.677 3.658.892 3.745 6.884 2.927 7.734 2.798 1.36 0 2.678.56 3.272.818.085.215.17.43.298.646v1.893H4.717V4.046h15.98v13.903h-3.613c-1.785.043-2.507.516-3.06.904Z"
          />
        </g>
      </g>
    </g>
  </svg>
)
