import { Box } from "@mui/material"
import { UserCheck } from "react-feather"
import { OperationSection } from "../../model"
import { ClockWarning, Megaphone, PhotoClick, StarPlus } from "../icons"
import { StatusCard } from "./StatusCard"
import { useMyMarketingOpSummary } from "./useMyMarketingOpSummary"

export const AdhMarketingOpSummary = ({
  listLink,
  year = new Date().getFullYear(),
  adherentId,
  compact = false,
}: {
  listLink: string
  adherentId?: string
  year?: number
  compact?: boolean
}) => {
  const { operationsSheet: summary, isLoading } = useMyMarketingOpSummary({
    year,
    id: adherentId,
  })

  return (
    <Box display="grid" gridTemplateColumns="repeat(5, 1fr)" gap={4}>
      <StatusCard
        title="Opérations"
        Icon={<Megaphone />}
        count={summary?.ALL.count}
        link={`${listLink}?filters%5Byear%5D=${year}`}
        dividerAccent
        isLoading={isLoading}
        compact={compact}
      />
      <StatusCard
        title="Inscriptions"
        Icon={<UserCheck />}
        count={summary?.REGISTERED.count}
        variant="info"
        link={`${listLink}?filters%5Byear%5D=${year}&filters%5Bsection%5D=${OperationSection.REGISTERED}`}
        isLoading={isLoading}
        compact={compact}
      />
      <StatusCard
        title="Auxquelles s’inscrire"
        Icon={<ClockWarning />}
        count={summary?.REGISTRABLE.count}
        variant="info"
        link={`${listLink}?filters%5Byear%5D=${year}&filters%5Bsection%5D=${OperationSection.REGISTRABLE}`}
        isLoading={isLoading}
        compact={compact}
      />
      <StatusCard
        title="À justifier"
        Icon={<PhotoClick />}
        count={summary?.REQUIRES_PROOF.count}
        variant="warning"
        link={`${listLink}?filters%5Byear%5D=${year}&filters%5Bsection%5D=${OperationSection.REQUIRES_PROOF}`}
        isLoading={isLoading}
        compact={compact}
      />
      <StatusCard
        title="Validées"
        Icon={<StarPlus />}
        count={summary?.VALIDATED.count}
        pointsCount={summary?.VALIDATED.pointsCount}
        variant="success"
        link={`${listLink}?filters%5Byear%5D=${year}&filters%5Bsection%5D=${OperationSection.VALIDATED}`}
        isLoading={isLoading}
        divider={false}
        compact={compact}
      />
    </Box>
  )
}
