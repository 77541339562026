import { api, useQuery } from "@pharmupp/p3-front-commons"

export const usePurchasesSummaryApi = ({ labId }: { labId: string }) => {
  const endpoint = `/api/laboratories/adherent/${labId}/purchases/summary`
  const queryKey = ["laboratories", labId, "adherent", "purchases", "summary"]

  // GET
  const { data: purchasesSummary, isLoading } = useQuery({
    queryKey,
    queryFn: () => api.get<ApiPurchasesSummary>(endpoint),
  })

  return { purchasesSummary, isLoading }
}

export type ApiPurchasesSummary = {
  amount: number
}
